/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const _emptySubscription = /* GraphQL */ `
  subscription _emptySubscription {
    _emptySubscription
  }
`;
export const onCalendarEntryParticipationCreated = /* GraphQL */ `
  subscription OnCalendarEntryParticipationCreated($userId: ID!) {
    onCalendarEntryParticipationCreated(userId: $userId) {
      calendarEntry {
        createdAt
        description
        end
        id
        isVirtual
        locationActionParam
        locationActionType
        locationName
        organizationId
        organizationIdStatus
        start
        status
        title
        topicName
        updatedAt
        userId
        userPoolName
      }
      calendarEntryId
      createdAt
      id
      start
      status
      statusUpdateMessage
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userIdStatus
    }
  }
`;
export const onCalendarEntryParticipationDeleted = /* GraphQL */ `
  subscription OnCalendarEntryParticipationDeleted($userId: ID!) {
    onCalendarEntryParticipationDeleted(userId: $userId) {
      calendarEntry {
        createdAt
        description
        end
        id
        isVirtual
        locationActionParam
        locationActionType
        locationName
        organizationId
        organizationIdStatus
        start
        status
        title
        topicName
        updatedAt
        userId
        userPoolName
      }
      calendarEntryId
      createdAt
      id
      start
      status
      statusUpdateMessage
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userIdStatus
    }
  }
`;
export const onConversationUpdated = /* GraphQL */ `
  subscription OnConversationUpdated($id: ID!) {
    onConversationUpdated(id: $id) {
      createdAt
      description
      id
      memberIdsHash
      members {
        nextToken
      }
      messages {
        nextToken
        startedAt
      }
      mostRecentMessage
      mostRecentMessageCreatedAt
      name
      type
      updatedAt
      userId
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($conversationId: ID!) {
    onCreateMessage(conversationId: $conversationId) {
      _deleted
      _lastChangedAt
      _version
      author {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      authorId
      content
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isSent
      members
      sotName
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($conversationId: ID!) {
    onDeleteMessage(conversationId: $conversationId) {
      _deleted
      _lastChangedAt
      _version
      author {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      authorId
      content
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isSent
      members
      sotName
      updatedAt
    }
  }
`;
export const onMeetingInviteChanged = /* GraphQL */ `
  subscription OnMeetingInviteChanged($id: ID!) {
    onMeetingInviteChanged(id: $id) {
      created
      createdAt
      id
      invitationType
      invitee {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      inviteeId
      inviter {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      inviterId
      meeting {
        createdAt
        end
        id
        start
        updatedAt
      }
      meetingId
      meetingTitle
      status
      updatedAt
    }
  }
`;
export const onMeetingInviteReceived = /* GraphQL */ `
  subscription OnMeetingInviteReceived($inviteeId: ID!) {
    onMeetingInviteReceived(inviteeId: $inviteeId) {
      created
      createdAt
      id
      invitationType
      invitee {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      inviteeId
      inviter {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      inviterId
      meeting {
        createdAt
        end
        id
        start
        updatedAt
      }
      meetingId
      meetingTitle
      status
      updatedAt
    }
  }
`;
export const onMeetingRoomAttendanceCreated = /* GraphQL */ `
  subscription OnMeetingRoomAttendanceCreated($chimeMeetingId: ID!) {
    onMeetingRoomAttendanceCreated(chimeMeetingId: $chimeMeetingId) {
      chimeMeetingId
      createdAt
      id
      updatedAt
    }
  }
`;
export const onMeetingRoomAttendanceDeleted = /* GraphQL */ `
  subscription OnMeetingRoomAttendanceDeleted($chimeMeetingId: ID!) {
    onMeetingRoomAttendanceDeleted(chimeMeetingId: $chimeMeetingId) {
      chimeMeetingId
      createdAt
      id
      updatedAt
    }
  }
`;
export const onMessageCreated = /* GraphQL */ `
  subscription OnMessageCreated($conversationId: ID!) {
    onMessageCreated(conversationId: $conversationId) {
      _deleted
      _lastChangedAt
      _version
      author {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      authorId
      content
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isSent
      members
      sotName
      updatedAt
    }
  }
`;
export const onNotificationCreated = /* GraphQL */ `
  subscription OnNotificationCreated($userId: ID!) {
    onNotificationCreated(userId: $userId) {
      actionParam
      actionType
      content
      createdAt
      displayGroup
      id
      read
      type
      updatedAt
      userId
      userIdDisplayGroup
    }
  }
`;
export const onRaisedHandCreated = /* GraphQL */ `
  subscription OnRaisedHandCreated($chimeMeetingId: ID!) {
    onRaisedHandCreated(chimeMeetingId: $chimeMeetingId) {
      chimeMeetingId
      createdAt
      id
      updatedAt
    }
  }
`;
export const onRaisedHandDeleted = /* GraphQL */ `
  subscription OnRaisedHandDeleted($chimeMeetingId: ID!) {
    onRaisedHandDeleted(chimeMeetingId: $chimeMeetingId) {
      chimeMeetingId
      createdAt
      id
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($conversationId: ID!) {
    onUpdateMessage(conversationId: $conversationId) {
      _deleted
      _lastChangedAt
      _version
      author {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      authorId
      content
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isSent
      members
      sotName
      updatedAt
    }
  }
`;
export const onUpdateUnreadCounterById = /* GraphQL */ `
  subscription OnUpdateUnreadCounterById($id: ID!) {
    onUpdateUnreadCounterById(id: $id) {
      contacts
      conversations
      createdAt
      id
      notifications
      requests
      schedules
      updatedAt
    }
  }
`;
export const onUpdateUserById = /* GraphQL */ `
  subscription OnUpdateUserById($id: ID!) {
    onUpdateUserById(id: $id) {
      conversations {
        nextToken
      }
      createdAt
      id
      incomingMeetings {
        nextToken
      }
      lastConnected
      name
      outgoingMeetings {
        nextToken
      }
      pictureUrl
      presenceStatus
      updatedAt
    }
  }
`;
export const onUserConversationCreatedByConversation = /* GraphQL */ `
  subscription OnUserConversationCreatedByConversation($conversationId: ID!) {
    onUserConversationCreatedByConversation(conversationId: $conversationId) {
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isChatOpen
      isMuted
      lastReadMessageCreatedAt
      mostRecentMessageCreatedAt
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
    }
  }
`;
export const onUserConversationDeleted = /* GraphQL */ `
  subscription OnUserConversationDeleted($userId: ID!) {
    onUserConversationDeleted(userId: $userId) {
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isChatOpen
      isMuted
      lastReadMessageCreatedAt
      mostRecentMessageCreatedAt
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
    }
  }
`;
export const onUserConversationDeletedByConversation = /* GraphQL */ `
  subscription OnUserConversationDeletedByConversation($conversationId: ID!) {
    onUserConversationDeletedByConversation(conversationId: $conversationId) {
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isChatOpen
      isMuted
      lastReadMessageCreatedAt
      mostRecentMessageCreatedAt
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
    }
  }
`;
export const onUserConversationUpdated = /* GraphQL */ `
  subscription OnUserConversationUpdated($userId: ID!) {
    onUserConversationUpdated(userId: $userId) {
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isChatOpen
      isMuted
      lastReadMessageCreatedAt
      mostRecentMessageCreatedAt
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
    }
  }
`;
