import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import _, { orderBy } from "lodash"
import moment, { Moment } from "moment"
import queryString from "query-string"
import { RefObject, useCallback, useEffect, useRef, useState } from "react"
import * as React from "react"
import { Col, Form, Row } from "react-bootstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import InView, { useInView } from "react-intersection-observer"
import { useHistory, useRouteMatch } from "react-router-dom"
import Select, { ActionMeta, OptionTypeBase, ValueType } from "react-select"
import styled from "styled-components"
import { CalendarEntryParticipationStatus } from "../../API"
import { syncFavorites } from "../../backendServices/FavoritesServices"
import { Category, EventDate } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { NextPageLoader } from "../../communicationArea/CommunicationArea"
import { useAppState } from "../../globalStates/AppState"
import { useCategoriesState } from "../../globalStates/CategoriesState"
import { useFavoriteState } from "../../globalStates/Favorites"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { useUserRestrictedAreaAccess } from "../../globalStates/UserRestrictedAreaAccess"
import { mySchedulePageRoute } from "../../navigationArea/RoutePaths"
import BackendError from "../../ui/BackendError"
import CenteredLoader from "../../ui/CenteredLoader"
import { IconArrowHeadDown, IconCalendarEntry, IconClose, IconProgramArrowLeft, IconProgramArrowRight } from "../../ui/Icons"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import SearchBar from "../../ui/SearchBar"
import useWindowDimensions, { WindowDimensions } from "../../ui/WindowDimensionsHook"
import {
    checkLatestTime,
    getIndexOfInitialDayToShow,
    getIndexOfInitialDayToShowIgnoreDefault,
    isToday,
    showCurrentTimeMarkerHelper
} from "../../utils/DateUtils"
import { device, MobileVersionContainer, DesktopVersionContainer } from "../../utils/Device"
import { isLivePhase, isOnboardingPhase, isPostEventPhase } from "../../utils/EventPhaseChecker"

import EmptyTile from "../reception/EmptyTile"
import EventDateEntry, { compareEventDatesMeetings, ListEventDateEntryColumn } from "./eventDateEntry/EventDateEntry"
import { DayData, ScheduleHelper } from "./ScheduleHelper"
import { DatePickerRootTemplate } from "../detailPages/components/StyledComponents"
import { ProgramHistoryState, MyScheduleTypeFilter, ProgramPageTab, CategoryFilter, StageFilter } from "./ProgramPageContent"
import { getTileViewMinReqWidth, PagesUsingTileView } from "../../globalStates/TileViewConfig"
import { loadEventDateDates, loadEventDateList, loadRoundTableList } from "../../backendServices/EventdateServices"
import { CalendarEntry, CalendarEntrySortType, getCalendarEntries } from "../../backendServices/GraphQLServices"
import { DisagreeButton, ModalRoot, SubmitButtonContainer, SubmitButtonNew } from "../../ui/modals/SayHelloModal"
import { useGuestModal } from "../../ui/modals/GuestModal"
import { getIamPartOf } from "../../globalStates/IAmPartOf"
import { SelectThemeCustom } from "../../conference/components/settings/Settings"
import { Filter, Stage } from "./ProgramBranding"
import { CustomSelectOption, CustomSelectPlaceholder } from "../GlobalSearchResultPageContent"
import { ResetFilterButton } from "../detailPages/components/ResetFilterButtonComponent"
import CalendarEntryModal2 from "../../ui/modals/calendarEntry/CalendarEntryModal2"
import { trackSearch } from "../../utils/GTMTracking"
import { CalendarEntryModalViewMode } from "../../ui/modals/calendarEntry/ModalFunctions"
import { OnlyBookmarksButton } from "../business/onlyBookmarksButton/OnlyBookmarksButton"

const FilterBarRoot = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 25px;
    flex-wrap: wrap;

    @media ${device.mobile} {
        padding-left: 20px;
        padding-right: 15px;
        gap: 17px;
    }
`

interface FilterButtonsComponentProps {
    onResetFilterButtonClick: () => void
    resetFilterVisible: boolean
}

const FilterButtonsComponent: React.FunctionComponent<FilterButtonsComponentProps> = (props: FilterButtonsComponentProps) => {
    const content: JSX.Element = (
        <>
            <ResetFilterButton
                onResetFilterButtonClick={() => props.onResetFilterButtonClick()}
                visibility={props.resetFilterVisible}
                text={branding.businessAreaPageContent.resetFilterButtonText}
                buttonHeight="38px"
            />
        </>
    )
    return (
        <>
            <DesktopVersionContainer>
                <FilterBarRoot style={{ padding: 0 }}>{content}</FilterBarRoot>
            </DesktopVersionContainer>
            <MobileVersionContainer>
                <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                    <FilterBarRoot style={{ padding: 0 }}>{content}</FilterBarRoot>
                </Row>
            </MobileVersionContainer>
        </>
    )
}

const ScheduleRoot = styled.div<{ isFavourite?: boolean }>`
    font-family: ${branding.font1};
    position: relative;
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
    ${(props) => props.isFavourite && "width: 100%; margin-right: 30px;"}
    padding: 0 25px 20px 25px;

    @media ${device.mobile} {
        padding-left: 20px;
        padding-right: 15px;
    }
`

const FiltersRoot = styled.div`
    display: flex;
    align-items: start;
    margin-left: 0px;
    flex-wrap: wrap;
    gap: 10px;

    @media ${device.mobile} {
        gap: 4.6vw;
    }

    @media (width: 414px) and (height: 736px) {
        gap: 5.5vw;
    }
`
const Revealer = styled.div`
    position: absolute;
    width: 1px;
    height: 1px;
    bottom: 300px;
`

const ScheduleGroupHeaderRoot = styled.div<{ visible: boolean }>`
    display: ${(props) => (props.visible ? "block" : "none")};

    @media ${device.mobile} {
        box-shadow: ${branding.primaryScrollDarkShadowTTB};
        margin-left: -20px;
        padding-left: 20px;
        margin-right: -15px;
        padding-right: 15px;
        margin-top: -25px;
        height: auto;
        min-height: 180px;
    }
`

const ScheduleGroupHeaderContainer = styled(Row)<{ hideOnScroll?: boolean }>`
    margin: 20px 0 40px 0;
    font-family: ${branding.font1};

    @media ${device.mobile} {
        margin-bottom: 0px;
    }

    &.mySchedule {
        margin: 0;
        padding-bottom: 15px;
        // padding-top: 25px;
        box-shadow: ${(props) => (!props.hideOnScroll ? "none" : "0px 15px 10px -8px rgba(0,0,0,0.16)")};
        position: relative;
        z-index: 302;
    }
`

const ArrowButtonGroup = styled.div`
    border-radius: 5px;
    display: flex;
    background-color: #fff;
    height: 35px;
`
const DatePickerRoot = styled(DatePickerRootTemplate)`
    display: flex;
    align-items: center;
    height: 20px;
    margin-top: 8px;

    & .react-datepicker-popper > div {
        /* This fixes the problem with closing the 
          datepicker by clicking
          manywhere on the DOM */
        width: 0;
    }

    & .react-datepicker {
        border: 1px solid #727272;
        right: 82px;
        top: 10px;
    }

    & .react-datepicker__day-name {
        width: 30px;
        margin: 3px 8px;
    }

    & .react-datepicker__day {
        width: 30px;
        margin: 1px 8px;
    }

    & .datepicker__triangle {
        display: none !important;
    }

    & .datepicker__triangle::before {
        display: none !important;
    }

    & .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
        display: none !important;
    }

    & .react-datepicker__triangle {
        position: absolute !important;
        left: 50% !important;
        border-bottom-color: #fff !important;
    }

    & .react-datepicker__triangle::before {
        border-bottom-color: #727272 !important;
    }
`

const NowButton = styled.div`
    min-width: 100px;
    max-width: 400px;
    padding: 0 20px;
    margin-left: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: ${branding.programSchedule.nowButtonBgColor ?? "#FFF"};
    color: ${branding.programSchedule.nowButtonColor ?? "#000"};
    font-family: ${branding.font1};
    font-size: 14px;
    font-weight: normal;
    border-radius: 5px;

    &.disabled {
        pointer-events: none;
        opacity: 0.3;
        border: none;
    }

    @media ${device.mobile} {
        display: none;
    }
`

export const ArrowButton = styled.div`
    cursor: pointer;
    padding: 5px 15px;

    :nth-child(1) {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    :nth-child(2) {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`

const MobileSearchRoot = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 25px;
    flex-wrap: wrap;

    @media ${device.mobile} {
        padding: 0;
        gap: 15px;
    }
`

const BookmarkButtonCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
        color: ${branding.primaryColor};
    }
`

const SearchBarContainer = styled.div`
    margin-top: "1px";
    svg {
        color: ${branding.primaryColor};
    }

    @media (max-width: 1200px) {
        & div.MuiTextField-root {
            width: 200px;
        }
    }
`

const styledSelectStyles = {
    control: (provided: any, state: any) => ({
        border: state.isFocused ? "1px solid #000" : "1px solid #c9c9c9",
        borderRadius: "5px",
        display: "flex"
    })
}

const StyledSelect = styled(Select)`
    width: 185px;
    z-index: 6;
    font-size: 12px;
    z-index: 110;

    @media ${device.mobile} {
        width: 43vw;
    }

    @media (max-width: 380px) {
        width: 42vw;
    }

    &-menu {
        z-index: 1000;
    }

    .mr-2 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
`

const isNowButtonEnabled = (days: Moment[]) => {
    const liveEventsToday = days.filter((day) => isToday(day))
    return isLivePhase && liveEventsToday && liveEventsToday.length > 0
}

interface ScheduleProps {
    category?: String
    onlyFavorites?: boolean
    addBannerHeight?: number
    guestBannerHeight: number
    onScroll?: Function
    dateColumnsDays?: moment.Moment[]
    mySchedule?: boolean
    removeOnBookmarkClick?: boolean
    roundScroller?: boolean
    isInMyFairPage?: boolean
    listViewMode: boolean
    filtersVisible?: boolean
    activeTab?: string
    activeSubCategory?: string
    reloadScheduleList?: boolean
    setReloadScheduleList?: (value: boolean) => void
    setItemsCount?: (value: number) => void
}

function buildFilterlist(
    props: ScheduleProps,
    isBookmarked: boolean,
    favorites: string,
    searchParam: string,
    stage: string,
    badgeFilterValues: CategoryFilter[],
    categoryFilterValues: CategoryFilter[],
    secondCategoryFilterValues: CategoryFilter[]
) {
    const parsedSearchParam = searchParam.replaceAll("_", "\\_")
    const filterlist = isBookmarked && favorites ? [favorites, parsedSearchParam] : ["entity_evtd", parsedSearchParam]
    const baseCategoriesFilter =
        props.activeTab === ProgramPageTab.SCHEDULE
            ? branding.programSchedule.mainProgramCategories
            : branding.programPageContent.additionalScheduleTabs.find((x) => x.value === props.activeTab)?.categories

    if (baseCategoriesFilter && baseCategoriesFilter.length > 0) {
        filterlist.push(baseCategoriesFilter.map((category) => `cat_${category}$OR`).join(", "))
    }

    if (props.category) filterlist.push(`cat_${props.category}`)

    if (props.activeSubCategory) filterlist.push(`cat_${props.activeSubCategory}`)

    if (branding.programSchedule.showBadgeFilter) filterlist.push(buildCategoriesFilterList(badgeFilterValues))

    if (categoryFilterValues && branding.programSchedule.useCategoriesInsteadOfStages) {
        filterlist.push(buildCategoriesFilterList(categoryFilterValues))
    } else {
        filterlist.push(stage)
    }

    if (branding.programSchedule.showSecondCategoriesFilter)
        filterlist.push(buildCategoriesFilterList(secondCategoryFilterValues))

    return filterlist
}

function buildCategoriesFilterList(categories: CategoryFilter[]) {
    // by including $OR we ensure that the page will show eventdates that are bound to one or more of selected categories
    // we skip first item, because we want to create new category filter group
    // see https://corussoft.atlassian.net/browse/BACKEND-329 for more details of the logic
    if (categories.length > 0)
        return categories
            .map((category, index) => (index === 0 ? `cat_${category.value}` : `cat_${category.value}$OR`))
            .join(", ")

    return ""
}

export function forceListView(windowSize: WindowDimensions): boolean {
    const tileViewMinReqWidth = getTileViewMinReqWidth(PagesUsingTileView.SCHEDULE)
    return windowSize.isMobile || windowSize.width < tileViewMinReqWidth || windowSize.height < 800
}

const Schedule: React.FunctionComponent<ScheduleProps> = (props: ScheduleProps) => {
    const appState = useAppState()
    const userAccessState = useUserRestrictedAreaAccess()
    const history = useHistory()
    const [revealerRef] = useInView()
    const [error, setError] = useState<string | null>(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [eventDates, setEventDates] = useState<EventDate[]>([])
    const [meetings, setMeetings] = useState<CalendarEntry[]>([])
    const [initialMeetings, setInitialMeetings] = useState<CalendarEntry[]>([])
    const [roundTables, setRoundTables] = useState<EventDate[]>([]) // eslint-disable-line
    const [days, setDays] = useState<moment.Moment[]>([])
    const [startResultRow, setStartResultRow] = useState(0)
    const favorites = useFavoriteState()
    const [refreshKey, setRefreshKey] = useState(0)
    const loggedInUserId = useLoggedInState().user()?.profileId
    const [groups, setGroups] = useState<DayData[]>([])
    // eslint-disable-next-line
    const [index, setIndex] = useState((history.location.state as ProgramHistoryState)?.selectedDateIndex ?? 0)
    const [isBookmarked, setIsBookmarked] = useState(props.onlyFavorites ? true : false)
    const [allStages] = useState<{ stage: string; filter: string }[]>([])
    const categories = branding.programSchedule.categoriesFilterList
    const secondCategories = branding.programSchedule.secondCategoriesFilterList
    const additionalTabConfig = branding.programPageContent.additionalScheduleTabs.find((x) => x.value === props.activeTab)
    const badgeFilterVisible =
        props.activeTab === ProgramPageTab.SCHEDULE
            ? branding.programSchedule.showBadgeFilter
            : additionalTabConfig?.categoryFilter.filterVisible
    const badgeFilterList =
        props.activeTab === ProgramPageTab.SCHEDULE
            ? branding.programSchedule.badgeFilterList
            : additionalTabConfig?.categoryFilter.categories

    const [initLoad, setInitLoad] = useState(true)
    const [hideOnScroll, setHideOnScroll] = useState(false)

    const queryParams: any = queryString.parse(window.location.search || window.location.hash.split("?")[1])
    const [filteredByQueryParams, setFilteredByQueryParams] = useState<boolean>(false)

    const { showGuestModal, GuestModal } = useGuestModal()
    const [showRequestMeetingModal, setShowRequestMeetingModal] = useState<boolean>(false)
    const userState = useLoggedInState()

    // search filters
    const [searchParam, setSearchParam] = useState((history.location.state as ProgramHistoryState)?.searchParam ?? "")
    const [triggerFilterWhenSearchParamWasPersisted, setTriggerFilterWhenSearchParamWasPersisted] = useState(false)

    const [stageFilter, setStageFilter] = useState<StageFilter | null>(
        (history.location.state as ProgramHistoryState)?.stageFilter || null
    )
    const [badgeFilterValues, setBadgeFilterValues] = useState<CategoryFilter[] | null>(
        (history.location.state as ProgramHistoryState)?.badgeFilterValues ?? null
    )
    const [firstCategoryFilterValues, setFirstCategoryFilterValues] = useState<CategoryFilter[] | null>(
        (history.location.state as ProgramHistoryState)?.firstCategoryFilterValues ?? null
    )
    const [secondCategoryFilterValues, setSecondCategoryFilterValues] = useState<CategoryFilter[] | null>(
        (history.location.state as ProgramHistoryState)?.secondCategoryFilterValues ?? null
    )

    const [myScheduleTypeFilter, setMyScheduleTypeFilter] = useState<MyScheduleTypeFilter | null>(
        (history.location.state as ProgramHistoryState)?.myScheduleTypeFilter ?? null
    )

    const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false)

    const [ordered, setOrdered] = useState<boolean>(true)

    const [nowButtonEnabled, setNowButtonEnabled] = useState(isNowButtonEnabled(days))
    const [jumpToLive, setJumpToLive] = useState(0)

    const [updatedMeetingKey, setUpdatedMeetingKey] = useState("")

    const [stageRefreshKey, setStageRefreshKey] = useState(0)
    const [countEventdates, setCountEventdates] = useState(0)
    // GUIDE-4668, SCC-642: temp fix 2
    // TODO this will be fixed as part of the Program Widget which will be re-used in the EG project
    const numResultRow = 200

    const stagesOrder =
        props.activeTab === ProgramPageTab.SCHEDULE
            ? branding.programSchedule.stagesOrder
            : branding.programPageContent.additionalScheduleTabs.find(
                  (additionalScheduleTab) => additionalScheduleTab.value === props.activeTab
              )?.stageFilter.stages || []
    const categoriesState = useCategoriesState()
    const [pointsBadgeData, setPointsBadgeData] = useState<Category>()
    const [showResetNowButtonModal, setShowResetNowButtonModal] = useState<boolean>(false)

    const windowSize = useWindowDimensions()
    const meetingDays = branding.eventTiming.meetingDays

    let datePickerRootRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setPointsBadgeData(categoriesState.getCategoryByAlias(branding.programSchedule.pointsBadgeCategoryAlias))

        userAccessState.fetchUserAccessForAllEventDates()
        // eslint-disable-next-line
    }, [])

    const listViewAsDefault =
        props.activeTab === ProgramPageTab.SCHEDULE
            ? branding.programSchedule.listViewAsDefault
            : additionalTabConfig?.listViewAsDefault
    const [showAsList, setShowAsList] = useState(listViewAsDefault ?? false)

    const lang = useLanguageState().getLanguage()
    let date: Date // Selected Date, quite important

    const eventDateFavorites = favorites.get("eventdate", true)

    useEffect(() => {
        setShowAsList(forceListView(windowSize) || props.listViewMode)
        // eslint-disable-next-line
    }, [windowSize, appState.isCommunicationCenterOpen()])

    useEffect(() => {
        if (props.onlyFavorites)
            setEventDates((eventDates) => eventDates.filter((eventDate) => favorites.is("eventdate", eventDate.id)))
    }, [eventDateFavorites]) //eslint-disable-line

    const isMoreDataToLoad = useCallback(() => {
        return countEventdates - numResultRow > startResultRow
    }, [countEventdates, startResultRow])

    useEffect(() => {
        setShowAsList(forceListView(windowSize) || props.listViewMode)
        // eslint-disable-next-line
    }, [props.listViewMode])

    useEffect(() => {
        setIsBookmarked(props.onlyFavorites ?? false)
        if (!initLoad) setStageFilter(null)
        setInitLoad(false)
    }, [props.onlyFavorites]) // eslint-disable-line

    useEffect(() => {
        /* #region  Date selection */

        let dateFromUrlIndex: number | undefined = undefined

        //get index of the date in URL
        if (queryParams && queryParams.day) {
            const dateFromUrlIndexTemp = days.findIndex((day: moment.Moment) => day.isSame(moment(queryParams.day)))

            if (dateFromUrlIndexTemp > -1) {
                dateFromUrlIndex = dateFromUrlIndexTemp
            }
        }

        //get date index from the history state
        const indexFromState =
            history.action === "POP" || history.action === "REPLACE"
                ? (history.location.state as ProgramHistoryState)?.selectedDateIndex
                : undefined

        const indexOfDayToDisplay =
            dateFromUrlIndex !== undefined
                ? dateFromUrlIndex
                : indexFromState !== undefined
                ? indexFromState
                : getIndexOfInitialDayToShow(days, props.mySchedule)

        setIndex(indexOfDayToDisplay)

        /* #endregion */

        /* #region  Stage selection */

        //added because of press and media page (need to show only press conference events)
        if (queryParams.pressStage) {
            setStageFilter({
                stage: branding.pressMediaPageContent.pressEventStage.stage,
                filter: branding.pressMediaPageContent.pressEventStage.filter
            })
        }
        //immediately filter by the stage that has an ID defined in the URL
        else if (queryParams.stage) {
            const stageFromQueryParam: Stage | undefined = stagesOrder.find((stage: Stage) => stage.id === queryParams.stage)

            if (stageFromQueryParam !== undefined) {
                setStageFilter({
                    stage: stageFromQueryParam.stage,
                    filter: stageFromQueryParam.stage
                })
            }
        }

        setFilteredByQueryParams(true)

        /* #endregion */
    }, [days]) // eslint-disable-line

    useEffect(() => {
        setStartResultRow(0)
    }, [
        isBookmarked,
        index,
        stageFilter,
        searchParam,
        showAsList,
        badgeFilterValues,
        appState.timezone,
        firstCategoryFilterValues,
        secondCategoryFilterValues
    ]) // eslint-disable-line

    useEffect(() => {
        setStageRefreshKey(stageRefreshKey + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stageFilter, searchParam, appState.timezone, firstCategoryFilterValues, secondCategoryFilterValues])

    useEffect(() => {
        allStages.splice(0, allStages.length)

        if (myScheduleTypeFilter) {
            setMyScheduleTypeFilter({
                value: myScheduleTypeFilter?.value,
                label:
                    myScheduleTypeFilter?.value === "eventdate"
                        ? branding.mySchedule.dropdownFilterLiveSession
                        : branding.mySchedule.dropdownFilterMeeting
            })
        }
    }, [lang, props.activeTab]) // eslint-disable-line

    useEffect(() => {
        setError(null)
        setIsLoaded(false)
        const categories =
            props.activeTab === ProgramPageTab.SCHEDULE
                ? branding.programSchedule.mainProgramCategories
                : branding.programPageContent.additionalScheduleTabs.find(
                      (additionalScheduleTab) => additionalScheduleTab.value === props.activeTab
                  )?.categories
        const params: any = {}
        if (categories && categories.length > 0) params.categories = categories.join(",")

        loadEventDateDates(params)
            .then((resp) => {
                if (resp.dates?.length > 0) {
                    let datesTemp: any = []

                    resp.dates.forEach((day) => {
                        datesTemp.push(day)
                    })

                    if (props.mySchedule) {
                        meetingDays.forEach((day) => {
                            datesTemp.push(day.split("T")[0])
                        })

                        datesTemp = _.uniq(datesTemp.sort())
                    }

                    const daysMoment = datesTemp.map((date: any) => moment(date))
                    setDays(daysMoment)
                    setNowButtonEnabled(isNowButtonEnabled(daysMoment))
                } else {
                    setError("no dates found")
                }
            })
            .catch((e: Error) => {
                return setError(e.message)
            })

        // eslint-disable-next-line
    }, [props.activeTab, props.activeSubCategory])

    async function loadCalendarEntries() {
        let temp: CalendarEntry[] = []

        let dataFuture
        let nextTokenFuture

        let dataPast
        let nextTokenPast
        if (loggedInUserId) {
            // getCalendarEntries now breaks with CalendarEntrySortType.ALL apparently, so I had to resort to cheating like this

            do {
                dataFuture = await getCalendarEntries(
                    loggedInUserId,
                    CalendarEntrySortType.FUTURE,
                    CalendarEntryParticipationStatus.ACCEPTED,
                    nextTokenFuture
                )
                if (dataFuture) {
                    dataFuture.items.forEach((x) => {
                        temp.push(x.calendarEntry)
                    })
                    nextTokenFuture = dataFuture?.nextToken
                } else {
                    nextTokenFuture = null
                }
            } while (nextTokenFuture)

            do {
                dataPast = await getCalendarEntries(
                    loggedInUserId,
                    CalendarEntrySortType.PAST,
                    CalendarEntryParticipationStatus.ACCEPTED,
                    nextTokenPast
                )
                if (dataPast) {
                    dataPast.items.forEach((x) => {
                        temp.push(x.calendarEntry)
                    })
                    nextTokenPast = dataPast?.nextToken
                } else {
                    nextTokenPast = null
                }
            } while (nextTokenPast)

            setMeetings(temp)
            setInitialMeetings(temp)
            if (searchParam !== "") {
                setTriggerFilterWhenSearchParamWasPersisted(true)
            }
        }
    }

    async function loadRoundTables() {
        if (loggedInUserId) {
            const data = await loadRoundTableList({}, loggedInUserId)
            setRoundTables(data.eventDates)
        }
    }

    useEffect(() => {
        if (props.mySchedule) {
            loadCalendarEntries()
            loadRoundTables()
        }

        //eslint-disable-next-line
    }, [props.mySchedule, updatedMeetingKey, appState.timezone, props.reloadScheduleList])

    useEffect(() => {
        let date = new Date()
        date.setMonth(date.getMonth() - 6)
        if (loggedInUserId) {
            syncFavorites({
                profileId: loggedInUserId,
                body: {
                    currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    lastSyncTime: favorites.getLastSyncTime()
                }
            })
                .then((resp) => {
                    favorites.setLastSyncTime(new Date())
                    const temp: Array<any> = resp.content.favorites
                    temp.forEach((item) => {
                        if (!item.deleted && !favorites.is("eventdate", item.id)) {
                            favorites.add("eventdate", item.id)
                        }
                    })
                    setRefreshKey(1)
                })
                .catch((e: Error) => {
                    setError(e.message)
                })
        }

        //eslint-disable-next-line
    }, [loggedInUserId])

    function filterMeetings() {
        if (!props.mySchedule) return
        let newMeetings: CalendarEntry[] = []
        const cleanSearchParam = searchParam.toLowerCase().trim()
        if (cleanSearchParam === "") {
            setMeetings(initialMeetings)
            return
        }
        initialMeetings.forEach((meeting: CalendarEntry) => {
            const cleanTitle = meeting.title.toLowerCase().trim()
            if (cleanTitle.includes(cleanSearchParam)) {
                newMeetings.push(meeting)
            }
        })
        setMeetings(newMeetings)
    }

    useEffect(() => {
        let abortController = new AbortController()
        if (refreshKey > 0 && days[index] !== undefined) {
            filterMeetings()

            const selectedDate = days[index].format("YYYY-MM-DD")
            const filterList = buildFilterlist(
                props,
                isBookmarked,
                favorites.get("eventdate"),
                searchParam,
                stageFilter?.filter || "",
                badgeFilterValues || [],
                firstCategoryFilterValues || [],
                secondCategoryFilterValues || []
            )
            setIsLoaded(false)

            if (
                searchParam.length > 0 ||
                stageFilter?.filter ||
                badgeFilterValues?.length! > 0 ||
                firstCategoryFilterValues?.length! > 0 ||
                secondCategoryFilterValues?.length! > 0
            ) {
                trackSearch(filterList.join(","), "Program")
            }

            const timeout = setTimeout(() => {
                loadEventDateList({
                    filterlist: filterList,
                    startresultrow: startResultRow,
                    numresultrows: numResultRow,
                    day: !props.mySchedule ? selectedDate : null,
                    order: "chrono"
                })
                    //}, false, abortController.signal)
                    //FIXME - the abortController signal sometimes causes schedule to not be loaded
                    .then((resp) => {
                        if (favorites.get("eventdate") === "" && isBookmarked) {
                            setEventDates([])
                            setCountEventdates(0)
                        } else if (refreshKey === 1) {
                            if (showAsList) {
                                setEventDates((e) =>
                                    startResultRow === 0
                                        ? (e = orderBy(resp.eventDates, ["startHour", "startMinutes"], ["asc"]))
                                        : e.concat(orderBy(resp.eventDates, ["startHour", "startMinutes"], ["asc"]))
                                )
                            } else {
                                resp.eventDates.forEach((element) => {
                                    element.order = parseInt(
                                        stagesOrder.find((stageItem) => stageItem.stage === element.location)?.order.toString() ||
                                            "-1"
                                    )
                                    if (
                                        element.dateTimeStart.indexOf(selectedDate) >= 0 &&
                                        element.dateTimeEnd.indexOf(selectedDate) < 0
                                    ) {
                                        element.endMinutes = 59
                                        element.endHour = 23
                                    } else if (
                                        element.dateTimeEnd.indexOf(selectedDate) >= 0 &&
                                        element.dateTimeStart.indexOf(selectedDate) < 0
                                    ) {
                                        element.startMinutes = 0
                                        element.startHour = 0
                                        element.date = selectedDate
                                    }
                                })
                                setEventDates((e) =>
                                    startResultRow === 0
                                        ? (e = orderBy(resp.eventDates, ["order"], ["asc"]))
                                        : e.concat(orderBy(resp.eventDates, ["order"], ["asc"]))
                                )
                            }
                            setCountEventdates((e) => (e = resp.count!))
                        }
                        setIsLoaded(true)
                    })
                    .catch((e: Error) => {
                        setIsLoaded(true)
                        return setError(e.message)
                    })
            }, 600)

            return () => clearTimeout(timeout)
        }

        if (!props.mySchedule) {
            // Quick fix for mySchedule favorites not loading, TODO fix this page
            return () => {
                abortController.abort()
            }
        }
        //eslint-disable-next-line
    }, [
        startResultRow,
        refreshKey,
        isBookmarked,
        stageRefreshKey,
        stageFilter,
        days,
        badgeFilterValues,
        triggerFilterWhenSearchParamWasPersisted,
        index
    ])

    useEffect(() => {
        if (!showAsList) {
            eventDates.forEach((element) => {
                element.order = parseInt(
                    stagesOrder.find((stageItem) => stageItem.stage === element.location)?.order.toString() || "-1"
                )
            })

            setEventDates(orderBy(eventDates, ["order"], ["asc"]))
        } else {
            setEventDates(orderBy(eventDates, ["startHour", "startMinutes"], ["asc"]))
        }

        setOrdered(true)

        // eslint-disable-next-line
    }, [showAsList])

    const handleMyScheduleScroll = (scrollValues: any) => {
        if ((scrollValues.scrollHeight ?? scrollValues.contentScrollHeight) > scrollValues.clientHeight) {
            setHideOnScroll(scrollValues.scrollTop > 0)
        }
    }

    const buildSearchParams = (date?: string, stage?: string): string => {
        let searchParams: string[] = []

        if (date) {
            searchParams.push("day=" + date!)
        }

        if (stage) {
            searchParams.push("stage=" + stage!)
        }

        return `?${searchParams.join("&")}`
    }

    useEffect(() => {
        if (filteredByQueryParams && branding.programSchedule.generateQueryParams) {
            const selectedDate: string | undefined = days[index]?.format("YYYY-MM-DD")
            const selectedStage: string | undefined = stagesOrder.find((stage: Stage) => stage.stage === stageFilter?.stage)?.id

            history.replace(
                `${history.location.pathname}${history.location.hash?.split("?")[0]}${buildSearchParams(
                    selectedDate,
                    selectedStage
                )}`,
                {
                    ...(history.location.state as ProgramHistoryState)
                }
            )
        }
        // eslint-disable-next-line
    }, [index, stageFilter])

    const handleClickHelper = (idx: number) => {
        setIndex(idx)

        history.replace(`${history.location.pathname}${history.location.hash}`, {
            ...(history.location.state as ProgramHistoryState),
            selectedDateIndex: idx
        })

        allStages.splice(0, allStages.length)
        setStageRefreshKey(stageRefreshKey + 1)
        setStageFilter(null)
    }

    function resetSearch() {
        setFirstCategoryFilterValues(null)
        setSecondCategoryFilterValues(null)
        setBadgeFilterValues(null)
        setStageFilter(null)
        setMyScheduleTypeFilter(null)
        setSearchParam("")
        history.replace(`${history.location.pathname}${history.location.hash}`, null)
    }

    function isScheduleEmpty(): boolean {
        if (props.mySchedule) {
            return (
                (!eventDates || eventDates.length === 0) &&
                (!meetings || meetings.length === 0) &&
                (!roundTables || roundTables.length === 0)
            )
        } else {
            return !eventDates || eventDates.length === 0
        }
    }

    function resetResults() {
        if (nowButtonEnabled) {
            if (!isToday(days[index])) handleClickHelper(getIndexOfInitialDayToShowIgnoreDefault(days))
            setJumpToLive(jumpToLive + 1)
        }

        resetSearch()
    }

    useEffect(() => {
        if (isScheduleEmpty()) {
            setGroups(getGroupsByDay(eventDates, date))
            return
        }

        if (props.mySchedule) {
            let eventsArray: EventDate[] = []
            if (eventDates) {
                eventsArray = eventsArray.concat(eventDates)
            }
            if (roundTables) {
                eventsArray = eventsArray.concat(roundTables)
            }
            setGroups(getGroupsByDayMySchedule(eventsArray, meetings, appState.timezone, days))
        } else {
            setGroups(getGroupsByDay(eventDates, date))
        }

        let stagesTemp = getStagesByDay(eventDates, date)

        if (stagesOrder && stagesOrder.length > 0) {
            allStages.splice(0, allStages.length)
            stagesTemp.forEach((element) => {
                element.order = parseInt(
                    stagesOrder.find((stageItem) => stageItem.stage === element.stage)?.order.toString() || "-1"
                )
            })
            stagesTemp = orderBy(stagesTemp, ["order"], ["asc"])

            stagesTemp.map((element) => {
                if (
                    !allStages.find(({ stage, filter }) => {
                        return element.stage === stage && element.filter === filter
                    })
                )
                    allStages.push(element)
                return 1
            })
        } else {
            allStages.length = 0
        }

        if (allStages.findIndex((stage) => stage.filter === stageFilter?.filter! && stage.stage === stageFilter?.stage) === -1) {
            setStageFilter(null)
        }

        //eslint-disable-next-line
    }, [eventDates, meetings, props.reloadScheduleList, lang, props.activeTab])

    const handleClickOutside = (event: any) => {
        if (datePickerRootRef.current && !datePickerRootRef.current.contains(event.target)) {
            setDatePickerOpen(false)
        }
    }

    useEffect(() => {
        const historyState = history.location.state as ProgramHistoryState
        setBadgeFilterValues(historyState?.badgeFilterValues ?? null)
        setFirstCategoryFilterValues(historyState?.firstCategoryFilterValues ?? null)
        setSecondCategoryFilterValues(historyState?.secondCategoryFilterValues ?? null)
        setStageFilter(historyState?.stageFilter ?? null)
        setMyScheduleTypeFilter(historyState?.myScheduleTypeFilter ?? null)
        setSearchParam(historyState?.searchParam ?? "")
    }, [props.activeTab]) // eslint-disable-line

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true)
        return () => {
            document.removeEventListener("click", handleClickOutside, true)
        }
    }, [])

    if (error) {
        return (
            <div style={{ marginTop: "20%" }}>
                <BackendError />
            </div>
        )
    } else {
        if (!eventDates) return <div>{branding.programSchedule.noDataMessage}</div>

        if (days.length === 0) {
            return <CenteredLoader />
        }

        const isTemporaryDate = (date: any) => {
            const temporaryDate = new Date(0) //returns 1 Jan 1970

            return (
                date.getDate() === temporaryDate.getDate() &&
                date.getMonth() === temporaryDate.getMonth() &&
                date.getFullYear() === temporaryDate.getFullYear()
            )
        }

        date = days[index] === undefined ? new Date(0) : days[index].toDate()

        const dayOfWeek = [
            branding.programSchedule.sunday,
            branding.programSchedule.monday,
            branding.programSchedule.tuesday,
            branding.programSchedule.wednesday,
            branding.programSchedule.thursday,
            branding.programSchedule.friday,
            branding.programSchedule.saturday
        ][date.getDay()] //TODO` Set back this to date asap
        const dateString = new Intl.DateTimeFormat(lang, {
            year: "numeric",
            month: "long",
            day: "numeric"
        }).format(date) //TODO Set back this to date asap

        const labelString = dayOfWeek + ", " + dateString

        const handleClickPreviousDay = () => {
            if (index > 0) handleClickHelper(index - 1)
        }

        const handleClickNextDay = () => {
            if (index < days.length - 1) handleClickHelper(index + 1)
        }

        const handleClickDatePicker = (date: Date) => {
            const clickedDate = moment(date)

            const i = days.findIndex((day) => day.isSame(clickedDate))
            if (i <= days.length - 1) handleClickHelper(i)
        }

        return (
            <ScheduleRoot isFavourite={props.onlyFavorites} className="schedule-root" onClick={() => setDatePickerOpen(false)}>
                {
                    <ScheduleGroupHeaderRoot
                        visible={props.filtersVisible ?? true}
                        className={props.mySchedule ? "mySchedule" : ""}
                    >
                        <ScheduleGroupHeaderContainer
                            style={{ fontSize: "12px" }}
                            className={props.mySchedule ? "mySchedule" : ""}
                            hideOnScroll={hideOnScroll}
                        >
                            <Col
                                xs={12}
                                style={{
                                    marginBottom: props.mySchedule ? "0px" : "15px",
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexWrap: "wrap",
                                    gap: "10px"
                                }}
                            >
                                {days && days.length > 0 && index < days.length && (
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <div style={{ display: "flex" }}>
                                            <ArrowButtonGroup>
                                                <ArrowButton
                                                    style={{
                                                        pointerEvents: index === 0 ? "none" : "auto",
                                                        fontSize: "16px",
                                                        borderLeft: index === 0 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                        borderRight: index === 0 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                        borderTop: index === 0 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                        borderBottom: index === 0 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                        marginRight: index === 0 ? "-1" : "0",
                                                        zIndex: index === 0 ? 0 : 1
                                                    }}
                                                    onClick={handleClickPreviousDay}
                                                >
                                                    {IconProgramArrowLeft({
                                                        fill: index === 0 ? "#c9c9c9" : branding.sideIconBar.sideIconColorDark
                                                    })}
                                                </ArrowButton>

                                                <ArrowButton
                                                    style={{
                                                        pointerEvents: index >= days.length - 1 ? "none" : "auto",
                                                        fontSize: "16px",
                                                        borderLeft:
                                                            index >= days.length - 1 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                        borderRight:
                                                            index >= days.length - 1 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                        borderTop:
                                                            index >= days.length - 1 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                        borderBottom:
                                                            index >= days.length - 1 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                        marginLeft: -1,
                                                        zIndex: index >= days.length - 1 ? 0 : 1
                                                    }}
                                                    onClick={handleClickNextDay}
                                                >
                                                    {IconProgramArrowRight({
                                                        fill:
                                                            index >= days.length - 1
                                                                ? "#c9c9c9"
                                                                : branding.sideIconBar.sideIconColorDark
                                                    })}
                                                </ArrowButton>
                                            </ArrowButtonGroup>

                                            <DatePickerRoot
                                                ref={datePickerRootRef}
                                                onClick={(e: any) => {
                                                    e.stopPropagation()
                                                }}
                                            >
                                                <DatePicker
                                                    locale={lang === "de" ? de : en}
                                                    selected={days[index].toDate()}
                                                    open={datePickerOpen}
                                                    useWeekdaysShort={true}
                                                    onChange={handleClickDatePicker}
                                                    customInput={
                                                        <GroupHeaderLabel datePickerOpen={datePickerOpen}>
                                                            <div
                                                                onClick={() => {
                                                                    setDatePickerOpen(!datePickerOpen)
                                                                }}
                                                                style={{ position: "relative", marginLeft: "20px" }}
                                                            >
                                                                {isTemporaryDate(date) ? "" : labelString}
                                                                <span>
                                                                    {IconArrowHeadDown({
                                                                        fill: branding.mainInfoColor,
                                                                        height: "12",
                                                                        width: "12"
                                                                    })}
                                                                </span>
                                                            </div>
                                                        </GroupHeaderLabel>
                                                    }
                                                    includeDates={days.map((day) => day.toDate())}
                                                    disabledKeyboardNavigation
                                                />
                                            </DatePickerRoot>
                                            {!showAsList && !props.mySchedule && (
                                                <NowButton
                                                    onClick={() => {
                                                        if (
                                                            stageFilter?.filter ||
                                                            badgeFilterValues ||
                                                            firstCategoryFilterValues ||
                                                            secondCategoryFilterValues ||
                                                            searchParam
                                                        ) {
                                                            if (appState.isDontShowModalClicked) {
                                                                if (!isToday(days[index]))
                                                                    handleClickHelper(
                                                                        getIndexOfInitialDayToShowIgnoreDefault(days)
                                                                    )

                                                                setJumpToLive(jumpToLive + 1)
                                                                resetResults()
                                                            } else setShowResetNowButtonModal(true)
                                                        } else {
                                                            if (!isToday(days[index]))
                                                                handleClickHelper(getIndexOfInitialDayToShowIgnoreDefault(days))

                                                            setJumpToLive(jumpToLive + 1)
                                                            resetResults()
                                                        }
                                                    }}
                                                    className={
                                                        nowButtonEnabled ||
                                                        stageFilter?.filter ||
                                                        badgeFilterValues ||
                                                        firstCategoryFilterValues ||
                                                        secondCategoryFilterValues
                                                            ? ""
                                                            : "disabled"
                                                    }
                                                >
                                                    {branding.programSchedule.nowButtonText}
                                                </NowButton>
                                            )}
                                            {props.mySchedule &&
                                                !isToday(moment(days[index])) &&
                                                days.some((day) => isToday(day)) && (
                                                    <ScheduleMeetingButton
                                                        style={{ marginLeft: "25px" }}
                                                        onClick={() => {
                                                            handleClickHelper(days.findIndex((day) => isToday(day)))
                                                        }}
                                                    >
                                                        <div className={"d-flex align-items-center"}>
                                                            <ScheduleMeetingTitle>
                                                                {branding.mySchedule.todayButtonTitle}
                                                            </ScheduleMeetingTitle>
                                                        </div>
                                                    </ScheduleMeetingButton>
                                                )}
                                            {showResetNowButtonModal && (
                                                <NowButtonResetModal
                                                    toggleResults={() => resetResults()}
                                                    onClose={() => setShowResetNowButtonModal(false)}
                                                />
                                            )}
                                        </div>
                                        <div style={{ display: "flex", color: branding.programSchedule.iconsColor ?? "#000" }}>
                                            {!ordered && (
                                                <div style={{ marginRight: "5px", textAlign: "center", cursor: "pointer" }}>
                                                    <div
                                                        style={{ height: "30px", width: "30px", margin: "0 auto" }}
                                                        className="size"
                                                    >
                                                        <CenteredLoader size="sm" />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {branding.programSchedule.allStagesText && (
                                    <div
                                        className="d-flex justify-content-flex-end align-items-end"
                                        style={{ paddingLeft: "0px", marginTop: "2px" }}
                                    >
                                        <FiltersRoot>
                                            {" "}
                                            <MobileVersionContainer style={{ width: "100%" }}>
                                                <MobileSearchRoot>
                                                    <SearchBarComponent
                                                        currentSearch={searchParam}
                                                        setCurrentSearch={(search: string) => {
                                                            setSearchParam(search)
                                                            history.replace(
                                                                `${history.location.pathname}${history.location.hash}`,
                                                                {
                                                                    ...(history.location.state as ProgramHistoryState),
                                                                    searchParam: search
                                                                }
                                                            )
                                                        }}
                                                        bookmarkFilter={isBookmarked}
                                                        setBookmarkFilter={setIsBookmarked}
                                                    />
                                                </MobileSearchRoot>
                                            </MobileVersionContainer>
                                            {!props.mySchedule && (
                                                <>
                                                    {branding.programSchedule.useCategoriesInsteadOfStages ? (
                                                        <CategoryFilterComponent
                                                            placeholderText={branding.programSchedule.allCategoriesFilterText}
                                                            options={categories}
                                                            filterValues={firstCategoryFilterValues}
                                                            setFilterValues={setFirstCategoryFilterValues}
                                                            updateHistoryState={(values: CategoryFilter[] | null) => {
                                                                history.replace(
                                                                    `${history.location.pathname}${history.location.hash}`,
                                                                    {
                                                                        ...(history.location.state as ProgramHistoryState),
                                                                        firstCategoryFilterValues: values
                                                                    }
                                                                )
                                                            }}
                                                        />
                                                    ) : (
                                                        <StyledSelect
                                                            styles={styledSelectStyles}
                                                            placeholder={
                                                                props.activeTab === ProgramPageTab.SCHEDULE
                                                                    ? branding.programSchedule.allStagesText
                                                                    : additionalTabConfig?.stageFilter.filterPlaceholder
                                                            }
                                                            isMulti={false}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            options={allStages.map((stage) => {
                                                                return { label: stage.stage, value: stage.filter }
                                                            })}
                                                            value={
                                                                stageFilter
                                                                    ? { label: stageFilter?.stage, value: stageFilter?.filter }
                                                                    : null
                                                            }
                                                            noOptionsMessage={() =>
                                                                branding.programSchedule.filterNoOptionsMessage
                                                            }
                                                            onChange={(
                                                                value: ValueType<OptionTypeBase, boolean>,
                                                                action: ActionMeta<OptionTypeBase>
                                                            ) => {
                                                                let stateValue = null
                                                                if (value !== null && action.action === "select-option") {
                                                                    const option = value as OptionTypeBase
                                                                    const newStage = { stage: option.label, filter: option.value }
                                                                    if (stageFilter?.filter !== newStage.filter) {
                                                                        setStageFilter(newStage)
                                                                        stateValue = newStage
                                                                    }
                                                                } else setStageFilter(null)

                                                                history.replace(
                                                                    `${history.location.pathname}${history.location.hash}`,
                                                                    {
                                                                        ...(history.location.state as ProgramHistoryState),
                                                                        stageFilter: stateValue
                                                                    }
                                                                )
                                                            }}
                                                            theme={SelectThemeCustom}
                                                        />
                                                    )}
                                                </>
                                            )}
                                            {!props.mySchedule && badgeFilterVisible && (
                                                <CategoryFilterComponent
                                                    placeholderText={
                                                        props.activeTab === ProgramPageTab.SCHEDULE
                                                            ? branding.programSchedule.badgeAllText
                                                            : additionalTabConfig?.categoryFilter.filterPlaceholder ??
                                                              branding.programSchedule.badgeAllText
                                                    }
                                                    options={badgeFilterList || []}
                                                    filterValues={badgeFilterValues}
                                                    setFilterValues={setBadgeFilterValues}
                                                    updateHistoryState={(values: CategoryFilter[] | null) => {
                                                        history.replace(`${history.location.pathname}${history.location.hash}`, {
                                                            ...(history.location.state as ProgramHistoryState),
                                                            badgeFilterValues: values
                                                        })
                                                    }}
                                                />
                                            )}
                                            {!props.mySchedule &&
                                                props.activeTab === ProgramPageTab.SCHEDULE &&
                                                branding.programSchedule.showSecondCategoriesFilter && (
                                                    <CategoryFilterComponent
                                                        placeholderText={branding.programSchedule.secondCategoriesFilterText}
                                                        options={secondCategories}
                                                        filterValues={secondCategoryFilterValues}
                                                        setFilterValues={setSecondCategoryFilterValues}
                                                        updateHistoryState={(values: CategoryFilter[] | null) => {
                                                            history.replace(
                                                                `${history.location.pathname}${history.location.hash}`,
                                                                {
                                                                    ...(history.location.state as ProgramHistoryState),
                                                                    secondCategoryFilterValues: values
                                                                }
                                                            )
                                                        }}
                                                    />
                                                )}
                                            {props.mySchedule &&
                                                (!isPostEventPhase ||
                                                    (isOnboardingPhase && getIamPartOf(queryParams) === "onboarding")) && (
                                                    <ScheduleMeetingButton
                                                        onClick={() => {
                                                            if (
                                                                userState.user() &&
                                                                userState.user()?.type === "guest" &&
                                                                userState.user()?.invitingOrganization
                                                            ) {
                                                                showGuestModal()
                                                                return
                                                            }

                                                            setShowRequestMeetingModal(true)
                                                        }}
                                                    >
                                                        <div className={"d-flex align-items-center"}>
                                                            <span className={"mr-2 mt-1"}>
                                                                {IconCalendarEntry({
                                                                    fill: branding.sideIconBar.sideIconColorDark,
                                                                    width: "20",
                                                                    height: "20"
                                                                })}
                                                            </span>
                                                            <ScheduleMeetingTitle>
                                                                {branding.communicationArea.scheduleMeetingText}
                                                            </ScheduleMeetingTitle>
                                                        </div>
                                                    </ScheduleMeetingButton>
                                                )}
                                            {props.mySchedule && (
                                                <StyledSelect
                                                    styles={styledSelectStyles}
                                                    placeholder={branding.mySchedule.dropdownFilterTitle}
                                                    isMulti={false}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    options={[
                                                        {
                                                            value: "eventdate",
                                                            label: branding.mySchedule.dropdownFilterLiveSession
                                                        },
                                                        {
                                                            value: "meeting",
                                                            label: branding.mySchedule.dropdownFilterMeeting
                                                        }
                                                    ]}
                                                    value={myScheduleTypeFilter}
                                                    noOptionsMessage={() => branding.programSchedule.filterNoOptionsMessage}
                                                    onChange={(
                                                        value: ValueType<OptionTypeBase, boolean>,
                                                        action: ActionMeta<OptionTypeBase>
                                                    ) => {
                                                        let stateValue = null
                                                        if (value !== null && action.action === "select-option") {
                                                            const option = value as OptionTypeBase
                                                            const newFilter = { label: option.label, value: option.value }
                                                            if (myScheduleTypeFilter?.value !== newFilter.value) {
                                                                setMyScheduleTypeFilter(newFilter)
                                                                stateValue = newFilter
                                                            }
                                                        } else setMyScheduleTypeFilter(null)

                                                        history.replace(`${history.location.pathname}${history.location.hash}`, {
                                                            ...(history.location.state as ProgramHistoryState),
                                                            myScheduleTypeFilter: stateValue
                                                        })
                                                    }}
                                                    theme={SelectThemeCustom}
                                                />
                                            )}
                                            <DesktopVersionContainer>
                                                <BookmarkButtonCol>
                                                    <SearchBarComponent
                                                        currentSearch={searchParam}
                                                        setCurrentSearch={(search: string) => {
                                                            setSearchParam(search)
                                                            history.replace(
                                                                `${history.location.pathname}${history.location.hash}`,
                                                                {
                                                                    ...(history.location.state as ProgramHistoryState),
                                                                    searchParam: search
                                                                }
                                                            )
                                                        }}
                                                        bookmarkFilter={isBookmarked}
                                                        setBookmarkFilter={setIsBookmarked}
                                                    />
                                                </BookmarkButtonCol>
                                            </DesktopVersionContainer>
                                            {(stageFilter !== null ||
                                                firstCategoryFilterValues !== null ||
                                                secondCategoryFilterValues !== null ||
                                                badgeFilterValues !== null ||
                                                myScheduleTypeFilter !== null ||
                                                searchParam !== "") && (
                                                <FilterButtonsComponent
                                                    onResetFilterButtonClick={() => resetSearch()}
                                                    resetFilterVisible={
                                                        stageFilter !== null ||
                                                        firstCategoryFilterValues !== null ||
                                                        secondCategoryFilterValues !== null ||
                                                        badgeFilterValues !== null ||
                                                        myScheduleTypeFilter !== null ||
                                                        searchParam !== ""
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            )}
                                        </FiltersRoot>
                                    </div>
                                )}
                            </Col>
                        </ScheduleGroupHeaderContainer>
                    </ScheduleGroupHeaderRoot>
                }
                {!isLoaded && !props.mySchedule ? (
                    <CenteredLoader />
                ) : (
                    <ScheduleGroupsContainer
                        showAsList={showAsList}
                        roundScroller={props.roundScroller}
                        onScroll={(e: any) => {
                            if (props.onScroll) props.onScroll(e)
                        }}
                        addBannerHeight={props.addBannerHeight}
                        groups={groups}
                        revealerRef={revealerRef}
                        isFavourite={props.onlyFavorites}
                        dateColumnDays={props.dateColumnsDays}
                        day={index}
                        setUpdatedMeetingKey={setUpdatedMeetingKey}
                        guestBannerHeight={props.guestBannerHeight}
                        eventdates={eventDates}
                        hasMoreData={isMoreDataToLoad()}
                        setStartResultRow={setStartResultRow}
                        numResultRow={numResultRow}
                        isLoaded={isLoaded}
                        startResultRow={startResultRow}
                        pointsBadgeData={pointsBadgeData}
                        isInMyFairPage={props.isInMyFairPage}
                        searchParam={searchParam}
                        dateToShow={days[index]}
                        jumpToLive={jumpToLive}
                        optionsOpen={props.filtersVisible ?? true}
                        resetFilterVisible={
                            stageFilter !== null ||
                            badgeFilterValues !== null ||
                            firstCategoryFilterValues !== null ||
                            secondCategoryFilterValues !== null ||
                            myScheduleTypeFilter !== null ||
                            searchParam !== ""
                                ? true
                                : false
                        }
                        myScheduleTypeFilter={myScheduleTypeFilter ?? undefined}
                        calendarModalClose={() => {
                            if (props.setReloadScheduleList) {
                                props.setReloadScheduleList(false)
                                props.setReloadScheduleList(true)
                            }
                        }}
                        handleMyScheduleScroll={handleMyScheduleScroll}
                        stagesOrder={stagesOrder}
                    />
                )}

                <GuestModal />

                {showRequestMeetingModal && (
                    <CalendarEntryModal2
                        viewMode={CalendarEntryModalViewMode.CREATE}
                        close={() => {
                            setShowRequestMeetingModal(false)
                            if (props.setReloadScheduleList) {
                                props.setReloadScheduleList(false)
                                props.setReloadScheduleList(true)
                            }
                        }}
                    />
                )}
            </ScheduleRoot>
        )
    }
}
export default Schedule

interface CategoryFilterComponentProps {
    placeholderText: string
    options: Filter[]
    filterValues: CategoryFilter[] | null
    setFilterValues: (values: CategoryFilter[] | null) => void
    updateHistoryState: (values: CategoryFilter[] | null) => void
}

const CategoryFilterComponent: React.FunctionComponent<CategoryFilterComponentProps> = (props: CategoryFilterComponentProps) => {
    return (
        <StyledSelect
            components={{
                Option: CustomSelectOption,
                Placeholder: CustomSelectPlaceholder
            }}
            styles={styledSelectStyles}
            placeholder={props.placeholderText}
            isMulti
            isSearchable={false}
            isClearable
            options={props.options}
            value={props.filterValues}
            hideSelectedOptions={false}
            controlShouldRenderValue={false}
            closeMenuOnSelect={false}
            noOptionsMessage={() => branding.programSchedule.filterNoOptionsMessage}
            onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                if (value !== null) {
                    const newValues: CategoryFilter[] | null =
                        value.length === 0
                            ? null
                            : value.map((v: OptionTypeBase) => {
                                  return {
                                      label: v.label,
                                      value: v.value
                                  }
                              })

                    props.setFilterValues(newValues)
                    props.updateHistoryState(newValues)
                }
            }}
            theme={SelectThemeCustom}
        />
    )
}

const ScheduleMeetingButton = styled.div`
    position: relative;
    display: inline-block;
    color: ${branding.crsTabs.tabItemDefaultTextColor ?? "black"};
    font-size: 16px;
    line-height: 17px;
    margin-top: 5px;
    margin-right: 20px;
    cursor: pointer;
    &:hover {
        background-color: transparent;
    }
    span {
        color: ${branding.crsTabs.tabItemDefaultTextColor ?? "black"};
    }
    svg {
        color: ${branding.crsTabs.tabItemDefaultTextColor ?? "black"};
    }
`

const ScheduleMeetingTitle = styled.span`
    font-family: ${branding.font1};
    margin-top: 5px;
`

const CloseButton = styled.div`
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
`

const Content = styled.div`
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
`

const CheckboxDiv = styled.div`
    margin-top: 15px;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding-left: 20px;

    & .form-check-label {
        margin-top: 3px;
    }
`

interface NowButtonResetModalProps {
    toggleResults: () => void
    onClose: () => void
}

const NowButtonResetModal: React.FunctionComponent<NowButtonResetModalProps> = (props: NowButtonResetModalProps) => {
    const [checkboxClicked, setCheckboxClicked] = useState<boolean>(false)
    const appState = useAppState()

    function handleClose() {
        props.onClose()
    }

    function handleContinueButton() {
        if (checkboxClicked) appState.setIsDontShowModalClicked(true)

        props.toggleResults()
        props.onClose()
    }

    return (
        <>
            <ModalRoot backdrop="static" show={true} animation={false}>
                <div onClick={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                        <h3 className="modal-title">{branding.programSchedule.nowButtonResetModalTitle}</h3>
                        <CloseButton onClick={() => handleClose()}>
                            {IconClose({ fill: branding.recommendModal.closeIconColor, width: "15", height: "15" })}
                        </CloseButton>
                    </div>
                    <div className="modal-body">
                        <Content>
                            {branding.programSchedule.nowButtonResetModalText1}{" "}
                            <b>{branding.programSchedule.nowButtonResetModalText2}</b>{" "}
                            {branding.programSchedule.nowButtonResetModalText3}
                        </Content>
                        <Content>{branding.programSchedule.nowButtonResetModalSubtitleText}</Content>
                    </div>
                    <CheckboxDiv>
                        <Form.Check
                            type="checkbox"
                            label={branding.programSchedule.nowButtonResetModalCheckboxText}
                            checked={checkboxClicked}
                            onChange={() => setCheckboxClicked(!checkboxClicked)}
                        ></Form.Check>
                    </CheckboxDiv>
                    <div className="modal-footer" style={{ marginTop: "25px" }}>
                        <SubmitButtonContainer>
                            <DisagreeButton onClick={() => handleClose()} className="d-flex align-items-center">
                                {branding.programSchedule.nowButtonResetModalCancelButton}
                            </DisagreeButton>
                            <SubmitButtonNew
                                type="submit"
                                className="d-flex align-items-center justify-content-center"
                                onClick={() => handleContinueButton()}
                            >
                                {branding.programSchedule.nowButtonResetModalAgreeButton}
                            </SubmitButtonNew>
                        </SubmitButtonContainer>
                    </div>
                </div>
            </ModalRoot>
        </>
    )
}

const SearchBarComponentRoot = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;
`

interface SearchBarComponentProps {
    currentSearch: string
    setCurrentSearch: (value: string) => void
    bookmarkFilter: boolean
    setBookmarkFilter: (value: boolean) => void
}

const SearchBarComponent: React.FunctionComponent<SearchBarComponentProps> = (props: SearchBarComponentProps) => {
    const { isMobile } = useWindowDimensions()
    const searchBarWidth = isMobile ? "72vw" : "244px"
    const mySchedule = useRouteMatch(mySchedulePageRoute)?.isExact

    return (
        <SearchBarComponentRoot>
            <SearchBarContainer className={"ml-0"}>
                <SearchBar
                    setSearchParam={props.setCurrentSearch}
                    searchValue={props.currentSearch}
                    width={searchBarWidth}
                    placeholder={branding.programSchedule.searchBarPlaceholder}
                    height={"35px"}
                    isSearchBar={true}
                    bottom={"12px"}
                    bottomEnd={"10px"}
                />
            </SearchBarContainer>
            <MobileVersionContainer>
                {!mySchedule && (
                    <OnlyBookmarksButton
                        bookmarkFilter={props.bookmarkFilter}
                        updateBookmarkFilter={() => props.setBookmarkFilter(!props.bookmarkFilter)}
                    />
                )}
            </MobileVersionContainer>
        </SearchBarComponentRoot>
    )
}

type ScheduleGroupsContainerProps = {
    groups: DayData[]
    isFavourite?: boolean
    revealerRef: (node?: Element | null | undefined) => void
    addBannerHeight?: number
    guestBannerHeight: number
    onScroll?: Function
    dateColumnDays?: moment.Moment[]
    onBookmarkClick?: Function
    showAsList: boolean
    roundScroller?: boolean
    day?: number
    setUpdatedMeetingKey?: (value: string) => void
    eventdates: EventDate[]
    hasMoreData: boolean
    setStartResultRow: (value: any) => void
    numResultRow: number
    isLoaded: boolean
    startResultRow: number
    pointsBadgeData?: Category
    isInMyFairPage?: boolean
    searchParam: string
    dateToShow: moment.Moment
    jumpToLive: number
    resetFilterVisible: boolean
    optionsOpen?: boolean
    myScheduleTypeFilter?: MyScheduleTypeFilter
    stagesOrder: Stage[]
    calendarModalClose?: () => void
    handleMyScheduleScroll: (scrollValues: any) => void
}

const ScheduleGroupsContainer: React.FunctionComponent<ScheduleGroupsContainerProps> = (props) => {
    const mySchedule = useRouteMatch(mySchedulePageRoute)?.isExact
    const { isMobile, useMobileDesign } = useWindowDimensions()

    const onNextPageLoaderVisibilityChange = (isVisible: boolean) => {
        if (isVisible) {
            props.setStartResultRow((e: number) => e + props.numResultRow)
        }
    }
    if (props.isLoaded && (!props.eventdates || props.eventdates.length === 0) && props.groups.length === 0) {
        return (
            <div style={{ marginTop: "12%" }}>
                <EmptyTile
                    header={
                        mySchedule
                            ? branding.mySchedule.noItemsText
                            : props.isFavourite
                            ? branding.programSchedule.noBookmarkedEventDatesText
                            : branding.programSchedule.noSearchResultsText
                    }
                    bgColor="transparent"
                    hideButton={true}
                />
            </div>
        )
    }

    if (mySchedule && props.groups.length > 0) {
        return props.showAsList ? (
            <ListViewRoot key={1}>
                <ContentScrollContainer
                    handleScroll={(e) => {
                        props.handleMyScheduleScroll(e)
                        props.onScroll!(e)
                    }}
                    scrollType={"round"}
                    mySchedule={mySchedule}
                >
                    <ListEventDateEntryContainer>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: useMobileDesign ? "100%" : "calc(100% - 10px)"
                            }}
                        >
                            <ListEventDateEntryColumn
                                eventDates={
                                    props.myScheduleTypeFilter && props.myScheduleTypeFilter?.value! === "meeting"
                                        ? []
                                        : Array.from(props.groups[props.day || 0].locations.values())[0]?.events || []
                                }
                                meetings={
                                    props.myScheduleTypeFilter && props.myScheduleTypeFilter?.value! === "eventdate"
                                        ? []
                                        : Array.from(props.groups[props.day || 0].locations.values())[0]?.meetings || []
                                }
                                calendarModalClose={props.calendarModalClose}
                                mySchedule
                            />
                            {props.hasMoreData && (
                                <InView threshold={0.1} onChange={onNextPageLoaderVisibilityChange}>
                                    <NextPageLoader />
                                </InView>
                            )}
                        </div>
                    </ListEventDateEntryContainer>
                </ContentScrollContainer>
            </ListViewRoot>
        ) : (
            <MyScheduleDateColumnGroup
                key={1}
                dayData={props.groups[props.day || 0]}
                isFavourite={props.isFavourite}
                revealerRef={props.revealerRef}
                onScroll={props.onScroll}
                dateColumnDays={props.dateColumnDays}
                roundScroller={props.roundScroller}
                groups={props.groups}
                day={props.day}
                setUpdatedMeetingKey={props.setUpdatedMeetingKey}
                pointsBadgeData={props.pointsBadgeData}
                isInMyFairPage={props.isInMyFairPage}
                dateToShow={props.dateToShow}
                mySchedule
                myScheduleTypeFilter={props.myScheduleTypeFilter}
                calendarModalClose={props.calendarModalClose}
                handleMyScheduleScroll={props.handleMyScheduleScroll}
                eventDates={props.eventdates}
            />
        )
    } else {
        // if !(mySchedule && props.groups.length > 0)
        // if !mySchedule || props.groups.length <== 0
        const adjustForHeader = isMobile
            ? props.optionsOpen
                ? 280
                : 120
            : (props.addBannerHeight ?? 0 + props.guestBannerHeight) + 320
        return (
            <div>
                {props.groups.map((day: DayData) => {
                    if (props.showAsList) {
                        return (
                            <ListViewRoot key={day.date}>
                                <ContentScrollContainer
                                    adjustForHeaderWith={`${adjustForHeader}px`}
                                    handleScroll={(e) => props.onScroll!(e)}
                                    mySchedule={mySchedule}
                                >
                                    <ListEventDateEntryContainer>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: useMobileDesign ? "100%" : "calc(100% - 10px)"
                                            }}
                                        >
                                            <ListEventDateEntryColumn
                                                eventDates={props.eventdates}
                                                helper={day.helper}
                                                pointBadgeData={props.pointsBadgeData}
                                            />
                                            {props.hasMoreData && (
                                                <InView threshold={0.1} onChange={onNextPageLoaderVisibilityChange}>
                                                    <NextPageLoader />
                                                </InView>
                                            )}
                                        </div>
                                    </ListEventDateEntryContainer>
                                </ContentScrollContainer>
                            </ListViewRoot>
                        )
                    } else {
                        // tile view
                        return (
                            <ScheduleGroup
                                key={day.date}
                                dayData={day}
                                isFavourite={props.isFavourite}
                                revealerRef={props.revealerRef}
                                addBannerHeight={props.addBannerHeight}
                                guestBannerHeight={props.guestBannerHeight}
                                onScroll={props.onScroll}
                                hasMoreData={props.hasMoreData}
                                setStartResultRow={props.setStartResultRow}
                                numResultRow={props.numResultRow}
                                isLoaded={props.isLoaded}
                                startResultRow={props.startResultRow}
                                pointsBadgeData={props.pointsBadgeData}
                                eventDates={props.eventdates}
                                jumpToLive={props.jumpToLive}
                                mySchedule={mySchedule}
                                stagesOrder={props.stagesOrder}
                            />
                        )
                    }
                })}
            </div>
        )
    }
}

// Only calculate the Height for the visible Day.
function getGroupsByDay(eventDates: EventDate[], selectedDate: Date): DayData[] {
    let groups: DayData[] = []
    let currentDayData: DayData | undefined
    if (selectedDate === undefined || eventDates.length === 0) return groups
    const dateString =
        selectedDate.getFullYear() +
        "-" +
        (selectedDate.getMonth() + 1 < 10 ? "0" + (selectedDate.getMonth() + 1) : selectedDate.getMonth() + 1) +
        "-" +
        (selectedDate.getDate() < 10 ? "0" + selectedDate.getDate() : selectedDate.getDate())
    eventDates
        .filter((eventDate) => eventDate.date === dateString)
        .forEach((eventDate) => {
            if (!currentDayData) {
                currentDayData = {
                    date: eventDate.date,
                    locations: new Map(),
                    times: [],
                    dayStartTime: new Date(),
                    helper: new ScheduleHelper()
                }
                groups.push(currentDayData)
            }
            if (eventDate.location === null || eventDate.location.length === 0) eventDate.location = ""
            let location = currentDayData.locations.get(eventDate.location)
            if (location == null) {
                location = { name: eventDate.location, events: [] }
                currentDayData.locations.set(eventDate.location, location)
            }
            location.events.push(eventDate)
        })
    if (!currentDayData?.helper) return groups

    let eventDatesCopy = [...eventDates]
    eventDatesCopy = eventDatesCopy.sort(compareByEndTimeEventDate)
    new ScheduleHelper().updateTimesEventDate(
        [...eventDatesCopy].sort(compareByStartTimeEventDate)[0],
        eventDatesCopy[eventDates.length - 1],
        currentDayData!
    )
    return groups
}

function getStagesByDay(eventDates: EventDate[], selectedDate: Date): { stage: string; filter: string; order?: number }[] {
    let stages: { stage: string; filter: string }[] = []

    if (selectedDate === undefined) return stages

    const dateString =
        selectedDate.getFullYear() +
        "-" +
        (selectedDate.getMonth() + 1 < 10 ? "0" + (selectedDate.getMonth() + 1) : selectedDate.getMonth() + 1) +
        "-" +
        (selectedDate.getDate() < 10 ? "0" + selectedDate.getDate() : selectedDate.getDate())

    eventDates
        .filter((eventDate) => eventDate.date === dateString)
        .forEach((eventDate) => {
            if (eventDate.location === null || eventDate.location.length === 0) eventDate.location = ""

            if (eventDate.location !== "" && stages.filter((s) => s.filter === eventDate.location).length === 0)
                stages.push({ stage: eventDate.location, filter: eventDate.location })
        })
    return stages
}

type EventDateEntryColumnCSS = {
    width: number
    backgroundColor: string
    justifyContent: string
    hasBefore?: boolean
    hasAfter?: boolean
}
const EventDateEntryColumnRoot = styled.div`
    min-width: calc(${(props: EventDateEntryColumnCSS) => props.width + "px"} - 0px);
    max-width: calc(${(props: EventDateEntryColumnCSS) => props.width + "px"} - 0px);

    background-color: ${(props: EventDateEntryColumnCSS) => props.backgroundColor};
    padding: 0 10px;
    position: relative;

    & .eventDate-entry-root {
        margin: 0 10px;
    }

    &.mySchedule {
        min-width: 100%;
        max-width: 100%;
    }

    &::before,
    &::after {
        position: absolute;
        width: 1px;
        height: calc(100% + 100px);
        background-color: #c4c4c4;
        top: -100px;
        z-index: 100;
    }

    &::before {
        ${(props: EventDateEntryColumnCSS) => (props.hasBefore ? "content: '';" : "")};
    }

    &::after {
        ${(props: EventDateEntryColumnCSS) => (props.hasAfter ? "content: '';" : "")};
        right: -10px;
    }
`
const EventDateLocationColumn = styled(EventDateEntryColumnRoot)`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: inline-flex;
    justify-content: ${(props: EventDateEntryColumnCSS) => props.justifyContent};
    height: 54px;
    font-weight: 700;
`
const EventDateTimeColumn = styled.div<{ isFavourite?: boolean; float?: string; mySchedule?: boolean }>`
    width: ${(props) => (props.mySchedule ? "80px" : "60px")};
    height: ${(props) => (props.mySchedule ? "auto" : "100%")};
    position: sticky; /* stay in view */
    left: 0; /* stay in view */
    float: ${(props) => props.float}; /* make room for the colums */
    margin-left: 4px; /* conter thet scroller */
    margin-top: ${(props) => (props.mySchedule ? "0px" : "43px")}; /* align with colums */
    z-index: 101;
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
`
const EventDateTimeTableInnerWrapper = styled.div`
    position: relative;
    left: 50px;
    top: 5px;

    &.mySchedule {
        top: 0px;
        @media (max-width: 1700px) {
            width: 130%;
        }

        @media (max-width: 1440px) {
            width: 150%;
        }
    }
`
const EventDateTimeLabel = styled.div<{ showBorder?: boolean; hasBefore?: boolean; hasAfter?: boolean }>`
    padding: 10px 0px;
    font-size: 10px;
    font-family: ${branding.font1};
    font-weight: 300;
    background-color: ${branding.contentBgColorForEachPage ?? "transparent"};
    color: ${branding.mainInfoColor};
    position: relative;

    &::before,
    &::after {
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #d9d9d9;
        left: 0;
        z-index: 0;
    }

    &::before {
        ${(props) => (props.showBorder && props.hasBefore ? "content: '';" : "")};
        top: 0;
    }

    &::after {
        ${(props) => (props.showBorder && props.hasAfter ? "content: '';" : "")};
        bottom: 0;
    }

    &.mySchedule {
        font-size: 14px;
        line-height: 16px;
        color: ${branding.crsTabs.defaultActionItemColor};
        margin-left: 3px;
        width: 130%;
    }

    &.myScheduleResponsive {
        @media (max-width: 1700px) {
            width: 130%;
        }

        @media (max-width: 1500px) {
            width: 150%;
        }
    }
`
const EventDateEntryContainer = styled.div<{ left?: string; mySchedule?: boolean }>`
    display: flex;
    flex-grow: 1;
    position: absolute;
    transition: left 0.5s ease-in-out;
    top: 0;
    bottom: 0;
    left: ${(props) => (props.left ? props.left : 0)};
    right: 0;
    padding-right: 50px; // make space when scroller is at end
    margin-left: 15px;
    width: 100%;
    overflow: ${(props) => (props.mySchedule ? "hidden" : "none")};
    ${(props) => (props.mySchedule ? "z-index: 103;" : "")};

    @media (max-width: 1700px) {
        width: 100%;
        overflow: ${(props) => (props.mySchedule ? "auto" : "none")};
    }
`

const ListViewRoot = styled.div``

const ListEventDateEntryContainer = styled.div`
    display: flex;
    flex-grow: 1;
    transition: left 0.5s ease-in-out;
    top: 0;
    bottom: 0;
    padding-bottom: 20px;
`
const GroupHeaderLabel = styled.div<{ datePickerOpen?: boolean }>`
    position: static;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    color: ${(props) => (props.datePickerOpen ? branding.crsTabs.tabItemDefaultActiveStateColor : branding.mainInfoColor)};
    font-size: ${branding.programSchedule.dateLabelFontSize};
    font-family: ${branding.programSchedule.dateLabelFontFamily};
    text-transform: ${branding.programSchedule.dateLabelTextTransform};
    font-weight: ${branding.programSchedule.dateLabelFontWeight};
    letter-spacing: ${branding.programSchedule.dateLabelLetterSpacing};
    cursor: pointer;
    margin: -1px;

    & svg {
        margin-top: -7px;
        fill: ${(props) => (props.datePickerOpen ? branding.crsTabs.tabItemDefaultActiveStateColor : branding.mainInfoColor)};
    }

    & div:hover {
        border-bottom: 1px solid ${branding.crsTabs.tabItemDefaultActiveStateColor};
        color: ${branding.crsTabs.tabItemDefaultActiveStateColor};

        & svg {
            fill: ${branding.crsTabs.tabItemDefaultActiveStateColor};
        }
        display: inline-flex !important;
    }

    & span {
        padding-left: 15px;
        margin-left: -3px;

        @media ${device.laptop} {
            padding-left: 5px;
        }

        @media ${device.mobile} {
            margin-left: -14px;
        }
    }

    @media ${device.mobile} {
        font-size: 12px;
        line-height: 17px;
    }
`

const LocationColumnsWrapper = styled.div<{ isFavourite?: boolean }>`
    position: sticky;
    top: -1px;
    height: 55px;
    margin-left: -15px;
    margin-right: -50px;
    z-index: 103;
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
    color: ${branding.mainInfoColor};
`

const EventDateLocationColumns = styled.div`
    display: flex;
    position: absolute;
    transition: left 0.5s ease-in-out;
    text-align: start;
    left: 30px;
    margin-left: 40px;
`

const NextPageLoaderParent = styled.div`
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    background: ${branding.dangerButtonColor};
    z-index: 10;
`

type GroupProps = {
    dayData: DayData
    isFavourite?: boolean
    revealerRef: (node?: Element | null | undefined) => void
    addBannerHeight?: number
    guestBannerHeight?: number
    onScroll?: Function
    hasMoreData: boolean
    setStartResultRow: (value: any) => void
    numResultRow: number
    isLoaded: boolean
    startResultRow: number
    pointsBadgeData?: Category
    eventDates: EventDate[]
    jumpToLive: number
    calendarModalClose?: () => void
    mySchedule?: boolean
    stagesOrder: Stage[]
}
const columnWidth = 370
const columnColors = ["acc7dc", "c06c84", "bfb1d5", "403d50", "93642e", "fed88f", "546f5a", "907759", "5d3c59", "b87d9b"]

// mostly Program-Page
const ScheduleGroup: React.FunctionComponent<GroupProps> = (props) => {
    const language = useLanguageState().getLanguage()
    const appState = useAppState()
    const timezone = appState.timezone
    const currentTimeMarkerRef = useRef<HTMLDivElement>(null)
    const showCurrentTimeMarker = showCurrentTimeMarkerHelper(props.dayData.date, timezone)
    const checkCurrentDayEnd = checkLatestTime(props.eventDates)

    let locations = Array.from(props.dayData.locations.values())

    let masterClasses = locations.filter((m) => m.name === "Masterclasses")
    const masterClassOverlapCount =
        masterClasses.length === 1 ? props.dayData.helper.getMasterClassColumnWidth(masterClasses[0].events) : 1
    let columnsWidth = (props.dayData.locations.size + masterClassOverlapCount - 1) * columnWidth + 40 + "px"
    const adjustScrollWidth = appState.isCommunicationCenterOpen() ? "calc(100vw - 440px)" : "calc(100vw - 180px)"
    const adjustForHeader = `${(props.addBannerHeight ?? 0) + (props.guestBannerHeight ?? 0) + 340}px`

    const currentTime = props.dayData.helper.getTopForCurrentTime()
    var date = moment()
    var currentDateTime = moment(new Date()).format("HH:mm")

    useEffect(() => {
        if (props.eventDates && props.eventDates.length > 0 && checkCurrentDayEnd) {
            if (props.dayData.date === date.format("YYYY-MM-DD").toString() && currentDateTime < checkCurrentDayEnd) {
                currentTimeMarkerRef.current?.scrollIntoView(false)
                currentTimeMarkerRef.current?.scrollIntoView({ block: "center", inline: "nearest" })
            } else {
                currentTimeMarkerRef.current?.scrollIntoView(false)
                currentTimeMarkerRef.current?.scrollIntoView({ block: "center", inline: "nearest" })
            }
        }
        // eslint-disable-next-line
    }, [props.jumpToLive])

    const onNextPageLoaderVisibilityChange = (isVisible: boolean) => {
        if (isVisible) {
            props.setStartResultRow((e: number) => e + props.numResultRow)
        }
    }

    function formatTimeInEnglish(time: any) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]

        if (time.length > 1) {
            // If time format correct
            time = time.slice(1) // Remove full string match value
            time[5] = +time[0] < 12 ? " AM" : " PM" // Set AM/PM
            time[0] = +time[0] % 12 || 12 // Adjust hours
        }
        return time.join("") // return adjusted time or original string
    }

    return (
        <div>
            <ContentScrollContainer
                adjustForHeaderWith={adjustForHeader}
                scrollType={props.isFavourite ? "round" : ""}
                noScrollX={true}
                handleScroll={(e) => {
                    props.onScroll!(e)
                }}
                width={adjustScrollWidth}
                roundHorizontalScrollbar={true}
                mySchedule={props.mySchedule}
            >
                {!props.isLoaded && props.startResultRow !== 0 && (
                    <NextPageLoaderParent>
                        {" "}
                        <NextPageLoader />
                    </NextPageLoaderParent>
                )}

                <div style={{ marginBottom: "20px", width: columnsWidth, scrollMarginTop: "50px" }}>
                    <EventDateTimeColumn isFavourite={props.isFavourite} float={"left"}>
                        {props.dayData.times.map((time, index) => {
                            const style = {
                                height: props.dayData.helper.getTimeLabelHeight() + "px"
                            }

                            return (
                                <EventDateTimeLabel key={time} style={style} hasBefore={true}>
                                    {language === "en" ? formatTimeInEnglish(time) : time}
                                </EventDateTimeLabel>
                            )
                        })}
                    </EventDateTimeColumn>
                    <LocationColumnsWrapper style={{ width: "auto" }} isFavourite={props.isFavourite}>
                        <EventDateLocationColumns>
                            {locations.map((location, index) => {
                                let isMasterclass = location.name === "Masterclasses"
                                const overlapCount = isMasterclass ? masterClassOverlapCount : 1
                                return (
                                    <EventDateLocationColumn
                                        key={index}
                                        width={overlapCount * columnWidth}
                                        backgroundColor={isMasterclass ? "white" : "transparent"}
                                        justifyContent={"center"}
                                    >
                                        {isMasterclass ? (
                                            <EventDateLocationNameMasterclass style={{ position: "relative", zIndex: 1 }}>
                                                {" "}
                                                <span></span>
                                                <div style={{}}>{location.name}</div>{" "}
                                            </EventDateLocationNameMasterclass>
                                        ) : (
                                            <EventDateLocationName> {location.name} </EventDateLocationName>
                                        )}
                                    </EventDateLocationColumn>
                                )
                            })}
                        </EventDateLocationColumns>
                    </LocationColumnsWrapper>
                    <EventDateTimeTableInnerWrapper style={{ width: columnsWidth }}>
                        {/* program page Current Time Pointer and Line */}
                        {branding.programSchedule.showCurrentTimeLabelAndLine && showCurrentTimeMarker && (
                            <CurrentTimeWrapper
                                style={{ display: checkCurrentDayEnd && currentDateTime < checkCurrentDayEnd ? "flex" : "none" }}
                                ref={currentTimeMarkerRef}
                                top={currentTime.top}
                                width="100%"
                            >
                                <CurrentTimeTitle style={{ marginLeft: "-50px" }}>
                                    {language === "en" ? currentTime.timeEN : currentTime.timeDE}
                                </CurrentTimeTitle>
                                <CurrentTimePointer>
                                    <CurrentTimeLine />
                                </CurrentTimePointer>
                            </CurrentTimeWrapper>
                        )}
                        <EventDateEntryContainer>
                            {locations.map((location, index) => {
                                let isMasterclass = location.name === "Masterclasses" ? true : false
                                const eventDateEntryBackgroundColor =
                                    props.stagesOrder.find((stage: any) => location.name === stage.stage)?.backgroundColor! ??
                                    "#fff"
                                const eventDateEntryBorderColor =
                                    props.stagesOrder.find((stage: any) => location.name === stage.stage)?.borderColor! ?? "#000"
                                const color = isMasterclass ? "#F2F2F2" : "#fff"
                                const overlapCount = isMasterclass
                                    ? props.dayData.helper.getMasterClassColumnWidth(location.events)
                                    : 1
                                return (
                                    <EventDateEntryColumn
                                        key={index}
                                        hasBefore={index < locations.length}
                                        hasAfter={index === locations.length - 1}
                                        eventDates={location.events}
                                        firstColumn={index === 0}
                                        helper={props.dayData.helper}
                                        dayStartTime={props.dayData.dayStartTime}
                                        width={overlapCount * columnWidth}
                                        backgroundColor={color}
                                        eventDateEntryBorderColor={eventDateEntryBorderColor}
                                        eventDateEntryBackgroundColor={eventDateEntryBackgroundColor}
                                        pointsBadgeData={props.pointsBadgeData}
                                        calendarModalClose={props.calendarModalClose}
                                    />
                                )
                            })}
                        </EventDateEntryContainer>
                        <div>
                            {props.dayData.times.map((time, index) => {
                                const style = {
                                    height: props.dayData.helper.getTimeLabelHeight() + "px"
                                }
                                return (
                                    <EventDateTimeLabel
                                        showBorder={true}
                                        key={time}
                                        style={style}
                                        hasBefore={index < props.dayData.times.length}
                                        hasAfter={index === props.dayData.times.length - 1}
                                    >
                                        &nbsp;
                                    </EventDateTimeLabel>
                                )
                            })}
                        </div>
                    </EventDateTimeTableInnerWrapper>
                </div>
                <div style={{ width: columnsWidth }}>
                    {props.hasMoreData && (
                        <InView threshold={0.1} onChange={onNextPageLoaderVisibilityChange}>
                            <div style={{ height: "1px" }} />
                        </InView>
                    )}
                </div>
            </ContentScrollContainer>
        </div>
    )
}

/* #region   My schedule part*/
const MyScheduleRoot = styled.div`
    margin-left: 30px;
    font-family: ${branding.font1};

    .rc-slider {
        top: 15px;
        z-index: 301;
        height: 83%;
        padding-bottom: 20px;
        left: 3px;
    }

    .rc-slider-handle {
        top: 15% !important;
    }

    .ScrollbarsCustom-Content {
        overflow: hidden;
    }

    .entry-column-root {
        padding: 0;
    }

    & #round-scrollable-div {
        margin-top: 1px;
        overflow-x: hidden;

        @media (max-width: 1700px) {
            overflow-x: auto;
        }
    }
`

const TempWrapperColumn = styled.div<{ column?: string }>`
    display: flex;
    justify-content: start;
    width: 100%;
    z-index: 0;
`

const EntryColumnParent = styled.div<{ backgroundShadow?: boolean }>`
    background: none;
    width: 100%;
    display: flex;
    justify-content: center;

    &.mySchedule {
        margin-left: 50px;
    }
`

interface CurrentTimeWrapperProps {
    top: number
    width: string
}
const CurrentTimeWrapper = styled.div<CurrentTimeWrapperProps>`
    width: ${(props) => props.width};
    position: absolute;
    z-index: 102;
    top: ${(props) => props.top + "px"};
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`
const CurrentTimeTitle = styled.div`
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    background: ${branding.programSchedule.currentTimeLabelAndLineBackgroundColor};
    border: 1px solid ${branding.programSchedule.currentTimeLabelAndLineBackgroundColor};
    border-radius: 5px;
    color: ${branding.programSchedule.currentTimeLabelAndLineColor};
    margin-top: -15px;
    margin-left: -50px;
    /* margin-left: 10px; */
    font-size: 14px;
`

const CurrentTimePointer = styled.div`
    /* width: 89%; */
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin-right: 4%; */
`

const CurrentTimeLine = styled.hr`
    border-top: 1px dotted ${branding.programSchedule.currentTimeLabelAndLineBackgroundColor};
    border-width: 2px;
    /* width: 93%; */
    width: 100%;
    margin: 0;

    &.mySchedule {
        border-top: 1px solid ${branding.programSchedule.currentTimeLabelAndLineBackgroundColor};
    }
`

type DateColumnProps = {
    dateColumnsDays?: moment.Moment[]
    isFavourite?: boolean
    revealerRef: (node?: Element | null | undefined) => void
    onScroll?: Function
    dayData: DayData
    dateColumnDays?: moment.Moment[]
    roundScroller?: boolean
    groups: DayData[]
    day?: number
    setUpdatedMeetingKey?: (value: string) => void
    pointsBadgeData?: Category
    isInMyFairPage?: boolean
    dateToShow: moment.Moment
    resetFilterVisible?: boolean
    mySchedule?: boolean
    myScheduleTypeFilter?: MyScheduleTypeFilter
    calendarModalClose?: () => void
    handleMyScheduleScroll: (scrollValues: any) => void
    eventDates: EventDate[]
}

const MyScheduleDateColumnGroup: React.FunctionComponent<DateColumnProps> = (props) => {
    const firstItemRef = useRef<HTMLDivElement>(null)
    const [timeTableWidth, setTimeTableWidth] = useState(100)
    const [roundHorizontalScrollbarIsHidden, setRoundHorizontalScrollbarIsHidden] = useState(true)

    const windowSize = useWindowDimensions()
    const languageState = useLanguageState()

    const language = languageState.getLanguage()

    const myScheduleRef = useRef<HTMLDivElement>(null)
    const columnRef = useRef<HTMLDivElement>(null)

    let allMeetings: CalendarEntry[] = []

    props.groups.forEach((item) => {
        const formattedDateKey = moment(new Date(item.date)).format("YYYY-MM-DD")
        allMeetings.push.apply(allMeetings, item.locations.get(formattedDateKey)?.meetings!)
    })

    useEffect(() => {
        calculateDynamicWidth()
        // eslint-disable-next-line
    }, [props.day])

    useEffect(() => {
        calculateDynamicWidth()
        const timeoutId = setTimeout(function () {
            calculateDynamicWidth()
        }, 1000)
        return function cleanUp() {
            clearTimeout(timeoutId)
        }
        // eslint-disable-next-line
    }, [])

    function calculateDynamicWidth() {
        const entries = document.getElementsByClassName("determineScheduleWidth")
        let maxXCoordinate = 1
        Array.from(entries).forEach((entry: any) => {
            const newValue = entry.offsetWidth + entry.offsetLeft
            if (newValue > maxXCoordinate) {
                maxXCoordinate = newValue
            }
        })
        const margin = 150
        const width = maxXCoordinate + margin

        // only show horizontal scrollbar, when calendar-entries side-by-side are wider than time table
        const timeTableContainer = document.getElementById("horizontal-round-scrollable-div")
        if (timeTableContainer) setRoundHorizontalScrollbarIsHidden(width < timeTableContainer.offsetWidth)
        setTimeTableWidth(width)
    }

    return (
        <MyScheduleRoot className={"my-schedule-root"} style={{ marginLeft: "30px" }} id={props.dayData.date} ref={myScheduleRef}>
            <ContentScrollContainer
                noScrollX={false}
                handleScroll={(e: any) => {
                    props.handleMyScheduleScroll(e)
                }}
                width={props.mySchedule ? "100%" : !props.isFavourite ? props.dayData.locations.size * 370 + "px" : "99%"}
                scrollType={props.roundScroller ? "round" : ""}
                roundHorizontalScrollbar={true}
                mySchedule={props.mySchedule}
                roundHorizontalScrollbarIsHidden={roundHorizontalScrollbarIsHidden}
            >
                <EventDateTimeColumn isFavourite={props.isFavourite} float={"left"} mySchedule={true}>
                    {props.dayData.times.map((time) => {
                        const style = {
                            height: props.dayData.helper.getTimeLabelHeight() + "px"
                        }
                        var date = new Date()
                        date.setHours(parseInt(time.split(":")[0]))
                        date.setMinutes(parseInt(time.split(":")[1]))

                        return (
                            <EventDateTimeLabel
                                key={time}
                                style={style}
                                showBorder={true}
                                hasBefore={true}
                                className="mySchedule"
                            >
                                {language === "en" ? moment(date).format("h:mm a").toUpperCase() : moment(date).format("HH:mm")}
                            </EventDateTimeLabel>
                        )
                    })}
                </EventDateTimeColumn>
                <EventDateTimeTableInnerWrapper className="mySchedule" style={{ width: "max(100%, " + timeTableWidth + "px)" }}>
                    {/* MySchedule-Page Current Time Pointer and Line */}
                    <CurrentTimeIndicator
                        scheduleHelper={props.dayData.helper}
                        firstItemRef={firstItemRef}
                        groups={props.groups}
                        dayData={props.dayData}
                        eventDates={props.eventDates}
                        day={props.day}
                    />
                    <div>
                        {props.dayData.times.map((time) => {
                            const style = {
                                height: props.dayData.helper.getTimeLabelHeight() + "px"
                            }
                            return (
                                <EventDateTimeLabel
                                    key={time}
                                    style={style}
                                    showBorder={true}
                                    hasBefore={true}
                                    className="myScheduleResponsive"
                                >
                                    &nbsp;
                                </EventDateTimeLabel>
                            )
                        })}
                    </div>
                    {Array.from(props.groups).map((group, index: number) => {
                        if (index === props.day!) {
                            return (
                                <EventDateEntryContainer key={index} id={group.date} left="0" mySchedule={true} ref={columnRef}>
                                    {Array.from(group.locations.values()).map((location, index) => {
                                        const color = columnColors[(index - 1 + columnColors.length) % columnColors.length]
                                        return (
                                            <TempWrapperColumn key={index} column={index % 2 === 0 ? "left" : ""}>
                                                <EntryColumnParent
                                                    backgroundShadow={
                                                        moment(new Date()).format("YYYY-MM-DD") ===
                                                        moment(group.date).format("YYYY-MM-DD")
                                                    }
                                                    className={props.mySchedule ? "mySchedule" : ""}
                                                >
                                                    <EventDateEntryColumn
                                                        firstItemRef={firstItemRef}
                                                        key={location.name}
                                                        eventDates={
                                                            props.myScheduleTypeFilter &&
                                                            props.myScheduleTypeFilter?.value! === "meeting"
                                                                ? []
                                                                : location.events
                                                        }
                                                        firstColumn={index === 0}
                                                        helper={props.dayData.helper}
                                                        dayStartTime={props.dayData.dayStartTime}
                                                        width={
                                                            windowSize.width < 1200
                                                                ? 400
                                                                : windowSize.width < 1400
                                                                ? 600
                                                                : windowSize.width < 1800
                                                                ? 800
                                                                : windowSize.width < 2000
                                                                ? 400
                                                                : windowSize.width < 2400
                                                                ? 600
                                                                : 800
                                                        }
                                                        backgroundColor={color}
                                                        meetings={
                                                            props.resetFilterVisible ||
                                                            (props.myScheduleTypeFilter &&
                                                                props.myScheduleTypeFilter?.value! === "eventdate")
                                                                ? []
                                                                : location.meetings
                                                        } // meetings are excluded from results when a user filters My Schedule page
                                                        pointsBadgeData={props.pointsBadgeData}
                                                        mySchedule={props.mySchedule}
                                                        calendarModalClose={props.calendarModalClose}
                                                    />
                                                </EntryColumnParent>
                                            </TempWrapperColumn>
                                        )
                                    })}
                                </EventDateEntryContainer>
                            )
                        }
                        return null
                    })}
                </EventDateTimeTableInnerWrapper>
                <Revealer ref={props.revealerRef} style={{ width: props.dayData.locations.size * 370 + "px" }} />
            </ContentScrollContainer>
        </MyScheduleRoot>
    )
}

/* #endregion */
export function compare(a: any, b: any) {
    var timeA = a.object.hasOwnProperty("startHour") ? a.object.start : moment(new Date(a.object.start)).format("HH:mm")
    var timeB = b.object.hasOwnProperty("startHour") ? b.object.start : moment(new Date(b.object.start)).format("HH:mm")

    const [aHours, aMinutes] = timeA.split(":")
    const aTimeStamp = parseInt(aHours) * 60 + parseInt(aMinutes)

    const [bHours, bMinutes] = timeB.split(":")
    const bTimeStamp = parseInt(bHours) * 60 + parseInt(bMinutes)

    if (aTimeStamp > bTimeStamp) return 1
    if (bTimeStamp > aTimeStamp) return -1

    return 0
}

function compareByEndTime(a: any, b: any) {
    var timeA = a.object.hasOwnProperty("endHour") ? a.object.end : moment(new Date(a.object.end)).format("HH:mm")
    var timeB = b.object.hasOwnProperty("endHour") ? b.object.end : moment(new Date(b.object.end)).format("HH:mm")

    const [aHours, aMinutes] = timeA.split(":")
    const aTimeStamp = parseInt(aHours) * 60 + parseInt(aMinutes)

    const [bHours, bMinutes] = timeB.split(":")
    const bTimeStamp = parseInt(bHours) * 60 + parseInt(bMinutes)

    if (aTimeStamp > bTimeStamp) return 1
    if (bTimeStamp > aTimeStamp) return -1

    if (aTimeStamp > bTimeStamp) return 1
    if (bTimeStamp > aTimeStamp) return -1

    return 0
}

function compareByEndTimeEventDate(a: EventDate, b: EventDate) {
    var x = ("0" + a.endHour).slice(-2) + ":" + ("0" + a.endMinutes).slice(-2)
    var y = ("0" + b.endHour).slice(-2) + ":" + ("0" + b.endMinutes).slice(-2)

    if (x > y) return 1
    if (y > x) return -1

    return 0
}
export function compareByStartTimeEventDate(a: EventDate, b: EventDate) {
    var x = ("0" + a.startHour).slice(-2) + ":" + ("0" + a.startMinutes).slice(-2)
    var y = ("0" + b.startHour).slice(-2) + ":" + ("0" + b.startMinutes).slice(-2)

    if (x > y) return 1
    if (y > x) return -1

    return 0
}

function getOverlappingObjects(all: any[], object: any): any {
    var objectDate = object.hasOwnProperty("startHour") ? object.date : moment(new Date(object.start)).format("YYYY-MM-DD")
    var objectTime = object.hasOwnProperty("startHour") ? object.start : moment(new Date(object.start)).format("HH:mm")
    const overlapping = all.filter((x) =>
        x.object.hasOwnProperty("startHour")
            ? x.object.date === objectDate && x.object.start === objectTime
            : moment(new Date(x.object.start)).format("YYYY-MM-DD") === objectDate &&
              moment(new Date(x.object.start)).format("HH:mm") === objectTime
    )
    return overlapping
}

function isAlreadyAdded(addedItems: string[], myScheduleObject: any) {
    const includes = addedItems.filter(
        (x) =>
            x ===
            (myScheduleObject!.hasOwnProperty("startHour")
                ? myScheduleObject!.start
                : moment(myScheduleObject!.start).format("HH:mm"))
    ).length

    return includes >= 2
}

type EntryColumnProps = {
    eventDates: EventDate[]
    meetings?: CalendarEntry[]
    firstColumn: boolean
    helper: ScheduleHelper
    dayStartTime: Date
    width: number
    backgroundColor: string
    pointsBadgeData?: Category
    eventDateEntryBackgroundColor?: string
    eventDateEntryBorderColor?: string
    hasBefore?: boolean
    hasAfter?: boolean
    mySchedule?: boolean
    calendarModalClose?: () => void
    firstItemRef?: RefObject<HTMLDivElement>
}

function getSemiOverlappingObjects(all: any[], objectToCheck: any): any {
    var objectToCheckStart = objectToCheck.hasOwnProperty("startHour")
        ? objectToCheck.start
        : moment(new Date(objectToCheck.start)).format("HH:mm")

    const overlapping = all.filter((potentialOverlap) =>
        potentialOverlap.object.hasOwnProperty("startHour")
            ? potentialOverlap.object.start < objectToCheckStart && potentialOverlap.object.end > objectToCheckStart
            : moment(new Date(potentialOverlap.object.start)).format("HH:mm") < objectToCheckStart &&
              moment(new Date(potentialOverlap.object.end)).format("HH:mm") > objectToCheckStart
    )
    return overlapping
}

const EventDateEntryColumn: React.FunctionComponent<EntryColumnProps> = (props) => {
    let all: Array<any> = []
    let lastEventDate: any = null

    props.eventDates.forEach((x) => {
        all.push({
            type: "eventdate",
            object: x
        })
    })

    if (props.meetings) {
        props.meetings.forEach((x) => {
            all.push({
                type: "meeting",
                object: x
            })
        })
    }
    // setTimeout(() => {
    if (props.meetings) {
        all = all.sort(compare)
    }
    // }, 1000);

    let overlappingObjects: any[] = []
    let addedItems: string[] = []
    const pathname = useRouteMatch(mySchedulePageRoute)?.isExact
    let zIndex = 0

    return (
        <EventDateEntryColumnRoot
            className={"entry-column-root" + (props.mySchedule ? " mySchedule" : "")}
            width={props.width}
            backgroundColor={props.backgroundColor}
            justifyContent={"start"}
            hasBefore={props.hasBefore}
            hasAfter={props.hasAfter}
        >
            {all.map((item, index) => {
                let marginTop = 0
                let eventDate = props.eventDates.find((d) => d.id === item.object.id)!
                let myScheduleObject = eventDate
                    ? eventDate
                    : props.meetings
                    ? props.meetings!.find((d) => d.id === item.object.id)
                    : null

                //my schedule -> overlapping
                let overlappingTemp: any
                overlappingObjects = []
                if (myScheduleObject) {
                    overlappingTemp = getOverlappingObjects(all, myScheduleObject)
                    if (overlappingTemp.length >= 2) {
                        overlappingTemp.forEach((item: any) => {
                            overlappingObjects.push({
                                object: item.object,
                                height: props.helper.getHeightForEventDate(item.object)
                            })
                        })
                        addedItems.push(
                            myScheduleObject.hasOwnProperty("startHour")
                                ? myScheduleObject.start
                                : moment(myScheduleObject.start).format("HH:mm")
                        )
                    }
                }

                if (lastEventDate === null) marginTop = props.helper.getMarginTop(eventDate ?? myScheduleObject)
                else {
                    marginTop = props.helper.getMarginTopBetween(lastEventDate, eventDate ?? myScheduleObject)
                }
                if (overlappingObjects.length < 2) lastEventDate = eventDate ?? myScheduleObject
                else {
                    const sortedOverlappingObjects = overlappingObjects.sort(function (a, b) {
                        return b.height - a.height
                    })
                    const heightestOverlappingObject =
                        sortedOverlappingObjects && sortedOverlappingObjects.length > 1
                            ? props.eventDates.find((x) => x.id === sortedOverlappingObjects[0].object.id) ||
                              props?.meetings?.find((x) => x.id === sortedOverlappingObjects[0].object.id)
                            : eventDate
                    lastEventDate = heightestOverlappingObject
                }

                let semiOverlappingObjects: any[] = getSemiOverlappingObjects(all, item.object) || []

                if (semiOverlappingObjects.length > 0) {
                    semiOverlappingObjects = semiOverlappingObjects.concat(
                        getSemiOverlappingObjects(all, semiOverlappingObjects[semiOverlappingObjects.length - 1].object)
                    )
                }
                let marginLeft = semiOverlappingObjects.length > 0 ? semiOverlappingObjects.length * 320 : 0

                zIndex++

                let entryContent = (
                    <EventDateEntry
                        key={eventDate ? eventDate.id : myScheduleObject!.id}
                        mySchedule={pathname}
                        eventDate={eventDate}
                        marginTop={marginTop}
                        marginLeft={marginLeft}
                        height={props.helper.getHeightForEventDate(eventDate ? eventDate : myScheduleObject)}
                        myScheduleObject={myScheduleObject}
                        overlappingObjects={overlappingObjects && overlappingObjects.length >= 2 ? overlappingObjects : null}
                        isAdded={isAlreadyAdded(addedItems, myScheduleObject)}
                        zIndex={zIndex}
                        pointBadgeData={props.pointsBadgeData}
                        background={props.eventDateEntryBackgroundColor ?? "#fff"}
                        border={props.eventDateEntryBorderColor !== "" ? props.eventDateEntryBorderColor : "#000"}
                        source="SCHEDULE"
                        calendarModalClose={props.calendarModalClose}
                    />
                )

                return index === 0 ? <div ref={props.firstItemRef}>{entryContent}</div> : <div>{entryContent}</div>
            })}
        </EventDateEntryColumnRoot>
    )
}

interface CurrentTimeIndicatorProps {
    scheduleHelper: ScheduleHelper
    firstItemRef: RefObject<HTMLDivElement> | undefined
    groups: DayData[]
    dayData: DayData
    eventDates?: EventDate[]
    day?: number
}

const CurrentTimeIndicator: React.FunctionComponent<CurrentTimeIndicatorProps> = (props) => {
    const [currentTime, setCurrentTime] = useState(props.scheduleHelper.getTopForCurrentTime())
    const currentTimeMarkerRef = useRef<HTMLDivElement>(null)
    const language = useLanguageState().getLanguage()
    const updateClockEveryXSeconds = 60
    const selectedDate = props.groups[props.day ?? props.groups.length - 1].date

    var date = moment()

    function refreshClock() {
        setCurrentTime(props.scheduleHelper.getTopForCurrentTime())
    }
    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000 * updateClockEveryXSeconds)
        return function cleanup() {
            clearInterval(timerId)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let timeoutId: NodeJS.Timeout
        const element = document.getElementById("horizontal-round-scrollable-div")
        if (!element) return
        // delayed scroll so it works when opening MySchedule for the first time
        if (isToday(moment(selectedDate)) && selectedDate === date.format("YYYY-MM-DD").toString()) {
            // today: scroll to current time
            timeoutId = setTimeout(function () {
                element.scrollTo({ top: currentTime.top - 300, left: 0, behavior: "smooth" })
            }, 1000)
        } else if (props.firstItemRef && props.firstItemRef.current) {
            // not today: scroll to first item
            timeoutId = setTimeout(function () {
                if (props.firstItemRef && props.firstItemRef.current) {
                    element.scrollTo({ top: props.firstItemRef.current.offsetTop - 150, behavior: "smooth" })
                }
            }, 1000)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
        // eslint-disable-next-line
    }, [currentTimeMarkerRef.current, props.firstItemRef, props.day])

    function checkIfCurrentDate() {
        return props.day && isToday(moment(props.groups[props.day].date))
    }

    if (!checkIfCurrentDate()) return <></>

    return (
        <CurrentTimeWrapper
            style={{
                display: "flex"
            }}
            top={currentTime.top}
            width={"100%"}
            ref={currentTimeMarkerRef}
        >
            <CurrentTimeTitle style={{ marginLeft: "-50px" }}>
                {language === "en" ? currentTime.timeEN : currentTime.timeDE}
            </CurrentTimeTitle>
            <CurrentTimePointer>
                <CurrentTimeLine />
            </CurrentTimePointer>
        </CurrentTimeWrapper>
    )
}

const EventDateLocationName = styled.div`
    align-self: center;
    line-height: 20px;
    font-size: 16px;
    padding-left: 20px;
    font-family: ${branding.font1};

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`
const EventDateLocationNameMasterclass = styled(EventDateLocationName)`
    width: 100%;
    position: relative;
    text-align: center;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: ${branding.mainInfoColor};

    span {
        content: " ";
        border-top: 1px solid black;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        z-index: -1;
    }

    div {
        background-color: white;
        display: inline-block;
        width: 200px;
    }
`

// TODO There should be a better way to do this
function getGroupsByDayMySchedule(
    eventDates: EventDate[],
    meetings: CalendarEntry[],
    timezone: string,
    eventDays: moment.Moment[]
): DayData[] {
    let groups: DayData[] = []
    const dayFormat = "YYYY-MM-DD"

    eventDays.forEach((date, index) => {
        const formattedDate = date.format(dayFormat)
        const currentDayData = {
            date: formattedDate,
            locations: new Map(),
            times: [],
            dayStartTime: new Date(),
            helper: new ScheduleHelper()
        }

        currentDayData.locations.set(formattedDate, { name: formattedDate, events: [], meetings: [] })
        groups.push(currentDayData)
    })
    groups.forEach((groupData) => {
        eventDates.forEach((eventDate, index) => {
            var formattedDate = moment(eventDate.date).format(dayFormat)
            let location = groupData.locations.get(formattedDate)
            if (location) {
                location.events.push(eventDate)
            }
        })

        meetings.forEach((meeting: any, index: number) => {
            var date = new Date(meeting.start)
            var formattedDate = moment(date).format(dayFormat)

            if (groupData.date === formattedDate) {
                let location = groupData.locations.get(formattedDate)
                if (location && location!.meetings) {
                    location.meetings.push(meeting)
                }
            }
        })

        if (groupData.locations) {
            let firstLastObject = getFirstLastTime(eventDates, meetings)

            if (firstLastObject)
                groupData!.helper.updateTimes(
                    firstLastObject.start && firstLastObject.start !== "aN:aN"
                        ? firstLastObject.start
                        : branding.eventTiming.eventDateDefaultStartTime,
                    firstLastObject.end && firstLastObject.end !== "aN:aN"
                        ? firstLastObject.end
                        : branding.eventTiming.eventDateDefaultEndTime,
                    groupData
                )
        }
    })
    return groups
}

function getFirstLastTime(eventDates: EventDate[], meetings: CalendarEntry[]) {
    let all: any = []

    eventDates.forEach((x) => {
        all.push({
            type: "eventdate",
            object: x
        })
    })

    meetings.forEach((x) => {
        all.push({
            type: "meeting",
            object: x
        })
    })

    all = all.sort(compareEventDatesMeetings)
    if (all.length !== 0) {
        var dateA = new Date(all[0].object.dateTimeStart ?? all[0].object.start)

        var x = all[0].object.hasOwnProperty("startHour")
            ? ("0" + all[0].object.startHour).slice(-2) + ":" + ("0" + all[0].object.startMinutes).slice(-2)
            : ("0" + dateA.getHours()).slice(-2) + ":" + ("0" + dateA.getMinutes()).slice(-2)

        all = all.sort(compareByEndTime)
        var dateB = new Date(all[all.length - 1].object.dateTimeStart ?? all[all.length - 1].object.start)
        var y = all[all.length - 1].object.hasOwnProperty("endHour")
            ? ("0" + all[all.length - 1].object.endHour).slice(-2) + ":" + ("0" + all[all.length - 1].object.endMinutes).slice(-2)
            : ("0" + dateB.getHours()).slice(-2) + ":" + ("0" + dateB.getMinutes()).slice(-2)

        return {
            start: x,
            end: y
        }
    }
    return null
}
