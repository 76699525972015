import styled from "styled-components"
import branding from "../../../branding/branding"

export const OnlyBookmarksButtonRoot = styled.div`
    display: flex;
    font-family: ${branding.font1};
    height: 38px;
    width: 54px;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border: 1px;
    border-style: solid;
    border-color: ${branding.programSpeakers.speakersListDividerColor ?? "#000"};
    color: ${branding.programSpeakers.speakersListDividerColor ?? "#000"};
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
`
