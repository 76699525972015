import { useState, useEffect, useRef } from "react"
import * as React from "react"
import styled from "styled-components"
import { IconArrowLeftCarousel, IconArrowRightCarousel, IconCloseBanner } from "../../ui/Icons"
import branding from "../../branding/branding"
import { DetailNavLink } from "../detailPages/DetailNavLink"
import Slider from "react-slick"
import { BannerPool } from "./TopBannerBranding"
import { DesktopVersionContainer } from "../../utils/Device"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { trackBannerClick, trackViewPromotion } from "../../utils/GTMTracking"
import { capitalizeFirstLetter } from "../../utils/StringUtils"
import { Exhibitor } from "../../backendServices/Types"
import { loadExhibitorsData } from "../../backendServices/ExhibitorServices"

const TopBannerContent = styled.div`
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    & img {
        width: 100%;
        height: 100%;
        max-height: 280px;
        object-fit: cover;
    }
`

const TopBannerCloseBtn = styled.div<{ hide: boolean }>`
    position: absolute;
    z-index: 2;
    right: 20px;
    top: 20px;
    display: ${(props) => (props.hide ? "none" : "flex")};
    height: 27px;
    width: 27px;
    padding: 1px;
    background-color: #fff;
    align-items: center;
    border-radius: 25px;
    opacity: ${(props) => (props.hide ? 0 : 1)};
    transition: opacity 0.3s;
    cursor: pointer;
    & span {
        display: block;
        text-transform: uppercase;
        font-family: ${branding.font1};
        margin: 0 0.9rem 0 0.1rem;
        font-size: 12px;
    }
    & .close-icon-wrapper {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        border-radius: 50%;

        & svg * {
            stroke: #fff !important;
        }
    }
`
const MainCarouselDiv = styled.div<{ hide?: boolean }>`
    position: relative;
    .slick-next,
    .slick-prev {
        width: 25px;
        height: 25px;
    }
    .slick-prev {
        left: 20px !important;
        z-index: 1;
    }
    .slick-next {
        right: 20px !important;
        z-index: 1;
    }
    .slick-dots {
        bottom: 20px !important;
    }
    .slick-dots li.slick-active button:before {
        color: #fff !important;
    }

    .slick-prev:before,
    .slick-next:before {
        font-size: 25px;
        opacity: 1;
    }

    .slick-dots li button:before {
        color: #fff !important;
        font-size: 10px;
        cursor: pointer;
    }

    .slick-slide img {
        display: flex;
    }
`

const IconWrapper = styled.div`
    &::before {
        content: none;
    }
`

interface TopBannerProps {
    type: string
    onHideBanner: Function
    hideOnScroll?: boolean
    setRef: Function
}

const TopBanner: React.FunctionComponent<TopBannerProps> = (props) => {
    const abortController = new AbortController()
    const abortSignal = abortController.signal

    const [sponsors, setSponsors] = useState<Exhibitor[]>()

    const [showBanner, setShowBanner] = useState<boolean>(true)

    const bannerPool = branding.topBanner.bannerPools.find((pool: BannerPool) => pool.title === props.type)

    const { isMobile } = useWindowDimensions()

    const topBannerRef = useRef<HTMLDivElement>(null)

    const getOrganizationName = (id: string): string | undefined => {
        let orgaName: string | undefined = undefined

        if (sponsors) {
            orgaName = sponsors.find((orga) => orga.id === id)?.name
        }

        return orgaName
    }

    useEffect(() => {
        window.sessionStorage.setItem("trackViewPromotion", "")

        return () => {
            window.sessionStorage.removeItem("trackViewPromotion")
        }
    }, [])

    useEffect(() => {
        if (topBannerRef && topBannerRef.current) props.setRef(topBannerRef)
        // eslint-disable-next-line
    }, [topBannerRef])

    useEffect(() => {
        if (bannerPool?.items && bannerPool?.items[0]) {
            //list of sponsors featured in banners is used for proper linking of their detail pages and for Google Tag Manager tracking
            let idList: string[] = []

            bannerPool?.items.forEach((item) => {
                if (item.id) {
                    idList.push("id_orga_" + item.id)
                }
            })

            let sponsorSlotParams: any = {
                filterlist: "entity_orga, " + idList.join(", "),
                startresultrow: 0,
                order: "lexic"
            }

            loadExhibitorsData(sponsorSlotParams, abortSignal)
                .then((resp) => {
                    const ex = resp.entities as Exhibitor[]
                    if (ex) {
                        setSponsors(ex)
                    }
                })
                .finally(() => {
                    trackViewPromotion(
                        bannerPool?.items[0]?.name,
                        bannerPool?.items[0]?.id,
                        getOrganizationName(bannerPool?.items[0]?.id),
                        true
                    )
                })
        }
        // eslint-disable-next-line
    }, [bannerPool])

    if (!bannerPool || isMobile) return null

    const renderBannerSlides = () =>
        bannerPool?.items.map((item, index) =>
            item?.externalLink ? (
                <a
                    key={index}
                    href={item.externalLink}
                    target={item.externalLink === "/" ? "" : "blank"}
                    onClick={() => {
                        trackBannerClick(
                            item.id,
                            item.name,
                            capitalizeFirstLetter(bannerPool.title),
                            getOrganizationName(item.id)
                        )
                    }}
                >
                    <TopBannerContent>
                        <img src={"/branding/contentMedia/advertisingBanners/" + item?.image || ""} alt="" />
                    </TopBannerContent>
                </a>
            ) : item?.internalLink && item.internalLink.indexOf("/company/") < 0 ? (
                <a
                    key={index}
                    href={item.internalLink}
                    target={item.internalLink === "/" ? "" : "blank"}
                    onClick={() => {
                        trackBannerClick(
                            item.id,
                            item.name,
                            capitalizeFirstLetter(bannerPool.title),
                            getOrganizationName(item.id)
                        )
                    }}
                >
                    <TopBannerContent>
                        <img src={"/branding/contentMedia/advertisingBanners/" + item?.image || ""} alt="" />
                    </TopBannerContent>
                </a>
            ) : (
                <DetailNavLink
                    key={index}
                    id={item?.id || ""}
                    type="organization"
                    name={getOrganizationName(item.id) || item?.name || ""}
                    width={"100%"}
                    source="BANNER"
                    searchKrit={props.type}
                    onClick={() => {
                        trackBannerClick(
                            item.id,
                            item.name,
                            capitalizeFirstLetter(bannerPool.title),
                            getOrganizationName(item.id)
                        )
                    }}
                >
                    <TopBannerContent>
                        <img src={"/branding/contentMedia/advertisingBanners/" + item?.image || ""} alt="" />
                    </TopBannerContent>
                </DetailNavLink>
            )
        )

    return (
        <DesktopVersionContainer>
            <MainCarouselDiv ref={topBannerRef} hide={!showBanner || (showBanner && (props.hideOnScroll as boolean))}>
                {branding.sideIconBar.showCloseBannerIcon && (
                    <TopBannerCloseBtn
                        hide={!showBanner || (props.hideOnScroll !== undefined && props.hideOnScroll)}
                        onClick={() => {
                            setShowBanner(false)
                            props.onHideBanner()
                        }}
                        className={"close-icon-wrapper"}
                    >
                        {IconCloseBanner({ fill: branding.sideIconBar.sideIconColorDark, width: "18", height: "18" })}
                    </TopBannerCloseBtn>
                )}
                {!((props.hideOnScroll !== undefined && props.hideOnScroll) || !showBanner) && (
                    <Slider
                        dots={true}
                        slidesToShow={1}
                        slidesToScroll={1}
                        autoplay={bannerPool?.items.length! > 1 ? true : false}
                        autoplaySpeed={branding.topBanner.autoPlayValue ?? 3000}
                        nextArrow={
                            <IconWrapper>{IconArrowRightCarousel({ width: "19px", height: "30px", fill: "#fff" })}</IconWrapper>
                        }
                        prevArrow={
                            <IconWrapper>{IconArrowLeftCarousel({ width: "19px", height: "30px", fill: "#fff" })}</IconWrapper>
                        }
                        afterChange={(currentSlide: number) =>
                            trackViewPromotion(
                                bannerPool?.items[currentSlide]?.name,
                                bannerPool?.items[currentSlide]?.id,
                                getOrganizationName(bannerPool?.items[currentSlide]?.id),
                                true
                            )
                        }
                    >
                        {renderBannerSlides()}
                    </Slider>
                )}
            </MainCarouselDiv>
        </DesktopVersionContainer>
    )
}

export default TopBanner
