import { Camera, Microphone, Phone, Sound } from "amazon-chime-sdk-component-library-react"
import { useCallback, useEffect, useState } from "react"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"
import { useHistory } from "react-router-dom"
import branding from "../../../branding/branding"
import { BackToFullscreenIcon, IconClose, IconStartScreenShare, IconStopScreenShare } from "../../../ui/Icons"
import { RaisedHandContext } from "../../ConferenceRoom/ConferenceRoom"
import { useAudioContext } from "../../context/AudioContext"
import { useControlBarContext } from "../../context/ControlBarContext"
import { useMeetingController } from "../../context/MeetingController"
import { useVideoContext } from "../../context/VideoContext"
import useCallDurationTimer from "../../hooks/useCallDurationTimer"
import { ControlButton, ControlButtonSize } from "../ControlBar/ControlBar"
import { ControlButtons } from "../ControlBar/ControlBar.styled"
import {
    BackToMeetingButton,
    ConferenceOverlayV2Controls,
    ConferenceOverlayV2Header,
    ConferenceOverlayV2Root,
    MeetingTitle,
    Time
} from "./ConferenceOverlayV2S.styled"
import { MinimizedLayout } from "./MinimizedLayout/MinimizedLayout"
import { useRaisedHands } from "../../hooks/useRaisedHands"
import { loadEventDateDetails } from "../../../backendServices/EventdateServices"
import moment from "moment"
import { deviceSize } from "../../../utils/Device"

function ConferenceOverlayV2() {
    const [positionChangedByUser, setPositionChangedByUser] = useState(false)
    const [position, setPosition] = useState<any>(
        window.innerWidth <= 1920
            ? { x: window.innerWidth - 360, y: window.innerHeight - 220 }
            : { x: window.innerWidth - 428, y: window.innerHeight - 260 }
    )

    const { isVideoEnabled, isContentShareEnabled, toggleVideo, toggleContentShare } = useVideoContext()
    const { isMuted, isAudioOn, toggleMute, toggleAudio } = useAudioContext()
    const { leave } = useControlBarContext()
    const meetingController = useMeetingController()
    const history = useHistory()
    const microphoneIcon = <Microphone muted={isMuted()} color={!isMuted() ? "#202428" : "#fff"} width="28px" />
    const soundIcon = <Sound disabled={!isAudioOn()} color={isAudioOn() ? "#202428" : "#fff"} width="28px" />
    const cameraIcon = <Camera disabled={!isVideoEnabled()} color={isVideoEnabled() ? "#202428" : "#fff"} width="28px" />
    const screenShareIcon = !isContentShareEnabled() ? (
        <IconStartScreenShare stroke={"#fff"} fill={"#fff"} width="18px" height="18px" />
    ) : (
        <IconStopScreenShare stroke={"#202428"} fill={"#202428"} width="19px" height="19px" />
    )
    const phoneIcon = <Phone color="#fff" width="28px" />
    const [isMouseHovering, setIsMouseHovering] = useState(false)
    const callDurationTimer = useCallDurationTimer(meetingController.getCallDuration() || 0)
    const buttonSize = window.innerWidth <= deviceSize.desktop ? ControlButtonSize.Small : ControlButtonSize.Medium
    const raisedHands = useRaisedHands()

    useEffect(() => {
        edgeDetection()
        // eslint-disable-next-line
    }, [window.innerWidth, window.innerHeight])

    useEffect(() => {
        callDurationTimer.start()
        return () => {
            meetingController.setCallDuration(callDurationTimer.duration)
        }
        // eslint-disable-next-line
    }, [callDurationTimer.duration])

    useEffect(() => {
        meetingController.setIsConferenceOverlayVisible(true)
        return () => {
            meetingController.setIsConferenceOverlayVisible(false)
        }
        // eslint-disable-next-line
    }, [meetingController.isConferenceOverlayVisible()])

    useEffect(() => {
        let leaveRoomTimeout: NodeJS.Timeout
        function cleanUp() {
            clearTimeout(leaveRoomTimeout)
        }

        if (meetingController.getMeetingKind() !== "roundtable") return cleanUp

        const meetingId = meetingController.getExternalMeetingId()?.split("rt_")[1]
        const params = { id: meetingId }
        loadEventDateDetails(params).then((response) => {
            const eventDate = response.content
            const secondsToEnd = moment(eventDate.enddatetime).diff(
                moment(moment(new Date()).format("yyyy-MM-DD HH:mm:ss")),
                "seconds"
            )
            const closeRoomAfterMilliseconds = secondsToEnd * 1000 + branding.configuration.roundTableOpenAfterMinutes * 60 * 1000
            if (closeRoomAfterMilliseconds <= 0) {
                // roundtable is over
                meetingController.leaveMeeting(true)
                return
            } else {
                // roundtable is live or roundtable is over, but less then roundTableOpenAfterMinutes minutes have passed
                leaveRoomTimeout = setTimeout(() => {
                    meetingController.leaveMeeting(true)
                }, closeRoomAfterMilliseconds)
            }
        })

        return cleanUp
        // eslint-disable-next-line
    }, [])

    const edgeDetection = useCallback(() => {
        // edge detection for default positions
        if (!positionChangedByUser) {
            if (window.innerWidth <= 1920) {
                setPosition({ x: window.innerWidth - 360, y: window.innerHeight - 220 })
            } else {
                setPosition({ x: window.innerWidth - 428, y: window.innerHeight - 260 })
            }
        }
        // edge detection for positions after the user replaced the draggable
        if (window.innerWidth <= 1920) {
            if (position && position.x + 360 >= window.innerWidth) {
                setPosition((position: any) => ({ x: window.innerWidth - 360, y: position.y }))
            }
        } else if (window.innerWidth >= 1920) {
            if (position && position.x + 428 >= window.innerWidth) {
                setPosition((position: any) => ({ x: window.innerWidth - 428, y: position.y }))
            }
        }
        // eslint-disable-next-line
    }, [window.innerWidth, window.innerHeight])

    const handleDrag = (event: DraggableEvent, data: DraggableData) => {
        setPositionChangedByUser(true)
        setPosition({ x: data.x, y: data.y })
    }

    return (
        <Draggable bounds={"html"} onDrag={handleDrag} handle=".handle" position={position} defaultPosition={position} scale={1}>
            <ConferenceOverlayV2Root
                className="handle"
                onMouseEnter={() => setIsMouseHovering(true)}
                onMouseLeave={() => setIsMouseHovering(false)}
            >
                <RaisedHandContext.Provider value={raisedHands}>
                    <ConferenceOverlayV2Header className={"hideUIElements"}>
                        <MeetingTitle>{meetingController.getMeetingTitle()}</MeetingTitle>
                        <Time>
                            {meetingController.getIsUserKickedOrBanned() ? "--:--" : callDurationTimer.formattedDuration()}
                        </Time>
                        <BackToMeetingButton
                            onClick={() => {
                                if (meetingController.getIsUserKickedOrBanned()) {
                                    meetingController.cleanUp()
                                } else {
                                    history.push(`/meetingV2/${meetingController.getExternalMeetingId()}`)
                                }
                            }}
                        >
                            {meetingController.getIsUserKickedOrBanned() ? (
                                <IconClose fill="#fff" width="13px" height="13px" />
                            ) : (
                                <BackToFullscreenIcon fill="#fff" width="15px" height="15px" />
                            )}
                        </BackToMeetingButton>
                    </ConferenceOverlayV2Header>

                    <MinimizedLayout isMouseHovering={isMouseHovering} />

                    {!meetingController.getIsUserKickedOrBanned() && (
                        <ConferenceOverlayV2Controls className={"hideUIElements"}>
                            <ControlButtons>
                                <ControlButton
                                    label={isMuted() ? branding.conferenceTexts.unmute : branding.conferenceTexts.mute}
                                    active={!isMuted()}
                                    size={buttonSize}
                                    onClick={toggleMute}
                                    icon={microphoneIcon}
                                />
                                <ControlButton
                                    label={!isAudioOn() ? branding.conferenceTexts.soundOff : branding.conferenceTexts.soundOn}
                                    active={isAudioOn()}
                                    size={buttonSize}
                                    onClick={toggleAudio}
                                    icon={soundIcon}
                                />
                                <ControlButton
                                    label={
                                        isVideoEnabled() ? branding.conferenceTexts.videoOn : branding.conferenceTexts.videoOff
                                    }
                                    active={isVideoEnabled()}
                                    size={buttonSize}
                                    onClick={toggleVideo}
                                    icon={cameraIcon}
                                />
                                <ControlButton
                                    label={
                                        isContentShareEnabled()
                                            ? branding.conferenceTexts.shareScreenStop
                                            : branding.conferenceTexts.shareScreenStart
                                    }
                                    active={isContentShareEnabled()}
                                    size={buttonSize}
                                    onClick={toggleContentShare}
                                    icon={screenShareIcon}
                                />
                                <ControlButton
                                    label={branding.conferenceTexts.leave}
                                    size={buttonSize}
                                    onClick={leave}
                                    backgroundColor={"#E30613"}
                                    icon={phoneIcon}
                                />
                            </ControlButtons>
                        </ConferenceOverlayV2Controls>
                    )}
                </RaisedHandContext.Provider>
            </ConferenceOverlayV2Root>
        </Draggable>
    )
}

export default ConferenceOverlayV2
