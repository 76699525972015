/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const _emptyMutation = /* GraphQL */ `
  mutation _emptyMutation {
    _emptyMutation
  }
`;
export const createCalendarEntry = /* GraphQL */ `
  mutation CreateCalendarEntry(
    $condition: ModelCalendarEntryConditionInput
    $input: CreateCalendarEntryInput!
  ) {
    createCalendarEntry(condition: $condition, input: $input) {
      createdAt
      description
      end
      id
      isVirtual
      locationActionParam
      locationActionType
      locationName
      organizationId
      organizationIdStatus
      participants {
        nextToken
      }
      start
      status
      title
      topicName
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userPoolName
    }
  }
`;
export const createCalendarEntryParticipation = /* GraphQL */ `
  mutation CreateCalendarEntryParticipation(
    $condition: ModelCalendarEntryParticipationConditionInput
    $input: CreateCalendarEntryParticipationInput!
  ) {
    createCalendarEntryParticipation(condition: $condition, input: $input) {
      calendarEntry {
        createdAt
        description
        end
        id
        isVirtual
        locationActionParam
        locationActionType
        locationName
        organizationId
        organizationIdStatus
        start
        status
        title
        topicName
        updatedAt
        userId
        userPoolName
      }
      calendarEntryId
      createdAt
      id
      start
      status
      statusUpdateMessage
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userIdStatus
    }
  }
`;
export const createCalendarEntryWithParticipants = /* GraphQL */ `
  mutation CreateCalendarEntryWithParticipants(
    $condition: ModelCalendarEntryConditionInput
    $input: CreateCalendarEntryWithParticipantsInput!
  ) {
    createCalendarEntryWithParticipants(condition: $condition, input: $input) {
      createdAt
      description
      end
      id
      isVirtual
      locationActionParam
      locationActionType
      locationName
      organizationId
      organizationIdStatus
      participants {
        nextToken
      }
      start
      status
      title
      topicName
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userPoolName
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $condition: ModelConversationConditionInput
    $input: CreateConversationInput!
  ) {
    createConversation(condition: $condition, input: $input) {
      createdAt
      description
      id
      memberIdsHash
      members {
        nextToken
      }
      messages {
        nextToken
        startedAt
      }
      mostRecentMessage
      mostRecentMessageCreatedAt
      name
      type
      updatedAt
      userId
    }
  }
`;
export const createMeeting = /* GraphQL */ `
  mutation CreateMeeting(
    $condition: ModelMeetingConditionInput
    $input: CreateMeetingInput!
  ) {
    createMeeting(condition: $condition, input: $input) {
      createdAt
      end
      id
      participants {
        nextToken
      }
      start
      updatedAt
    }
  }
`;
export const createMeetingParticipant = /* GraphQL */ `
  mutation CreateMeetingParticipant(
    $condition: ModelMeetingParticipantConditionInput
    $input: CreateMeetingParticipantInput!
  ) {
    createMeetingParticipant(condition: $condition, input: $input) {
      created
      createdAt
      id
      invitationType
      invitee {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      inviteeId
      inviter {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      inviterId
      meeting {
        createdAt
        end
        id
        start
        updatedAt
      }
      meetingId
      meetingTitle
      status
      updatedAt
    }
  }
`;
export const createMeetingRoomAttendance = /* GraphQL */ `
  mutation CreateMeetingRoomAttendance(
    $condition: ModelMeetingRoomAttendanceConditionInput
    $input: CreateMeetingRoomAttendanceInput!
  ) {
    createMeetingRoomAttendance(condition: $condition, input: $input) {
      chimeMeetingId
      createdAt
      id
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $condition: ModelMessageConditionInput
    $input: CreateMessageInput!
  ) {
    createMessage(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      author {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      authorId
      content
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isSent
      members
      sotName
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $condition: ModelNotificationConditionInput
    $input: CreateNotificationInput!
  ) {
    createNotification(condition: $condition, input: $input) {
      actionParam
      actionType
      content
      createdAt
      displayGroup
      id
      read
      type
      updatedAt
      userId
      userIdDisplayGroup
    }
  }
`;
export const createRaisedHand = /* GraphQL */ `
  mutation CreateRaisedHand(
    $condition: ModelRaisedHandConditionInput
    $input: CreateRaisedHandInput!
  ) {
    createRaisedHand(condition: $condition, input: $input) {
      chimeMeetingId
      createdAt
      id
      updatedAt
    }
  }
`;
export const createUnreadCounter = /* GraphQL */ `
  mutation CreateUnreadCounter(
    $condition: ModelUnreadCounterConditionInput
    $input: CreateUnreadCounterInput!
  ) {
    createUnreadCounter(condition: $condition, input: $input) {
      contacts
      conversations
      createdAt
      id
      notifications
      requests
      schedules
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $condition: ModelUserConditionInput
    $input: CreateUserInput!
  ) {
    createUser(condition: $condition, input: $input) {
      conversations {
        nextToken
      }
      createdAt
      id
      incomingMeetings {
        nextToken
      }
      lastConnected
      name
      outgoingMeetings {
        nextToken
      }
      pictureUrl
      presenceStatus
      updatedAt
    }
  }
`;
export const createUserAction = /* GraphQL */ `
  mutation CreateUserAction(
    $condition: ModelUserActionConditionInput
    $input: CreateUserActionInput!
  ) {
    createUserAction(condition: $condition, input: $input) {
      actionType
      countryCode
      createdAt
      duration
      endTime
      id
      param
      sotName
      source
      startTime
      topic
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userType
    }
  }
`;
export const createUserConversation = /* GraphQL */ `
  mutation CreateUserConversation(
    $condition: ModelUserConversationConditionInput
    $input: CreateUserConversationInput!
  ) {
    createUserConversation(condition: $condition, input: $input) {
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isChatOpen
      isMuted
      lastReadMessageCreatedAt
      mostRecentMessageCreatedAt
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
    }
  }
`;
export const createUserSession = /* GraphQL */ `
  mutation CreateUserSession(
    $condition: ModelUserSessionConditionInput
    $input: CreateUserSessionInput!
  ) {
    createUserSession(condition: $condition, input: $input) {
      countryCode
      createdAt
      id
      location
      locationLevel1
      locationLevel2
      locationLevel3
      queryHelper
      sotName
      source
      time
      topic
      ttl
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userType
    }
  }
`;
export const deleteCalendarEntry = /* GraphQL */ `
  mutation DeleteCalendarEntry(
    $condition: ModelCalendarEntryConditionInput
    $input: DeleteCalendarEntryInput!
  ) {
    deleteCalendarEntry(condition: $condition, input: $input) {
      createdAt
      description
      end
      id
      isVirtual
      locationActionParam
      locationActionType
      locationName
      organizationId
      organizationIdStatus
      participants {
        nextToken
      }
      start
      status
      title
      topicName
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userPoolName
    }
  }
`;
export const deleteCalendarEntryParticipation = /* GraphQL */ `
  mutation DeleteCalendarEntryParticipation(
    $condition: ModelCalendarEntryParticipationConditionInput
    $input: DeleteCalendarEntryParticipationInput!
  ) {
    deleteCalendarEntryParticipation(condition: $condition, input: $input) {
      calendarEntry {
        createdAt
        description
        end
        id
        isVirtual
        locationActionParam
        locationActionType
        locationName
        organizationId
        organizationIdStatus
        start
        status
        title
        topicName
        updatedAt
        userId
        userPoolName
      }
      calendarEntryId
      createdAt
      id
      start
      status
      statusUpdateMessage
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userIdStatus
    }
  }
`;
export const deleteMeetingRoomAttendance = /* GraphQL */ `
  mutation DeleteMeetingRoomAttendance(
    $condition: ModelMeetingRoomAttendanceConditionInput
    $input: DeleteMeetingRoomAttendanceInput!
  ) {
    deleteMeetingRoomAttendance(condition: $condition, input: $input) {
      chimeMeetingId
      createdAt
      id
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $condition: ModelMessageConditionInput
    $input: DeleteMessageInput!
  ) {
    deleteMessage(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      author {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      authorId
      content
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isSent
      members
      sotName
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $condition: ModelNotificationConditionInput
    $input: DeleteNotificationInput!
  ) {
    deleteNotification(condition: $condition, input: $input) {
      actionParam
      actionType
      content
      createdAt
      displayGroup
      id
      read
      type
      updatedAt
      userId
      userIdDisplayGroup
    }
  }
`;
export const deleteRaisedHand = /* GraphQL */ `
  mutation DeleteRaisedHand(
    $condition: ModelRaisedHandConditionInput
    $input: DeleteRaisedHandInput!
  ) {
    deleteRaisedHand(condition: $condition, input: $input) {
      chimeMeetingId
      createdAt
      id
      updatedAt
    }
  }
`;
export const deleteUnreadCounter = /* GraphQL */ `
  mutation DeleteUnreadCounter(
    $condition: ModelUnreadCounterConditionInput
    $input: DeleteUnreadCounterInput!
  ) {
    deleteUnreadCounter(condition: $condition, input: $input) {
      contacts
      conversations
      createdAt
      id
      notifications
      requests
      schedules
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $condition: ModelUserConditionInput
    $input: DeleteUserInput!
  ) {
    deleteUser(condition: $condition, input: $input) {
      conversations {
        nextToken
      }
      createdAt
      id
      incomingMeetings {
        nextToken
      }
      lastConnected
      name
      outgoingMeetings {
        nextToken
      }
      pictureUrl
      presenceStatus
      updatedAt
    }
  }
`;
export const deleteUserAction = /* GraphQL */ `
  mutation DeleteUserAction(
    $condition: ModelUserActionConditionInput
    $input: DeleteUserActionInput!
  ) {
    deleteUserAction(condition: $condition, input: $input) {
      actionType
      countryCode
      createdAt
      duration
      endTime
      id
      param
      sotName
      source
      startTime
      topic
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userType
    }
  }
`;
export const deleteUserConversation = /* GraphQL */ `
  mutation DeleteUserConversation(
    $condition: ModelUserConversationConditionInput
    $input: DeleteUserConversationInput!
  ) {
    deleteUserConversation(condition: $condition, input: $input) {
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isChatOpen
      isMuted
      lastReadMessageCreatedAt
      mostRecentMessageCreatedAt
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
    }
  }
`;
export const deleteUserSession = /* GraphQL */ `
  mutation DeleteUserSession(
    $condition: ModelUserSessionConditionInput
    $input: DeleteUserSessionInput!
  ) {
    deleteUserSession(condition: $condition, input: $input) {
      countryCode
      createdAt
      id
      location
      locationLevel1
      locationLevel2
      locationLevel3
      queryHelper
      sotName
      source
      time
      topic
      ttl
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userType
    }
  }
`;
export const updateCalendarEntry = /* GraphQL */ `
  mutation UpdateCalendarEntry(
    $condition: ModelCalendarEntryConditionInput
    $input: UpdateCalendarEntryInput!
  ) {
    updateCalendarEntry(condition: $condition, input: $input) {
      createdAt
      description
      end
      id
      isVirtual
      locationActionParam
      locationActionType
      locationName
      organizationId
      organizationIdStatus
      participants {
        nextToken
      }
      start
      status
      title
      topicName
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userPoolName
    }
  }
`;
export const updateCalendarEntryParticipation = /* GraphQL */ `
  mutation UpdateCalendarEntryParticipation(
    $condition: ModelCalendarEntryParticipationConditionInput
    $input: UpdateCalendarEntryParticipationInput!
  ) {
    updateCalendarEntryParticipation(condition: $condition, input: $input) {
      calendarEntry {
        createdAt
        description
        end
        id
        isVirtual
        locationActionParam
        locationActionType
        locationName
        organizationId
        organizationIdStatus
        start
        status
        title
        topicName
        updatedAt
        userId
        userPoolName
      }
      calendarEntryId
      createdAt
      id
      start
      status
      statusUpdateMessage
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userIdStatus
    }
  }
`;
export const updateCalendarEntryWithParticipants = /* GraphQL */ `
  mutation UpdateCalendarEntryWithParticipants(
    $condition: ModelCalendarEntryConditionInput
    $input: UpdateCalendarEntryWithParticipantsInput!
  ) {
    updateCalendarEntryWithParticipants(condition: $condition, input: $input) {
      createdAt
      description
      end
      id
      isVirtual
      locationActionParam
      locationActionType
      locationName
      organizationId
      organizationIdStatus
      participants {
        nextToken
      }
      start
      status
      title
      topicName
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userPoolName
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $condition: ModelConversationConditionInput
    $input: UpdateConversationInput!
  ) {
    updateConversation(condition: $condition, input: $input) {
      createdAt
      description
      id
      memberIdsHash
      members {
        nextToken
      }
      messages {
        nextToken
        startedAt
      }
      mostRecentMessage
      mostRecentMessageCreatedAt
      name
      type
      updatedAt
      userId
    }
  }
`;
export const updateMeetingParticipant = /* GraphQL */ `
  mutation UpdateMeetingParticipant(
    $condition: ModelMeetingParticipantConditionInput
    $input: UpdateMeetingParticipantInput!
  ) {
    updateMeetingParticipant(condition: $condition, input: $input) {
      created
      createdAt
      id
      invitationType
      invitee {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      inviteeId
      inviter {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      inviterId
      meeting {
        createdAt
        end
        id
        start
        updatedAt
      }
      meetingId
      meetingTitle
      status
      updatedAt
    }
  }
`;
export const updateMeetingRoomAttendance = /* GraphQL */ `
  mutation UpdateMeetingRoomAttendance(
    $condition: ModelMeetingRoomAttendanceConditionInput
    $input: UpdateMeetingRoomAttendanceInput!
  ) {
    updateMeetingRoomAttendance(condition: $condition, input: $input) {
      chimeMeetingId
      createdAt
      id
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $condition: ModelMessageConditionInput
    $input: UpdateMessageInput!
  ) {
    updateMessage(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      author {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      authorId
      content
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isSent
      members
      sotName
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $condition: ModelNotificationConditionInput
    $input: UpdateNotificationInput!
  ) {
    updateNotification(condition: $condition, input: $input) {
      actionParam
      actionType
      content
      createdAt
      displayGroup
      id
      read
      type
      updatedAt
      userId
      userIdDisplayGroup
    }
  }
`;
export const updateRaisedHand = /* GraphQL */ `
  mutation UpdateRaisedHand(
    $condition: ModelRaisedHandConditionInput
    $input: UpdateRaisedHandInput!
  ) {
    updateRaisedHand(condition: $condition, input: $input) {
      chimeMeetingId
      createdAt
      id
      updatedAt
    }
  }
`;
export const updateUnreadCounter = /* GraphQL */ `
  mutation UpdateUnreadCounter(
    $condition: ModelUnreadCounterConditionInput
    $input: UpdateUnreadCounterInput!
  ) {
    updateUnreadCounter(condition: $condition, input: $input) {
      contacts
      conversations
      createdAt
      id
      notifications
      requests
      schedules
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $condition: ModelUserConditionInput
    $input: UpdateUserInput!
  ) {
    updateUser(condition: $condition, input: $input) {
      conversations {
        nextToken
      }
      createdAt
      id
      incomingMeetings {
        nextToken
      }
      lastConnected
      name
      outgoingMeetings {
        nextToken
      }
      pictureUrl
      presenceStatus
      updatedAt
    }
  }
`;
export const updateUserAction = /* GraphQL */ `
  mutation UpdateUserAction(
    $condition: ModelUserActionConditionInput
    $input: UpdateUserActionInput!
  ) {
    updateUserAction(condition: $condition, input: $input) {
      actionType
      countryCode
      createdAt
      duration
      endTime
      id
      param
      sotName
      source
      startTime
      topic
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userType
    }
  }
`;
export const updateUserConversation = /* GraphQL */ `
  mutation UpdateUserConversation(
    $condition: ModelUserConversationConditionInput
    $input: UpdateUserConversationInput!
  ) {
    updateUserConversation(condition: $condition, input: $input) {
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isChatOpen
      isMuted
      lastReadMessageCreatedAt
      mostRecentMessageCreatedAt
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
    }
  }
`;
export const updateUserSession = /* GraphQL */ `
  mutation UpdateUserSession(
    $condition: ModelUserSessionConditionInput
    $input: UpdateUserSessionInput!
  ) {
    updateUserSession(condition: $condition, input: $input) {
      countryCode
      createdAt
      id
      location
      locationLevel1
      locationLevel2
      locationLevel3
      queryHelper
      sotName
      source
      time
      topic
      ttl
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userType
    }
  }
`;
