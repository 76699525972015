import styled from "styled-components"

export const PreviewVideoContainer = styled.div`
    min-height: 240px;
    position: relative;
`

export const PreviewVideoOverlay = styled.div`
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    height: 25px;
    background-color: rgba(0, 0, 0, 0.25);
    color: #fff;
    text-align: center;
    z-index: 5;
    font-size: 12px;
    line-height: 12px;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 0.5em;
`
