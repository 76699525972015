import styled from "styled-components"
import { device } from "../../utils/Device"
import branding from "../../branding/branding"
export const ToggleSwitchRoot = styled.div<{
    deactivatedToggleBackgroundColor?: string
    deactivatedToggleBorderColor?: string
    activatedToggleBorderColor?: string
    sliderBackgroundColor?: string
    deactivatedSliderBorderColor?: string
    activatedSliderBorderColor?: string
    scaleSize?: number
}>`
    width: 44px;
    transform: ${(props) => (props.scaleSize ? `scale(${props.scaleSize}) !important` : `scale(1})`)};
    & .switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 25px;
        margin: 0;
    }

    & .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    & .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${(props) =>
            props.deactivatedToggleBackgroundColor ?? "transparent"}; // deactivated toggle background color
        border: ${(props) =>
            `2px solid ${
                props.deactivatedToggleBorderColor ?? branding.toggleButtonBranding.toggleOffColor
            }`}; // deactivated toggle border color
        border-radius: 12px;
    }

    & .slider:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 3px;
        bottom: 3px;
        background-color: ${(props) => props.sliderBackgroundColor ?? "transparent"}; // slider background color;
        border: ${(props) =>
            `2px solid ${
                props.deactivatedSliderBorderColor ?? branding.toggleButtonBranding.toggleOffColor
            }`}; // deactivated slider border color
        -webkit-transition: 0.2s ease-in-out;
        transition: 0.2s ease-in-out;
        border-radius: 50%;
    }

    & input.checked + .slider {
        background-color: transparent;
        border: ${(props) => `2px solid ${props.activatedToggleBorderColor ?? `#5DB130`}`}; // activated toggle border color
    }

    & input.checked + .slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
        -webkit-transition: transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out;
        border: ${(props) => `2px solid ${props.activatedSliderBorderColor ?? `#5DB130`}`}; //activated slider border color
    }

    @media ${device.laptop} {
        transform: none;
    }
`
