import { Link, useRouteMatch } from "react-router-dom"
import { meetingPageRoute } from "../../RoutePaths"
import { SideIconBarItemIds } from "../SIdeIconBarConfig"
import branding from "../../../branding/branding"
import { ListItem, ListItemIconContainer, ListItemText, ListItemTextContainer } from "../SideIconBar.styled"
import { CustomOverlayTrigger } from "../../../ui/CustomOverlayTrigger"
import { Tooltip } from "react-bootstrap"

interface ListItemCustomProps {
    onClick: (e: any) => void
    id: SideIconBarItemIds
    selected: boolean
    icon: any
    title: string
    shortName: string
    path: string
    textVisible: boolean
    isExternalLink?: boolean
    customUrl?: string
    isNavOpen?: boolean
}

const ListItemCustom: React.FunctionComponent<ListItemCustomProps> = (props: ListItemCustomProps) => {
    const className: string[] = ["hovered"]

    if (props.selected) className.push("drawerButtonSelected")

    let isMeetingPage = useRouteMatch(meetingPageRoute)

    const color: string = props.selected
        ? isMeetingPage
            ? branding.sideIconBar.sideIconColorLight
            : branding.sideIconBar.sideIconColorDark
        : "gray"

    const content: JSX.Element = (
        <ListItem className={className.join(" ")} key="text">
            {!props.textVisible && (
                <CustomOverlayTrigger
                    placement="right"
                    trigger={["focus", "click", "hover"]}
                    delay={{ show: 0, hide: 0 }}
                    overlay={
                        <Tooltip
                            id={props.title}
                            show={!props.textVisible}
                            style={{ color: branding.mainInfoColor, pointerEvents: "none", fontFamily: branding.font1 }}
                        >
                            {props.title}
                        </Tooltip>
                    }
                >
                    <div
                        style={{
                            display: "block",
                            flexFlow: "column",
                            maxWidth: "40px",
                            textAlign: "center",
                            float: "left",
                            fontSize: "9px",
                            color:
                                !props.isNavOpen && props.selected
                                    ? branding.navBarActiveIconColor
                                    : branding.sideIconBar.inactiveItemColor,
                            lineHeight: "10px",
                            width: "100px"
                        }}
                    >
                        <ListItemIconContainer selected={props.selected}>{props.icon}</ListItemIconContainer>
                        <div
                            style={{
                                width: "65px",
                                display: branding.sideIconBar.descriptionTextUnderIconVisible ? "inline-block" : "none",
                                marginLeft: props.id === SideIconBarItemIds.MY_SCHEDULE ? "-12px" : "-13px"
                            }}
                        >
                            {/* {" "} */}
                            <span style={{ whiteSpace: "pre-wrap", wordBreak: "keep-all", fontFamily: branding.font1 }}>
                                {props.shortName}
                            </span>
                        </div>
                    </div>
                </CustomOverlayTrigger>
            )}
            {props.textVisible && <ListItemIconContainer selected={props.selected}>{props.icon}</ListItemIconContainer>}
            <ListItemTextContainer>
                {props.textVisible && <ListItemText style={{ color: color }}>{props.title}</ListItemText>}
            </ListItemTextContainer>
        </ListItem>
    )

    return (
        <>
            {props.isExternalLink ? (
                <a href={props.customUrl ?? ""} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                    {content}
                </a>
            ) : (
                <Link to={props.path} onClick={(e: any) => props.onClick(e)} style={{ textDecoration: "none" }}>
                    {content}
                </Link>
            )}
        </>
    )
}

export default ListItemCustom
