import { MeetingStatus, useMeetingManager } from "amazon-chime-sdk-component-library-react"
import { API } from "aws-amplify"
import { useEffect, useRef, useState } from "react"
import { Overlay, Tooltip } from "react-bootstrap"
import { buildGraphqlArgs, getMeetingRoomAttendanceByChimeMeetingId } from "../../../backendServices/GraphQLServices"
import branding from "../../../branding/branding"
import { onMeetingRoomAttendanceCreated, onMeetingRoomAttendanceDeleted } from "../../../graphql/subscriptions"
import ToggleSwitch from "../../../ui/ToggleSwitch/ToggleSwitch"
import { useMeetingController } from "../../context/MeetingController"
import {
    PreMeetingSettingContext,
    PreMeetingSettingType,
    usePreMeetingSettingContext
} from "../../context/PreMeetingSettingsContext"
import { IconWrapper, SwitcherContainer, SwitcherItem, ToggleWrapper } from "./SettingsSwithcer.styled"
import { defaultLogger as logger } from "../../../globalStates/AppState"
import { useDevicesV2 } from "../../hooks/useDevices"

export interface SettingsSwitcherItem {
    icon: any
    label: string
    key: string
}

export interface SettingsSwitcherProps {
    settingsItems: SettingsSwitcherItem[]
    setActiveItem: (activeItem: string) => void
    activeItem: string
}

function generateAudioTooltipMessage(
    muteUserByMeetingRoomAttendances: boolean,
    meetingRoomAttendances: string[],
    preMeetingSettingsContext: PreMeetingSettingContext
) {
    return muteUserByMeetingRoomAttendances
        ? branding.conferenceTexts.preMeetingAudioStateMutedByAttendaces.replace(
              "{number}",
              meetingRoomAttendances.length.toString()
          )
        : preMeetingSettingsContext.getSetting(PreMeetingSettingType.AUDIOINPUTSTATE) === true
        ? branding.conferenceTexts.preMeetingAudioStateTooltipInfoTextOn
        : branding.conferenceTexts.preMeetingAudioStateTooltipInfoTextOff
}

function SettingsSwitcher({ settingsItems, setActiveItem, activeItem }: SettingsSwitcherProps) {
    const preMeetingSettingsContext = usePreMeetingSettingContext()
    const meetingManager = useMeetingManager()
    const audioTooltipTarget = useRef(null)
    const [showAudioTooltip, setShowAudioTooltip] = useState(false)
    const videoTooltipTarget = useRef(null)
    const [showVideoTooltip, setShowVideoTooltip] = useState(false)
    const [hasMicrophonePermission, setHasMicrophonePermission] = useState(true)
    const [hasCameraPermission, setHasCameraPermission] = useState(true)
    const meetingController = useMeetingController()
    const [meetingRoomAttendances, setMeetingRoomAttendances] = useState([])
    const [muteUserByMeetingRoomAttendances, setMuteUserByMeetingRoomAttendances] = useState(false)
    const devices = useDevicesV2()

    const audioTooltipLabel = generateAudioTooltipMessage(
        muteUserByMeetingRoomAttendances,
        meetingRoomAttendances,
        preMeetingSettingsContext
    )

    useEffect(() => {
        setHasCameraPermission(devices.hasVideoInputPermission())
        setHasMicrophonePermission(devices.hasAudioInputPermission())

        // eslint-disable-next-line
    }, [devices])

    useEffect(() => {
        const audioTooltipTimer = setTimeout(() => {
            setShowAudioTooltip(false)
        }, 2000)
        return () => {
            clearTimeout(audioTooltipTimer)
        }
    }, [showAudioTooltip, hasMicrophonePermission])

    useEffect(() => {
        const audioTooltipTimeot = setTimeout(() => {
            if (hasMicrophonePermission) setShowAudioTooltip(true)
        }, 1000)

        const videoTooltipTimeot = setTimeout(() => {
            if (hasCameraPermission) setShowVideoTooltip(true)
        }, 1500)

        return () => {
            clearTimeout(audioTooltipTimeot)
            clearTimeout(videoTooltipTimeot)
        }
    }, [hasMicrophonePermission, hasCameraPermission])

    useEffect(() => {
        const videoTooltipTimer = setTimeout(() => {
            setShowVideoTooltip(false)
        }, 2000)
        return () => {
            clearTimeout(videoTooltipTimer)
        }
    }, [showVideoTooltip])

    useEffect(() => {
        getMeetingRoomAttendanceByChimeMeetingId(meetingController.getExternalMeetingId() as string).then((data: any) => {
            setMeetingRoomAttendances(
                data.data.listMeetingRoomAttendances.items.map((item: any) => {
                    return item.id
                })
            )
        })

        /** Meeting room attendance subscriptions and unsubscriptions */
        let createMeetingRoomAttendaceSubscription = (
            API.graphql(
                buildGraphqlArgs(onMeetingRoomAttendanceCreated, { chimeMeetingId: meetingController.getExternalMeetingId() })
            ) as any
        ).subscribe({
            next: (next: any) => {
                getMeetingRoomAttendanceByChimeMeetingId(meetingController.getExternalMeetingId() as string).then((data: any) => {
                    setMeetingRoomAttendances(
                        data.data.listMeetingRoomAttendances.items.map((item: any) => {
                            return item.id
                        })
                    )
                })
            },
            error: (error: any) => {
                logger.error({
                    message: "Subscription Error: onMeetingRoomAttendaceCreated",
                    errorMessage: error.message,
                    errorStack: error.stack
                })
            },
            complete: () => {
                logger.info("Subscription Complete: onMeetingRoomAttendanceCreated")
            }
        })

        let deleteMeetingRoomAttendanceSubscription = (
            API.graphql(
                buildGraphqlArgs(onMeetingRoomAttendanceDeleted, { chimeMeetingId: meetingController.getExternalMeetingId() })
            ) as any
        ).subscribe({
            next: (next: any) => {
                getMeetingRoomAttendanceByChimeMeetingId(meetingController.getExternalMeetingId() as string).then((data: any) => {
                    setMeetingRoomAttendances(
                        data.data.listMeetingRoomAttendances.items.map((item: any) => {
                            return item.id
                        })
                    )
                })
            },
            error: (error: any) => {
                logger.error({
                    message: "Subscription Error: onMeetingRoomAttendanceDeleted",
                    errorMessage: error.message,
                    errorStack: error.stack
                })
            },
            complete: () => {
                logger.info("Subscription Complete: onMeetingRoomAttendanceDeleted")
            }
        })

        return () => {
            createMeetingRoomAttendaceSubscription.unsubscribe()
            deleteMeetingRoomAttendanceSubscription.unsubscribe()
        }
        // eslint-disable-next-line
    }, [meetingController.getExternalMeetingId()])

    useEffect(() => {
        if (meetingRoomAttendances.length >= branding.configuration.meetingRoomAttendanceMuteThreshold) {
            setMuteUserByMeetingRoomAttendances(true)
            if (preMeetingSettingsContext.getSetting(PreMeetingSettingType.AUDIOINPUTSTATE)) {
                setShowAudioTooltip(true)
                preMeetingSettingsContext.storeSetting(PreMeetingSettingType.AUDIOINPUTSTATE, false)
            }
        }
        // eslint-disable-next-line
    }, [meetingRoomAttendances])

    return (
        <SwitcherContainer>
            {settingsItems &&
                settingsItems.map((settingsItem, index) => {
                    return (
                        <SwitcherItem
                            onClick={() => setActiveItem(settingsItem.key)}
                            key={index}
                            isActive={settingsItem.key === activeItem}
                        >
                            <IconWrapper>
                                {settingsItem.icon} {settingsItem.label}
                            </IconWrapper>
                            {settingsItem.key === "audio" && meetingManager.meetingStatus !== MeetingStatus.Succeeded && (
                                <ToggleWrapper>
                                    <Overlay target={audioTooltipTarget.current} show={showAudioTooltip} placement="top">
                                        <Tooltip id="custom-audio-tooltip" style={{ fontFamily: branding.font1 }}>
                                            {audioTooltipLabel}
                                        </Tooltip>
                                    </Overlay>
                                    <ToggleSwitch
                                        sliderBackgroundColor={
                                            hasMicrophonePermission &&
                                            preMeetingSettingsContext.getSetting(PreMeetingSettingType.AUDIOINPUTSTATE)
                                                ? branding.toggleButtonBranding.toggleOnColor
                                                : branding.toggleButtonBranding.toggleOffColorSettingsSwitcher
                                        }
                                        deactivatedSliderBorderColor={
                                            branding.toggleButtonBranding.toggleOffColorSettingsSwitcher
                                        }
                                        deactivatedToggleBorderColor={
                                            branding.toggleButtonBranding.toggleOffColorSettingsSwitcher
                                        }
                                        activatedToggleBorderColor={branding.toggleButtonBranding.toggleOnColor}
                                        activatedSliderBorderColor={branding.toggleButtonBranding.toggleOnColor}
                                        scaleSize={0.5}
                                        isEnabled={
                                            hasMicrophonePermission &&
                                            preMeetingSettingsContext.getSetting(PreMeetingSettingType.AUDIOINPUTSTATE)
                                        }
                                        onClick={() => {
                                            if (!hasMicrophonePermission) return
                                            if (muteUserByMeetingRoomAttendances) setMuteUserByMeetingRoomAttendances(false)
                                            setShowAudioTooltip(true)
                                            preMeetingSettingsContext.storeSetting(
                                                PreMeetingSettingType.AUDIOINPUTSTATE,
                                                !preMeetingSettingsContext.getSetting(PreMeetingSettingType.AUDIOINPUTSTATE)
                                            )
                                        }}
                                    />
                                    <div ref={audioTooltipTarget} style={{ marginTop: "-26px" }}></div>
                                </ToggleWrapper>
                            )}
                            {settingsItem.key === "video" && meetingManager.meetingStatus !== MeetingStatus.Succeeded && (
                                <ToggleWrapper>
                                    <Overlay target={videoTooltipTarget.current} show={showVideoTooltip} placement="bottom">
                                        <Tooltip id="custom-video-tooltip" style={{ fontFamily: branding.font1 }}>
                                            {preMeetingSettingsContext.getSetting(PreMeetingSettingType.VIDEOINPUTSTATE) === true
                                                ? branding.conferenceTexts.preMeetingVideoStateTooltipInfoTextOn
                                                : branding.conferenceTexts.preMeetingVideoStateTooltipInfoTextOff}
                                        </Tooltip>
                                    </Overlay>
                                    <ToggleSwitch
                                        sliderBackgroundColor={
                                            hasCameraPermission &&
                                            preMeetingSettingsContext.getSetting(PreMeetingSettingType.VIDEOINPUTSTATE)
                                                ? branding.toggleButtonBranding.toggleOnColor
                                                : branding.toggleButtonBranding.toggleOffColorSettingsSwitcher
                                        }
                                        deactivatedSliderBorderColor={
                                            branding.toggleButtonBranding.toggleOffColorSettingsSwitcher
                                        }
                                        deactivatedToggleBorderColor={
                                            branding.toggleButtonBranding.toggleOffColorSettingsSwitcher
                                        }
                                        activatedToggleBorderColor={branding.toggleButtonBranding.toggleOnColor}
                                        activatedSliderBorderColor={branding.toggleButtonBranding.toggleOnColor}
                                        scaleSize={0.5}
                                        isEnabled={
                                            hasCameraPermission &&
                                            preMeetingSettingsContext.getSetting(PreMeetingSettingType.VIDEOINPUTSTATE)
                                        }
                                        onClick={() => {
                                            if (!hasCameraPermission) return
                                            setShowVideoTooltip(true)
                                            preMeetingSettingsContext.storeSetting(
                                                PreMeetingSettingType.VIDEOINPUTSTATE,
                                                !preMeetingSettingsContext.getSetting(PreMeetingSettingType.VIDEOINPUTSTATE)
                                            )
                                        }}
                                    />
                                    <div ref={videoTooltipTarget} style={{ marginTop: "-12px" }}></div>
                                </ToggleWrapper>
                            )}
                        </SwitcherItem>
                    )
                })}
        </SwitcherContainer>
    )
}

export default SettingsSwitcher
