import { SyntheticEvent, useEffect, useRef, useState, Touch } from "react"
import * as React from "react"
import { useAppState, VideoPlayerStatus } from "../../globalStates/AppState"
import moment from "moment"
import { useUserRestrictedAreaAccess } from "../../globalStates/UserRestrictedAreaAccess"
import PictureInPictureVideoPlayerReal, { PlayerHandle } from "./PictureInPictureVideoPlayerReal"
import PictureInPictureVideoPlayerDummy from "./PictureInPictureVideoPlayerDummy"
import { EventDate } from "../../backendServices/Types"
import styled, { keyframes } from "styled-components"
import CenteredLoader from "../../ui/CenteredLoader"
import { useRouteMatch } from "react-router-dom"
import { eventDateDetailPageRoute, videoRoomPageRoute } from "../../navigationArea/RoutePaths"
import { Resizable, ResizeCallbackData } from "react-resizable"
import Draggable, { DraggableData, DraggableEvent, DraggableEventHandler } from "react-draggable"
import { IconCloseV2 } from "../../ui/Icons"
import branding from "../../branding/branding"
import { useChimeContext } from "../../conference/context/ChimeContext"
import { accessPresenceState, EventType } from "../../ui/PresenceIndicator"
import { MeetingStatusCode } from "../../conference/enums/MeetingStatusCode"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import { EventDateChannelFirstDetailsResponse, loadChannelFirstEventDate } from "../../backendServices/EventdateServices"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { ReloadButton } from "./VideoPlayerReal"
import { MeetingStatus, useMeetingManager } from "amazon-chime-sdk-component-library-react"

/* parent component that switches between PictureInPictureVideoPlayerReal and -Dummy depending on user access to the eventDate,
 * it can look different depending on the following states: locked-ticket, locked-backoffice, unlocked, loading, error
 */

function calculateChange(currentEventEndDate: string, nextEventStartDate: string) {
    return moment(nextEventStartDate, "YYYY-MM-DD HH:mm").diff(moment(currentEventEndDate, "YYYY-MM-DD HH:mm")) / 2
}

function calculateEnd(currentEventEndDate: string) {
    const format = "YYYY-MM-DD HH:mm"
    return moment(moment(currentEventEndDate).format(format)).diff(moment().format(format))
}

export interface PipPlayerSize {
    width: number
    height: number
}
export interface PipPlayerPosition {
    x: number
    y: number
}

export interface ResizeState {
    width: number
    height: number
    relativeLeft?: number | any
    relativeTop?: number | any
}

export interface TmpVideoPlayerStatus extends VideoPlayerStatus {
    updateCounter: number
}

/**
 * Dragging works for mobile and desktop version of the app via react-draggable,
 * desktop resizing works by pulling handles via react-resizable
 * mobile resizing works via home-made pinch-zoom-resizing with two fingers
 * @returns
 */

const PictureInPictureVideoPlayer: React.FC = () => {
    const appState = useAppState()
    const userAccessState = useUserRestrictedAreaAccess()
    const [currentEventDate, setCurrentEventDate] = useState<EventDate | undefined>()
    const channel = appState.liveStreamChannel
    const userControlsEnabled = true
    const isVideoPage = useRouteMatch(videoRoomPageRoute)
    const isEventDatePage = useRouteMatch(eventDateDetailPageRoute)
    const chime = useChimeContext()
    const meetingManager = useMeetingManager()
    const [errorOccured, setErrorOccured] = useState(false)
    const [playerShouldReload, setPlayerShouldReload] = useState(false)
    const [isPaused, setIsPaused] = useState(
        appState.videoPlayerStatus?.isPaused === undefined ? false : appState.videoPlayerStatus.isPaused
    )
    const realPlayerRef = useRef<PlayerHandle>(null)
    const { width, height, isMobile } = useWindowDimensions()
    const minSize: PipPlayerSize = isMobile ? { width: 192, height: 108 } : { width: 240, height: 135 }
    const maxSize: PipPlayerSize = isMobile ? { width: width, height: height } : { width: 2400, height: 1350 }
    const tmpVideoPlayerStatus = useRef<TmpVideoPlayerStatus>({ updateCounter: 1 })

    // mouse resizing
    let startClickX = 0
    let startClickY = 0
    let isResizing = false
    let shouldClose = false
    let shouldHandleClick = false

    const initialResizeState: ResizeState = isMobile
        ? {
              width: 240,
              height: 135,
              relativeLeft: 0,
              relativeTop: 0
          }
        : {
              width: 426,
              height: 240,
              relativeLeft: 0,
              relativeTop: 0
          }
    const [resizeState, setResizeState] = useState<ResizeState>(initialResizeState)
    const [deltaSize, setDeltaSize] = useState<PipPlayerPosition>({ x: 0, y: 0 })
    const initialPosition: PipPlayerPosition = {
        x: window.innerWidth - resizeState.width - 40,
        y: window.innerHeight - resizeState.height - 48
    }

    const [pipPlayerDefaultPosition, setPipPlayerDefaultPosition] = useState<PipPlayerPosition>(initialPosition)

    // touch resizing and double-tap
    let touchPointCache: Touch[] = []
    const doubleTapTimer = useRef<NodeJS.Timeout | null>(null)
    const waitForSecondTap: number = 300

    useEffect(() => {
        function loadData() {
            if (!isVideoPage && appState.liveStreamChannel && !appState.vodEventDate) {
                loadChannelFirstEventDate(appState.liveStreamChannel?.id).then((data) => {
                    if ((data as BackendServiceError).httpStatus) {
                    } else {
                        const resp = data as EventDateChannelFirstDetailsResponse
                        appState.setLiveStreamChannel(
                            Object.assign({}, appState.liveStreamChannel, { eventDate: resp.currentEventDate })
                        )

                        const currentEventDateTemp = resp.currentEventDate
                        setCurrentEventDate(currentEventDateTemp)

                        if (resp.currentEventDate && !resp.nextEventDate) {
                            const remainingCurrentEventDate = calculateEnd(resp.currentEventDate?.enddatetime)
                            if (remainingCurrentEventDate > 0) {
                                remainingCurrentEventDateTimer = window.setTimeout(() => {
                                    loadData()
                                }, remainingCurrentEventDate)
                            }
                        } else if (resp.currentEventDate && resp.nextEventDate) {
                            const currentEventDateEndTime = resp.currentEventDate.enddatetime
                            const remainingCurrentEventDate = calculateEnd(currentEventDateEndTime)
                            if (remainingCurrentEventDate > 0) {
                                remainingCurrentEventDateTimer = window.setTimeout(() => {
                                    // Trigger on event end
                                    // reload data at halfway mark between end of current and beginning of next event
                                    const changeExecution = calculateChange(
                                        currentEventDateEndTime,
                                        resp.nextEventDate?.startdatetime!
                                    )
                                    changeExecutionTimer = window.setTimeout(() => {
                                        loadData()
                                    }, changeExecution)
                                }, remainingCurrentEventDate)
                            }
                        }
                    }
                })
            }
        }

        let remainingCurrentEventDateTimer: number
        let changeExecutionTimer: number

        loadData()
        return () => {
            clearTimeout(remainingCurrentEventDateTimer)
            clearTimeout(changeExecutionTimer)
        }
        // eslint-disable-next-line
    }, [appState.liveStreamChannel?.url, isVideoPage])

    useEffect(() => {
        if (currentEventDate && !userAccessState.isLoaded(currentEventDate))
            userAccessState.fetchUserAccessForSingleRestrictedArea(currentEventDate)
    }, [currentEventDate]) // eslint-disable-line

    useEffect(() => {
        if (isVideoPage) {
            // FOR LIVESTREAMS: write videoPlayerStatus on PiP close
            // write play/pause-state and timeOffset into appState, so it stays the same when user switches between Video- and PiP-Player
            // this data is written here, because in PictureInPictureVideoPlayerReal's clean-up function wrong values were written,
            // because it unmounted too often when UserRestrictedAreaAccess was fetching data and in the process setting isLoaded to false
            if (tmpVideoPlayerStatus.current.updateCounter < 1 && !errorOccured) {
                appState.setVideoPlayerStatus({
                    isPaused: isPaused,
                    timeOffsetliveStream: tmpVideoPlayerStatus.current?.timeOffsetliveStream
                })
            }
            if (errorOccured) setErrorOccured(false)
            tmpVideoPlayerStatus.current.updateCounter++
        }
    }, [isVideoPage]) // eslint-disable-line

    useEffect(() => {
        if (isEventDatePage) {
            // FOR VODs: write videoPlayerStatus on PiP close
            // write play/pause-state and timeOffset into appState, so it stays the same when user switches between Video- and PiP-Player
            // this data is written here, because in PictureInPictureVideoPlayerReal's clean-up function wrong values were written,
            // because it unmounted too often when UserRestrictedAreaAccess was fetching data and in the process setting isLoaded to false
            if (tmpVideoPlayerStatus.current.updateCounter < 1 && !errorOccured) {
                appState.setVideoPlayerStatus({
                    isPaused: isPaused,
                    timeOffsetVOD: tmpVideoPlayerStatus.current?.timeOffsetVOD
                })
            }
            if (errorOccured) setErrorOccured(false)
            tmpVideoPlayerStatus.current.updateCounter++
        }
    }, [isEventDatePage]) // eslint-disable-line

    useEffect(() => {
        appState.setVideoPlayerStatus({
            timeOffsetliveStream: 0
        })
    }, [appState.liveStreamChannel?.id]) // eslint-disable-line

    const handleDragStart: DraggableEventHandler = (e: DraggableEvent, data: DraggableData) => {
        if (isResizing) {
            return false
        }
        if ((e as any).hasOwnProperty("nativeEvent")) {
            e = (e as any).nativeEvent
        }

        if (e instanceof MouseEvent) {
            const me = e as MouseEvent
            startClickX = me.screenX
            startClickY = me.screenY
        } else if (e instanceof TouchEvent) {
            startClickX = e.changedTouches[0].clientX
            startClickY = e.changedTouches[0].clientY
        }
        e.stopPropagation()
        e.preventDefault()
    }

    function isCloseButton(element: any): boolean {
        if (element) {
            let node = element
            for (let i = 0; i < 4; i++) {
                if (node?.parentNode) {
                    node = node.parentNode
                    if (node.classList.contains("pip-close-button")) return true
                } else break
            }
        }
        return false
    }

    function handleDragClick(event: any) {
        // only handle click if not closing, no error occured and close button was not event target
        if (shouldClose) closePlayer()
        else if (!errorOccured) {
            if (event.target) {
                const target = event.target as any
                if (!isCloseButton(target)) {
                    shouldHandleClick = true
                }
            }
        }
    }

    const handleDragStop: DraggableEventHandler = (draggableEvent: DraggableEvent, data: DraggableData) => {
        if (draggableEvent instanceof MouseEvent) {
            const me = draggableEvent as MouseEvent
            if (Math.floor(startClickX) === Math.floor(me.screenX) && Math.floor(startClickY) === Math.floor(me.screenY)) {
                handleDragClick(draggableEvent)
            } else {
                // save size and position of player for rerenders
                setPipPlayerDefaultPosition({
                    x: data.x + deltaSize.x, // account for change in size from resizing
                    y: data.y + deltaSize.y
                })
            }
        } else if (draggableEvent instanceof TouchEvent) {
            if (
                Math.abs(startClickX - draggableEvent.changedTouches[0].clientX) < 10 &&
                Math.abs(startClickY - draggableEvent.changedTouches[0].clientY) < 10
            ) {
                handleDragClick(draggableEvent)
            } else {
                // save size and position of player for rerenders
                setPipPlayerDefaultPosition({
                    x: data.x,
                    y: data.y
                })
            }
        }
        draggableEvent.stopPropagation()
    }

    const handleDesktopResizeStart = (event: SyntheticEvent) => {
        isResizing = true
        event.stopPropagation()
        event.preventDefault()
    }

    const handleDesktopResize = (e: SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {
        setResizeState((prevState: ResizeState) => {
            let newLeft = prevState.relativeLeft
            let newTop = prevState.relativeTop
            const deltaHeight = data.size.height - prevState.height
            const deltaWidth = data.size.width - prevState.width

            if (newLeft === undefined) {
                newLeft = 0
            }
            if (newTop === undefined) {
                newTop = 0
            }

            if (data.handle[0] === "n") {
                newTop -= deltaHeight
            } else if (data.handle[0] === "s") {
            }
            if (data.handle[data.handle.length - 1] === "w") {
                newLeft -= deltaWidth
            } else if (data.handle[data.handle.length - 1] === "e") {
            }

            const newResizeState: ResizeState = {
                width: data.size.width,
                height: data.size.height,
                relativeLeft: newLeft,
                relativeTop: newTop
            }
            return newResizeState
        })
    }

    const handleDesktopResizeStop = (event: SyntheticEvent, data: ResizeCallbackData) => {
        // save position of player for rerenders
        const deltaX = -(deltaSize.x - resizeState.relativeLeft)
        const deltaY = -(deltaSize.y - resizeState.relativeTop)
        setPipPlayerDefaultPosition({ x: pipPlayerDefaultPosition.x + deltaX, y: pipPlayerDefaultPosition.y + deltaY })
        setDeltaSize({ x: resizeState.relativeLeft, y: resizeState.relativeTop })
        isResizing = false
        event.stopPropagation()
        event.preventDefault()
    }

    function prepareClosing() {
        shouldClose = true
    }

    function closePlayer() {
        const useConferenceRoomV2 = branding.configuration.useConferenceRoomV2
        const meetingStatus = useConferenceRoomV2 ? meetingManager.meetingStatus : chime.getMeetingStatus().meetingStatusCode
        if (
            (useConferenceRoomV2 && meetingStatus !== MeetingStatus.Succeeded) ||
            (!useConferenceRoomV2 && meetingStatus !== MeetingStatusCode.Succeeded) // when chimeV1 is deprecated, we can remove this line and use only meeting status from the meetingManager
        ) {
            accessPresenceState.updateMyPresence(EventType.EVENT_END)
        }
        appState.setLiveStreamChannel(null)
        appState.setVodEventDate(null)
    }

    const togglePlayPause = () => {
        if (null !== realPlayerRef.current) {
            realPlayerRef.current.handleClick()
        }
    }

    let content = null
    if (appState.vodEventDate) {
        content = (
            <PictureInPictureVideoPlayerReal
                userControlsEnabled={userControlsEnabled}
                resizeState={resizeState}
                errorOccured={errorOccured}
                setErrorOccured={setErrorOccured}
                setIsPaused={setIsPaused}
                ref={realPlayerRef}
                tmpVideoPlayerStatus={tmpVideoPlayerStatus}
                key={"" + playerShouldReload}
                sourceIsStatic={true}
            />
        )
    } else if (!channel || isVideoPage || !currentEventDate) {
        return null
    } else if (!userAccessState.isLoaded(currentEventDate)) {
        content = (
            // position Player in bottom right corner in vertical alignment with the conference overlay or where user placed it
            <LoadingPlayerRoot
                positionY={pipPlayerDefaultPosition.y + "px"}
                positionX={pipPlayerDefaultPosition.x + "px"}
                width={resizeState.width + "px"}
                height={resizeState.height + "px"}
            >
                <CenteredLoader />
            </LoadingPlayerRoot>
        )
    } else {
        content = (
            <>
                {
                    // if event is not private or user has access,
                    // we can display real PictureInPicture Video Player
                    // otherwise, we will display dummy PictureInPicture Video Player
                    userAccessState.isUnlocked(currentEventDate) ? (
                        <PictureInPictureVideoPlayerReal
                            userControlsEnabled={userControlsEnabled}
                            resizeState={resizeState}
                            errorOccured={errorOccured}
                            setErrorOccured={setErrorOccured}
                            setIsPaused={setIsPaused}
                            ref={realPlayerRef}
                            tmpVideoPlayerStatus={tmpVideoPlayerStatus}
                            key={"" + playerShouldReload}
                        />
                    ) : (
                        <PictureInPictureVideoPlayerDummy
                            resizeState={resizeState}
                            accessProvider={currentEventDate.accessProvider}
                        />
                    )
                }
            </>
        )
    }

    function touchStartHandler(touchEvent: React.TouchEvent<HTMLDivElement>) {
        // Cache the touch points for later processing of 2-touch pinch/zoom
        if (touchEvent.targetTouches.length === 2) {
            for (let i = 0; i < touchEvent.targetTouches.length; i++) {
                touchPointCache.push(touchEvent.targetTouches[i])
            }
        }
        ClickAndTapHandler(touchEvent)
    }

    function touchMoveHandler(touchEvent: React.TouchEvent<HTMLDivElement>) {
        pinchZoomHandler(touchEvent)
    }

    function pinchZoomHandler(touchEvent: React.TouchEvent<HTMLDivElement>) {
        if (touchEvent.targetTouches.length === 2 && touchEvent.changedTouches.length === 2) {
            // Check if the two target touches are the same ones that started the touch with two fingers
            let point1 = -1
            let point2 = -1
            for (let i = 0; i < touchPointCache.length; i++) {
                if (touchPointCache[i].identifier === touchEvent.targetTouches[0].identifier) point1 = i
                if (touchPointCache[i].identifier === touchEvent.targetTouches[1].identifier) point2 = i
            }
            if (point1 >= 0 && point2 >= 0) {
                const point_1_old_X = touchPointCache[point1].clientX
                const point_2_old_X = touchPointCache[point2].clientX
                const point_1_new_X = touchEvent.targetTouches[0].clientX
                const point_2_new_X = touchEvent.targetTouches[1].clientX

                const point_1_old_Y = touchPointCache[point1].clientY
                const point_2_old_Y = touchPointCache[point2].clientY
                const point_1_new_Y = touchEvent.targetTouches[0].clientY
                const point_2_new_Y = touchEvent.targetTouches[1].clientY

                const oldDistanceBetweenFingers = Math.sqrt(
                    Math.pow(point_2_old_X - point_1_old_X, 2) + Math.pow(point_2_old_Y - point_1_old_Y, 2)
                )
                const newDistanceBetweenFingers = Math.sqrt(
                    Math.pow(point_2_new_X - point_1_new_X, 2) + Math.pow(point_2_new_Y - point_1_new_Y, 2)
                )

                let resizeRatio = newDistanceBetweenFingers / oldDistanceBetweenFingers

                // keep new size within constraints
                const newHeight = resizeRatio * resizeState.height
                if (newHeight < minSize.height) resizeRatio = minSize.height / resizeState.height
                if (newHeight > maxSize.height) resizeRatio = maxSize.height / resizeState.height

                const target = document.querySelector(".pinch-resizable") as any
                if (Math.abs(1 - resizeRatio) > 0.01) {
                    target.style.setProperty("transform", "scale(" + resizeRatio + ")")
                }
            } else {
                // empty cache
                touchPointCache = []
            }
        }
    }

    function touchEndHandler(touchEvent: React.TouchEvent<HTMLDivElement>) {
        const target = document.querySelector(".pinch-resizable") as any
        let newScaleValue = 1
        const transform0 = target.style.transform

        if (transform0?.includes("scale")) {
            const transform = transform0.split(")")
            const transform2 = transform[0].split("(")
            const previousScaleValue = transform2[1]
            newScaleValue = parseFloat(previousScaleValue)
        }

        const deltaX = (resizeState.width - resizeState.width * newScaleValue) / 2
        const deltaY = (resizeState.height - resizeState.height * newScaleValue) / 2

        setResizeState((prevState: ResizeState) => {
            const newResizeState: ResizeState = {
                width: prevState.width * newScaleValue,
                height: prevState.height * newScaleValue,
                relativeLeft: prevState.relativeLeft + deltaX,
                relativeTop: prevState.relativeTop + deltaY
            }
            return newResizeState
        })

        target.style.setProperty("transform", "scale(1)")
        touchEvent.preventDefault()
    }

    function ClickAndTapHandler(event: React.TouchEvent<HTMLDivElement> | MouseEvent) {
        if (!doubleTapTimer.current) {
            doubleTapTimer.current = setTimeout(() => {
                // do single click/tap stuff
                if (shouldHandleClick) {
                    shouldHandleClick = false
                    togglePlayPause()
                }
                doubleTapTimer.current = null
            }, waitForSecondTap)
        } else {
            // do double click/tap stuff
            clearTimeout(doubleTapTimer.current)
            doubleTapTimer.current = null
            // check that not pinch-resizing, i.e. only one finger touches the target
            const touchEvent = event as React.TouchEvent<HTMLDivElement>
            if (touchEvent.targetTouches && touchEvent.targetTouches.length === 1) {
                // touch event
                realPlayerRef.current?.handleDoubleClick()
            } else if (!touchEvent.targetTouches) {
                // click event
                realPlayerRef.current?.handleDoubleClick()
            }
        }
    }

    function handleReloadButtonClick(e: any) {
        setPlayerShouldReload(!playerShouldReload)
    }

    return isMobile ? (
        <div
            onTouchStartCapture={touchStartHandler}
            onTouchMove={touchMoveHandler}
            onTouchEnd={touchEndHandler}
            onTouchCancel={touchEndHandler}
        >
            <Draggable
                // position Player in bottom right corner in vertical alignment with the conference overlay or where user placed it
                defaultPosition={{ x: pipPlayerDefaultPosition.x - deltaSize.x, y: pipPlayerDefaultPosition.y - deltaSize.y }} // account for change in size from resizing
                onStart={handleDragStart}
                onStop={handleDragStop}
                onMouseDown={ClickAndTapHandler}
            >
                <ResizableRoot
                    className={"picture-in-picture-player"}
                    style={{
                        top: resizeState.relativeTop,
                        left: resizeState.relativeLeft,
                        width: resizeState.width,
                        height: resizeState.height
                    }}
                >
                    <div className={"pinch-resizable"}>
                        <CloseIconRoot
                            className="pip-close-button"
                            title={branding.videoPlayerBranding.closeText}
                            onMouseDown={prepareClosing}
                            onTouchStartCapture={prepareClosing}
                            isPaused={isPaused}
                            isMobile={isMobile}
                        >
                            <IconCloseV2 fill="#FFF" stroke="#FFF" />
                        </CloseIconRoot>
                        {content}
                        {errorOccured && (
                            <ReloadButton onClick={handleReloadButtonClick} onTouchStartCapture={handleReloadButtonClick}>
                                {branding.videoPlayerBranding.reloadButtonText}
                            </ReloadButton>
                        )}
                    </div>
                </ResizableRoot>
            </Draggable>
        </div>
    ) : (
        <>
            <Draggable
                // position Player in bottom right corner in vertical alignment with the conference overlay or where user placed it
                defaultPosition={{ x: pipPlayerDefaultPosition.x - deltaSize.x, y: pipPlayerDefaultPosition.y - deltaSize.y }} // account for change in size from resizing
                onStart={handleDragStart}
                onStop={handleDragStop}
                onMouseDown={ClickAndTapHandler}
            >
                <Resizable
                    onResizeStart={handleDesktopResizeStart}
                    onResize={handleDesktopResize}
                    onResizeStop={handleDesktopResizeStop}
                    width={resizeState.width}
                    height={resizeState.height}
                    className="box absolutely-positioned"
                    resizeHandles={["sw", "se", "nw", "ne"]} // handles in all for corners
                    // resizeHandles={['sw', 'se', 'nw', 'ne', 's', 'e', 'w', 'n']}     // handles in four corners and on four edges
                    axis="both"
                    handleSize={[8, 8]}
                    lockAspectRatio={true}
                    minConstraints={[minSize.width, minSize.height]}
                    maxConstraints={[maxSize.width, maxSize.height]}
                >
                    <ResizableRoot
                        className={"picture-in-picture-player"}
                        style={{
                            top: resizeState.relativeTop,
                            left: resizeState.relativeLeft,
                            width: resizeState.width,
                            height: resizeState.height
                        }}
                        title={branding.videoPlayerBranding.pipDoubleClickHint}
                    >
                        <CloseIconRoot
                            className="pip-close-button"
                            title={branding.videoPlayerBranding.closeText}
                            onMouseDown={prepareClosing}
                            onTouchStartCapture={prepareClosing}
                            isMobile={isMobile}
                        >
                            <IconCloseV2 fill="#FFF" stroke="#FFF" />
                        </CloseIconRoot>
                        {content}
                        {errorOccured && (
                            <ReloadButton onClick={handleReloadButtonClick}>
                                {branding.videoPlayerBranding.reloadButtonText}
                            </ReloadButton>
                        )}
                    </ResizableRoot>
                </Resizable>
            </Draggable>
        </>
    )
}

interface PictureInPicturePlayerRootProps {
    positionY: string
    positionX: string
    width: string
    height: string
}

const LoadingPlayerRoot = styled.div<PictureInPicturePlayerRootProps>`
    ${(props) => `width: ${props.width};`}
    ${(props) => `height: ${props.height};`}
    position: absolute;
    z-index: 100;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background-color: #000;
`

const ResizableRoot = styled.div`
    // opacity is used here, because display: none causes the player to flicker on resize
    .react-resizable-handle {
        opacity: 0;

        @media (max-width: 769px) {
            opacity: 1;
        }
    }
    :hover .react-resizable-handle {
        opacity: 1;
    }

    position: absolute !important;
    z-index: 15000 !important;
    /* https://developers.google.com/web/fundamentals/performance/rendering/stick-to-compositor-only-properties-and-manage-layer-count#use_transform_and_opacity_changes_for_animations */
    will-change: transform;

    #virtualGuide & .layoutRoot {
        display: flex;
        background: #eee;
        margin-bottom: 20px;
        flex-wrap: wrap;
    }
    #virtualGuide & .absoluteLayout {
        height: 600px;
        position: relative;
        justify-content: center;
        align-items: center;
    }
    #virtualGuide & .scaledLayout {
        width: 125%;
        left: -12.5%;
        transform: scale(0.75);
        margin-top: -7.5%;
    }

    #virtualGuide & .box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: black;
        text-align: center;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
    }
    #virtualGuide & .box .text {
        text-align: center;
    }

    #virtualGuide & .absolutely-positioned {
        position: absolute !important;
    }
    #virtualGuide & .left-aligned {
        left: 0;
    }
    #virtualGuide & .right-aligned {
        right: 0;
    }
    #virtualGuide & .top-aligned {
        top: 0;
    }
    #virtualGuide & .bottom-aligned {
        bottom: 0;
    }

    #virtualGuide & .custom-box {
        overflow: visible;
    }
    #virtualGuide & .custom-handle {
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: #1153aa;
        opacity: 0.75;
        border-radius: 4px;
    }
    #virtualGuide & .custom-handle-sw {
        bottom: -4px;
        left: -4px;
        cursor: sw-resize;
    }
    #virtualGuide & .custom-handle-se {
        bottom: -4px;
        right: -4px;
        cursor: se-resize;
    }
    #virtualGuide & .custom-handle-nw {
        top: -4px;
        left: -4px;
        cursor: nw-resize;
    }
    #virtualGuide & .custom-handle-ne {
        top: -4px;
        right: -4px;
        cursor: ne-resize;
    }
    #virtualGuide & .custom-handle-w,
    #virtualGuide & .custom-handle-e {
        top: 50%;
        margin-top: -4px;
        cursor: ew-resize;
    }
    #virtualGuide & .custom-handle-w {
        left: -4px;
    }
    #virtualGuide & .custom-handle-e {
        right: -4px;
    }
    #virtualGuide & .custom-handle-n,
    #virtualGuide & .custom-handle-s {
        left: 50%;
        margin-left: -4px;
        cursor: ns-resize;
    }
    #virtualGuide & .custom-handle-n {
        top: -4px;
    }
    #virtualGuide & .custom-handle-s {
        bottom: -4px;
    }
    #virtualGuide & .react-resizable {
        position: relative;
    }
    #virtualGuide & .react-resizable-handle {
        position: absolute;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-origin: content-box;
        box-sizing: border-box;
        background: url("/videoPlayerIcons/arrow-head-down.svg");
        filter: invert(100%);
        /* background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+'); */
        background-position: bottom right;
        padding: 0 3px 3px 0;
        z-index: 10000;
    }
    #virtualGuide & .react-resizable-handle-sw {
        bottom: 0;
        left: 0;
        cursor: sw-resize;
        transform: rotate(45deg);
    }
    #virtualGuide & .react-resizable-handle-se {
        bottom: 0;
        right: 0;
        cursor: se-resize;
        transform: rotate(-45deg);
    }
    #virtualGuide & .react-resizable-handle-nw {
        top: 0;
        left: 0;
        cursor: nw-resize;
        transform: rotate(135deg);
    }
    #virtualGuide & .react-resizable-handle-ne {
        top: 0;
        right: 0;
        cursor: ne-resize;
        transform: rotate(225deg);
    }
    #virtualGuide & .react-resizable-handle-w,
    #virtualGuide & .react-resizable-handle-e {
        top: 50%;
        margin-top: -10px;
        cursor: ew-resize;
    }
    #virtualGuide & .react-resizable-handle-w {
        left: 0;
        transform: rotate(90deg);
    }
    #virtualGuide & .react-resizable-handle-e {
        right: 0;
        transform: rotate(270deg);
    }
    #virtualGuide & .react-resizable-handle-n {
        left: 50%;
        margin-left: -10px;
        cursor: ns-resize;
        transform: rotate(180deg);
    }

    #virtualGuide & .react-resizable-handle-s {
        left: 50%;
        margin-left: -10px;
        cursor: ns-resize;
    }
    #virtualGuide & .react-resizable-handle-n {
        top: 0;
        transform: rotate(180deg);
    }
    #virtualGuide & .react-resizable-handle-s {
        bottom: 0;
    }
`

interface CloseIconRootProps {
    isPaused?: boolean
    isMobile?: boolean
}

const fade = (fadeIn: boolean | undefined) => keyframes`
    0% {
        opacity: ${fadeIn !== undefined && fadeIn ? 0 : 1};
    }
    100% {
        opacity: ${fadeIn !== undefined && fadeIn ? 1 : 0};
    }
`

const CloseIconRoot = styled.div<CloseIconRootProps>`
    position: absolute;
    ${(props) => (props.isMobile ? "top: 4px;" : "top: 16px;")};
    ${(props) => (props.isMobile ? "right: 7px;" : "right: 20px;")};
    z-index: 10001;
    cursor: pointer;
    // opacity is used here, because display: none can cause the player to flicker on resize
    opacity: 0;

    ${ResizableRoot}:hover & {
        opacity: 1;
    }

    ${(props) => (props.isPaused ? "opacity: 1;" : "")};
    animation: ${(props) => fade(props.isPaused)} 0.2s linear;
`

export default PictureInPictureVideoPlayer
