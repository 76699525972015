import styled from "styled-components"
import branding from "../branding/branding"
import CenteredLoader from "../ui/CenteredLoader"

export const AvatarWrapper = styled.div`
    cursor: pointer;
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    margin-left: 15px;
`

export const TheirAvatarWrapper = styled.div`
    cursor: pointer;
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    margin-left: 15px;
    bottom: 0;
    position: absolute;
`

export const MineAvatarWrapper = styled.div`
    cursor: pointer;
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    margin-left: calc(100% - 50px);
    bottom: 0;
    position: absolute;
`
export const MessageRow = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    max-width: 85%;
    margin-bottom: 10px;
    font-family: ${branding.font1};
    position: relative;
    &.withAvatar {
        flex-direction: row;
    }

    &.neutral {
        align-self: center;
    }
    &.mine {
        align-self: flex-end;
    }
    &.their {
        align-self: flex-start;
    }
`

export const MessageElement = styled.div<{ marginLeft: string; marginRight: string }>`
    display: flex;
    flex-direction: column;
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
`

export const MessageContent = styled.div`
    font-size: 12px;
    font-family: ${branding.font1};
    line-height: 14px;
    white-space: pre-wrap;
    word-break: break-word;
    max-width: 417px;
`
export const MessageStatus = styled.div``

export const MessageItemDate = styled.div`
    font-size: 10px;
    font-family: ${branding.font1};
    line-height: 12px;
    margin: 0;
    align-self: flex-end;
`

export const MessageItemBubble = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-width: 100%;
    font-family: ${branding.font1};

    &.neutral {
        font-style: normal;
        font-size: 12px;
        align-self: center;
        background: antiquewhite;
        border-radius: 5px;
        padding: 7px 10px;
        margin: 10px 0;
    }
    &.mine {
        align-self: flex-end;
        background: #32a6fd;
        border-radius: 5px;
        border-bottom-right-radius: 0px;
        color: #fff;
    }
    &.their {
        align-self: flex-start;
        background: #f0f0f0;
        border-radius: 5px;
        border-bottom-left-radius: 0px;
    }
`

export const MessageItemAuthor = styled.div`
    font-weight: bold;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};
`

export const HeaderElement = styled.div`
    font-size: 10px;
    line-height: 20px;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 16px;
    margin-bottom: 8px;
    width: 95%;

    p:after,
    p:before {
        content: " ";
        display: block;
    }

    p {
        text-align: center;
        left: 4px;
        right: 4px;
        border-radius: 5px;
        background: #f0f0f0 !important;
        color: ${branding.chatInputColorTime ?? branding.mainInfoColor};
        margin-right: auto;
        margin-left: auto;
        width: 71px;
        height: 20px;
    }
`

export const ScrollingContainer = styled.div`
    flex: 1;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    color: ${branding.chatListColor ?? "black"};
`
export const ConversationContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: auto !important;
    padding: 8px 0 8px 0;
    width: 100%;
    max-width: 100%;
    margin-bottom: 5px;
`
export const NextPageLoader = styled(CenteredLoader)`
    height: 80px;
`

export const ChatHeaderItem = styled.p`
    background: ${branding.chatBranding.headerInfoBubbleBackgroundColor ?? "#f0f0f0"} !important;
    color: ${branding.chatBranding.headerInfoBubbleColor ?? branding.mainInfoColor} !important;
`
