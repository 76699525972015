import styled from "styled-components"

export const SpecialSponsorLogoDivRoot = styled.div`
    width: 100%;
    justify-content: center;

    @media (max-height: 900px) {
        margin-top: 2px;
    }
`

export const SpecialSponsorLogoContainer = styled.div`
    width: 60px;
    height: 60px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin-left: 15px;
`

export const SpecialSponsorLogoRoot = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`
