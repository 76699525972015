import styled from "styled-components"
import branding from "../branding/branding"
import { NavLink } from "react-bootstrap"

export const ContactEntryRoot = styled.div`
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    overflow: hidden;
`

export const HoverRoot = styled(NavLink)`
    width: 100%;

    &:hover {
        background-color: rgba(240, 240, 240, 1);
        text-decoration: none;
    }
    &.disabled:hover {
        background-color: rgba(0, 0, 0, 0.25);
        text-decoration: none;
    }
    &:active,
    &:focus {
        text-decoration: none;
    }
`

export const ContactEntryContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding-right: 10px;
    background-color: transparent;
    color: ${branding.mainInfoColor ?? "#000"};
    width: 100%;

    &:hover {
        background-color: rgba(240, 240, 240, 1);
    }

    &.disabled {
        opacity: 25%;
    }
`

export const PersonName = styled.div`
    margin-top: 13px;
    margin-left: 2px;
    font-family: ${branding.contactEntry.personNameFont ?? branding.font1};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: ${branding.contactEntry.contactEntryLetterSpacing};
`

export const PersonPosition = styled.div`
    margin-left: 2px;
    margin-top: 3px;
    font-family: ${branding.contactEntry.personPositionFont ?? branding.font1};
    font-weight: ${branding.contactEntry.personPositionFontWeight};
    font-style: normal;
    font-size: 10px;
    line-height: 12px;
`

export const ContactEntryPersonPosition = styled(PersonPosition)`
    font-size: ${(props) => props.theme.branding.contactEntry.personPositionFontSize};
    color: ${(props) => props.theme.branding.mainInfoColor ?? "#000"};
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 16px;
`

export const PersonDataRoot = styled.div<{ color?: string }>`
    display: flex;
    flex-grow: 1;
    grid-template-columns: 30% 70%;
    flex-direction: row;
    margin-bottom: 8px;
    color: ${(props) => props.color};
`

export const UnreadMarker = styled.div`
    width: 1px;
    height: 1px;
    border-radius: 50%;
    background-color: ${branding.conversationEntry.unreadMarkerBgColor ?? "#0000FF"};
    margin-top: 22px;
    margin-right: 5px;
    padding: 5px;
`

export const AvatarContainer = styled.div`
    margin-top: 8px;
    flex-grow: 0;
    display: flex;
`

export const PersonDetailsContainer = styled.div`
    flex-grow: 1;
    padding-left: 10px;

    .person-name {
        font-size: ${branding.contactEntry.personNameFontSize};
    }

    .person-position {
        font-size: ${branding.contactEntry.personPositionFontSize};
    }
`

export const MenuButtonContainer = styled.div`
    align-self: center;
`
