import _ from "lodash"
import { useEffect, useRef, useState } from "react"
import * as React from "react"
import { Col, Row, SafeAnchor } from "react-bootstrap"
import styled, { css } from "styled-components"
import { trackNewsAction, UserOrganizationVisitSource } from "../backendServices/TrackingServices"
import {
    Category,
    Coupon,
    EntityType,
    EventDate,
    Exhibitor,
    JobOffer,
    NetworkingUser,
    News,
    Person,
    Product,
    Trademark
} from "../backendServices/Types"
import branding from "../branding/branding"
import { getCompanyMenuModalItems, getNewsDateFormatted } from "../contentArea/detailPages/components/DetailPageSections"
import { DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import ActionsWrapper from "../contentArea/entitiesActions/ActionsWrapper"
import { useFavoriteState } from "../globalStates/Favorites"
import { useLanguageState } from "../globalStates/LanguageState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { useSuggestContext } from "../navigationArea/useSuggestContext"
import { device, MobileVersionContainer, DesktopVersionContainer } from "../utils/Device"
import { Section, sectionOrder, Sections, SectionType } from "../utils/searchUtils"
import ContextMenu from "./ContextMenu"
import {
    IconCoupon,
    IconJoboffer,
    IconNews,
    IconNewsLink,
    IconOrganization,
    IconProduct,
    IconProgram,
    IconThreeDotsMenuVertical,
    IconTrademark,
    IconUserCustom
} from "./Icons"
import { useRecommendModal } from "./modals/RecommendModal"
import { MenuButton, MenuPanel } from "./StaffEntry"
import useWindowDimensions from "./WindowDimensionsHook"
import { HalleStandComponent } from "./CompaniesTilesLayout"
import BadgeArea from "./BadgeArea"
import moment from "moment"
import { format } from "date-fns"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import { ProductType } from "../contentArea/detailPages/components/ProductDetailInfo"
import { getUserTitle } from "../communicationArea/helpers/GlobalFunctions"
import AvatarWithPresenceState from "./avatarWithPresenceState/AvatarWithPresenceState"
import CalendarEntryModal2 from "./modals/calendarEntry/CalendarEntryModal2"
import { trackBannerClick, trackSelectContent, trackViewPromotion, trackViewSearchResult } from "../utils/GTMTracking"
import { capitalizeFirstLetter } from "../utils/StringUtils"
import useEntityLogoUrl from "../hooks/useEntityLogoUrl"
import { CalendarEntryModalViewMode } from "./modals/calendarEntry/ModalFunctions"

const getEntityConfig = (entity: any, lang: string) => {
    switch (entity.entityType) {
        case "organization":
            const organization = entity as Exhibitor
            return {
                entity: organization,
                name: organization.name,
                logoUrl: organization.pictureURL,
                initials: organization.initials,
                canHaveFavorites: true,
                entityDescription: organization.teaser,
                entityTitle: branding.globalSearchResultPage.exhibitorEntityTitle,
                stands: organization.stands,
                icon: IconOrganization({
                    width: "16px",
                    height: "16px",
                    fill: branding.globalSearchResultPage.listLayoutIconColor
                })
            }
        case "product":
            const product = entity as Product
            return {
                entity: product,
                name: product.name,
                logoUrl: product.pictureURL,
                initials: product.initials,
                canHaveFavorites: true,
                entityDescription: product.descriptionTeaser,
                entityTitle: branding.globalSearchResultPage.productEntityTitle,
                stands: product.stands,
                icon: IconProduct({ width: "16px", height: "16px", fill: branding.globalSearchResultPage.listLayoutIconColor })
            }
        case "trademark":
            const trademark = entity as Trademark
            return {
                entity: trademark,
                name: trademark.name,
                logoUrl: trademark.pictureURL,
                initials: trademark.initials,
                canHaveFavorites: true,
                entityDescription: trademark.descriptionTeaser,
                entityTitle: branding.globalSearchResultPage.trademarkEntityTitle,
                icon: IconTrademark({ width: "16px", height: "16px", fill: branding.globalSearchResultPage.listLayoutIconColor })
            }
        case "networking_user":
            const networkingUser = entity as NetworkingUser
            let createdInitials: any
            if (networkingUser.firstName !== null && networkingUser.lastName !== null) {
                // createdInitials = networkingUser.firstName?.substring(0, 1) + networkingUser.lastName?.substring(0, 1)
                createdInitials = [networkingUser.firstName, networkingUser.lastName].filter(Boolean).join(" ")
            }
            return {
                entity: networkingUser,
                name: [networkingUser.firstName, networkingUser.lastName].filter(Boolean).join(" "),
                logoUrl: networkingUser.logoUrl!,
                initials: createdInitials,
                canHaveFavorites: true,
                entityDescription: getUserTitle(networkingUser.position, networkingUser.company, networkingUser.organization),
                entityTitle: branding.globalSearchResultPage.networkingUserEntityTitle,
                icon: IconUserCustom({ width: "15px", height: "15px", fill: branding.globalSearchResultPage.listLayoutIconColor })
            }
        case "news":
            const news = entity as News
            return {
                entity: news,
                name: news.name,
                logoUrl: news.logoUrl,
                initials: news.initials,
                canHaveFavorites: true,
                entityDescription: news.descriptionTeaser,
                entityTitle: branding.globalSearchResultPage.newsEntityTitle,
                icon: IconNews({ width: "16px", height: "16px", fill: branding.globalSearchResultPage.listLayoutIconColor })
            }
        case "eventdate":
            const eventDate = entity as EventDate
            return {
                entity: eventDate,
                name: eventDate.name,
                logoUrl: eventDate.logoUrl ?? "/branding/applicationMedia/no-event-default-image.png",
                initials: eventDate.name?.substring(0, 1),
                canHaveFavorites: true,
                entityDescription: eventDate.teaser,
                entityTitle: branding.globalSearchResultPage.eventdateEntityTitle,
                icon: IconProgram({
                    width: "16px",
                    height: "16px",
                    fill: branding.globalSearchResultPage.listLayoutIconColor,
                    stroke: "#fff"
                })
            }
        case "coupon":
            const coupon = entity as Coupon
            return {
                entity: coupon,
                name: coupon.title,
                logoUrl: coupon.pictureURL,
                initials: coupon.initials,
                canHaveFavorites: false,
                entityDescription: coupon.description,
                entityTitle: branding.globalSearchResultPage.couponEntityTitle,
                icon: IconCoupon({ width: "16px", height: "16px", fill: branding.globalSearchResultPage.listLayoutIconColor })
            }
        case "category":
            const category = entity as Category
            return {
                entity: category,
                name: lang === "de" ? category.nameDe : category.name,
                logoUrl: category.iconSmallUrl,
                initials: category.name?.substring(0, 1),
                canHaveFavorites: false,
                entityDescription: "",
                entityTitle: branding.globalSearchResultPage.categoryEntityTitle,
                icon: null
            }
        case "person":
            const person = entity as Person
            return {
                entity: person,
                name: [person.title, person.firstName, person.lastName].filter(Boolean).join(" "),
                logoUrl: person.logoUrl,
                initials: [person.firstName, person.lastName].filter(Boolean).join(" "),
                canHaveFavorites: true,
                entityDescription: getUserTitle(person.position, person.organization),
                entityTitle: branding.globalSearchResultPage.personEntityTitle,
                icon: IconUserCustom({ width: "15px", height: "15px", fill: branding.globalSearchResultPage.listLayoutIconColor })
            }
        case "joboffer":
            const joboffer = entity as JobOffer
            return {
                entity: joboffer,
                name: joboffer.name,
                logoUrl: joboffer.pictureURL,
                initials: joboffer.initials,
                canHaveFavorites: true,
                entityDescription: joboffer.descriptionTeaser,
                entityTitle: branding.globalSearchResultPage.jobofferEntityTitle,
                exhibitor: joboffer.organization.name,
                location: joboffer.location
                    ? branding.exhibitorsPageContent.jobofferLocationTitleTemplate.replace("{$location}", joboffer.location)
                    : undefined,
                start: joboffer.startdate
                    ? branding.exhibitorsPageContent.jobofferStartDateTitleTemplate.replace(
                          "{$start}",
                          moment(joboffer.startdate).locale(lang).format("MMMM DD, YYYY")
                      )
                    : undefined,
                level: joboffer.careerLevel
                    ? branding.exhibitorsPageContent.jobofferCareerLevelTitleTemplate.replace("{$level}", joboffer.careerLevel)
                    : undefined,
                icon: IconJoboffer({ width: "16px", height: "16px", fill: branding.globalSearchResultPage.listLayoutIconColor }),
                homeOffice:
                    joboffer.homeOffice !== undefined
                        ? branding.jobofferDetailPageContent.homeOfficeTitle +
                          (joboffer.homeOffice ? branding.systemCheck.yes : branding.systemCheck.no)
                        : ""
            }
        default:
            return null
    }
}

const createGroups = (section: Section) => {
    const alphaNums = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
    let groupedItems: any = []
    let currentInitial = ""

    section.entities.forEach((entity) => {
        const resultEntity = entity as Exhibitor | Product | Trademark | Coupon | News | EventDate | NetworkingUser
        const groupTitle = resultEntity.orderkey.replace("\\n", "").substring(0, 1).toUpperCase()

        if (!alphaNums.includes(_.deburr(groupTitle))) {
            if (!groupedItems["#"]) {
                groupedItems["#"] = []
            }

            currentInitial = "#"
        } else if (!groupedItems[_.deburr(groupTitle)]) {
            groupedItems[_.deburr(groupTitle)] = []
            currentInitial = _.deburr(groupTitle)
        }

        groupedItems[currentInitial].push(entity)
    })

    return groupedItems
}

interface ResultListLayoutProps {
    sections: Sections
    isSponsor: boolean
    isMediaPartner: boolean
    isStartup: boolean
    entityType?: EntityType | string
    isResultPage?: boolean
    searchKrit?: string
    searchParams?: string
}

const ResultEntryRoot = styled.div`
    @media ${device.mobile} {
        border-bottom: 1px solid ${branding.listDividerColor};
        padding-bottom: 20px;
    }
`

const ResultRowRoot = styled(Row)<{ marginBottom: string }>`
    font-family: ${branding.font1};
    padding: 25px 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 12px;
    line-height: 17px;
    svg {
        color: ${branding.primaryColor};
    }

    @media ${device.tablet} {
        border-bottom: 1px solid ${branding.listDividerColor};
    }

    @media ${device.mobile} {
        margin-bottom: ${(props) => props.marginBottom};
    }
`

const ResultLogoRoot = styled.div<{
    entityType: string
    organizationLogoShapeSquare?: boolean
    trademarkLogoShapeSquare?: boolean
}>`
    width: ${(props) =>
        (props.entityType === "trademark" && props.trademarkLogoShapeSquare) ||
        (props.entityType === "organization" && props.organizationLogoShapeSquare) ||
        props.entityType === "joboffer"
            ? "60px"
            : "100px"};
    height: 60px;
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (props.entityType === "eventdate" ? "0" : "2px")};
    overflow: hidden;
    font-size: 22px;

    @media ${device.tablet} {
        width: ${(props) =>
            (props.entityType === "trademark" && props.trademarkLogoShapeSquare) ||
            (props.entityType === "organization" && props.organizationLogoShapeSquare) ||
            props.entityType === "joboffer"
                ? "95px"
                : "160px"};
        height: 95px;
    }

    & p {
        font-family: ${branding.font1};
        max-width: 40px;
        font-size: 12px;
        line-height: 0.6rem;
        color: rgb(0, 0, 0);
        text-align: center;
        display: inline-table;
        margin-bottom: 0;
        white-space: normal;
        word-break: break-word;
    }
`

const EntityMarkerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
`

const EntityTitleMarker = styled.div`
    font-family: ${branding.font1};
    font-size: 12px;
    line-height: 16px;
    margin-left: 10px;
`

const ResultTitleRoot = styled.div<{ entityType?: EntityType }>`
    padding-left: ${(props) => (props.entityType === "networking_user" || props.entityType === "person" ? "65px" : "25px")};
    float: left;

    a:link,
    a:visited,
    a:hover,
    a:active {
        color: ${branding.exhibitorsPageContent.listLayoutTitleRootColor};
        text-decoration: none;
    }
`

const ResultLogo = styled.img<{ entityType: EntityType }>`
    object-fit: contain;
    border-radius: none;
    width: 100%;
    height: 100%;
`

const ResultIconsRoot = styled(Col)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    align-self: flex-start;
`
const EntityTitle = styled.div<{ entityType?: string }>`
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: bold;
    color: ${branding.exhibitorsPageContent.companiesListLayoutTitleColor}; // default color
    font-family: ${branding.exhibitorsPageContent.companiesListLayoutTitleFont}; // default font family

    /**
        Titles for entities should be brandable separately.
    */

    ${(props) =>
        props.entityType === "organization" &&
        css`
            color: ${branding.exhibitorsPageContent.companiesListLayoutTitleColor};
            font-family: ${branding.exhibitorsPageContent.companiesListLayoutTitleFont};
        `}

    ${(props) =>
        props.entityType === "product" &&
        css`
            color: ${branding.productsPageContent.productsListLayoutTitleColor};
            font-family: ${branding.productsPageContent.productsListLayoutTitleFont};
        `}

    ${(props) =>
        props.entityType === "trademark" &&
        css`
            color: ${branding.trademarksPageContent.trademarksListLayoutTitleColor};
            font-family: ${branding.trademarksPageContent.trademarksListLayoutTitleFont};
        `}

    ${(props) =>
        props.entityType === "news" &&
        css`
            color: ${branding.newsPageContent.newsListLayoutTitleColor};
            font-family: ${branding.newsPageContent.newsListLayoutTitleFont};
        `}

    ${(props) =>
        props.entityType === "joboffer" &&
        css`
            color: ${branding.newsPageContent.jobOfferListLayoutTitleColor};
            font-family: ${branding.newsPageContent.jobOfferListLayoutTitleFont};
        `}

        ${(props) =>
        props.entityType === "eventdate" &&
        css`
            color: ${branding.eventDateDetailPageContent.eventListLayoutTitleColor};
            font-family: ${branding.newsPageContent.jobOfferListLayoutTitleFont};
        `}

        ${(props) =>
        props.entityType === "event" &&
        css`
            color: ${branding.eventDateDetailPageContent.eventListLayoutTitleColor};
            font-family: ${branding.newsPageContent.jobOfferListLayoutTitleFont};
        `}

        ${(props) =>
        props.entityType === "person" &&
        css`
            color: ${branding.globalSearchResultPage.personListLayoutTitleColor};
            font-family: ${branding.font1};
        `}

        ${(props) =>
        props.entityType === "networking_user" &&
        css`
            color: ${branding.globalSearchResultPage.networkingUserListLayoutTitleColor};
            font-family: ${branding.font1};
        `}

            ${(props) =>
        props.entityType === "sotuser" &&
        css`
            color: ${branding.globalSearchResultPage.sotUserListLayoutTitleColor};
            font-family: ${branding.font1};
        `}

        ${(props) =>
        props.entityType === "country" &&
        css`
            color: ${branding.globalSearchResultPage.countryListLayoutTitleColor};
            font-family: ${branding.font1};
        `}

        ${(props) =>
        props.entityType === "category" &&
        css`
            color: ${branding.globalSearchResultPage.categoryListLayoutTitleColor};
            font-family: ${branding.font1};
        `}

        ${(props) =>
        props.entityType === "coupon" &&
        css`
            color: ${branding.globalSearchResultPage.couponListLayoutTitleColor};
            font-family: ${branding.font1};
        `}
`

const EntityNewsExternalIndicator = styled.div`
    margin: -3px 0 0 8px;
`

export const EntityProductTypeMarker = styled.div`
    font-size: 14px;
    font-family: ${branding.exhibitorsPageContent.companiesListLayoutTitleFont};
    color: #727272;
    text-decoration: underline;
    display: inline-block;
    overflow: hidden;
    font-weight: normal;
    margin-bottom: 5px;

    &.list {
        margin-bottom: 5px;
    }

    &.detail {
    }
`
const EntityDateTimeTitle = styled.div`
    font-size: 12px;
    display: inline-block;
    overflow: hidden;
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: ${branding.newsPageContent.newsListLayoutDateTimeColor};
    font-family: ${branding.exhibitorsPageContent.companiesListLayoutTitleFont};

    @media ${device.tablet} {
        font-size: 14px;
    }
`
const EntityDescription = styled.div<{ entityType?: string }>`
    font-size: 12px;
    display: inline-block;
    overflow: hidden;
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: ${branding.exhibitorsPageContent.companiesListLayoutTitleColor}; // default color
    font-family: ${branding.exhibitorsPageContent.companiesListLayoutTitleFont}; // default font family

    /**
        Titles for entities should be brandable separately.
    */

    ${(props) =>
        props.entityType === "organization" &&
        css`
            color: ${branding.exhibitorsPageContent.companiesListLayoutDescriptionColor};
            font-family: ${branding.exhibitorsPageContent.companiesListLayoutTitleFont};
        `}

    ${(props) =>
        props.entityType === "product" &&
        css`
            color: ${branding.productsPageContent.productsListLayoutDescriptionColor};
            font-family: ${branding.productsPageContent.productsListLayoutTitleFont};
        `}

    ${(props) =>
        props.entityType === "trademark" &&
        css`
            color: ${branding.trademarksPageContent.trademarksListLayoutDescriptionColor};
            font-family: ${branding.trademarksPageContent.trademarksListLayoutTitleFont};
        `}

    ${(props) =>
        props.entityType === "news" &&
        css`
            color: ${branding.newsPageContent.newsListLayoutDescriptionColor};
            font-family: ${branding.newsPageContent.newsListLayoutTitleFont};
        `}

        ${(props) =>
        props.entityType === "joboffer" &&
        css`
            color: ${branding.newsPageContent.jobOfferListLayoutDescriptionColor};
            font-family: ${branding.newsPageContent.jobOfferListLayoutTitleFont};
        `}

        ${(props) =>
        props.entityType === "eventdate" &&
        css`
            color: ${branding.eventDateDetailPageContent.eventListLayoutDescriptionColor};
            font-family: ${branding.newsPageContent.jobOfferListLayoutTitleFont};
        `}

        ${(props) =>
        props.entityType === "event" &&
        css`
            color: ${branding.eventDateDetailPageContent.eventListLayoutDescriptionColor};
            font-family: ${branding.newsPageContent.jobOfferListLayoutTitleFont};
        `}

        ${(props) =>
        props.entityType === "person" &&
        css`
            color: ${branding.globalSearchResultPage.personListLayoutDescriptionColor};
            font-family: ${branding.font1};
        `}
    
            ${(props) =>
        props.entityType === "networking_user" &&
        css`
            color: ${branding.globalSearchResultPage.networkingUserListLayoutDescriptionColor};
            font-family: ${branding.font1};
        `}
    
                ${(props) =>
        props.entityType === "sotuser" &&
        css`
            color: ${branding.globalSearchResultPage.sotUserListLayoutDescriptionColor};
            font-family: ${branding.font1};
        `}
    
            ${(props) =>
        props.entityType === "country" &&
        css`
            color: ${branding.globalSearchResultPage.countryListLayoutDescriptionColor};
            font-family: ${branding.font1};
        `}
    
            ${(props) =>
        props.entityType === "category" &&
        css`
            color: ${branding.globalSearchResultPage.categoryListLayoutDescriptionColor};
            font-family: ${branding.font1};
        `}
    
            ${(props) =>
        props.entityType === "coupon" &&
        css`
            color: ${branding.globalSearchResultPage.couponListLayoutDescriptionColor};
            font-family: ${branding.font1};
        `}

    @media ${device.tablet} {
        font-size: 14px;
    }
`

const JobOfferHeadline = styled.div`
    display: flex;
`

const Divider = styled.div`
    height: 15px;
    width: 1px;
    background: #000;
    margin: 0 10px;
`

const SectionHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const SectionHeaderTitle = styled.div`
    color: ${branding.mainInfoColor ?? "#000"};
    //margin-top: 10px;

    font-size: 16px;

    font-family: ${branding.exhibitorsPageContent.sectionTitleFontFamily};
    text-transform: ${branding.exhibitorsPageContent.sectionTitleTextTransform};
    font-weight: ${branding.exhibitorsPageContent.sectionTitleFontWeight};
    letter-spacing: ${branding.exhibitorsPageContent.sectionTitleLetterSpacing};

    @media ${device.tablet} {
        font-size: ${branding.exhibitorsPageContent.sectionTitleFontSize};
        margin-top: 32px;
    }
`

const ClickableDiv = styled.div`
    cursor: pointer;
`
const CompaniesListRoot = styled.div`
    margin: 0 25px;

    @media ${device.mobile} {
        margin-left: 20px;
        margin-right: 15px;
        margin-top: 30px;
    }
`
const GroupedListTitle = styled.h3`
    font-size: 16px;
    font-weight: bold;
    position: sticky;

    @media ${device.tablet} {
        font-size: 22px;
    }
`

const GroupedList = styled.div<{ addPadding?: boolean }>`
    padding-top: ${(props) => (props.addPadding ? "25px" : "")};

    @media ${device.tablet} {
        padding-top: ${(props) => (props.addPadding ? "50px" : "")};
    }

    & a {
        color: ${branding.newsPageContent.newsListLayoutTitleColor};
        font-family: ${branding.font1};
        text-decoration: none;

        &.active {
            text-decoration: none;
        }
    }
`

interface ResultEntryProps {
    isMobile: boolean
    entity: any
    config?: any
    isResultPage: boolean
    customBookmarkFunction?: (id: string) => void
    searchKrit?: string
    searchParams?: string
    src: UserOrganizationVisitSource
    suggestContext: any
    sectionType?: string
}

export const ResultEntry: React.FunctionComponent<ResultEntryProps> = (props) => {
    const { RecommendModal, showRecommendModal } = useRecommendModal()
    const [showRequestMeetingModal, setShowRequestMeetingModal] = useState<boolean>(false)
    const [contextMenuOpened, setContextMenuOpened] = useState<boolean>(false)
    const favoriteState = useFavoriteState()
    const user = useLoggedInState().user()
    const lang = useLanguageState().getLanguage()

    const targetRef = useRef(null)

    const entityLogo = useEntityLogoUrl(props.config?.logoUrl)

    const callback = (entries: any) => {
        entries.forEach((entry: any) => {
            if (entry.isIntersecting) {
                props.sectionType === SectionType.ALL
                    ? trackViewSearchResult(
                          props.entity.id,
                          props.config?.name!,
                          capitalizeFirstLetter(props.entity.entityType),
                          capitalizeFirstLetter(window.location.pathname.substring(1)),
                          props.searchParams
                      )
                    : trackViewPromotion(props.entity.name, props.entity.id, props.entity.name, undefined, "Top-of-the-List")
            }
        })
    }

    useEffect(() => {
        const targetElement = targetRef.current

        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0
        }

        const observer = new IntersectionObserver(callback, options)

        if (targetElement) {
            observer.observe(targetElement)
        }

        return () => {
            if (targetElement) {
                observer.unobserve(targetElement)
            }
        }
    }, []) // eslint-disable-line

    function getActionsWrapper(entity: any, config?: any) {
        return (
            <ActionsWrapper
                layout="list-layout"
                organization={entity.entityType === "organization" ? (entity as Exhibitor)! : undefined}
                entity={entity}
                disableFavorites={!config?.canHaveFavorites}
                exhibitorHasContacts={
                    (entity as Exhibitor) && (entity as Exhibitor).contacts && (entity as Exhibitor).contacts.length > 0
                }
                basisPremiumType={(entity as Exhibitor)?.basisPremium}
                hideActions={props.isMobile}
            />
        )
    }

    const getFormattedDate = (eventDate: any) => {
        return (
            format(moment(eventDate.date).toDate(), branding.eventTiming.eventDaysFormatPattern, {
                locale: lang === "de" ? de : en
            }) +
            ", " +
            eventDate.start +
            " - " +
            eventDate.end +
            (lang === "de" ? " Uhr" : "")
        )
    }

    return (
        <ResultEntryRoot ref={targetRef}>
            <ResultRowRoot marginBottom={contextMenuOpened ? "-20px" : "-60px"}>
                <div
                    onClick={() => {
                        if (props.entity.entityType === "news") {
                            trackNewsAction(user?.profileId!, "news", props.entity.id)
                        }
                    }}
                    className={props.isMobile ? "col-10" : "col"}
                    style={{ display: "flex", paddingLeft: "0" }}
                >
                    <div>
                        {props.entity.entityType === "networking_user" || props.entity.entityType === "person" ? (
                            <AvatarWithPresenceState
                                avatarSize={101}
                                badgeSize={20}
                                badgeRight={-40}
                                badgeTop={70}
                                showAvatarBadge={props.entity.entityType === "networking_user"}
                                userId={props.entity.id}
                                initPresenceByList={false}
                                content={{
                                    pictureUrl: props.config?.logoUrl,
                                    alt: props.config?.initials
                                }}
                            ></AvatarWithPresenceState>
                        ) : (
                            <ResultLogoRoot
                                entityType={props.entity.entityType}
                                organizationLogoShapeSquare={
                                    branding.exhibitorsPageContent.companiesListLayoutOrganizationLogoSquareShape
                                }
                                trademarkLogoShapeSquare={
                                    branding.exhibitorsPageContent.companiesListLayoutTrademarkLogoSquareShape
                                }
                            >
                                {entityLogo ? (
                                    <ResultLogo src={entityLogo} entityType={props.entity.entityType} />
                                ) : (
                                    <p style={{ fontFamily: branding.font1 }}>{props.config?.initials}</p>
                                )}
                            </ResultLogoRoot>
                        )}
                    </div>
                    <ResultTitleRoot entityType={props.entity.entityType}>
                        {props.isResultPage && (
                            <EntityMarkerWrapper>
                                <div>{props.config?.icon}</div>
                                <EntityTitleMarker>{props.config?.entityTitle}</EntityTitleMarker>
                            </EntityMarkerWrapper>
                        )}
                        {props.entity.entityType === "news" && (props.entity as News)?.date && (
                            <EntityDateTimeTitle className="mb-2">
                                {getNewsDateFormatted((props.entity as News)?.date, lang)}
                            </EntityDateTimeTitle>
                        )}
                        {(props.entity.entityType === "organization" || props.entity.entityType === "product") &&
                            branding.exhibitorsPageContent.showHalleStandInfo && (
                                <HalleStandComponent stands={props.config?.stands} view="list" />
                            )}
                        {props.entity.entityType === "product" && branding.exhibitorsPageContent.showProductTypeInfo && (
                            <ProductType product={props.entity as Product} view="list" suggestContext={props.suggestContext} />
                        )}
                        {props.entity.entityType === "joboffer" && (
                            <JobOfferHeadline className="mb-3">
                                {props.config?.exhibitor && (
                                    <EntityDescription entityType={props.entity.entityType}>
                                        {props.config?.exhibitor}
                                    </EntityDescription>
                                )}
                                {props.config?.location && !props.isMobile && (
                                    <>
                                        <Divider />
                                        <EntityDescription entityType={props.entity.entityType}>
                                            {props.config?.location}
                                        </EntityDescription>
                                    </>
                                )}
                                {props.config?.start && !props.isMobile && (
                                    <>
                                        <Divider />
                                        <EntityDescription entityType={props.entity.entityType}>
                                            {props.config?.start}
                                        </EntityDescription>
                                    </>
                                )}
                                {props.config?.level && !props.isMobile && (
                                    <>
                                        <Divider />
                                        <EntityDescription entityType={props.entity.entityType}>
                                            {props.config?.level}
                                        </EntityDescription>
                                    </>
                                )}
                                {props.config?.homeOffice && !props.isMobile && (
                                    <>
                                        <Divider />
                                        <EntityDescription entityType={props.entity.entityType}>
                                            {props.config?.homeOffice}
                                        </EntityDescription>
                                    </>
                                )}
                            </JobOfferHeadline>
                        )}
                        <EntityTitle entityType={props.entity.entityType}>
                            {props.config?.name}{" "}
                            {props.entity.entityType === "news" && (props.entity as News).externalUrl && (
                                <EntityNewsExternalIndicator>
                                    {IconNewsLink({
                                        fill: branding.newsPageContent.newsTilesLayoutDateTimeColor,
                                        height: "13",
                                        width: "13"
                                    })}
                                </EntityNewsExternalIndicator>
                            )}
                        </EntityTitle>
                        {props.entity.entityType === "eventdate" && (
                            <EntityDescription entityType={props.entity.entityType} style={{ marginBottom: "10px" }}>
                                {getFormattedDate(props.entity)}
                            </EntityDescription>
                        )}
                        <DesktopVersionContainer>
                            <EntityDescription entityType={props.entity.entityType}>
                                {props.config?.entityDescription &&
                                    props.config?.entityDescription
                                        ?.split("\\n")
                                        .map((item: any, i: any) => <span key={i}>{item} </span>)}
                            </EntityDescription>
                        </DesktopVersionContainer>

                        <BadgeArea categories={props.entity.categories || []} marginTop="20px" />
                    </ResultTitleRoot>
                </div>
                <ResultIconsRoot xs={props.isMobile ? 1 : 3}>
                    {props.entity.entityType === "organization" ? (
                        <>
                            <DesktopVersionContainer>{getActionsWrapper(props.entity, props.config)}</DesktopVersionContainer>

                            <MobileVersionContainer>
                                <MenuPanel>
                                    <MenuButton
                                        style={{ marginTop: "15px" }}
                                        onClick={(e: any) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setContextMenuOpened(!contextMenuOpened)
                                        }}
                                    >
                                        {IconThreeDotsMenuVertical({
                                            fill: branding.sideIconBar.sideIconColorDark
                                        })}
                                    </MenuButton>
                                </MenuPanel>
                                {showRequestMeetingModal && user && (
                                    <CalendarEntryModal2
                                        viewMode={CalendarEntryModalViewMode.CREATE}
                                        organizationId={props.entity.id}
                                        close={() => setShowRequestMeetingModal(false)}
                                    />
                                )}
                                <RecommendModal />
                            </MobileVersionContainer>
                        </>
                    ) : (
                        <div>{getActionsWrapper(props.entity, props.config)}</div>
                    )}
                </ResultIconsRoot>
            </ResultRowRoot>

            <div style={{ flexGrow: 0, flexShrink: 0, marginTop: "15px" }}>
                <ContextMenu
                    collapsed={!contextMenuOpened}
                    mobile={true}
                    items={() =>
                        getCompanyMenuModalItems(
                            false,
                            favoriteState,
                            showRecommendModal,
                            setShowRequestMeetingModal,
                            props.entity as Exhibitor,
                            user
                        )
                    }
                />
            </div>
        </ResultEntryRoot>
    )
}

export const ResultListLayout: React.FC<ResultListLayoutProps> = React.memo((props) => {
    const lang = useLanguageState()
    const suggestState = useSuggestContext()
    const { isMobile } = useWindowDimensions()

    useEffect(() => {
        window.sessionStorage.setItem("trackViewSearchResult", "")

        return function cleanUp() {
            window.sessionStorage.removeItem("trackViewSearchResult")
        }
    }, [])

    return (
        <CompaniesListRoot>
            {sectionOrder.map((sectionType, key) => {
                const section = props.sections[sectionType]!
                if (!section || (section && section.count === 0)) return null

                const titleSponsors = `${branding.receptionPage.sponsorsTitle}(${section.count.toString()})`
                const titleTemplate = props.isSponsor
                    ? titleSponsors
                    : props.isMediaPartner
                    ? titleSponsors
                    : section.type === SectionType.TOP
                    ? branding.exhibitorsPageContent.sectionHeaderTop
                    : section.entities[0] && section.entities[0].entityType === "product"
                    ? branding.exhibitorsPageContent.sectionHeaderAllProducts
                    : section.entities[0] && section.entities[0].entityType === "trademark"
                    ? branding.exhibitorsPageContent.sectionHeaderAllTrademarks
                    : branding.exhibitorsPageContent.sectionHeaderAllExhibitors

                const src: UserOrganizationVisitSource = props.isSponsor
                    ? "SPONSORS"
                    : props.isStartup
                    ? "STARTUP"
                    : section.type === SectionType.TOP
                    ? props.isResultPage
                        ? "SEARCHSPONSOR"
                        : "FLOORSPONSOR"
                    : props.isResultPage
                    ? "SEARCH"
                    : "FLOOR"
                //in some brandings the "all" section has exhibitors in the title
                //so titles for three sections needed to be separated
                const title = titleTemplate.split("{$count}").join(section.count.toString())

                let groupedItems: any = createGroups(section)

                if (section && section?.count <= 0) {
                    return null
                }

                return (
                    <div key={key}>
                        <SectionHeaderContainer
                            style={{ marginTop: isMobile ? (sectionType === SectionType.TOP ? "0" : "25px") : "0" }}
                        >
                            <SectionHeaderTitle>{title}</SectionHeaderTitle>
                        </SectionHeaderContainer>
                        <>
                            {Object.keys(groupedItems)
                                .sort()
                                .map((group, key) => {
                                    return (
                                        <GroupedList addPadding={sectionType !== "top" && props.entityType !== "news"} key={key}>
                                            {sectionType === "top" || props.entityType === "news" ? null : (
                                                <GroupedListTitle>{group}</GroupedListTitle>
                                            )}

                                            {Object.values(groupedItems[group]).map((entity: any, index: number) => {
                                                const config = getEntityConfig(entity, lang.getLanguage())
                                                const content = (
                                                    <ResultEntry
                                                        key={index}
                                                        isMobile={isMobile}
                                                        entity={entity}
                                                        config={config}
                                                        isResultPage={props.isResultPage ?? false}
                                                        searchKrit={props.searchKrit}
                                                        searchParams={props.searchParams}
                                                        src={src}
                                                        suggestContext={suggestState}
                                                        sectionType={sectionType}
                                                    />
                                                )

                                                let wrapper = <></>
                                                switch (entity.entityType) {
                                                    case "coupon":
                                                        const coupon = entity as Coupon
                                                        if (coupon.url)
                                                            wrapper = (
                                                                <SafeAnchor
                                                                    key={index}
                                                                    onClick={() =>
                                                                        trackSelectContent(
                                                                            config?.name!,
                                                                            "Details",
                                                                            capitalizeFirstLetter(entity.entityType)
                                                                        )
                                                                    }
                                                                    href={coupon.url}
                                                                    target="_blank"
                                                                >
                                                                    {content}
                                                                </SafeAnchor>
                                                            )
                                                        else wrapper = content
                                                        break
                                                    case "category":
                                                        const category = entity as Category
                                                        wrapper = (
                                                            <ClickableDiv
                                                                key={index}
                                                                onClick={() =>
                                                                    suggestState.addExternalSuggestion({
                                                                        icon: "",
                                                                        id: "",
                                                                        subtitle: "",
                                                                        title: config?.name!,
                                                                        value: "cat_" + category.id,
                                                                        active: true
                                                                    })
                                                                }
                                                            >
                                                                {content}
                                                            </ClickableDiv>
                                                        )
                                                        break
                                                    default:
                                                        wrapper = (
                                                            <React.Fragment key={index}>
                                                                {entity.entityType === "news" && (entity as News).externalUrl ? (
                                                                    <a
                                                                        href={(entity as News).externalUrl}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        onClick={() =>
                                                                            trackSelectContent(
                                                                                config?.name!,
                                                                                "Details",
                                                                                capitalizeFirstLetter(entity.entityType)
                                                                            )
                                                                        }
                                                                    >
                                                                        {content}
                                                                    </a>
                                                                ) : (
                                                                    <DetailNavLink
                                                                        key={entity.id}
                                                                        id={entity.id}
                                                                        type={entity.entityType}
                                                                        name={config?.name!}
                                                                        source={src}
                                                                        searchKrit={props.searchKrit}
                                                                        onClick={() => {
                                                                            sectionType === SectionType.ALL
                                                                                ? trackSelectContent(
                                                                                      config?.name!,
                                                                                      "Details",
                                                                                      capitalizeFirstLetter(entity.entityType)
                                                                                  )
                                                                                : trackBannerClick(
                                                                                      entity.id,
                                                                                      entity.name,
                                                                                      "Details",
                                                                                      "Top-of-the-List",
                                                                                      undefined,
                                                                                      "Promotion"
                                                                                  )
                                                                        }}
                                                                    >
                                                                        {content}
                                                                    </DetailNavLink>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                }

                                                return wrapper
                                            })}
                                        </GroupedList>
                                    )
                                })}
                        </>
                    </div>
                )
            })}
        </CompaniesListRoot>
    )
})
