import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { CalendarEntry } from "../../../backendServices/GraphQLServices"
import { Input, InputIcons } from "./CalendarEntryModal.styled"
import { CalendarEntryModalViewMode, onInputTextChanged, renderIcon, showValidationIconAndTextLimitField } from "./ModalFunctions"

interface TextInputFieldProps {
    className?: string
    inputType: string
    inputPlaceholder: string
    showIconInInput?: boolean
    icon?: string
    setTitle?: Dispatch<SetStateAction<string>>
    title?: string
    calendarEntry?: CalendarEntry
    viewMode: CalendarEntryModalViewMode | undefined
    showErrorModal?: boolean
}

const MAX_NOTES_LENGTH = 100

const TextInputField = (props: TextInputFieldProps) => {
    const [showTextLimit, setShowTextLimit] = useState(false)
    const [showValidIcon, setShowValidIcon] = useState(false)
    const [showErrorBorder, setShowErrorBorder] = useState<boolean | undefined>(false)
    const inputRef = useRef(null)
    const [firstFocused, setFirstFocused] = useState<boolean>(true)

    useEffect(() => {
        setShowErrorBorder(props.showErrorModal)
    }, [props.showErrorModal])

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            event.preventDefault()
        }
    }
    function updateValidationStatus() {
        !props.title ? setShowErrorBorder(true) : setShowErrorBorder(false)
    }

    return (
        <InputIcons>
            {props.showIconInInput && renderIcon(props.icon)}
            <Input
                ref={inputRef}
                className={props.className}
                readOnly={props.viewMode === CalendarEntryModalViewMode.VIEW ? true : false}
                type={props.inputType}
                value={props.title}
                placeholder={props.inputPlaceholder}
                showIconInInput={props.showIconInInput}
                onChange={(event) =>
                    props.viewMode === CalendarEntryModalViewMode.VIEW
                        ? undefined
                        : onInputTextChanged(event, MAX_NOTES_LENGTH, props.setTitle!)
                }
                onFocus={() => {
                    setShowTextLimit(true)
                    setShowValidIcon(false)
                    if (!firstFocused) updateValidationStatus()
                    setFirstFocused(false)
                }}
                onBlur={() => {
                    setShowTextLimit(false)
                    setShowValidIcon(true)
                    updateValidationStatus()
                }}
                onKeyDown={handleKeyDown}
                onKeyUp={() => updateValidationStatus()}
                showErrorBorder={showErrorBorder && !props.title ? true : false}
            />
            {showValidationIconAndTextLimitField(props.viewMode, showTextLimit, props.title, showValidIcon, MAX_NOTES_LENGTH)}
        </InputIcons>
    )
}

export default TextInputField
