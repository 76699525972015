import { darkTheme, useMeetingManager } from "amazon-chime-sdk-component-library-react"
import { DeviceType } from "amazon-chime-sdk-component-library-react/lib/types"
import branding from "../../../branding/branding"
import { useDevicesV2 } from "../../hooks/useDevices"

import { DeviceSelect, SettingsLabel } from "../Settings/Settings.styled"

function SpeakerSelection() {
    const devices = useDevicesV2()
    const meetingManager = useMeetingManager()
    return (
        <>
            <SettingsLabel>{branding.audioVideoSettings.audioOutputLabel}</SettingsLabel>
            <DeviceSelect
                options={devices.audioOutputDevices()}
                isMulti={false}
                isSearchable={false}
                isClearable={false}
                theme={darkTheme}
                value={devices.currentAudioOutputDevice()?.value}
                placeholder={devices.currentAudioOutputDevice()?.label}
                onChange={async (device: DeviceType) => {
                    devices.setAudioOutputDevice(device.deviceId, device.label)
                    await meetingManager.audioVideo?.chooseAudioOutput(device.deviceId)
                }}
            />
        </>
    )
}

export default SpeakerSelection
