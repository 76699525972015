import { createState, State, useState } from "@hookstate/core"
import { useLocalAudioOutput, useMeetingManager, useToggleLocalMute } from "amazon-chime-sdk-component-library-react"
import { useDevicesV2 } from "../hooks/useDevices"

interface StateValues {}

const getStartValues = (): StateValues => {
    return {}
}

export interface AudioContext {
    toggleMute: () => void
    isMuted: () => boolean
    isAudioOn: () => boolean
    toggleAudio: () => void
    onCallStart: () => Promise<void>
    onCallEnd: () => Promise<void>
    subscribe: () => void
    unsubscribe: () => void
}
const state = createState<StateValues>(getStartValues())

const useStateWrapper = (state: State<StateValues>): AudioContext => {
    const { muted, toggleMute } = useToggleLocalMute()
    const { isAudioOn, toggleAudio } = useLocalAudioOutput()
    const meetingManager = useMeetingManager()
    const devices = useDevicesV2()

    const observer = {
        eventDidReceive(name: any, attributes: any) {
            // Currently not in use
        }
    }

    return {
        toggleMute: () => {
            toggleMute()
        },
        isMuted: () => {
            return muted
        },
        isAudioOn: () => {
            return isAudioOn
        },
        toggleAudio: () => {
            toggleAudio()
        },
        onCallStart: async () => {
            if (devices.currentAudioInputDevice())
                await meetingManager.audioVideo?.startAudioInput(devices.currentAudioInputDevice()!!.value)
            if (devices.currentAudioOutputDevice())
                await meetingManager.audioVideo?.chooseAudioOutput(devices.currentAudioOutputDevice()!!.value)
        },
        onCallEnd: async () => {
            meetingManager.audioVideo?.stopAudioInput()
        },
        subscribe: () => {
            meetingManager.meetingSession?.eventController.addObserver(observer)
        },
        unsubscribe: () => {}
    }
}

export const useAudioContext = (): AudioContext => useStateWrapper(useState(state))
