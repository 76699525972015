import * as React from "react"
import styled from "styled-components"
import branding from "../branding/branding"

const TextLimitRoot = styled.div<{
    textAlign: string
    marginRight: string
    marginTop?: string
    position?: string
    bottom?: string
    right?: string
    display?: string
}>`
    text-align: ${(props) => props.textAlign};
    font-size: 9px;
    line-height: 20px;
    margin-top: ${(props) => props.marginTop ?? "5px"};
    margin-right: ${(props) => props.marginRight};
    font-family: ${branding.font1};
    color: #727272;
    position: ${(props) => props.position};
    bottom: ${(props) => props.bottom};
    right: ${(props) => props.right};
    display: ${(props) => props.display ?? "block"};
`

interface TextLimitProps {
    textLength: number
    maxTextLength: number
    fontSize?: string
    fontFamily?: string
    textAlign?: string
    marginRight?: string
    marginTop?: string
    position?: string
    bottom?: string
    right?: string
    display?: string
}

const TextLimit: React.FunctionComponent<TextLimitProps> = React.memo((props: TextLimitProps) => {
    return (
        <TextLimitRoot
            position={props.position}
            textAlign={props.textAlign ?? "right"}
            marginRight={props.marginRight ?? "5px"}
            marginTop={props.marginTop ?? "5px"}
            bottom={props.bottom}
            right={props.right}
            display={props.display}
        >
            {props.textLength} / {props.maxTextLength}
        </TextLimitRoot>
    )
})

export default TextLimit
