import { useEffect, useState } from "react"
import branding, { TopSponsor } from "../../../branding/branding"
import { Exhibitor } from "../../../backendServices/Types"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { loadExhibitorsData } from "../../../backendServices/ExhibitorServices"
import { orderBy } from "lodash"
import { ListItem, SponsorsListRoot } from "../SideIconBar.styled"
import SponsorListItem from "../sponsorListItem/SponsorListItem"
import { SponsorsGridRoot } from "./SponsorsList.styled"
import SponsorGridItem from "../sponsorGridItem/SponsorGridItem"

interface SponsorsListProps {
    items: TopSponsor[]
    title: string
    onClick: (e: any) => void
    isNavOpen: boolean
}

const SponsorsList: React.FunctionComponent<SponsorsListProps> = (props: SponsorsListProps) => {
    const abortController = new AbortController()
    const abortSignal = abortController.signal

    const [sponsors, setSponsors] = useState<Exhibitor[]>()
    const lang = useLanguageState().getLanguage()

    useEffect(() => {
        let idList: string[] = []

        props.items.forEach((item) => {
            idList.push("id_orga_" + item.id)
        })

        let sponsorSlotParams: any = {
            filterlist: "entity_orga, " + idList.join(", "),
            startresultrow: 0,
            order: "lexic",
            lang: lang
        }

        loadExhibitorsData(sponsorSlotParams, abortSignal).then((resp) => {
            const ex = resp.entities as Exhibitor[]
            if (ex) {
                ex.forEach((exhibitor: any) => {
                    exhibitor.order = props.items.find((x) => x.id === exhibitor.id)?.order || -1
                })

                setSponsors((e) => orderBy(ex, ["order"], ["asc"]))
            }
        })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {sponsors && sponsors?.length! <= 3 && (
                <SponsorsListRoot>
                    <br />
                    <ListItem key="text" style={{ marginBottom: "-25px", paddingLeft: "30px" }}>
                        <div
                            style={{
                                fontFamily: branding.font1,
                                fontSize: "1rem",
                                color: "grey",
                                visibility: props.isNavOpen ? "visible" : "hidden"
                            }}
                        >
                            {props.title}
                        </div>
                    </ListItem>
                    {sponsors?.map((sponsor, index) => (
                        <SponsorListItem
                            key={index}
                            sponsor={sponsor}
                            isNavOpen={props.isNavOpen}
                            onClick={(e: any) => props.onClick(e)}
                        />
                    ))}
                </SponsorsListRoot>
            )}
            {sponsors && sponsors?.length! > 3 && (
                <SponsorsListRoot>
                    <br />
                    <ListItem key="text" style={{ marginBottom: "-25px" }}>
                        <div
                            style={{
                                fontFamily: branding.font1,
                                fontSize: "1rem",
                                color: "grey",
                                visibility: props.isNavOpen ? "visible" : "hidden",
                                marginLeft: "6px"
                            }}
                        >
                            {props.title}
                        </div>
                    </ListItem>
                    <SponsorsGridRoot navigationOpen={props.isNavOpen} style={{ backgroundColor: "transparent" }}>
                        {sponsors?.map((sponsor, index) => (
                            <SponsorGridItem
                                key={index}
                                sponsor={sponsor}
                                isNavOpen={props.isNavOpen}
                                onClick={(e: any) => props.onClick(e)}
                            />
                        ))}
                    </SponsorsGridRoot>
                </SponsorsListRoot>
            )}
        </>
    )
}

export default SponsorsList
