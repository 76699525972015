import { darkTheme, useMeetingManager } from "amazon-chime-sdk-component-library-react"
import { DeviceType } from "amazon-chime-sdk-component-library-react/lib/types"
import branding from "../../../branding/branding"
import { useDevicesV2 } from "../../hooks/useDevices"
import { DeviceSelect, SettingsLabel } from "../Settings/Settings.styled"

function MicSelection() {
    const devices = useDevicesV2()
    const meetingManager = useMeetingManager()
    return (
        <>
            <SettingsLabel>{branding.audioVideoSettings.audioInputLabel}</SettingsLabel>
            <DeviceSelect
                options={devices.audioInputDevices()}
                isMulti={false}
                isSearchable={false}
                isClearable={false}
                theme={darkTheme}
                value={devices.currentAudioInputDevice()?.value}
                placeholder={devices.currentAudioInputDevice()?.label}
                onChange={async (device: DeviceType) => {
                    devices.setAudioInputDevice(device.deviceId, device.label)
                    await meetingManager.audioVideo?.startAudioInput(devices.currentAudioInputDevice()!!.value)
                }}
            />
        </>
    )
}

export default MicSelection
