import styled, { css } from "styled-components"

export const MuteAllButtonWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;
`

export const MuteAllButtonRoot = styled.div<{ disabled: boolean }>`
    cursor: pointer;
    :hover {
        span {
            color: #727272;
        }
        svg {
            path {
                fill: #727272;
            }
        }
    }
    ${(props) =>
        props.disabled &&
        css`
            span {
                color: #727272;
            }
            svg {
                path {
                    fill: #727272;
                }
            }
        `}
`

export const MuteAllButtonText = styled.span`
    margin-left: 3px;
    color: #fff;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
`
