import * as React from "react"
import { Tooltip } from "react-bootstrap"
import { Placement } from "react-bootstrap/esm/Overlay"
import { AvatarWithDefault } from "../AvatarWithDefault"
import branding from "../../branding/branding"
import AvatarDefaultContent from "../AvatarDefaultContent"
import PresenceIndicator from "../PresenceIndicator"
import { AvatarType } from "../../backendServices/GraphQLServices"
import { IconSpeakerIndicator, IconStaffIndicator } from "../Icons"
import { CustomOverlayTrigger } from "../CustomOverlayTrigger"
import { AvatarContainer, BadgeWrapperWithIcon, BadgeWrapperWithStatus, IconWrapper } from "./AvatarWithPresenceState.styled"
export interface avatarTypeProps {
    type: AvatarType
    bordercolor: string
}

export interface badgeProps {
    bgcolor: string
    btop: number
    bright: number
    bwidth: number
    bfontsize: number
    bpaddingtop: number
}

export interface badgePresenceProps {
    bgcolor: string
    badgesize?: number
    top?: number
    right?: number
}

interface PictureUrl {
    pictureUrl: string
    alt: string
}

export interface AvatarWithPresenceStateProps {
    userId?: string
    userType?: any
    content: any | PictureUrl
    avatarSize: number
    badgeSize?: number
    badgeTop?: number
    badgeLeft?: number
    badgeRight?: number
    showAvatarBadge?: boolean
    initPresenceByList?: boolean
    wrapperWidth?: string
    wrapperMarginTop?: string
    wrapperMarginLeft?: string
    iconSpeakerWidth?: string
    iconSpeakerHeight?: string
    iconStaffWidth?: string
    iconStaffHeight?: string
    indicatorTooltipPlacement?: Placement
    containerWidth?: string
}

export const AvatarWithPresenceState: React.FC<AvatarWithPresenceStateProps> = (props) => {
    const userId = props.userId

    if (!userId) return null

    const pictureUrlContent = props.content as PictureUrl

    const content: JSX.Element | null =
        pictureUrlContent.pictureUrl || pictureUrlContent.alt ? (
            <AvatarWithDefault
                size={props.avatarSize - 6}
                alt={pictureUrlContent.alt}
                src={pictureUrlContent.pictureUrl}
                badgesize={props.badgeSize !== null ? props.badgeSize : undefined}
                badgeTop={props.badgeTop !== null ? props.badgeTop : undefined}
                badgeRight={props.badgeRight !== null ? props.badgeRight : undefined}
                badgeLeft={props.badgeLeft !== null ? props.badgeLeft : undefined}
                backgroundSize={"cover"}
            />
        ) : React.isValidElement(props.content) ? (
            props.content
        ) : (
            <AvatarDefaultContent />
        )

    return (
        <AvatarContainer className="avt-cont" containerWidth={props.containerWidth}>
            <BadgeWrapperWithStatus bsize={props.avatarSize}>
                <BadgeWrapperWithIcon
                    wrapperWidth={props.wrapperWidth}
                    wrapperMarginTop={props.wrapperMarginTop}
                    wrapperMarginLeft={props.wrapperMarginLeft}
                >
                    {(props.userType === "speaker" || props.userType === "staff") && (
                        <div>
                            {props.userType === "speaker" && (
                                <CustomOverlayTrigger
                                    placement={props.indicatorTooltipPlacement ? props.indicatorTooltipPlacement : "left"}
                                    delay={{ show: 100, hide: 100 }}
                                    overlay={
                                        <Tooltip
                                            id="speakerIndicator-tooltip"
                                            style={{ pointerEvents: "none", fontFamily: branding.font1 }}
                                        >
                                            {branding.avatarBranding.speakerIndicatorTooltipText}
                                        </Tooltip>
                                    }
                                >
                                    <IconWrapper>
                                        {IconSpeakerIndicator({
                                            width: props.iconSpeakerWidth,
                                            height: props.iconSpeakerHeight
                                        })}
                                    </IconWrapper>
                                </CustomOverlayTrigger>
                            )}
                            {props.userType === "staff" && (
                                <CustomOverlayTrigger
                                    placement={props.indicatorTooltipPlacement ? props.indicatorTooltipPlacement : "left"}
                                    delay={{ show: 100, hide: 100 }}
                                    overlay={
                                        <Tooltip
                                            id="staffIndicator-tooltip"
                                            style={{ pointerEvents: "none", fontFamily: branding.font1 }}
                                        >
                                            {branding.avatarBranding.staffIndicatorTooltipText}
                                        </Tooltip>
                                    }
                                >
                                    <IconWrapper>
                                        {IconStaffIndicator({
                                            width: props.iconStaffWidth,
                                            height: props.iconStaffHeight
                                        })}
                                    </IconWrapper>
                                </CustomOverlayTrigger>
                            )}
                        </div>
                    )}
                </BadgeWrapperWithIcon>
                {content}
                {props.showAvatarBadge && props.badgeSize !== null && (
                    <PresenceIndicator userId={userId} size={props.badgeSize} top={props.badgeTop} right={props.badgeRight} />
                )}
            </BadgeWrapperWithStatus>
        </AvatarContainer>
    )
}

export default AvatarWithPresenceState
