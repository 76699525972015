import React from "react"
import { ConversationType } from "../../../API"
import { CalendarEntry } from "../../../backendServices/GraphQLServices"
import { Contact, InvitePerson } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { ChatConversationParam } from "../../../communicationArea/ChatPage"
import { useAppState } from "../../../globalStates/AppState"
import { IconChat } from "../../Icons"
import {
    ChatWithParticipant,
    ParticipantText,
    SearchBarPickerWrapper,
    StyledSearchPersonInput
} from "./CalendarEntryModal.styled"
import { CalendarEntryModalViewMode } from "./ModalFunctions"
import { CalendarEntryParticipationStatus } from "../../../API"

interface SearchBarPickerProps {
    viewMode?: CalendarEntryModalViewMode
    calendarEntryModal: boolean
    personClicked?: (person: Contact) => void
    excludeIds?: string[]
    contactsInvited?: InvitePerson[]
    calendarEntry?: CalendarEntry
    closeModal?: () => void
    userStatus?: CalendarEntryParticipationStatus
}

const SearchBarPicker = (props: SearchBarPickerProps) => {
    const appState = useAppState()
    function chatWithParticipants() {
        onChatWithParticipants()
    }

    function onChatWithParticipants() {
        appState.setChatsSubtab(
            ChatConversationParam.conversationByConversationId(ConversationType.CALENDARENTRY, props.calendarEntry!.id)
        )
        props.closeModal && props.closeModal()
    }

    return (
        <SearchBarPickerWrapper>
            {props.viewMode === CalendarEntryModalViewMode.VIEW ? (
                props.userStatus === CalendarEntryParticipationStatus.ACCEPTED && (
                    <ChatWithParticipant onClick={chatWithParticipants}>
                        <IconChat width="14px" height="14px" fill={branding.sideIconBar.sideIconColorDark} />
                        <ParticipantText>{branding.calendarEntryListView.chatButtonTitle}</ParticipantText>
                    </ChatWithParticipant>
                )
            ) : (
                <StyledSearchPersonInput
                    calendarEntryModal={true}
                    // start={dateWithUTCIncrasedByTimezone(selectedTimezone, start!, day!)}
                    // end={dateWithUTCIncrasedByTimezone(selectedTimezone, end!, day!)}
                    placeholder={branding.calendarEntryModalPageContent.searchPersonPlaceholder}
                    personClicked={props.personClicked}
                    excludeIds={props.contactsInvited?.map((p) => p.id)}
                    topClear="7px"
                    topSearch="0px"
                />
            )}
        </SearchBarPickerWrapper>
    )
}

export default SearchBarPicker
