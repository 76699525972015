import { debounce } from "lodash"
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react"
import { GroupTypeBase } from "react-select"
import { loadSuggestions } from "../../../backendServices/SuggestionServices"
import branding from "../../../branding/branding"
import { Input, InputIcons, LocationWrapper, StyledCrossIcon, TimezoneDropdownList } from "./CalendarEntryModal.styled"
import LocationCard from "./LocationCard"
import {
    CalendarEntryModalViewMode,
    clearLocationSearchInput,
    determineInitialLocationMode,
    Location,
    LocationActionType,
    LocationOption,
    LocationPickerMode,
    onInputTextChanged,
    shouldUseHallName,
    showToggleSwitcherUnderLocationInput
} from "./ModalFunctions"
import { IconClose } from "../../Icons"
import TextLimit from "../../TextLimit"
import { CalendarEntry } from "../../../backendServices/GraphQLServices"

interface LocationPickerProps {
    placeholder: string
    customPlaceholder?: string
    selected?: string
    location?: Location
    setLocation?: Dispatch<SetStateAction<Location | undefined>>
    setTitle?: Dispatch<SetStateAction<string>>
    calendarEntry?: CalendarEntry
    showToggleSwitcher?: boolean
    setIsVirtualMeeting?: Dispatch<SetStateAction<boolean>> | undefined
    isVirtualMeeting?: boolean
    viewMode?: CalendarEntryModalViewMode
    canEditMeeting?: boolean
    currentLocation?: string
    setCurrentLocation?: Dispatch<SetStateAction<string>>
}
const MAX_NOTES_LENGTH = 100

const LocationPicker = (props: LocationPickerProps) => {
    const [optionGroups, setOptionGroups] = useState<Array<GroupTypeBase<LocationOption>>>([])
    const [openLocationDropdownList, setOpenLocationDropdownList] = useState<boolean>(false)
    const searchConfig = branding.calendarEntryModalPageContent.locationPickerSearchConfig
    const [showCrossIcon, setShowCrossIcon] = useState<boolean>(false)
    const locationRef = useRef(null)

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            e.preventDefault()
        }
    }

    const debouncedSearch = React.useMemo(
        () =>
            debounce((searchStr: string) => {
                if (searchStr.length < 1 || searchStr.includes("|")) {
                    // setLocationOptionsLoaded(true)
                    setOptionGroups([])
                    return
                }

                let tempOptionGroup: Array<GroupTypeBase<LocationOption>> = []
                // setLocationOptionsLoaded(false)
                loadSuggestions(searchStr, [searchConfig]).then((response) => {
                    if (response.suggestGroups.length === 0) return
                    response.suggestGroups[0].suggestions.forEach((suggestion) => {
                        const data = suggestion.additionalData as LocationOption
                        if (shouldUseHallName(data)) {
                            data.label = data.organizationName + " " + data.hallName + " | " + data.standName
                            data.value =
                                data.organizationName + "_" + data.hallName + "_" + data.standAlias + "_" + data.standName
                        } else {
                            data.label = data.organizationName + " " + data.standName
                            data.value = data.organizationName + "_" + data.standAlias + "_" + data.standName
                        }
                        const optionArray: LocationOption[] = [data]

                        let previousGroup: GroupTypeBase<LocationOption> | undefined = tempOptionGroup.find(
                            (optionGroup: GroupTypeBase<LocationOption>) => optionGroup.label === data.organizationName
                        )
                        if (!previousGroup)
                            tempOptionGroup.push({
                                label: data.organizationName,
                                options: optionArray
                            })
                        else {
                            previousGroup.options = [...previousGroup.options, ...optionArray]
                        }
                    })
                    setOptionGroups(tempOptionGroup)
                })
            }, 100),
        [searchConfig]
    )

    const handleSearchDebounced = useCallback(
        (value: string) => {
            debouncedSearch(value)
        },
        [debouncedSearch]
    )

    useEffect(() => {
        if (props.currentLocation && props.currentLocation.length > 0) {
            setOpenLocationDropdownList(true)
            props.setLocation!({ ...props.location, locationName: props.currentLocation })
            setShowCrossIcon(true)
        } else {
            setOpenLocationDropdownList(false)
            setShowCrossIcon(false)
            props.setLocation!({ ...props.location, locationName: "", locationActionType: LocationActionType.NONE, standId: "" })
        }
        if (
            (props.currentLocation && props.currentLocation.length < 1) ||
            (props.currentLocation && props.currentLocation.includes("|"))
        ) {
            // setLocationOptionsLoaded(true)
            setOptionGroups([])
        }
        handleSearchDebounced(props.currentLocation!)
        //eslint-disable-next-line
    }, [props.currentLocation])

    useEffect(() => {
        if (props.calendarEntry) {
            // load existing location
            let tmpLocation: Location = {
                locationActionType:
                    determineInitialLocationMode(props.calendarEntry) === LocationPickerMode.STAND
                        ? LocationActionType.STANDLINK
                        : LocationActionType.CUSTOM
            }
            if (props.calendarEntry.locationName) {
                props.setCurrentLocation!(props.calendarEntry.locationName)
                tmpLocation.locationName = props.calendarEntry.locationName
            }
            if (props.calendarEntry.locationActionParam) tmpLocation.standId = props.calendarEntry.locationActionParam
            props.setLocation!(tmpLocation)
        }
        //eslint-disable-next-line
    }, [])

    return (
        <LocationWrapper>
            <InputIcons>
                <Input
                    ref={locationRef}
                    readOnly={props.viewMode === CalendarEntryModalViewMode.VIEW ? true : false}
                    placeholder={props.selected === LocationPickerMode.CUSTOM ? props.placeholder : props.customPlaceholder}
                    value={props.currentLocation}
                    onChange={(e) =>
                        props.viewMode === CalendarEntryModalViewMode.VIEW
                            ? undefined
                            : onInputTextChanged(e, MAX_NOTES_LENGTH, props.setCurrentLocation!)
                    }
                    onKeyDown={handleKeyDown}
                />
                <TextLimit
                    position="absolute"
                    display="none"
                    bottom="7px"
                    right="5px"
                    textLength={props.currentLocation?.length || 0}
                    maxTextLength={MAX_NOTES_LENGTH}
                />
                {showCrossIcon && props.viewMode !== CalendarEntryModalViewMode.VIEW && (
                    <StyledCrossIcon className="cross-icon" onClick={() => clearLocationSearchInput(props.setCurrentLocation)}>
                        <IconClose width="10px" height="10px" />
                    </StyledCrossIcon>
                )}
            </InputIcons>
            {openLocationDropdownList &&
                optionGroups.length > 0 &&
                props.selected === LocationPickerMode.STAND &&
                props.viewMode !== CalendarEntryModalViewMode.VIEW && (
                    <TimezoneDropdownList>
                        {optionGroups?.map((option, index) => {
                            return (
                                <LocationCard
                                    key={index}
                                    label={option.label}
                                    options={option.options as unknown as LocationOption[]}
                                    setCurrentValue={props.setCurrentLocation!}
                                    setOpenLocationDropdownList={setOpenLocationDropdownList}
                                    openLocationDropdownList={openLocationDropdownList}
                                />
                            )
                        })}
                    </TimezoneDropdownList>
                )}
            {showToggleSwitcherUnderLocationInput(props.viewMode, props.isVirtualMeeting, props.setIsVirtualMeeting)}
        </LocationWrapper>
    )
}

export default LocationPicker
