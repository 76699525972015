import { API } from "aws-amplify"
import { useEffect, useState } from "react"
import { getRaisedHandsByChimeMeetingId, buildGraphqlArgs } from "../../backendServices/GraphQLServices"
import { defaultLogger } from "../../globalStates/AppState"
import { onRaisedHandCreated, onRaisedHandDeleted } from "../../graphql/subscriptions"
import { useMeetingController } from "../context/MeetingController"

export const useRaisedHands = () => {
    const [raisedHands, setRaisedHands] = useState<string[]>([])
    const externalMeetingId = useMeetingController().getExternalMeetingId()

    useEffect(() => {
        /** Load raised hands when initialy entering a conference room */
        getRaisedHandsByChimeMeetingId(externalMeetingId as string).then((data: any) => {
            setRaisedHands(
                data.data.listRaisedHands.items.map((item: any) => {
                    return item.id
                })
            )
        })
        /** Raised hand subscriptions and unsubscriptions */
        let createRaisedHandSubscription = (
            API.graphql(buildGraphqlArgs(onRaisedHandCreated, { chimeMeetingId: externalMeetingId })) as any
        ).subscribe({
            next: (next: any) => {
                getRaisedHandsByChimeMeetingId(externalMeetingId as string).then((data: any) => {
                    setRaisedHands(
                        data.data.listRaisedHands.items.map((item: any) => {
                            return item.id
                        })
                    )
                })
            },
            error: (error: any) => {
                defaultLogger.error({
                    message: "Subscription Error: onRaisedHandCreated",
                    errorMessage: error.message,
                    errorStack: error.stack
                })
            },
            complete: () => {
                defaultLogger.info("Subscription Complete: onRaisedHandCreated")
            }
        })

        let deleteRaisedHandSubscription = (
            API.graphql(buildGraphqlArgs(onRaisedHandDeleted, { chimeMeetingId: externalMeetingId })) as any
        ).subscribe({
            next: (next: any) => {
                getRaisedHandsByChimeMeetingId(externalMeetingId as string).then((data: any) => {
                    setRaisedHands(
                        data.data.listRaisedHands.items.map((item: any) => {
                            return item.id
                        })
                    )
                })
            },
            error: (error: any) => {
                defaultLogger.error({
                    message: "Subscription Error: onRaisedHandDeleted",
                    errorMessage: error.message,
                    errorStack: error.stack
                })
            },
            complete: () => {
                defaultLogger.info("Subscription Complete: onRaisedHandDeleted")
            }
        })

        return () => {
            createRaisedHandSubscription.unsubscribe()
            deleteRaisedHandSubscription.unsubscribe()
        }
    }, [externalMeetingId])

    return raisedHands
}
