import styled from "styled-components"

export const RosterAttendeesClosedRoot = styled.div`
    height: 95vh;
    display: flex;
    flex-direction: column;
    padding: 15px 5px 15px 5px;
`
export const RosterAttendeesClosedGroup = styled.div<{ bottomDivider?: boolean }>`
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-bottom: ${(props) => (props.bottomDivider ? "1px solid #D9D9D9" : "none")};
    margin-bottom: ${(props) => (props.bottomDivider ? "10px " : null)};
    padding: 0 5px 15px 5px;

    &::-webkit-scrollbar {
        width: 5px; // manage scrollbar width here
    }
    &::-webkit-scrollbar * {
        background: transparent; // manage scrollbar background color here
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.1) !important; // manage scrollbar thumb background color here
    }
`
