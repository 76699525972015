import branding from "../../branding/branding"

// determines if a string is falsy or contains whitespaces only
export const isNullOrEmpty = (inputString: string | undefined): boolean => {
    return !inputString || /^ *$/.test(inputString)
}

// returns the title of a given user in the default format. example: "Software Developer at Corussoft GmbH". if a user is missing one of the two(company or position), returns only the one that is available,
// without the filler word, example: "Software Developer" or "Corussoft GmbH".
// if the given user is missing both, an empty string gets returned
export function getUserTitle(positionEn?: string, positionDe?: string, company?: string, organization?: string): string {
    const position = localStorage.getItem("virtualGuide-language") === "en" ? positionEn : positionDe ?? positionEn

    const companyFinal = company || organization || ``
    const positionFinal = position || ``
    const filler =
        !isNullOrEmpty(positionFinal) && !isNullOrEmpty(companyFinal) ? branding.communicationArea.personCompanyLink : ``

    return `${positionFinal} ${filler} ${companyFinal}`
}

// a version of getUserTitle that is used when searching users through search (when scheduling a meeting, etc.), couldn't be merged into a single function because it would require more parameters
// returns the title of a given user in the userSearch format. example: " | Software Developer / Corussoft"
export function getSearchPersonTitle(company: string, position: string): string {
    const companyFinal = !isNullOrEmpty(company) ? company : ``
    const positionFinal = !isNullOrEmpty(position) ? position : ``
    const divider = positionFinal && companyFinal ? "/" : ``

    return `${positionFinal} ${divider} ${companyFinal}`
}

/**
 * @param entityName name of the entity
 * @param length (optional: default is 1) number of initials which needs to be generated
 *
 * getEntityInitials("Sanjin Pajic", 2) => SP
 * getEntityInitials("My Little Product", 3) => MLP
 *
 * @returns first two intials of the person title string
 */
export function getEntityInitials(entityName: string, length: number = 1) {
    // if the entity is a person we need to return both initials
    return entityName
        .split(" ") // split the string into a string array eg. "Edin Dervishalidovic Dino" -> ["Edim", "Dervishalidovic", "Dino"]
        .slice(0, length) // if the length param is 2, take only the first two elements or said array -> ["Edin", "Dervishalidovic"]
        .map((word) => word.charAt(0).toUpperCase()) // after slicing the array, take the first letter of each  -> ["E", "D"]
        .join("") // join all of the arrays into a string with no seperator -> "ED"}
}
