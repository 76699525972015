import { useEffect } from "react"
import { useRouteMatch } from "react-router-dom"
import { meetingPageRoute } from "../../RoutePaths"
import branding from "../../../branding/branding"
import { BottomMenuContainer } from "./BottomMenu.styled"
import { Col, Row } from "react-bootstrap"
import LanguageToggler from "../../../ui/LanguageToggle"
import BottomMenuItem from "./bottomMenuItem/BottomMenuItem"
import { trackSelectContent } from "../../../utils/GTMTracking"

interface BottomMenuProps {
    isNavOpen: boolean
    foregroundColor: string
    contentAreaScrollable: boolean
    hideOnScrollEnd: boolean
}

const BottomMenu: React.FunctionComponent<BottomMenuProps> = (props: BottomMenuProps) => {
    let isMeetingPage = useRouteMatch(meetingPageRoute)

    useEffect(() => {
        var closeBtn = document.getElementById("close-pc-btn-handler")
        closeBtn?.addEventListener("click", () => {
            var darkOverlay = document.querySelector(".onetrust-pc-dark-filter")
            if (darkOverlay) (darkOverlay as HTMLElement).style.display = "none"
        })
    }, [document.getElementById("onetrust-pc-sdk")]) // eslint-disable-line

    return (
        <div style={{ backgroundColor: isMeetingPage ? branding.mainInfoColor : "#fff", width: "345px" }}>
            <BottomMenuContainer
                showShadow={props.contentAreaScrollable && !props.hideOnScrollEnd}
                style={{
                    display: props.isNavOpen ? "flex" : "none",
                    fontFamily: branding.font1,
                    background: isMeetingPage ? branding.mainInfoColor : "#fff",
                    width: "350px",
                    zIndex: 100
                }}
            >
                <div
                    style={{
                        position: "relative",
                        fontSize: "12px",
                        lineHeight: "18px",
                        marginLeft: "10px",
                        paddingBottom: "5px"
                    }}
                >
                    <Row>
                        <Col xs={24}>
                            <LanguageToggler
                                fullToggler={true}
                                color={isMeetingPage ? props.foregroundColor : branding.mainInfoColor}
                                fontSize="inherit"
                            />
                        </Col>
                    </Row>
                    <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px", marginLeft: "1px" }}>
                        {branding.sideIconBar.sideIconBarFooterItemsOrder.map((item, index) => {
                            switch (item) {
                                case "HELP": {
                                    return (
                                        <BottomMenuItem
                                            key={index}
                                            index={index}
                                            isExternalLink={branding.sideIconBar.helpItem.isExternalLink}
                                            link={branding.sideIconBar.helpItem.link}
                                            title={branding.sideIconBar.helpItem.title}
                                            onClick={() =>
                                                trackSelectContent(branding.sideIconBar.helpItem.title, "Details", "Help")
                                            }
                                        />
                                    )
                                }
                                case "RULES": {
                                    return (
                                        <BottomMenuItem
                                            key={index}
                                            index={index}
                                            isExternalLink={branding.sideIconBar.rulesItem.isExternalLink}
                                            link={branding.sideIconBar.rulesItem.link}
                                            title={branding.sideIconBar.rulesItem.title}
                                            onClick={() =>
                                                trackSelectContent(branding.sideIconBar.rulesItem.title, "Details", "Rules")
                                            }
                                        />
                                    )
                                }
                                case "TERMS_OF_USE": {
                                    return (
                                        <BottomMenuItem
                                            key={index}
                                            index={index}
                                            isExternalLink={branding.sideIconBar.termsOfUseItem.isExternalLink}
                                            link={branding.sideIconBar.termsOfUseItem.link}
                                            title={branding.sideIconBar.termsOfUseItem.title}
                                            onClick={() =>
                                                trackSelectContent(
                                                    branding.sideIconBar.termsOfUseItem.title,
                                                    "Details",
                                                    "TermsOfUse"
                                                )
                                            }
                                        />
                                    )
                                }
                                case "DATA_PRIVACY_POLICY": {
                                    return (
                                        <BottomMenuItem
                                            key={index}
                                            index={index}
                                            isExternalLink={branding.sideIconBar.dataPrivacyPolicyItem.isExternalLink}
                                            link={branding.sideIconBar.dataPrivacyPolicyItem.link}
                                            title={branding.sideIconBar.dataPrivacyPolicyItem.title}
                                            onClick={() =>
                                                trackSelectContent(
                                                    branding.sideIconBar.dataPrivacyPolicyItem.title,
                                                    "Details",
                                                    "PrivacyPolicy"
                                                )
                                            }
                                        />
                                    )
                                }
                                case "IMPRINT": {
                                    return (
                                        <BottomMenuItem
                                            key={index}
                                            index={index}
                                            isExternalLink={branding.sideIconBar.imprintItem.isExternalLink}
                                            link={branding.sideIconBar.imprintItem.link}
                                            title={branding.sideIconBar.imprintItem.title}
                                            onClick={() =>
                                                trackSelectContent(branding.sideIconBar.imprintItem.title, "Details", "Imprint")
                                            }
                                        />
                                    )
                                }
                                case "COOKIE_SETTINGS": {
                                    return (
                                        branding.cookieOneTrustScript &&
                                        branding.cookieOneTrustScript !== "" && (
                                            <Row key={index}>
                                                <Col xs={12}>
                                                    <div
                                                        id="ot-sdk-btn"
                                                        className="ot-sdk-show-settings"
                                                        onClick={() =>
                                                            trackSelectContent(
                                                                branding.sideIconBar.cookieSettingsTitle,
                                                                "Details",
                                                                "CookieSettings"
                                                            )
                                                        }
                                                    >
                                                        {branding.sideIconBar.cookieSettingsTitle}
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    )
                                }
                                default:
                                    return null
                            }
                        })}
                    </div>
                </div>
            </BottomMenuContainer>
            {!props.isNavOpen && (
                <BottomMenuContainer
                    showShadow={props.contentAreaScrollable && !props.hideOnScrollEnd}
                    style={{ width: "80px", height: "60px" }}
                >
                    <div
                        style={{
                            background: isMeetingPage ? branding.mainInfoColor : "#fff",
                            position: "relative",
                            fontSize: "15px",
                            lineHeight: "20px",
                            marginTop: "75px",
                            alignItems: "center",
                            width: "60px",
                            bottom: 0,
                            paddingBottom: "10px"
                        }}
                    >
                        <LanguageToggler
                            color={isMeetingPage ? props.foregroundColor : branding.mainInfoColor}
                            fontSize="inherit"
                            margin="0 1.2rem 5px 1.2rem"
                        />
                    </div>
                </BottomMenuContainer>
            )}
        </div>
    )
}

export default BottomMenu
