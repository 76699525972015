import {
    ActionType,
    MeetingStatus,
    Severity,
    useMeetingManager,
    useNotificationDispatch,
    useRosterState
} from "amazon-chime-sdk-component-library-react"
import { DataMessage } from "amazon-chime-sdk-js"
import { useEffect, useState } from "react"
import { AttendeeData } from "../../../backendServices/MeetingServices"
import branding from "../../../branding/branding"
import { useMeetingController } from "../../context/MeetingController"
import { DataMessageType } from "../../enums/DataMessageType"
import { EGMeetingStatus } from "../../enums/EGMeetingStatus"
import useCallCountdownTimer from "../../hooks/useCallCountdownTimer"
import useCallDurationTimer from "../../hooks/useCallDurationTimer"
import { TimerOutput, TimerRoot } from "./Timer.styled"
import { loadEventDateDetails } from "../../../backendServices/EventdateServices"
import moment from "moment"
import { useHistory } from "react-router-dom"
import { EventDate } from "../../../backendServices/Types"

/// TODO: This should be refactored. Probably once once we implement all meeting kinds.
/// My idea is to implement a TimerContext which will handle all displaying of the correct timer
/// This will also clean up this component and reduce the useEffects

function Timer() {
    const meetingManager = useMeetingManager()
    const meetingController = useMeetingController()
    const callDurationTimer = useCallDurationTimer(meetingController.getCallDuration() || 0)
    const callCountdownTimer = useCallCountdownTimer()
    const dispatch = useNotificationDispatch()
    const [isCountdownNotificationVisible, setIsNotificationVisible] = useState(false)
    const { roster } = useRosterState()
    const [attendees, setAttendees] = useState(Object.values(roster))
    const [moderatorsCount, setModeratorsCount] = useState(0)
    const [countdownTime, setCountDownTime] = useState<number | null>(null)
    const [showTimer, setShowTimer] = useState(false)
    const history = useHistory()
    const [eventDate, setEventDate] = useState<EventDate | null>(null)

    function leaveRoundTable(eventDate: EventDate | null) {
        if (meetingController.getMeetingKind() !== "roundtable") return
        meetingController.leaveMeeting(true)
        if (!eventDate) return
        const meetingId = meetingController.getExternalMeetingId()?.split("rt_")[1]
        // wait one second to allow the meetingController to leave the room, so the ConferenceOverlay does not show up briefly after redirecting
        setTimeout(() => {
            history.push("/eventdate/" + eventDate.name + "--" + meetingId)
        }, 1000)
    }

    useEffect(() => {
        let timerVisibleTimeout: NodeJS.Timeout
        let activateEndTimerTimeout: NodeJS.Timeout
        if (meetingController.getCallDuration()) {
            setShowTimer(true)
        } else {
            // Show timer after 2 secs, because the calculations are wrong for a moment.
            // And the user can get confused.
            timerVisibleTimeout = setTimeout(() => {
                setShowTimer(true)
            }, 2000)
        }

        if (meetingController.getMeetingKind() === "roundtable") {
            const meetingId = meetingController.getExternalMeetingId()?.split("rt_")[1]
            const params = { id: meetingId }
            loadEventDateDetails(params).then((response) => {
                const eventDate = response.content
                setEventDate(eventDate)
                const secondsToEnd = moment(eventDate.enddatetime).diff(
                    moment(moment(new Date()).format("yyyy-MM-DD HH:mm:ss")),
                    "seconds"
                )
                const closeRoomAfterMilliseconds =
                    secondsToEnd * 1000 + branding.configuration.roundTableOpenAfterMinutes * 60 * 1000
                if (closeRoomAfterMilliseconds < 0) {
                    // roundtable is over
                    leaveRoundTable(eventDate)
                    return
                }
                if (secondsToEnd > 0) {
                    // roundtable is live
                    activateEndTimerTimeout = setTimeout(() => {
                        if (branding.configuration.roundTableOpenAfterMinutes === 0) leaveRoundTable(eventDate)
                        else {
                            setShowTimer(true)
                            setCountDownTime(branding.configuration.roundTableOpenAfterMinutes * 60000)
                        }
                    }, secondsToEnd * 1000)
                } else {
                    if (closeRoomAfterMilliseconds <= 0) return
                    // roundtable is over, but less then roundTableOpenAfterMinutes minutes have passed
                    setShowTimer(true)
                    setCountDownTime(closeRoomAfterMilliseconds)
                }
            })
        }

        return () => {
            clearTimeout(timerVisibleTimeout)
            clearTimeout(activateEndTimerTimeout)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // Set timeout, because we wait for the response from our backend to fill the attendee with our data.
        const backendDataTimeout = setTimeout(() => {
            setAttendees(Object.values(roster))
        }, 1000)

        return () => {
            clearTimeout(backendDataTimeout)
        }
    }, [roster])

    useEffect(() => {
        setModeratorsCount(attendees.filter((attendee: AttendeeData) => attendee.role === "moderator").length)
    }, [attendees])

    useEffect(() => {
        // Here we start the countdown timer if needed
        // 1. case is in the breakout room if there is no moderators
        // 2. case is in the call and that data we receive from the backend
        // 3. case is when the moderator leaves a conference room

        if (meetingController.getMeetingKind() === "breakout") {
            if (moderatorsCount < 1) {
                setCountDownTime(branding.breakoutTimeoutDuration)
            } else {
                setCountDownTime(null)
            }
        } else if (meetingController.getMeetingKind() === "call") {
            setCountDownTime(meetingController.getMeetingTimeLeft() || null)
        } else if (meetingController.getMeetingKind() === "conferenceroom") {
            if (moderatorsCount < 1) {
                setCountDownTime(branding.configuration.conferenceRoomNoModRemainingDuration * 100) // seconds -> milliseconds
            } else {
                setCountDownTime(null)
            }
        } else if (meetingController.getMeetingKind() === "calenderEntry") {
            if (moderatorsCount < 1) {
                setCountDownTime(meetingController.getMeetingTimeLeft())
            } else {
                setCountDownTime(null)
            }
        }

        // eslint-disable-next-line
    }, [meetingController.getMeetingTimeLeft(), attendees, meetingController.getMeetingKind(), moderatorsCount])

    useEffect(() => {
        // If the countdown time is set, start the countdown timer, else stop it and reset
        // We can have usecase when two participants talk with each other and the countdown is counting down,
        // but once a moderator enters a room, the countdown timer should stop/reset and switch to the callDurationTimer
        // also the callDuration timer, always starts to count once the meeting starts, but depending on the case
        // we show the differnet timers
        // also in the future we could show the user the duration of the call at the end of a call
        if (countdownTime) {
            callCountdownTimer.start(countdownTime)
        } else {
            callCountdownTimer.stop()
            callCountdownTimer.reset()
        }
        // eslint-disable-next-line
    }, [countdownTime])

    useEffect(() => {
        // The last 10 seconds we dispatch a notification to the user that te meeting will end soon
        if (callCountdownTimer.timeLeft && callCountdownTimer.timeLeft <= 10000 && !isCountdownNotificationVisible) {
            dispatch({
                type: ActionType.ADD,
                payload: {
                    severity: Severity.WARNING,
                    message: branding.conferenceTexts.callEndSoon,
                    autoCloseDelay: 10000
                }
            })

            setIsNotificationVisible(true)
        }

        let leaveMeetingTimeout: any
        if (callCountdownTimer.formattedDuration === "00:00") {
            if (meetingController.getMeetingKind() === "roundtable") leaveRoundTable(eventDate)
            else {
                meetingController.setEGMeetingStatus(EGMeetingStatus.TimeUp)
                leaveMeetingTimeout = setTimeout(() => {
                    meetingController.leaveMeeting()
                }, 1000)
            }
        }

        return () => {
            clearTimeout(leaveMeetingTimeout)
        }

        // eslint-disable-next-line
    }, [callCountdownTimer.timeLeft, moderatorsCount])

    useEffect(() => {
        // Should I replace this, when I implement the PiP?
        // This starts the the call duration timer, and is always running in the background
        // But the condition to be displayed is callCountdownTimer.timeLeft is 0
        // meaning that this call has no limit in duration
        if (meetingManager.meetingStatus === MeetingStatus.Succeeded) {
            callDurationTimer.start()
        }

        if (meetingManager.meetingStatus === MeetingStatus.Left || meetingManager.meetingStatus === MeetingStatus.Ended) {
            callDurationTimer.stop()
        }
        return () => {
            callDurationTimer.stop()
        }
        // eslint-disable-next-line
    }, [meetingManager.meetingStatus])

    useEffect(() => {
        // Subscription for TIMELIMITCHANGED
        // This resets the timer when a moderator enters a room
        // for example when the user invites a moderator to the meeting
        meetingManager.audioVideo?.realtimeSubscribeToReceiveDataMessage(
            meetingController.getExternalMeetingId()?.slice(0, 10) || "",
            (dataMessage: DataMessage) => {
                const messageData = dataMessage.json()
                const messageDataType: DataMessageType = messageData.type
                if (!messageDataType) return

                switch (messageDataType) {
                    case DataMessageType.TIMELIMITCHANGED:
                        callCountdownTimer.stop()
                        callCountdownTimer.reset()
                        break
                }
            }
        )
        // eslint-disable-next-line
    }, [meetingManager.audioVideo])

    useEffect(() => {
        return () => {
            meetingController.setCallDuration(callDurationTimer.duration)
        }
        // eslint-disable-next-line
    }, [callDurationTimer.duration])

    return (
        <>
            {showTimer ? (
                <TimerRoot>
                    {callCountdownTimer.timeLeft === null && <TimerOutput>{callDurationTimer.formattedDuration()}</TimerOutput>}

                    {callCountdownTimer.timeLeft !== null && (
                        <TimerOutput>
                            {`This room will close in `} {callCountdownTimer.formattedDuration}
                        </TimerOutput>
                    )}
                </TimerRoot>
            ) : (
                <TimerRoot>
                    {/* We show the empty timer root for 2 secs, when entering a
                    room. Wee need to wait for all the calculations and depending on
                     that show the callDurationTimer or callCountdowntimer */}
                </TimerRoot>
            )}
        </>
    )
}

export default Timer
