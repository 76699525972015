import { useHistory } from "react-router-dom"
import { MenuToolbarLayoutRoot, SideBarLogo, SideBarLogo2, SidebarToggleIcon } from "./MenuToolbarLayout.styled"
import branding from "../../../branding/branding"
import { homePageRoute } from "../../RoutePaths"
import { CustomOverlayTrigger } from "../../../ui/CustomOverlayTrigger"
import { Tooltip } from "react-bootstrap"
import { IconMenu } from "../../../ui/Icons"

interface MenuToolbarLayoutProps {
    isNavOpen: boolean
    isMeetingPage: any
    logo: any
    handleDrawerOpen: () => void
    handleDrawerClose: () => void
    contentAreaScrollable?: boolean
    hideOnScrollEnd?: boolean
}

const MenuToolbarLayout: React.FunctionComponent<MenuToolbarLayoutProps> = (props: MenuToolbarLayoutProps) => {
    const history = useHistory()
    return (
        <>
            <MenuToolbarLayoutRoot
                showShadow={props.isNavOpen}
                style={{
                    zIndex: 100,
                    position: "relative",
                    backgroundColor: window.location.href.includes("/meeting/") ? "transparent" : "#fff"
                }}
            >
                {props.isNavOpen ? (
                    <>
                        {branding.sideIconBar.hasSecondLogoIcon ? (
                            <SideBarLogo2
                                src={props.logo}
                                onClick={(e: any) => {
                                    history.push(homePageRoute)
                                }}
                            />
                        ) : (
                            <SideBarLogo
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    props.handleDrawerClose()
                                    history.push(homePageRoute)
                                }}
                                src={props.logo}
                                style={{ margin: 0, marginLeft: 2 }}
                            />
                        )}
                    </>
                ) : (
                    <SideBarLogo
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            props.handleDrawerClose()
                            history.push(homePageRoute)
                        }}
                        src={props.logo}
                        style={{ margin: 0, marginLeft: 2 }}
                    />
                )}
                <div style={{ visibility: props.isNavOpen ? "visible" : "hidden" }} onClick={() => props.handleDrawerClose()}>
                    <CustomOverlayTrigger
                        placement="right"
                        trigger={["focus", "click", "hover"]}
                        delay={{ show: 0, hide: 0 }}
                        overlay={
                            <Tooltip id="menu-tooltip-open" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                                {branding.sideIconBar.menuTooltipTextnavBarOpen}
                            </Tooltip>
                        }
                    >
                        <SidebarToggleIcon>
                            {IconMenu({
                                fill: props.isMeetingPage
                                    ? branding.sideIconBar.sideIconColorLight ?? "#fff"
                                    : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                            })}
                        </SidebarToggleIcon>
                    </CustomOverlayTrigger>
                </div>
            </MenuToolbarLayoutRoot>
            {!props.isNavOpen && (
                <MenuToolbarLayoutRoot
                    style={{ marginTop: "-1px" }}
                    showShadow={props.contentAreaScrollable && !props.hideOnScrollEnd}
                    hideTopShadow={true}
                    isNavOpen={props.isNavOpen}
                >
                    <CustomOverlayTrigger
                        placement="right"
                        trigger={["focus", "click", "hover"]}
                        delay={{ show: 0, hide: 0 }}
                        overlay={
                            <Tooltip id="menu-tooltip-closed" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                                {branding.sideIconBar.menuTooltipTextnavBarClosed}
                            </Tooltip>
                        }
                    >
                        <SidebarToggleIcon onClick={props.handleDrawerOpen} style={{ margin: 0, marginLeft: "6px" }}>
                            {IconMenu({
                                fill: props.isMeetingPage
                                    ? branding.sideIconBar.sideIconColorLight ?? "#fff"
                                    : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                            })}
                        </SidebarToggleIcon>
                    </CustomOverlayTrigger>
                </MenuToolbarLayoutRoot>
            )}
        </>
    )
}

export default MenuToolbarLayout
