import { ListGroup } from "react-bootstrap"
import styled from "styled-components"
import { useAppState } from "../../globalStates/AppState"
import branding from "../../branding/branding"

const drawerWidth = 350

export const ListItemIconContainer = styled.div<{ selected?: boolean }>`
    margin-left: 7px;
    width: 25px;
    height: 25px;
    margin-top: 0px;
`

export const SponsorLogo = styled.div<{ pictureUrl?: string }>`
    height: 40px;
    width: 40px;

    ${(props) =>
        props.pictureUrl
            ? `background: url("${props.pictureUrl}");`
            : `background: url('/branding/applicationMedia/rsz_navbarlogo.png');`}
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
`
export const ListItem = styled(ListGroup.Item)`
    display: flex;
    border: none;
    position: relative;
    min-width: 350px;
    max-width: 350px;
    max-height: 70px;
    z-index: 100;
    background-color: transparent;
    /* padding: .75rem 1.7rem !important; */

    &.drawerButtonSelected {
        //background-color: rgba(0, 0, 0, 0.1);
        background-color: ${() =>
            useAppState().isNavBarOpen === false
                ? branding.sideIconBar.drawerClosedButtonSelectBackgroundColor
                : branding.sideIconBar.drawerButtonSelectBackgroundColor};
    }

    &.hovered:hover {
        /* background-color: rgba(0, 0, 0, .04); */
    }
`

export const ListItemTextContainer = styled.div`
    margin-left: 20px;
`

export const ListItemText = styled.div`
    font-size: ${branding.sideIconBar.sideBarListItemFontSize ?? "16px"};
    line-height: 25px;
    max-width: 350px;
    white-space: pre-line;
    font-family: ${branding.font1};
    font-weight: ${branding.sideIconBar.listItemTextFontWeight ?? "normal"};
`
export const SponsorsListRoot = styled.div`
    margin-top: 30px;
    margin-left: -5px;

    @media (max-height: 900px) {
        margin-top: 2px;
    }
`

export const SideIconBarRoot = styled.div<{ backgroundColor: string; foregroundColor: string; isMeetingPage: any | null }>`
    position: fixed;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.foregroundColor};
    border-right: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    z-index: 9999;
    height: 100%;

    & a,
    & a:hover,
    & a:link,
    & a:visited {
        color: ${(props) => (props.isMeetingPage ? branding.sideIconBar.sideIconBarMeetingTextColor : branding.mainInfoColor)};
    }

    & .ScrollbarsCustom-Track {
        z-index: 105;
    }

    & .ScrollbarsCustom-Content {
        padding: 0 !important;
    }
`
export const StyledDrawer = styled.div<{ backgroundColor: string }>`
    width: ${drawerWidth}px;
    z-index: 99;
    background-color: ${(props) => props.backgroundColor};
    white-space: nowrap;
    transition: width 0.2s cubic-bezier(0.4, 0, 0.6, 1) 0ms;

    &.drawerOpen {
        width: ${drawerWidth}px;
    }

    &.drawerClose {
        overflow-x: hidden;
        width: 80px;
    }
`
