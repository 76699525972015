import { Link } from "react-router-dom"
import { SpecialSponsorLogoContainer, SpecialSponsorLogoDivRoot, SpecialSponsorLogoRoot } from "./SpecialSponsorLogo.styled"
import branding from "../../../branding/branding"
import { ListItem, SponsorsListRoot } from "../SideIconBar.styled"

interface SpecialSponsorLogoProps {
    isNavOpen: boolean
}

const SpecialSponsorLogo: React.FunctionComponent<SpecialSponsorLogoProps> = (props: SpecialSponsorLogoProps) => {
    return (
        <>
            {props.isNavOpen && (
                <SpecialSponsorLogoDivRoot>
                    <Link to={branding.sideIconBar.specialSponsorLogoLink} onClick={(e: any) => e.stopPropagation()}>
                        <SpecialSponsorLogoContainer style={{ width: "200px", height: "200px", marginLeft: "75px" }}>
                            <SpecialSponsorLogoRoot src={branding.sideIconBar.specialSponsorLogo} alt=" " />
                        </SpecialSponsorLogoContainer>
                    </Link>
                </SpecialSponsorLogoDivRoot>
            )}
            {!props.isNavOpen && (
                <SponsorsListRoot>
                    <ListItem key="text" style={{ marginBottom: "-25px" }}></ListItem>
                    <Link to={branding.sideIconBar.specialSponsorLogoLink} onClick={(e: any) => e.stopPropagation()}>
                        <SpecialSponsorLogoContainer>
                            <SpecialSponsorLogoRoot src={branding.sideIconBar.specialSponsorLogo} alt=" " />
                        </SpecialSponsorLogoContainer>
                    </Link>
                </SponsorsListRoot>
            )}
        </>
    )
}

export default SpecialSponsorLogo
