import { useRouteMatch } from "react-router-dom"
import { SponsorItemProps } from "../SideIconBar"
import { meetingPageRoute } from "../../RoutePaths"
import TrackingLink from "../../../tracking/NavigationSource"
import { trackSelectContent } from "../../../utils/GTMTracking"
import { buildDetailLink } from "../../../contentArea/detailPages/DetailNavLink"
import { ListItem, ListItemIconContainer, ListItemText, ListItemTextContainer, SponsorLogo } from "../SideIconBar.styled"
import branding from "../../../branding/branding"

const SponsorListItem: React.FunctionComponent<SponsorItemProps> = (props: SponsorItemProps) => {
    let isMeetingPage = useRouteMatch(meetingPageRoute)
    return (
        <TrackingLink
            doClick={(e: any) => {
                trackSelectContent(props.sponsor.name, "Top", "Sponsor")
                props.onClick(e)
            }}
            to={buildDetailLink(props.sponsor.id, props.sponsor.name, "organization")}
            style={{ textDecoration: "none" }}
            src="SIDEBAR"
        >
            <ListItem key="text">
                <ListItemIconContainer
                    style={{
                        background: isMeetingPage ? branding.sideIconBar.backgroundColorForTopSponsors : "#fff",
                        width: "auto",
                        height: "auto"
                    }}
                >
                    <SponsorLogo pictureUrl={props.sponsor.pictureURL} style={{ marginTop: "12px" }} />
                </ListItemIconContainer>
                <ListItemTextContainer style={{ marginTop: "20px" }}>
                    {props.isNavOpen && <ListItemText>{props.sponsor.name}</ListItemText>}
                </ListItemTextContainer>
            </ListItem>
        </TrackingLink>
    )
}

export default SponsorListItem
