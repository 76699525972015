import styled from "styled-components"
import { DetailNavLink } from "../contentArea/detailPages/DetailNavLink"

export const PinboardMessageRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    margin: 10px 0;
    align-self: flex-start;
    padding: 0 10px 0 20px;
`
export const AvatarWrapperPinboard = styled(DetailNavLink)`
    flex: 0 0 auto;
    width: 25px;
    height: 25px;
`
export const PinboardChatMessageHeader = styled.div`
    display: flex;
    flex-direction: row;
`
export const PinboardChatMessageAuthor = styled(DetailNavLink)`
    font-weight: bold;
    flex-direction: row;
    font-size: 12px;
`
export const PinboardChatMessageTime = styled.div`
    font-size: 12px;
    color: #b3b3b3;
    margin-left: 5px;
`
