import styled from "styled-components"
import branding from "../../../branding/branding"

export const MenuToolbarLayoutRoot = styled.div<{ showShadow?: boolean; hideTopShadow?: boolean; isNavOpen?: boolean }>`
    justify-content: space-between;
    display: flex;
    height: 50px;
    padding-top: 13px;
    min-height: 60px;
    padding-left: 24px;
    padding-right: 12px;
    box-shadow: ${(props) => (props.showShadow ? "0px -1px 6px 0px rgba(0, 0, 0, 0.16)" : "none")};
`
export const SideBarLogo = styled.img`
    width: ${branding.sideIconBar.sideBarLogoWidth ?? "30px"};
    height: ${branding.sideIconBar.sideBarLogoHeight ?? "30px"};
    min-width: 30px;
    margin-top: 10px;
    margin-left: 25px;
    object-fit: contain;
    cursor: pointer;
`
export const SideBarLogo2 = styled.img`
    min-width: 30px;
    max-width: ${branding.sideIconBar.maxWidthForSecondLogoIcon};
    margin-left: 2px;
    object-fit: contain;
    cursor: pointer;
`

export const SidebarToggleIcon = styled.div`
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 100;
    color: ${branding.mainInfoColor ?? "black"};
`
