import styled from "styled-components"
import branding from "./branding/branding"

export const Divider = styled.div`
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    margin-left: 15px;
`
export const FallbackInitialsParagraph = styled.p<{ fontSize?: string }>`
    font-family: ${branding.font1};
    font-size: ${(props) => props.fontSize ?? null};
`

export const FallbackInitialsSpan = styled.span<{ fontSize?: string }>`
    font-family: ${branding.font1};
    font-size: ${(props) => props.fontSize ?? null};
`

export const FallbackInitialsH2 = styled.h2<{ fontSize?: string; marginTop?: string }>`
    font-family: ${branding.font1};
    font-size: ${(props) => props.fontSize ?? null};
    ${(props) => props.marginTop && `margin-top: ${props.marginTop}`}
`
