import { Row } from "react-bootstrap"
import Slider from "react-slick"
import styled from "styled-components"
import branding from "../../branding/branding"
import { device } from "../../utils/Device"

export const TopSectionRoot = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px 30px 25px;
    height: auto;

    @media ${device.mobile} {
        padding: 0 10px 20px 20px;
    }

    &.lobby {
        padding: 0;
    }
`

export const SliderAndBannerSection = styled.div`
    display: flex;
    flex-direction: column;
`

export const Prio1Container = styled.div`
    width: calc(50% - 6px);
`

export const Prio2Container = styled.div`
    width: calc(50% - 6px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px 10px;
`

export const NewsSlider = styled(Slider)`
    width: calc(50% - 10px);
    height: 390px;

    .slick-arrow {
        display: none;
    }

    .slick-dots {
        bottom: 5px !important;
    }

    .slick-dots li {
        width: 10px;
    }

    .slick-dots li.slick-active button:before {
        color: #fff !important;
    }

    .slick-dots li button:before {
        color: #fff !important;
        font-size: 5px;
        cursor: pointer;
    }

    @media ${device.mobile} {
        width: calc(100vw - 40px);
        height: 220px;
    }
`

export const AdSpiritAdBannerWrapper = styled.div`
    display: flex;
    justify-content: center;
`

export const AdBanner = styled.ins`
    display: none;
    width: 1000px;
    height: 90px;
    text-align: left;
    text-decoration: none;
    margin: -10px 0 20px 26px !important;
    justify-content: center;
    margin: 0 auto;
    max-width: 95%;
    overflow: hidden;

    @media ${device.tablet} {
        display: flex;
    }

    & span {
        display: none;
    }
`

export const MobileAdBanner = styled.ins`
    margin: 1.2rem 0 0 0 !important;
    width: 300px;
    height: 250px;
    text-align: left;
    align-self: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    @media ${device.tablet} {
        display: none;
    }

    & span {
        display: none;
    }
`

export const GeneralSectionRoot = styled.div`
    width: 100%;
    font-family: ${branding.font1};
    background-color: #fff;
    overflow-x: hidden;
    display: grid;
    grid-gap: 15px;
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
    padding: 0 25px 55px 25px;

    &.mostReadSectionNotVisible {
        padding: 0 0 55px 25px;
    }

    & > :last-child {
        border-right: none;
    }

    @media (min-width: 2200px) {
        grid-template-columns: repeat(4, 1fr);

        & > :nth-child(4n + 4) {
            border-right: none;
            padding-right: 0px !important;
        }
    }

    @media (max-width: 2200px) {
        grid-template-columns: repeat(3, 1fr);

        & > :nth-child(3n + 3) {
            border-right: none;
            padding-right: 0px !important;
        }
    }

    @media (max-width: 1300px) {
        grid-template-columns: repeat(2, 1fr);

        & > :nth-child(2n + 2) {
            border-right: none;
            padding-right: 0px !important;
        }
    }

    @media ${device.mobile} {
        grid-template-columns: repeat(1, 1fr);
        padding: 0 5px 55px 18px;
    }

    @media (max-height: 1000px) {
        padding-bottom: 40px;
    }

    @media (max-height: 600px) {
        padding-bottom: 65px;
    }
`

export const TileRoot = styled.div`
    display: flex;
    flex-direction: column;
    border-right: 1px solid ${branding.listDividerColor};
    cursor: pointer;

    &.general {
        padding: 0px 15px 15px 0px;
        margin-bottom: 30px;

        @media ${device.mobile} {
            padding: 0px 0px 15px 0px;
            margin-bottom: 10px;
        }
    }

    @media ${device.mobile} {
        border-right: none;
        margin-bottom: 10px;
    }

    &.top,
    &.mid {
        border-right: none;
    }

    & a {
        color: ${branding.newsPageContent.newsListLayoutTitleColor};
        font-family: ${branding.font1};
        text-decoration: none;

        &.active {
            text-decoration: none;
        }
    }
`

export const Image = styled.div<{ src?: string }>`
    width: 100%;
    background: white;
    border-radius: 6px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &.general {
        padding: 25%;
        box-sizing: border-box;
        position: relative;
        background-image: url("${(props) => props.src}");
        margin-bottom: 7px;

        &.hover {
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 25%),
                url("${(props) => props.src}");
        }
    }

    &.top {
        height: 390px;
        display: flex;
        flex-direction: column;

        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 25%),
            linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 33%), url("${(props) => props.src}");

        @media ${device.mobile} {
            height: 220px;

            background-image: url(${(props) => props.src});
        }
    }

    &.mid {
        height: 192px;
        display: flex;
        flex-direction: column;

        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 25%),
            linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 25%), url("${(props) => props.src}");
    }
`

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;

    @media ${device.mobile} {
        display: none;
    }
`

export const TopNewsContentWrapper = styled.div`
    position: relative; /* Make sure the overlay is relative to this div */

    ::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${branding.newsPageContent.topSectionOverlay ?? "rgba(0,0,0, .5)"};
        z-index: 1;
    }
`

export const StyledNewsTopItem = styled.div`
    display: flex;
    flex-direction: row;
    z-index: 2;
`

export const TopBookmarkContainer = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
    padding: 15px;
    height: 20px;

    @media ${device.mobile} {
        display: none;
    }
`

export const LinkIcon = styled.div`
    height: 15px;
    position: absolute;
    top: 7%;
    right: 3%;
    z-index: 1;

    @media ${device.mobile} {
        display: none;
    }
`

export const TopDateTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px 20px 0 20px;
    margin-top: auto;
    margin-bottom: 20px;

    @media ${device.mobile} {
        margin-bottom: 25px;
    }
`

export const BadgeAreaContainer = styled.div`
    margin-top: 10px;
`

export const NewsDate = styled.div`
    color: ${branding.newsPageContent.newsTilesLayoutDateTimeColor};
    font-size: 12px;
    line-height: 14px;
    margin-top: 4px;

    &.top,
    &.mid {
        color: #fff;
        line-height: 17px;
        padding-top: 3px;

        @media ${device.mobile} {
            font-size: 10px;
        }
    }
`

export const NewsLinkIconContainer = styled.div`
    margin-left: 11px;
`

export const NewsTitle = styled.div`
    color: ${branding.newsPageContent.newsTilesLayoutTitleColor};
    z-index: 2;
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    margin-top: 10px;

    @media ${device.mobile} {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &.top,
    &.mid {
        font-size: 18px;
        line-height: 20px;
        margin-top: 8px;
        color: #fff;

        @media ${device.mobile} {
            font-size: 14px;
            line-height: 16px;
        }
    }

    &.mid {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &.mostRead  {
        font-weight: normal;
        margin-top: 0px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
`

export const NewsTeaser = styled.div`
    color: ${branding.newsPageContent.newsTilesLayoutDescriptionColor};
    font-size: 14px;
    line-height: 16px;
    margin-top: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @media ${device.mobile} {
        display: none;
    }
`

export const MostReadSectionRoot = styled.div`
    display: flex;
    flex-direction: column;

    @media ${device.mobile} {
        margin-left: 20px;
        margin-bottom: 30px;
    }

    & a {
        color: ${branding.newsPageContent.newsListLayoutTitleColor};
        font-family: ${branding.font1};
        text-decoration: none;

        &.active {
            text-decoration: none;
        }
    }
`

export const MostReadSectionTileWrapper = styled.div<{ count?: number }>`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(${(props) => props.count}, 135px);
    grid-gap: 8px;
`

export const MostReadSectionTitle = styled.div`
    border-left: 4px solid ${branding.crsTabs.defaultActionItemActiveStateColor};
    height: 25px;
    font-size: 18px;
    font-weight: bold;
    font-family: ${branding.font1};
    padding-left: 8px;
    margin-left: -15px;

    @media ${device.mobile} {
        border-left: none;
        margin-left: -10px;
        margin-bottom: 10px;
        font-size: 16px;
    }
`

export const MostReadItemRoot = styled(Row)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 0 15px 0;
    border-bottom: 1px solid ${branding.listDividerColor};

    @media ${device.mobile} {
        margin-left: 0px;
        border-bottom: none;
        overflow: hidden;
    }

    &.lastInList {
        border-bottom: none;
    }
`

export const MostReadItemLogoRoot = styled.div`
    width: 80px;
    height: 80px;
    background: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;

    @media (max-width: 1700px) {
        width: 60px;
        height: 60px;
    }

    @media (max-width: 1400px) {
        width: 50px;
        height: 50px;
    }

    @media (max-width: 1300px) {
        width: 45px;
        height: 45px;
    }

    @media (max-width: 1200px) {
        width: 35px;
        height: 35px;
    }

    @media ${device.mobile} {
        width: 135px;
        height: 135px;
        margin-bottom: 10px;
    }
`

export const MostReadItemLogo = styled.img`
    object-fit: cover;
    border-radius: 5px;
    width: 100%;
    height: 100%;
`

export const MostReadItemContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
`

export const SourceTagRoot = styled.div`
    display: flex;
    flex-direction: row;
    margin: 16px 0 0 -8px;
    height: 35px;

    &.general {
        position: absolute;
        z-index: 1;
        margin: 12px 0 0 -8px;
    }
`

export const SourceTagTriangle = styled.div`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 8px 8px;
    border-color: transparent transparent ${branding.newsPageContent.sourceTagConfiguration.backgroundColor};
    filter: brightness(70%);
`

export const SourceTagTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const SourceTagText = styled.div`
    background: ${branding.newsPageContent.sourceTagConfiguration.backgroundColor};
    opacity: ${branding.newsPageContent.sourceTagConfiguration.opacity};
    color: ${branding.newsPageContent.sourceTagConfiguration.textColor};
    font-family: ${branding.font1};
    font-size: ${branding.newsPageContent.sourceTagConfiguration.fontSize};
    line-height: 17px;
    padding: 5px 18px 5px 18px;
    margin: 8px 0 0 -8px;
    text-align: center;
    height: 27px;

    &.double {
        position: relative;
        color: ${branding.newsPageContent.sourceTagConfiguration.textColor};
        margin-top: -27px;
        background: transparent;
        opacity: 1;
        z-index: 1;
    }
`
