import moment from "moment"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import ComponentDecorator from "./ComponentDecorator"
import {
    MessageContent,
    MessageElement,
    MessageItemAuthor,
    MessageItemBubble,
    MessageItemDate,
    MessageStatus
} from "./ChatPageMessageList.styled"
import Linkify from "react-linkify"

interface MessageElementContentDefaultProps {
    content: string
    className: string
    timestamp: Date
    authorName?: string | null
    marginLeft: string
    marginRight: string
}

export const MessageElementContentDefault: React.FC<MessageElementContentDefaultProps> = (props) => {
    const loggedInState = useLoggedInState()
    return (
        <MessageElement marginLeft={props.marginLeft} marginRight={props.marginRight}>
            <MessageItemBubble className={props.className}>
                <MessageItemAuthor>
                    {props.authorName
                        ? props.authorName
                        : props.className.includes("mine")
                        ? loggedInState.user()?.firstName + " " + loggedInState.user()?.lastName
                        : "#"}
                </MessageItemAuthor>
                <Linkify componentDecorator={ComponentDecorator}>
                    <MessageContent>{props.content}</MessageContent>
                    <MessageItemDate className={props.className}>{moment(props.timestamp).format("HH:mm")}</MessageItemDate>
                </Linkify>
            </MessageItemBubble>

            <MessageStatus />
        </MessageElement>
    )
}
