import styled from "styled-components"

export const SponsorsGridRoot = styled.div<{ navigationOpen: boolean }>`
    margin-top: 30px;
    margin-left: ${(props) => (props.navigationOpen ? "22px" : "18px")};
    overflow-x: hidden;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
`
