import { useState, useEffect, RefObject } from "react"
import * as React from "react"
import branding from "../../branding/branding"
import { RouteComponentProps, useHistory } from "react-router-dom"
import styled from "styled-components"
import GuestUserBannerSharedState from "../guestUserBanner/GuestUserBannerSharedState"
import GuestUserBanner from "../guestUserBanner/GuestUserBanner"
import TopBar from "../../navigationArea/TopBar"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import LobbyPageContent from "./LobbyPageContent"
import MyFairPageContent from "./MyFairPageContent"
import CrsTabs from "../../ui/CrsTabs"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import TopBannerSharedState from "../advertisingBanner/TopBannerSharedState"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { useRef } from "react"
import { DesktopVersionContainer } from "../../utils/Device"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { useAppState } from "../../globalStates/AppState"
import { IconArrowRightCarousel, IconArrowLeftCarousel } from "../../ui/Icons"
import { programPageRoute } from "../../navigationArea/RoutePaths"
import { trackPageView, trackSelectContent } from "../../utils/GTMTracking"

export const TileWrapperSpeakers = styled.div<{ $count?: number }>`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(${(props) => props.$count}, 280px);
    grid-gap: ${branding.receptionPage.speakersTileGridGap ?? "10px"};
    /* grid-gap: 20px; */
    padding-bottom: 1.25rem;
`

export const MobileTileWrapperSpeakers = styled.div<{ $count?: number }>`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(${(props) => props.$count}, 95px);
    grid-gap: ${branding.receptionPage.speakersTileGridGap ?? "10px"};
    /* grid-gap: 20px; */
    padding-bottom: 1.25rem;
`

/*********************************************************************************************
 * FAIR INFO
 **********************************************************************************************/

const FairInfoContainerContent = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`

const HeaderTitleRoot = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    vertical-align: center;
    justify-content: center;
    flex: 1;
`

const HeaderTitleText = styled.div`
    color: ${branding.secondaryTextColor ?? "#fff"};
    font-size: ${branding.receptionPage.headerTitleInitialFontSize ?? "100px"};
    font-family: ${branding.receptionPage.headerTitleFontFamily};
    text-align: center;
    font-style: normal;
    text-transform: ${branding.receptionPage.headerTitleTextTransform};
    font-weight: ${branding.receptionPage.headerTitleFontWeight};
    letter-spacing: ${branding.receptionPage.headerTitleLetterSpacing};
    z-index: 2;
    margin-top: ${branding.receptionPage.lobbyHeaderTitleMarginTop ?? "40px"};
    @media only screen and (max-width: 2200px) {
        font-size: ${5 - (branding.receptionPage.headerTitleFontSizeIndex ?? 0) + "vw"};
        margin-top: ${branding.receptionPage.lobbyHeaderTitleMarginTopForSmallerScreen ?? "0px"};
    }
    @media only screen and (max-width: 1800px) {
        font-size: ${4.38 - (branding.receptionPage.headerTitleFontSizeIndex ?? 0) + "vw"};
        margin-top: ${branding.receptionPage.lobbyHeaderTitleMarginTopForSmallerScreen ?? "0px"};
    }

    @media only screen and (max-width: 1500px) {
        font-size: ${3.91 - (branding.receptionPage.headerTitleFontSizeIndex ?? 0) + "vw"};
        margin-top: ${branding.receptionPage.lobbyHeaderTitleMarginTopForSmallerScreen ?? "0px"};
    }

    @media only screen and (max-width: 1200px) {
        font-size: ${3.6 - (branding.receptionPage.headerTitleFontSizeIndex ?? 0) + "vw"};
        margin-top: ${branding.receptionPage.lobbyHeaderTitleMarginTopForSmallerScreen ?? "0px"};
    }
`

const HeaderFooterText = styled.div<{ title: string; description: string }>`
    font-size: ${(props) =>
        props.title.length > props.description?.length
            ? 2.5 - branding.receptionPage.headerFooterFontSizeIndex + "vw"
            : `${
                  branding.receptionPage.receptionPageHeaderFontSize ? branding.receptionPage.receptionPageHeaderFontSize : "22px"
              }`};
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: ${branding.receptionPage.receptionPageHeaderFontWeight};
    z-index: 2;
    color: ${branding.secondaryTextColor ?? "#fff"};
`

const MyFairPageHeader = styled.div<{ title: string; description: string }>`
    font-size: ${(props) => (props.title.length > props.description?.length ? "2.5vw" : "22px")};
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 300;
    color: ${branding.secondaryTextColor ?? "#fff"};
    a {
        color: ${branding.secondaryTextColor ?? "#fff"};
        border-bottom: 1px solid ${branding.secondaryTextColor ?? "#fff"};
    }
    z-index: 2;
    margin-top: ${branding.receptionPage.myFairHeaderSubtitleMarginTop ?? "5px"};
`

const CarouselInfoContainer = styled.div<{ containerBackground: string; isLobbyHeader: boolean }>`
    position: relative;
    color: white;
    margin: 0 -15px;
    /* height: 550px; */
    padding-top: 12.5%;
    background: ${(props) =>
            props.isLobbyHeader
                ? branding.receptionPage.lobbyHeaderLinearGradient1
                : branding.receptionPage.lobbyHeaderLinearGradient2},
        ${(props) => props.containerBackground};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    display: flex;
    flex-direction: column;

    .networkingCollapsed {
        border-bottom: 0;
        padding-left: 8px;
        padding-right: 10px;
        a {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .active {
        border-bottom: 0;
        padding-left: 8px;
        padding-right: 10px;
        a {
            padding-left: 0;
            padding-right: 0;
        }
    }
`

const MainCarouselDiv = styled.div`
    .slick-next,
    .slick-prev {
        width: 25px;
        height: 25px;
    }
    .slick-prev {
        left: 20px !important;
        z-index: 1;
    }
    .slick-next {
        right: 20px !important;
        z-index: 1;
    }
    .slick-dots {
        bottom: 20px !important;
    }
    .slick-dots li.slick-active button:before {
        color: #fff !important;
    }
    .slick-prev:before,
    .slick-next:before {
        font-size: 25px;
        opacity: 1;
    }

    .slick-dots li button:before {
        color: #fff !important;
        font-size: 10px;
        cursor: pointer;
    }
`
const IconWrapper = styled.div`
    &::before {
        content: none;
    }
`

interface CarouselViewProps {
    eventKey: string
    setEventKey: (value: string) => void
    onRouteChange: (route: string) => void
    fairInfoRootRef?: React.RefObject<HTMLDivElement>
}

const CarouselView: React.FunctionComponent<CarouselViewProps> = (props) => {
    const history = useHistory()

    const scheduleLink = (
        <div
            style={{ textDecoration: "underline", cursor: "pointer", display: "inline-block" }}
            onClick={() => {
                history.push(programPageRoute)
            }}
        >
            {branding.receptionPage.receptionPageMyHeaderDescriptionSecondPart}
        </div>
    )

    function getTitle(): string {
        let title: string = ""
        switch (props.eventKey) {
            case "LOBBY": {
                title = branding.receptionPage.receptionPageLobbyHeaderTitle
                break
            }
            case "MY_FAIR": {
                title = branding.receptionPage.receptionPageMyHeaderTitle
                break
            }
        }
        return title
    }

    function getDescription(): string {
        let description: string = ""
        switch (props.eventKey) {
            case "LOBBY": {
                description = branding.receptionPage.receptionPageLobbyHeaderDescription
                break
            }
            case "MY_FAIR": {
                description = branding.receptionPage.receptionPageMyFairHeaderDesc
                break
            }
        }
        return description
    }

    const title = getTitle()
    const description = getDescription()

    const lobbyHeaderPool: string[] = branding.receptionPage.lobbyHeaderPool
    const lobbyHeaderPoolNumber: number[] = branding.receptionPage.lobbyHeaderPoolNumber

    const myPageHeaderPool: string[] = branding.receptionPage.myFairPageHeaderPool
    const myPageHeaderPoolNumber: number[] = branding.receptionPage.myFairPageHeaderPoolNumber

    const isLobbyHeader: boolean = props.eventKey === "LOBBY"

    const renderLobbySlides = () =>
        lobbyHeaderPoolNumber.map((num, index) => (
            <CarouselInfoContainer
                key={index}
                containerBackground={"url(" + lobbyHeaderPool[num] + ")"}
                isLobbyHeader={isLobbyHeader}
            >
                <FairInfoContainerContent>
                    {
                        <HeaderTitleRoot>
                            <HeaderTitleText>{title}</HeaderTitleText>
                            <HeaderFooterText title={title} description={description}>
                                {description}
                            </HeaderFooterText>
                        </HeaderTitleRoot>
                    }
                </FairInfoContainerContent>
            </CarouselInfoContainer>
        ))

    const renderMyFairPageSlides = () =>
        myPageHeaderPoolNumber.map((num, index) => (
            <CarouselInfoContainer key={index} containerBackground={"url(" + myPageHeaderPool[num] + ")"} isLobbyHeader={false}>
                <FairInfoContainerContent>
                    {
                        <HeaderTitleRoot>
                            <HeaderTitleText>{title}</HeaderTitleText>
                            <MyFairPageHeader title={title} description={description}>
                                {branding.receptionPage.receptionPageMyHeaderDescriptionFirstPart} {scheduleLink}{" "}
                                {branding.receptionPage.receptionPageMyHeaderDescriptionThirdPart}
                            </MyFairPageHeader>
                        </HeaderTitleRoot>
                    }
                </FairInfoContainerContent>
            </CarouselInfoContainer>
        ))

    return (
        <MainCarouselDiv className="App">
            <Slider
                dots={true}
                slidesToShow={1}
                slidesToScroll={1}
                autoplay={lobbyHeaderPoolNumber.length > 1 || myPageHeaderPoolNumber.length > 1 ? true : false}
                autoplaySpeed={
                    props.eventKey === "LOBBY"
                        ? branding.receptionPage.autoPlayValueLobbyPool
                        : branding.receptionPage.autoPlayValueMyFairPool
                }
                nextArrow={<IconWrapper>{IconArrowRightCarousel({ fill: "#fff" })}</IconWrapper>}
                prevArrow={<IconWrapper>{IconArrowLeftCarousel({ fill: "#fff" })}</IconWrapper>}
            >
                {props.eventKey === "LOBBY" ? renderLobbySlides() : renderMyFairPageSlides()}
            </Slider>
        </MainCarouselDiv>
    )
}

/*********************************************************************************************
 * HEADER ROW
 **********************************************************************************************/

const HeaderRow = styled.div<{ showShadow?: boolean }>`
    position: sticky;
    top: 0;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    height: 70px;
    color: ${branding.mainInfoColor};
    background-color: #fff;
    z-index: 1000;
    box-sizing: content-box;
    flex-direction: column;
    &::before {
        transition: box-shadow 0.3s ease-in;
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 70px;
        box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowTTB : "initial")};
    }
`
interface HeaderRowProps {
    eventKey: string
    onEventKeySelect: (value: string) => void
    showShadow?: boolean
    ref?: any
}

const HeaderRowContent: React.FunctionComponent<HeaderRowProps> = (props) => {
    const itemsOrder: any = branding.receptionPage.crsTabsItemsOrder
    //eslint-disable-next-line

    enum viewType {
        LOBBY = "LOBBY",
        MY_FAIR = "MY_FAIR"
    }

    let items: any = []

    itemsOrder.forEach((item: any) => {
        let newItem
        switch (item) {
            case viewType.LOBBY:
                newItem = { label: branding.receptionPage.eventKeyLobby, value: viewType.LOBBY }
                break
            case viewType.MY_FAIR:
                newItem = { label: branding.receptionPage.eventKeyMyPage, value: viewType.MY_FAIR }
                break
        }

        if (newItem) {
            items.push(newItem)
        }
    })

    useEffect(() => {
        if (props.eventKey === "LOBBY") {
            trackPageView(branding.receptionPage.eventKeyLobby, "Home", "Home")
        } else {
            trackPageView(branding.receptionPage.eventKeyMyPage, "Home", "MyFair")
        }
    }, [props.eventKey])

    return (
        <HeaderRow showShadow={props.showShadow}>
            <CrsTabs
                tabItems={items}
                activeItem={items.length === 1 ? items[0].value : props.eventKey}
                onTabItemClicked={props.onEventKeySelect}
            />
        </HeaderRow>
    )
}

/*********************************************************************************************
 * RECEPTION PAGE CONTENT
 **********************************************************************************************/

const ReceptionPageRoot = styled.div<{ guestBannerHeight: number }>`
    height: 100%; //calc(100% - ${(props) => props.guestBannerHeight + 50 + "px"});
`

const MainContent = styled.div<{ height?: number; carouselHeight: number; headerRowHeight: number }>`
    height: 100%;
    width: 100%;
    background-color: white;

    & .ScrollbarsCustom-TrackY {
        z-index: 10001;
    }

    & .ScrollbarsCustom-Track {
        top: ${(props) => props.carouselHeight + props.headerRowHeight + 160 + "px"} !important; // fix this not to be hard coded
        height: ${(props) =>
            "calc(100% - " +
            props.carouselHeight +
            props.headerRowHeight +
            180 +
            "px)"} !important; // fix this not to be hard coded
    }
`

const MainContentRoot = styled.div`
    padding-bottom: 44px;
`

const ToggledContent = styled.div<{ displayed: string }>`
    display: ${(props) => props.displayed};
    flex-flow: column;

    &.MY_FAIR {
        background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
    }

    &.LOBBY {
        background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
    }
`

export interface ReceptionHistoryState {
    eventKey: string
}

interface ReceptionPageProps extends RouteComponentProps {
    receptionRoute: string
    trigger: number
    changeRoute: (route: string) => void
    reloadScheduleList: boolean
    setReloadScheduleList: (value: boolean) => void
}

const ReceptionPageContent: React.FunctionComponent<ReceptionPageProps> = (props) => {
    let userState = useLoggedInState()
    const appState = useAppState()
    const lang = useLanguageState().getLanguage()
    const history = useHistory()
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
    const { topBarRef, setTopBarRef } = TopBannerSharedState()
    const [eventKey, setEventKey] = useState<string>(
        (history.location.state as ReceptionHistoryState)?.eventKey ?? (props.receptionRoute ? props.receptionRoute : "LOBBY")
    )
    const fairInfoRootRef = useRef<HTMLDivElement>(null)
    const [showShadow, setShowShadow] = useState(false)
    // eslint-disable-next-line
    const [breadcrumbRef, setBreadcrumbRef] = useState<RefObject<HTMLDivElement>>()
    const { isMobile } = useWindowDimensions()
    const carouselRef = useRef<HTMLDivElement>(null)
    const headerRowRef = useRef<HTMLDivElement>(null)

    function setMobileBreadcrumb() {
        if (eventKey === "LOBBY") {
            appState.setCurrentMobileBreadcrumb(branding.sideIconBar.lobbyMenuText)
        } else if (eventKey === "MY_FAIR") {
            appState.setCurrentMobileBreadcrumb(branding.receptionPage.eventKeyMyPage)
        }
    }

    useEffect(() => {
        setMobileBreadcrumb()
        //eslint-disable-next-line
    }, [eventKey])

    useEffect(() => {
        setMobileBreadcrumb()
        // eslint-disable-next-line
    }, [lang])

    useEffect(() => {
        //first we check the history state, to get the last selected tab, then the global receptionRoute variable where the selected tab is stored, and in the end LOBBY as the default case
        const route: string =
            (history.location.state as ReceptionHistoryState)?.eventKey ?? (props.receptionRoute ? props.receptionRoute : "LOBBY")
        setEventKey(route)
        props.changeRoute(route)

        //perserving history.location.search to the temp variable
        const tempParams = history.location.search

        //update the history state when the reception page tab is changed through the sidebar navigation
        if (props.receptionRoute) {
            history.replace({
                state: {
                    ...(history.location.state as ReceptionHistoryState),
                    eventKey: route
                }
            })
            // history.location.search needs to save existing url params for the cc v2 schedule tab to work properly
            history.location.search = tempParams
        }
        // eslint-disable-next-line
    }, [props.trigger, props.receptionRoute])

    const heightToAdjust =
        (topBarRef && topBarRef.current ? topBarRef.current.clientHeight : 0) +
        (guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0)

    const guestBannerHeight = guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0

    const handleScroll = (scrollValues: any) => {
        if (scrollValues.contentScrollHeight > scrollValues.clientHeight) {
            setShowShadow(scrollValues.scrollTop > 0)
        }

        if (scrollValues && fairInfoRootRef.current && breadcrumbRef?.current) {
            if (scrollValues.scrollTop > fairInfoRootRef.current?.clientHeight + breadcrumbRef?.current.clientHeight) {
                if (!showShadow) setShowShadow(true)
            } else {
                if (showShadow) setShowShadow(false)
            }
        }
    }

    return (
        <ReceptionPageRoot guestBannerHeight={guestBannerHeight}>
            <DesktopVersionContainer>
                <GuestUserBanner setRef={setGuestUserBannerRef} />
                <TopBar setRef={setTopBarRef} />
            </DesktopVersionContainer>

            <MainContent
                className="scroll"
                height={heightToAdjust}
                carouselHeight={carouselRef.current?.clientHeight || 0}
                headerRowHeight={headerRowRef.current?.clientHeight || 0}
            >
                <ContentScrollContainer handleScroll={handleScroll} adjustForHeaderWith={heightToAdjust + "px"}>
                    <MainContentRoot style={{ paddingBottom: isMobile ? "130px" : "44px" }} className={eventKey}>
                        {isMobile ? (
                            <div />
                        ) : (
                            <>
                                {
                                    <div ref={carouselRef}>
                                        <CarouselView
                                            fairInfoRootRef={fairInfoRootRef}
                                            eventKey={eventKey}
                                            setEventKey={setEventKey}
                                            onRouteChange={props.changeRoute}
                                        ></CarouselView>
                                    </div>
                                }

                                <HeaderRowContent
                                    ref={headerRowRef}
                                    showShadow={showShadow}
                                    eventKey={eventKey}
                                    onEventKeySelect={(value: string) => {
                                        setEventKey(value)

                                        //update the global receptionRoute variable so that proper item is selected in the sidebar navigation
                                        props.changeRoute(value)

                                        history.replace({
                                            state: {
                                                ...(history.location.state as ReceptionHistoryState),
                                                eventKey: value
                                            }
                                        })

                                        trackSelectContent(
                                            value === "LOBBY"
                                                ? branding.sideIconBar.lobbyMenuText
                                                : branding.receptionPage.eventKeyMyPage,
                                            "Overallview",
                                            value === "LOBBY" ? "Lobby" : "MyFair"
                                        )
                                    }}
                                />
                            </>
                        )}
                        <ToggledContent displayed={"flex"} className={eventKey} style={{ marginTop: "-40px" }}>
                            {eventKey === "LOBBY" && (
                                <LobbyPageContent
                                    eventKey={eventKey}
                                    profileId={userState.user()?.profileId!}
                                    isMobile={isMobile}
                                />
                            )}

                            {eventKey === "MY_FAIR" && (
                                <MyFairPageContent
                                    eventKey={eventKey}
                                    setEventKey={setEventKey}
                                    guestBannerHeight={guestUserBannerRef?.current?.clientHeight || 0}
                                    isMobile={isMobile}
                                />
                            )}
                        </ToggledContent>
                    </MainContentRoot>
                </ContentScrollContainer>
            </MainContent>
        </ReceptionPageRoot>
    )
}

export default ReceptionPageContent
