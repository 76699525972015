import { DeviceMessage, DeviceMessageContainer, DeviceSettingsRoot, SettingsGroup } from "../AudioSettings/AudioSettings.styled"
import { NoContentShift } from "../BackgroundSettings/BackgroundSettings.styled"
import PreviewVideo from "../PreviewVideo/PreviewVideo"
import { PreviewVideoContainer, PreviewVideoOverlay } from "./VideoSettings.styled"
import branding from "../../../branding/branding"
import { SettingsLabel } from "../Settings/Settings.styled"
import CameraSelection from "../CameraSelection/CameraSelection"
import { useEffect, useState } from "react"
import { PreMeetingSettingType, usePreMeetingSettingContext } from "../../context/PreMeetingSettingsContext"
import { IconCameraOff } from "../../../ui/Icons"
import { useDevicesV2 } from "../../hooks/useDevices"
import { MeetingStatus, useMeetingStatus } from "amazon-chime-sdk-component-library-react"
import { useVideoContext } from "../../context/VideoContext"

function VideoSettings() {
    const preMeetingSettingsContext = usePreMeetingSettingContext()
    const [hasCameraPermission, setHasCameraPermission] = useState(true)
    const devices = useDevicesV2()
    const meetingStatus = useMeetingStatus()
    const videoContext = useVideoContext()
    let isPreviewVisible = true

    if (meetingStatus !== MeetingStatus.Succeeded) {
        isPreviewVisible = preMeetingSettingsContext.getSetting(PreMeetingSettingType.VIDEOINPUTSTATE)
    } else if (meetingStatus === MeetingStatus.Succeeded) {
        isPreviewVisible = videoContext.isVideoEnabled()
    }

    useEffect(() => {
        preMeetingSettingsContext.applyStoredVideoSettings()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setHasCameraPermission(devices.hasVideoInputPermission())
        // eslint-disable-next-line
    }, [devices])

    return (
        <>
            {hasCameraPermission ? (
                <DeviceSettingsRoot>
                    <SettingsGroup>
                        <PreviewVideoContainer>
                            {isPreviewVisible ? (
                                <NoContentShift>
                                    <PreviewVideo />
                                    <PreviewVideoOverlay>
                                        <span> {branding.audioVideoSettings.preview}</span>
                                    </PreviewVideoOverlay>
                                </NoContentShift>
                            ) : (
                                <DeviceMessageContainer>
                                    <DeviceMessage>
                                        <IconCameraOff fill="#fff" width="25px" height="25px" />
                                    </DeviceMessage>
                                    <DeviceMessage>
                                        {branding.communicationAreaAudioVideoSettingsVideoDisabledMessage}
                                    </DeviceMessage>
                                </DeviceMessageContainer>
                            )}
                        </PreviewVideoContainer>
                        <SettingsLabel>{branding.audioVideoSettings.videoInputLabel}</SettingsLabel>
                        <CameraSelection />
                    </SettingsGroup>
                </DeviceSettingsRoot>
            ) : (
                <DeviceSettingsRoot>
                    <DeviceMessageContainer>
                        <DeviceMessage>
                            <IconCameraOff fill="#fff" width="25px" height="25px" />
                        </DeviceMessage>
                        <DeviceMessage>{branding.communicationAreaAudioVideoSettingsVideoPermissionMessage}</DeviceMessage>
                    </DeviceMessageContainer>
                </DeviceSettingsRoot>
            )}
        </>
    )
}

export default VideoSettings
