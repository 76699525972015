import { useEffect, useRef, useState } from "react"
import * as React from "react"
import { Button } from "react-bootstrap"
import styled from "styled-components"
import branding from "../../../branding/branding"
import { useFavoriteState } from "../../../globalStates/Favorites"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import CenteredLoader from "../../../ui/CenteredLoader"
import { TextField } from "../../../ui/TextField"
import useWindowDimensions from "../../../ui/WindowDimensionsHook"
import { MobileVersionContainer, DesktopVersionContainer, device } from "../../../utils/Device"
import {
    CenterLoginStyleContactUsLabel,
    CenterLoginStyleErrorMessage,
    CenterLoginStyleForgotPassword,
    CenterLoginStyleHeader,
    CenterLoginStyleHeaderSubitle,
    CenterLoginStyleHeaderTitle,
    CenterLoginStyleInputContainer,
    CenterLoginStyleLoaderAttributes,
    CenterLoginStyleSubmitButton,
    CenterLoginTextFieldContainer,
    ErrorMessagesContainer,
    getTextFieldBackgroundColor,
    getTextFieldBorderColor,
    getTextFieldTextColor,
    MobileCenterLoginStyleHeaderTitle,
    MobileLoginGetYourTicketSection,
    PrivacyCheckbox
} from "../CenterLoginSharedComponents"
import LoginError from "../LoginError"
import { connect, registerWithToken } from "../LoginHelper"
import { LoginModule } from "../LoginModule"
import { ProfileResponseStatus } from "../LoginSite"
import { DataPrivacyDoc } from "../../../backendServices/AuthenticationServices"
import { useAppState } from "../../../globalStates/AppState"
import { trackLogin, trackPageView, trackTakeAction } from "../../../utils/GTMTracking"
import { LoginType } from "../LoginSiteBranding"

const CenterLoginStyleInfoMessage = styled.div`
    color: ${branding.loginRegistrationSite.successMessageColor ?? "#6dd401"};
`
/**
 * Main Component
 */
export interface LoginWithTokenThirdPartyData {}

interface LoginWithTokenProperties {
    dataPrivacyDoc: DataPrivacyDoc
}

function LoginWithTokenComponent(props: LoginWithTokenProperties) {
    const loginAbortController = new AbortController()
    const loggedInContext = useLoggedInState()
    const [isLoaded, setIsLoaded] = useState(true)
    const form = useRef<HTMLFormElement>(null)

    useEffect(() => {
        return () => {
            loginAbortController.abort()
        }
    }, []) // eslint-disable-line

    const isTokenStep = !!loggedInContext.user()?.email
    const step = isTokenStep ? (
        <InputToken
            dataPrivacyDoc={props.dataPrivacyDoc}
            email={loggedInContext.user()?.email!}
            setIsLoaded={setIsLoaded}
            form={form}
        />
    ) : (
        <InputEmail dataPrivacyDoc={props.dataPrivacyDoc} setIsLoaded={setIsLoaded} form={form} />
    )

    useEffect(() => {
        trackPageView("Login", "Registration", isTokenStep ? "TokenEntry" : "EmailEntry")
    }, [isTokenStep])

    return (
        <form id="loginForm" ref={form} autoComplete="on">
            <DesktopVersionContainer>
                <CenterLoginStyleHeader>
                    <CenterLoginStyleHeaderTitle>
                        {branding.loginRegistrationSite.loginRegistrationTitle}
                    </CenterLoginStyleHeaderTitle>
                    <CenterLoginStyleHeaderSubitle>
                        {branding.loginRegistrationSite.loginRegistrationSubtitle}
                    </CenterLoginStyleHeaderSubitle>
                </CenterLoginStyleHeader>
            </DesktopVersionContainer>
            <CenterLoginStyleInputContainer className={!isLoaded ? "loading" : ""}>
                {!isLoaded && <CenteredLoader loaderWrapperStyle={CenterLoginStyleLoaderAttributes}></CenteredLoader>}
                <DesktopVersionContainer>
                    <p style={{ textAlign: "left", order: 1 }}>
                        {isTokenStep
                            ? branding.loginRegistrationSite.centerLoginStyleTitleCode
                            : branding.loginRegistrationSite.centerLoginStyleTitle}
                    </p>
                </DesktopVersionContainer>

                <MobileVersionContainer>
                    <MobileCenterLoginStyleHeaderTitle>
                        {branding.loginRegistrationSite.loginRegistrationTitle}
                    </MobileCenterLoginStyleHeaderTitle>
                </MobileVersionContainer>

                {step}

                {branding.loginRegistrationSite.contactUsLabelVisible && (
                    <CenterLoginStyleContactUsLabel style={{ order: 99 }}>
                        {branding.loginRegistrationSite.contactUsLabel}
                    </CenterLoginStyleContactUsLabel>
                )}
            </CenterLoginStyleInputContainer>
        </form>
    )
}

/**
 * E-Mail Input Component
 */
interface InputEmailProps {
    dataPrivacyDoc: DataPrivacyDoc
    setIsLoaded: (isLoaded: boolean) => void
    form: React.RefObject<HTMLFormElement>
}

function InputEmail(props: InputEmailProps) {
    const loggedInContext = useLoggedInState()
    const loginAbortController = new AbortController()
    const [privacyDocsAccepted, setPrivacyDocsAccepted] = useState(false)

    const privacyCheckboxVisible = branding.loginRegistrationSite.privacyDocsCheckbox.visible
    const privacySecondCheckboxVisible = branding.loginRegistrationSite.privacyDocsSecondCheckbox.visible
    const [accepted, setAccepted] = useState(!privacyCheckboxVisible || false)
    const [acceptedSecond, setAcceptedsecond] = useState(!privacySecondCheckboxVisible || false)

    const [email, setEmail] = useState("")
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const [resp, setResp] = useState(ProfileResponseStatus.NONE)

    useEffect(() => {
        return () => {
            loginAbortController.abort()
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        setPrivacyDocsAccepted(accepted && acceptedSecond)
    }, [accepted, acceptedSecond])

    useEffect(() => {
        if (props.form.current) {
            const onSubmitButtonClicked = (ev: Event) => {
                ev.preventDefault()
                if (submitDisabled || !privacyDocsAccepted) return

                props.setIsLoaded(false)
                setSubmitDisabled(true)
                const registerResponse = registerWithToken(loggedInContext, email, props.dataPrivacyDoc)
                registerResponse.then((resp) => {
                    if (!loginAbortController.signal.aborted) {
                        setSubmitDisabled(false)
                        props.setIsLoaded(true)
                        setResp(resp)
                    }
                    localStorage.removeItem("logoutReason")
                })
            }

            props.form.current.addEventListener("submit", onSubmitButtonClicked as any)

            return () => {
                if (props.form.current) {
                    props.form.current.removeEventListener("submit", onSubmitButtonClicked as any)
                }
            }
        }
    }, [props.form, privacyDocsAccepted, submitDisabled, email, props.dataPrivacyDoc]) // eslint-disable-line

    useEffect(() => {
        const emailRegex = new RegExp("^.+@.+\\.[A-Za-z]{2,20}$")
        const disabled = !emailRegex.test(email)
        if (disabled !== submitDisabled)
            // Prevent rerenders
            setSubmitDisabled(disabled)
    }, [email]) // eslint-disable-line

    const windowSize = useWindowDimensions()

    return (
        <>
            <CenterLoginTextFieldContainer style={{ order: 2 }}>
                <TextField
                    placeholder={branding.loginRegistrationSite.enterEmail}
                    setValue={setEmail}
                    onFocus={() => trackTakeAction("Email Textbox", "Email", "Registration")}
                    name="email"
                    autoComplete="email"
                    width="100%"
                    fontSize="16px"
                    paddingLeft="8px"
                    borderRadius="5px"
                    height="45px"
                    backgroundColor={getTextFieldBackgroundColor(windowSize)}
                    borderAround={"1px solid " + getTextFieldBorderColor(windowSize)}
                    outline="1px solid"
                    textColor={getTextFieldTextColor(windowSize)}
                    fontFamily={branding.font1}
                    loginField
                />
            </CenterLoginTextFieldContainer>

            <DesktopVersionContainer style={{ order: 3 }}>
                <div style={{ order: 3 }}>
                    <PrivacyCheckbox
                        accepted={accepted}
                        setAccepted={setAccepted}
                        acceptedSecond={acceptedSecond}
                        setAcceptedSecond={setAcceptedsecond}
                        dataPrivacyDoc={props.dataPrivacyDoc}
                    />
                </div>

                <div style={{ order: 4 }}>
                    <LoginError profileResponseStatus={resp} />
                </div>
            </DesktopVersionContainer>

            <CenterLoginStyleSubmitButton
                id="loginSubmit"
                type="submit"
                style={{ order: 5 }}
                disabled={submitDisabled || !privacyDocsAccepted}
            >
                {branding.loginRegistrationSite.submitTokenButton}
            </CenterLoginStyleSubmitButton>

            <MobileVersionContainer style={{ order: 6 }}>
                <div style={{ order: 6 }}>
                    <PrivacyCheckbox
                        accepted={accepted}
                        setAccepted={setAccepted}
                        acceptedSecond={acceptedSecond}
                        setAcceptedSecond={setAcceptedsecond}
                        dataPrivacyDoc={props.dataPrivacyDoc}
                    />
                </div>

                <div style={{ order: 7 }}>
                    <LoginError profileResponseStatus={resp} />
                </div>

                <MobileLoginGetYourTicketSection />
            </MobileVersionContainer>

            {branding.loginRegistrationSite.showExternalLinkButton && (
                <div style={{ order: 6 }}>
                    <ExternaLinkButtonComponent />
                </div>
            )}
        </>
    )
}

/**
 * External Link Button Component
 */

const ExternalLinkButtonRoot = styled.div`
    margin: 10px 0;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: -15px;
    border-top: 1px solid ${branding.loginRegistrationSite.loginModalTextColor};

    @media ${device.mobile} {
        border-top: 1px solid ${branding.loginRegistrationSite.mobileLoginTextColor};
    }
`

const ExternalLinkButton = styled.div`
    font-family: ${branding.font1};
    color: ${branding.loginRegistrationSite.loginModalTextColor};
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    margin-top: 25px;
    text-align: center;
    font-weight: bold;
    text-decoration: underline;

    @media ${device.mobile} {
        color: ${branding.loginRegistrationSite.mobileLoginTextColor};
    }
`

export const ExternaLinkButtonComponent: React.FunctionComponent = () => {
    const onExternalLinkButtonClick = () => {
        window.open(`${branding.loginRegistrationSite.externalLinkButtonUrl}`, "_blank")
    }

    return (
        <ExternalLinkButtonRoot>
            <ExternalLinkButton onClick={onExternalLinkButtonClick}>
                {branding.loginRegistrationSite.externalLinkButtonText}
            </ExternalLinkButton>
        </ExternalLinkButtonRoot>
    )
}

/**
 * Token Input Component
 */
interface InputTokenProps {
    email: string
    dataPrivacyDoc: DataPrivacyDoc
    form: React.RefObject<HTMLFormElement>
    setIsLoaded: (isLoaded: boolean) => void
}

function InputToken(props: InputTokenProps) {
    const loggedInContext = useLoggedInState()
    const favoriteContext = useFavoriteState()
    const appState = useAppState()
    const [token, setToken] = useState("")
    const [registerResponse, setRegisterResponse] = useState(ProfileResponseStatus.NONE)
    const [connectResponse, setConnectResponse] = useState(ProfileResponseStatus.NONE)
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false)

    const tokenInputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        tokenInputRef.current?.focus()
    }, []) // eslint-disable-line

    useEffect(() => {
        if (props.form.current) {
            const onSubmitButtonClicked = async (ev: Event) => {
                setSubmitButtonDisabled(true)
                ev.preventDefault()

                const connectResponse = await connect(loggedInContext, favoriteContext, appState, token)
                setConnectResponse(connectResponse)
                setToken("")
                if (connectResponse === ProfileResponseStatus.SUCCESS) {
                    props.setIsLoaded(false)
                }
                setSubmitButtonDisabled(false)
            }

            props.form.current.addEventListener("submit", onSubmitButtonClicked as any)

            return () => {
                if (props.form.current) {
                    props.form.current.removeEventListener("submit", onSubmitButtonClicked as any)
                }
            }
        }
    }, [props.form, token]) // eslint-disable-line

    function onTokenChanged() {
        setConnectResponse(ProfileResponseStatus.NONE)
        setRegisterResponse(ProfileResponseStatus.NONE)
    }

    async function onCreateNewTokenButtonClicked() {
        const resp = await registerWithToken(loggedInContext, props.email, props.dataPrivacyDoc)
        setRegisterResponse(resp)
        setConnectResponse(ProfileResponseStatus.NONE)
        setToken("")
    }

    const windowSize = useWindowDimensions()

    return (
        <>
            <CenterLoginTextFieldContainer style={{ order: 2 }}>
                <TextField
                    value={props.email}
                    name="email"
                    disabled={true}
                    paddingLeft="8px"
                    width="100%"
                    borderRadius="5px"
                    fontSize="16px"
                    height="45px"
                    backgroundColor={getTextFieldBackgroundColor(windowSize)}
                    borderAround={"1px solid " + getTextFieldBorderColor(windowSize)}
                    outline="1px solid"
                    textColor={getTextFieldTextColor(windowSize)}
                    fontFamily={branding.font1}
                    loginField
                />
            </CenterLoginTextFieldContainer>
            <CenterLoginStyleForgotPassword
                tabIndex={0}
                style={{ order: 3 }}
                onClick={() => {
                    loggedInContext.setUser(undefined)
                    trackTakeAction("Change Email", "EmailAddress", "Registration")
                }}
            >
                {branding.loginRegistrationSite.changeEmailButton}
            </CenterLoginStyleForgotPassword>

            <CenterLoginTextFieldContainer tabIndex={0} style={{ order: 4 }}>
                <TextField
                    placeholder={branding.loginRegistrationSite.enterToken}
                    name="token"
                    focused={true}
                    value={token.trim()}
                    setValue={setToken}
                    onChange={onTokenChanged}
                    onFocus={() => trackTakeAction("Confirmation Code Textbox", "ConfirmationCode", "Registration")}
                    width="100%"
                    fontSize="16px"
                    borderRadius="5px"
                    paddingLeft="8px"
                    height="45px"
                    backgroundColor={getTextFieldBackgroundColor(windowSize)}
                    borderAround={"1px solid " + getTextFieldBorderColor(windowSize)}
                    outline="1px solid"
                    textColor={getTextFieldTextColor(windowSize)}
                    fontFamily={branding.font1}
                    loginField
                />
            </CenterLoginTextFieldContainer>
            <CenterLoginStyleForgotPassword
                tabIndex={0}
                style={{ marginBottom: "15px", order: 5 }}
                onClick={() => {
                    onCreateNewTokenButtonClicked()
                    trackTakeAction("Resend Registration Code", "ResendCode", "Registration")
                }}
                onKeyPress={(e: React.KeyboardEvent) => {
                    if (e.key === "Enter" || e.key === "Spacebar" || e.key === " " || e.code === "Space") {
                        onCreateNewTokenButtonClicked()
                        trackTakeAction("Resend Registration Code", "ResendCode", "Registration")
                    }
                }}
            >
                {branding.loginRegistrationSite.resendToken}
            </CenterLoginStyleForgotPassword>

            <TokenMessageDisplay
                type={calcTokenMessagePanelType(registerResponse, connectResponse)}
                clickListener={onCreateNewTokenButtonClicked}
            />

            <LoginError profileResponseStatus={registerResponse} />

            <CenterLoginStyleSubmitButton
                id="loginSubmit"
                type="submit"
                disabled={token.length === 0 || submitButtonDisabled}
                onClick={() => {
                    trackLogin(LoginType.TOKEN)
                }}
            >
                {branding.loginRegistrationSite.submitTokenButton}
            </CenterLoginStyleSubmitButton>

            {branding.loginRegistrationSite.showExternalLinkButton && (
                <div style={{ order: 99 }}>
                    <ExternaLinkButtonComponent />
                </div>
            )}
        </>
    )
}

/**
 * HELPER
 */

enum TokenMessagePanelType {
    NONE,
    ERROR_TOKEN_INVALID,
    ERROR_TOKEN_INVALIDATED,
    ERROR_CREATE_TOKEN_FAILED,
    SUCCESS_CREATE_TOKEN,
    MAXIMUM_LOGIN_ATTEMPTS
}

function calcTokenMessagePanelType(registerStatus: ProfileResponseStatus, connectStatus: ProfileResponseStatus) {
    const registerResponseFailed =
        registerStatus === ProfileResponseStatus.FAILURE ||
        registerStatus === ProfileResponseStatus.FAILURE_EMAIL_INVALID ||
        registerStatus === ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED

    if (registerResponseFailed) return TokenMessagePanelType.ERROR_CREATE_TOKEN_FAILED

    if (registerStatus === ProfileResponseStatus.SUCCESS) return TokenMessagePanelType.SUCCESS_CREATE_TOKEN

    if (connectStatus === ProfileResponseStatus.TOKEN_INVALIDATED) {
        return TokenMessagePanelType.ERROR_TOKEN_INVALIDATED
    } else if (connectStatus === ProfileResponseStatus.WRONG_TOKEN) {
        return TokenMessagePanelType.ERROR_TOKEN_INVALID
    } else if (connectStatus === ProfileResponseStatus.MAXIMUM_LOGIN_ATTEMPTS) {
        return TokenMessagePanelType.MAXIMUM_LOGIN_ATTEMPTS
    }

    return TokenMessagePanelType.NONE
}

export const CreateNewTokenButton = styled(Button)`
    padding-left: 0px;
    padding-bottom: 0px;

    &:focus {
        outline: none;
    }
`

interface TokenMessagePanelProps {
    type: TokenMessagePanelType
    clickListener: () => any
}

function TokenMessageDisplay(props: TokenMessagePanelProps) {
    if (props.type === TokenMessagePanelType.NONE) return null

    return (
        <ErrorMessagesContainer style={{ order: 6 }}>
            <CenterLoginStyleErrorMessage>
                {props.type === TokenMessagePanelType.ERROR_TOKEN_INVALID && <p>{branding.loginRegistrationSite.wrongToken}</p>}

                {props.type === TokenMessagePanelType.MAXIMUM_LOGIN_ATTEMPTS && (
                    <div>
                        <p>{branding.loginRegistrationSite.maximumLoginAttempts}</p>
                        <CreateNewTokenButton variant="link" onClick={props.clickListener}>
                            {branding.loginRegistrationSite.createNewToken}
                        </CreateNewTokenButton>
                    </div>
                )}

                {props.type === TokenMessagePanelType.ERROR_TOKEN_INVALIDATED && (
                    <div>
                        <p>{branding.loginRegistrationSite.wrongToken}</p>
                        <CreateNewTokenButton variant="link" onClick={props.clickListener}>
                            {branding.loginRegistrationSite.createNewToken}
                        </CreateNewTokenButton>
                    </div>
                )}

                {props.type === TokenMessagePanelType.ERROR_CREATE_TOKEN_FAILED && (
                    <p>{branding.loginRegistrationSite.createTokenFailed}</p>
                )}
            </CenterLoginStyleErrorMessage>
            <CenterLoginStyleInfoMessage>
                {props.type === TokenMessagePanelType.SUCCESS_CREATE_TOKEN && (
                    <p>{branding.loginRegistrationSite.createTokenSuccess}</p>
                )}
            </CenterLoginStyleInfoMessage>
        </ErrorMessagesContainer>
    )
}

const LoginWithToken: LoginModule = {
    component: LoginWithTokenComponent,
    isSessionValid: () =>
        new Promise<Boolean>((resolve, _) => {
            resolve(true)
        }),
    logout: () => new Promise((resolve) => resolve())
}
export default LoginWithToken
