import * as React from "react"
import ConversationListContent from "./ConversationListPage"
import { communicationCenterSelectedTab, useAppState } from "../globalStates/AppState"
import branding from "../branding/branding"
import ChatPage from "./ChatPage"
import NetworkingTabErrorBoundary from "../contentArea/errorPages/NetworkingTabErrorBoundary"
import { useEffect } from "react"
import { trackPageView } from "../utils/GTMTracking"

interface ChatsTabProps {
    conversations: number
}
const ChatsTab: React.FunctionComponent<ChatsTabProps> = (props) => {
    const appState = useAppState()

    const errorMessage = branding.communicationArea.chatListErrorMessage
    const reloadButtonText = branding.communicationArea.reloadButtonText

    useEffect(() => {
        trackPageView(branding.communicationArea.chatTabTitle, "List", "Chat")
    }, [])

    if (
        appState.communicationCenterSelectedTab === communicationCenterSelectedTab.CHATS &&
        appState.communicationCenterSelectedSubtab
    ) {
        return (
            <NetworkingTabErrorBoundary
                errorMessage={branding.communicationArea.chatErrorMessage}
                reloadButtonText={reloadButtonText}
            >
                <div style={{ height: "calc(100vh - 100px)" }}>
                    <ChatPage />
                </div>
            </NetworkingTabErrorBoundary>
        )
    }

    return (
        <NetworkingTabErrorBoundary errorMessage={errorMessage} reloadButtonText={reloadButtonText}>
            <ConversationListContent conversations={props.conversations} />
        </NetworkingTabErrorBoundary>
    )
}

export default ChatsTab
