import { Dispatch, SetStateAction, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { CalendarEntryParticipationStatus } from "../../../API"
import {
    CalendarEntry,
    CalendarEntryParticipation,
    createNewCalendarEntry,
    createNewCalendarEntryParticipation,
    deleteCalendarEntryParticipationById,
    updateCalendarEntryById,
    updateCalendarEntryParticipationStatus,
    UserBusyTime
} from "../../../backendServices/GraphQLServices"
import { trackVisit } from "../../../backendServices/TrackingServices"
import { Contact, InvitePerson } from "../../../backendServices/Types"
import { useChimeContext } from "../../../conference/context/ChimeContext"
import { useAppState } from "../../../globalStates/AppState"
import { User } from "../../../globalStates/LoggedInUser"
import { isBeforeToday, isToday, momentWithoutTimezoneFromTimezonedMoment } from "../../../utils/DateUtils"
import { MeetingSlotsDay } from "../../MeetingSlots"
import { Form } from "./CalendarEntryModal.styled"
import { InputProps } from "./CalendarEntryModalData2"
import ModalCustomInput from "./ModalCustomInput"
import {
    calculateDivWidth,
    calculatePositionLeft,
    CalendarEntryModalViewMode,
    getPositionLeft,
    Location,
    resetTimes,
    showButtons
} from "./ModalFunctions"
import moment from "moment"
import branding from "../../../branding/branding"
import { trackTakeAction } from "../../../utils/GTMTracking"
import { Obligations } from "./CalendarEntryModal2"

interface FormProps {
    data: InputProps[]
    setOpenAvailabilityCalendar: Dispatch<SetStateAction<boolean>>
    personClicked?: (person: Contact) => void
    contactsInvited: InvitePerson[]
    setContactsInvited: Dispatch<SetStateAction<InvitePerson[]>>
    meetingOrganizer?: InvitePerson
    user: User | undefined
    predefinedStartTime?: Date
    meetingSlotsTable?: MeetingSlotsDay[]
    viewMode?: CalendarEntryModalViewMode
    predefinedTitle?: string
    setShowErrorModal: Dispatch<SetStateAction<boolean>>
    closeModal: () => void
    startTime: Date | null
    setStartTime: Dispatch<SetStateAction<Date | null>>
    endTime: Date | null
    setEndTime: Dispatch<SetStateAction<Date | null>>
    date: Date | null
    setDate: Dispatch<SetStateAction<Date | null>>
    calendarEntry?: CalendarEntry
    setShowCancelModal: Dispatch<SetStateAction<boolean>>
    title: string
    setTitle: Dispatch<SetStateAction<string>>
    note: string | undefined
    setNote: Dispatch<SetStateAction<string | undefined>>
    setShowDeclineModal: Dispatch<SetStateAction<boolean>>
    setIsVirtualMeeting: Dispatch<SetStateAction<boolean>>
    isVirtualMeeting: boolean
    location?: Location
    setLocation: Dispatch<SetStateAction<Location | undefined>>
    organizationId?: string
    organizationName?: string
    onUpdated?: (calendarEntry: CalendarEntry) => void
    sotUser?: InvitePerson[] | undefined
    setErrorModalMessage: Dispatch<SetStateAction<string>>
    meetings: Obligations[]
    setMeetings: Dispatch<SetStateAction<Obligations[]>>
    times: UserBusyTime[]
    setTimes?: Dispatch<SetStateAction<UserBusyTime[]>>
    positionLeft: number
    timer: number
    canEditMeeting: boolean
    setCanEditMeeting: Dispatch<SetStateAction<boolean>>
    acceptMeeting?: (participation: CalendarEntryParticipation) => void
    showErrorModal?: boolean
}

const CalendarEntryForm = (props: FormProps) => {
    const history = useHistory()
    const chimeContext = useChimeContext()
    const appState = useAppState()
    const org = props.calendarEntry ? props.calendarEntry.organizationId : props.organizationId

    async function createOrUpdateCalendarEntry(timezone: string) {
        if (
            isDateInValid(props.date, props.startTime, props.endTime) ||
            props.title.length === 0 ||
            !isTimeValid(props.startTime) ||
            !isTimeValid(props.endTime, true)
        ) {
            props.setShowErrorModal(true)
            props.setErrorModalMessage(branding.calendarEntryModalPageContent.fieldError)
            return
        }
        if (props.user?.profileId != null && props.startTime != null && props.endTime != null && props.date != null) {
            const startDateMoment = moment(props.date).tz(timezone)
            startDateMoment.set("year", props.date.getFullYear())
            startDateMoment.set("month", props.date.getMonth())
            startDateMoment.set("date", props.date.getDate())
            startDateMoment.set("hour", props.startTime.getHours())
            startDateMoment.set("minute", props.startTime.getMinutes())

            const endDateMoment = moment(props.date).tz(timezone)
            endDateMoment.set("year", props.date.getFullYear())
            endDateMoment.set("month", props.date.getMonth())
            endDateMoment.set("date", props.date.getDate())
            endDateMoment.set("hour", props.endTime.getHours())
            endDateMoment.set("minute", props.endTime.getMinutes())

            const startDate = startDateMoment.toDate()
            const endDate = endDateMoment.toDate()

            var result: CalendarEntry | null = null
            const calendarEntry = props.calendarEntry
            const participantsId = props.contactsInvited.map((contact) => {
                return contact.id
            })
            if (props.viewMode === CalendarEntryModalViewMode.CREATE && props.contactsInvited != null) {
                result = await createNewCalendarEntry(
                    props.user.profileId,
                    participantsId,
                    props.title,
                    startDate,
                    endDate,
                    props.isVirtualMeeting,
                    props.note,
                    org,
                    props.location?.locationName,
                    props.location?.locationActionType ? props.location.locationActionType.toString() : "",
                    props.location?.standId
                )
                // result = await createNewCalendarEntry(profileId, participantsId, title, startDate, endDate, notes, org)
                // TODO create groupchat conversation
            } else if (props.viewMode === CalendarEntryModalViewMode.EDIT && calendarEntry != null) {
                await Promise.all(
                    calendarEntry.participants.items.map(async (oldParticipation) => {
                        // Delete removed participations
                        if (!participantsId.includes(oldParticipation.userId)) {
                            await deleteCalendarEntryParticipationById(oldParticipation.id)
                        } else {
                            // Update existing participation when startDate, endDate or title was changed
                            if (
                                calendarEntry.start !== startDate.toISOString() ||
                                calendarEntry.end !== endDate.toISOString() ||
                                calendarEntry.title !== props.title
                            ) {
                                await updateCalendarEntryParticipationStatus(
                                    oldParticipation.id,
                                    oldParticipation.userId,
                                    startDate
                                )
                            }
                        }
                    })
                )

                const oldParticipantIds = calendarEntry.participants.items.map((participation) => {
                    return participation.userId
                })
                await Promise.all(
                    participantsId.map(async (participantId) => {
                        // Create new participations
                        if (!oldParticipantIds.includes(participantId)) {
                            await createNewCalendarEntryParticipation(
                                calendarEntry.id,
                                participantId,
                                CalendarEntryParticipationStatus.REQUESTED,
                                startDate
                            )
                        }
                    })
                )

                result = await updateCalendarEntryById(
                    calendarEntry,
                    props.title,
                    startDate,
                    endDate,
                    props.isVirtualMeeting,
                    props.note,
                    props.location?.locationName,
                    props.location?.locationActionType ? props.location.locationActionType.toString() : "",
                    props.location?.standId
                )
            }

            if (result != null) {
                if (props.viewMode === CalendarEntryModalViewMode.CREATE && props.organizationId) {
                    trackVisit(props.user.profileId, props.organizationId, "CALENDARENTRY#SENT")

                    trackTakeAction(`Meeting Organization ${props.organizationName}`, "Meeting", "Organization")
                }
                onCloseModalView()
                if (props.onUpdated) {
                    props.onUpdated(result)
                }
            } else {
                // TODO ERROR WHAT TO SHOW HERE?
            }
        }
    }

    function onCloseModalView() {
        if (props.user?.profileId === undefined) {
            return
        }
        if (props.viewMode === CalendarEntryModalViewMode.CREATE) {
            props.setContactsInvited(
                props.sotUser
                    ? ([props.meetingOrganizer].concat(props.sotUser) as InvitePerson[])
                    : ([props.meetingOrganizer] as InvitePerson[])
            )
            props.setNote("")
            props.setTitle("")
            if (props.calendarEntry) {
                props.setStartTime(moment(props.calendarEntry.start).toDate())
                props.setEndTime(moment(props.calendarEntry.end).toDate())
                props.setDate(moment(props.calendarEntry.start).toDate())
            } else {
                resetTimes(props.setStartTime, props.setEndTime)
                props.setDate(new Date())
            }
        }
        props.closeModal()
    }

    function isDateInValid(day: Date | null, startDate: Date | null, endDate: Date | null): Boolean {
        if (day == null || startDate == null || endDate == null) {
            return true
        }
        // cannot create meeting in the past
        if (isBeforeToday(moment(day))) return true
        return startDate > endDate || endDate < startDate
    }
    const isTimeValid = (time: Date | null, isEndTime?: boolean) => {
        const isTodayTemp = isToday(moment(props.date))
        // this check is used only when available meeting slots table of the user is passed to calendar entry component
        // if (props.meetingSlotsTable && props.meetingSlotsTable.length > 0 && time) return isTimeSlotAvailable(time, isEndTime)
        return (
            time !== null &&
            ((isTodayTemp && moment(time).isAfter(momentWithoutTimezoneFromTimezonedMoment(moment(), appState.timezone))) ||
                !isTodayTemp)
        )
    }

    useEffect(() => {
        props.meetings?.map((meet) =>
            // eslint-disable-next-line
            meet.userBusyTimes.items?.map((item) => {
                props.setTimes!((times) => [...times, item as unknown as UserBusyTime])
            })
        )
        // eslint-disable-next-line
    }, [props.meetings])

    let filteredUsers: UserBusyTime[] = []

    filteredUsers = props.times.filter(
        (time) =>
            getPositionLeft(time.start) === calculatePositionLeft(props.startTime, props.positionLeft, "start") ||
            (getPositionLeft(time.start) < calculatePositionLeft(props.startTime, props.positionLeft, "start") &&
                getPositionLeft(time.end) > calculatePositionLeft(props.startTime, props.positionLeft, "start")) ||
            (getPositionLeft(time.start) > calculatePositionLeft(props.startTime, props.positionLeft, "start") &&
                calculatePositionLeft(props.startTime, props.positionLeft, "start") + calculateDivWidth(props.timer) >
                    getPositionLeft(time.start))
    )

    let copy = filteredUsers.filter(
        (value, index, self) =>
            index === self.findIndex((t) => t.userId === value.userId && t.start === value.start && t.end === value.end)
    )

    let userStatus
    let searchBarPickerUserStatus: CalendarEntryParticipationStatus

    props.calendarEntry?.participants.items.forEach((item) => {
        if (item.userId === props.user?.profileId) {
            userStatus = item
            searchBarPickerUserStatus = item.status
        }
    })

    let isUserWithRequestedStatus = props.calendarEntry?.participants.items.find((item) => item.userId === props.user?.profileId)
    let isPastMeeting = moment(props.endTime).isBefore()

    return (
        <Form>
            {props.data?.map((item, index) => (
                <ModalCustomInput
                    key={index}
                    item={item}
                    setOpenAvailabilityCalendar={props.setOpenAvailabilityCalendar}
                    personClicked={props.personClicked}
                    contactsInvited={props.contactsInvited}
                    setContactsInvited={props.setContactsInvited}
                    meetingOrganizer={props.meetingOrganizer}
                    user={props.user}
                    predefinedStartTime={props.predefinedStartTime}
                    meetingSlotsTable={props.meetingSlotsTable}
                    viewMode={props.viewMode}
                    date={props.date}
                    setDate={props.setDate}
                    startTime={props.startTime}
                    setStartTime={props.setStartTime}
                    endTime={props.endTime}
                    setEndTime={props.setEndTime}
                    title={props.title}
                    setTitle={props.setTitle}
                    setShowErrorModal={props.setShowErrorModal}
                    setNote={props.setNote}
                    note={props.note}
                    calendarEntry={props.calendarEntry}
                    closeModal={props.closeModal}
                    setIsVirtualMeeting={props.setIsVirtualMeeting}
                    isVirtualMeeting={props.isVirtualMeeting}
                    location={props.location}
                    setLocation={props.setLocation}
                    setErrorModalMessage={props.setErrorModalMessage}
                    copy={copy}
                    canEditMeeting={props.canEditMeeting}
                    setCanEditMeeting={props.setCanEditMeeting}
                    meetings={props.meetings}
                    showErrorModal={props.showErrorModal}
                    userStatus={searchBarPickerUserStatus}
                />
            ))}
            {showButtons(
                props.canEditMeeting ? CalendarEntryModalViewMode.EDIT : props.viewMode,
                props.closeModal,
                props.calendarEntry,
                props.user,
                props.setShowDeclineModal,
                chimeContext,
                history,
                props.setShowCancelModal,
                createOrUpdateCalendarEntry,
                appState.timezone,
                userStatus,
                props.acceptMeeting,
                isUserWithRequestedStatus,
                isPastMeeting
            )}
        </Form>
    )
}

export default CalendarEntryForm
