import styled from "styled-components"
import branding from "../../../branding/branding"

export const DeviceSettingsRoot = styled.div`
    font-family: ${branding.font1};
    color: #fff !important; // dark mode only currently
    label {
        color: #fff;
    }
    select {
        color: #fff !important;
        border-color: #17181a;
        background-color: #17181a;
        color: #fff;
        outline: none !important;
        box-shadow: none !important;
        border: none !important;
    }
`
export const SettingsGroup = styled.div`
    margin-bottom: 35px;
`
export const TestSpeakerButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const DeviceMessageContainer = styled.div`
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    flex-direction: column;
`

export const DeviceMessage = styled.div`
    font-size: 16px;
    color: #fff;
    line-height: 18px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 15px;
`
