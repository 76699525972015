import { Link } from "react-router-dom"
import branding from "../../../../branding/branding"

interface BottomMenuItemProps {
    isExternalLink: boolean
    link: string
    title: string
    index: number
    onClick: () => void
}

const BottomMenuItem: React.FunctionComponent<BottomMenuItemProps> = (props: BottomMenuItemProps) => {
    const hideTheDot: boolean = props.index === branding.sideIconBar.sideIconBarFooterItemsOrder.length - 1

    return (
        <div onClick={() => props.onClick()}>
            {props.isExternalLink ? (
                <a href={props.link} target="_blank" rel="noopener noreferrer">
                    {props.title}
                    <p style={{ display: hideTheDot ? "none" : "inline-block", marginLeft: "5px", marginRight: "5px" }}>•</p>
                </a>
            ) : (
                <Link to={props.link}>
                    {props.title}
                    <p style={{ display: hideTheDot ? "none" : "inline-block", marginLeft: "5px", marginRight: "5px" }}>•</p>
                </Link>
            )}
        </div>
    )
}

export default BottomMenuItem
