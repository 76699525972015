import styled from "styled-components"
import * as React from "react"
import branding from "../branding/branding"
import Slider from "rc-slider"
import "rc-slider/assets/index.css"

const ScrollBar = styled(Slider)`
    .rc-slider-track {
        background-color: ${branding.customScrollbarTrackBgColor};
        height: 6px;
        z-index: 2;
    }

    .rc-slider-rail,
    .rc-slider-step {
        height: 6px;
        background-color: #d9d9d9;
    }

    .rc-slider-handle,
    .rc-slider-handle:hover,
    .rc-slider-handle:active,
    .rc-slider-handle:focus {
        background-color: ${branding.customScrollbarBgColor ?? "#FFF"};
        border-color: ${branding.customScrollbarBorderColor ?? "#000"};
        width: ${branding.customScrollbarWidth ?? "14px"};
        height: ${branding.customScrollbarHeight ?? "14px"};
        outline: none;
        box-shadow: none;
        margin: 0;
        top: 50%;
        transform: translate(-50%, -50%) !important;
        z-index: 2;
    }

    .rc-slider-step {
        border-radius: 25%;
    }

    flex: 1;
`
const ScrollBarVertical = styled(Slider)<{ visibility?: boolean }>`
    height: 100%;
    visibility: ${(props) => (props.visibility ? "visible" : "hidden")};

    .rc-slider-track {
        background-color: ${branding.customScrollbarTrackBgColor};
        width: 6px;
        z-index: 2;
        left: 0;
    }

    .rc-slider-step {
        border-radius: 25%;
    }

    .rc-slider-rail,
    .rc-slider-step {
        width: 6px !important;
        background-color: #f2f2f5;
    }

    .rc-slider-handle,
    .rc-slider-handle:hover,
    .rc-slider-handle:active,
    .rc-slider-handle:focus {
        background-color: ${branding.customScrollbarBgColor ?? "#FFF"};
        border-color: ${branding.customScrollbarBorderColor ?? "#000"};
        width: ${branding.customScrollbarWidth ?? "14px"};
        height: ${branding.customScrollbarHeight ?? "14px"};
        outline: none;
        box-shadow: none;
        margin: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }
`

const ScrollBarRoot = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;

    .rc-slider {
        height: 6px;
        padding: 0;
        margin: 0 !important;
    }
`

const VerticalBarRoot = styled.div`
    position: absolute;
    right: 10px;
    bottom: 0;
    height: 80%;
`

const VerticalBarDiv = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .rc-slider {
        width: 6px;
        padding: 0;
        margin: 0 !important;
    }
`

interface ScrollBarComponentProps {
    marginLeft?: string
    marginRight?: string
    maxScrollRange: number
    value: number
    onScroll: (position: number) => void
    isVertical?: boolean
    visiblity?: boolean
}

const ScrollBarComponent: React.FunctionComponent<ScrollBarComponentProps> = (props) => {
    const vertical = props.isVertical ? props.isVertical : false
    if (props.maxScrollRange && props.maxScrollRange > 0) {
        if (!vertical) {
            return (
                <ScrollBarRoot>
                    <ScrollBar
                        value={props.value}
                        style={{ marginRight: props.marginRight }}
                        min={0}
                        max={props.maxScrollRange}
                        onChange={props.onScroll}
                    ></ScrollBar>
                </ScrollBarRoot>
            )
        } else {
            return (
                <VerticalBarRoot className="verticalBarRoot">
                    <VerticalBarDiv>
                        <ScrollBarVertical
                            value={props.value}
                            min={0}
                            max={props.maxScrollRange}
                            onChange={props.onScroll}
                            vertical
                            reverse
                            visibility={props.visiblity !== undefined ? props.visiblity : true}
                        ></ScrollBarVertical>
                    </VerticalBarDiv>
                </VerticalBarRoot>
            )
        }
    } else {
        return <></>
    }
}

export default ScrollBarComponent
