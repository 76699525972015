import { useLocation, useRouteMatch } from "react-router-dom"
import { meetingPageRoute } from "../../RoutePaths"
import { useAppState } from "../../../globalStates/AppState"
import { useEffect } from "react"
import moment from "moment"
import { SideBarConfig, SideIconBarItemKeys, checkActiveItem } from "../SIdeIconBarConfig"
import { WrapperContainer } from "./MenuDrawerLayout.styled"
import { ListGroup } from "react-bootstrap"
import branding from "../../../branding/branding"
import ListItemCustom from "../listItemCustom/ListItemCustom"
import { trackSelectContent } from "../../../utils/GTMTracking"
import SponsorsList from "../sponsorsList/SponsorsList"
import BottomMenu from "../bottomMenu/BottomMenu"
import SpecialSponsorLogo from "../specialSponsorLogo/SpecialSponsorLogo"

interface MenuDrawerLayoutProps {
    isNavOpen: boolean
    receptionPageToggle: (route: string) => void
    myToggle: string
    myIcon: any
    logo: any
    setTrigger: (trigger: number) => void
    contentAreaScrollable: boolean
    hideOnScrollEnd: boolean
}
const MenuDrawerLayout: React.FunctionComponent<MenuDrawerLayoutProps> = (props: MenuDrawerLayoutProps) => {
    const location = useLocation().pathname.substring(1)
    const appState = useAppState()

    let isMeetingPage = useRouteMatch(meetingPageRoute)

    let isNavOpen = props.isNavOpen ? true : false

    useEffect(() => {
        if (
            !(
                (location.includes("person") || location.includes("user")) &&
                (appState.currentItem === "program" || appState.currentItem === "organization")
            )
        ) {
            appState.setCurrentItem(getCurrentItem(location, props.receptionPageToggle))
        }
        // eslint-disable-next-line
    }, [location])

    function updateCurrentItem(item: string, receptionPageToggle: string) {
        appState.setCurrentItem(item)

        props.receptionPageToggle(receptionPageToggle)

        if (receptionPageToggle.length > 0) {
            props.setTrigger(moment().valueOf())
        }
    }

    const foregroundColor = isMeetingPage ? "#fff" : branding.primaryColor

    const sideBarItems = SideBarConfig(appState.currentItem, isMeetingPage ? true : false, props.myToggle, isNavOpen, false)

    return (
        <>
            <WrapperContainer isNavOpen={props.isNavOpen}>
                <ListGroup style={{ lineHeight: "18px" }}>
                    <br />
                    {/* sidebar navigation items */}
                    {branding.sideIconBar.sideIconBarItemsOrder.map((item) => {
                        const sideBarItem = sideBarItems.filter((x) => x.id === item)[0]
                        if (sideBarItem) {
                            const selected = checkActiveItem(
                                sideBarItem.key,
                                sideBarItem.lobbyCheck ?? false,
                                sideBarItem.checkString ?? "",
                                appState.currentItem,
                                props.myToggle,
                                false
                            )

                            const updateCurrentItemParam = sideBarItem.lobbyCheck ? sideBarItem.checkString! : ""

                            return (
                                <ListItemCustom
                                    key={sideBarItem.id}
                                    id={sideBarItem.id}
                                    path={sideBarItem.path}
                                    icon={sideBarItem.icon}
                                    textVisible={props.isNavOpen}
                                    isNavOpen={props.isNavOpen}
                                    title={sideBarItem.title}
                                    shortName={sideBarItem.shortName}
                                    selected={selected}
                                    isExternalLink={sideBarItem.isExternalLink}
                                    customUrl={sideBarItem.customUrl}
                                    onClick={(e: any) => {
                                        // reset the last visited tab
                                        if (appState) {
                                            appState.setLastVisitedTab(null)
                                        }

                                        if (!sideBarItem.isExternalLink) {
                                            updateCurrentItem(sideBarItem.key, updateCurrentItemParam)
                                        }

                                        trackSelectContent(sideBarItem.title, "Navigation", "Navigation")
                                        e.stopPropagation()
                                    }}
                                />
                            )
                        }

                        return null
                    })}

                    {/* top sponsors */}
                    {branding.sideIconBar.topSponsorsVisible && (
                        <SponsorsList
                            title={branding.sideIconBar.topSponsorsText}
                            items={branding.topSponsors}
                            isNavOpen={props.isNavOpen}
                            onClick={(e: any) => {
                                updateCurrentItem(SideIconBarItemKeys.organization, "")

                                e.stopPropagation()
                            }}
                        />
                    )}

                    {/* idea sponsors */}
                    {branding.sideIconBar.ideaSponsorsVisible && (
                        <SponsorsList
                            title={branding.sideIconBar.ideaSponsorsText}
                            items={branding.ideaSponsors}
                            isNavOpen={props.isNavOpen}
                            onClick={(e: any) => {
                                updateCurrentItem(SideIconBarItemKeys.organization, "")

                                e.stopPropagation()
                            }}
                        />
                    )}

                    {/* special sponsor's logo */}
                    {branding.sideIconBar.specialSponsorLogoVisible && <SpecialSponsorLogo isNavOpen={props.isNavOpen} />}
                </ListGroup>

                {/* bottom menu (language switcher, help, rules, imprint, terms of use ) */}
                <BottomMenu
                    isNavOpen={props.isNavOpen}
                    foregroundColor={foregroundColor}
                    contentAreaScrollable={props.contentAreaScrollable}
                    hideOnScrollEnd={props.hideOnScrollEnd}
                />
            </WrapperContainer>
        </>
    )
}

export function getCurrentItem(location: string, receptionPageToggle: (value: string) => void): string {
    if (location.endsWith("sponsor")) return "sponsor"
    if (location.endsWith("news")) return "news"
    else if (location.includes("channel")) return "program"
    else if (
        location.endsWith("myprofile") ||
        location.endsWith("myspeakers") ||
        location.endsWith("myexhibitors") ||
        location.endsWith("myproducts") ||
        location.endsWith("mytrademarks") ||
        location.endsWith("mynews") ||
        ((location.includes("person") || location.includes("user")) && location.includes("meeting") === false)
    ) {
        receptionPageToggle("MY_FAIR")
        return "home"
    } else if (
        location.endsWith("showfloor") ||
        location.includes("company") ||
        location.includes("product") ||
        location.includes("trademark") ||
        location.includes("news") ||
        location.includes("joboffer")
    )
        return "organization"
    else if (location.endsWith("coupon")) return "coupon"
    else if (location.includes("meetings") || location.includes("meeting")) return "meetings"
    else if (location.includes("collections")) return "collections"
    else if (location.endsWith("press") || location.includes("mediapartner")) return "press_media"
    else if (location.endsWith("business")) return "business"
    else if (location.includes("iframetest")) return "iframe_test"
    else if (location.endsWith("help")) return "help"
    else if (location.endsWith("startup")) return "startup"
    else if (location.includes("hallplan")) return "hallplan"
    else if (location.endsWith("myschedule")) {
        return "my_schedule"
    } else if (
        location.endsWith("help") ||
        location.endsWith("rules") ||
        location.endsWith("info") ||
        location.endsWith("termsofuse")
    ) {
        receptionPageToggle("LOBBY")
        return "home"
    } else if (
        location.includes("eventdate") ||
        location.includes("event") ||
        location.endsWith("program") ||
        location.includes("channel")
    )
        return "program"
    else if (location.includes("results")) return ""
    // to deselect all side icon bar items when user is on global search page
    else return "home"
}

export default MenuDrawerLayout
