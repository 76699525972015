import ChatMessageElementDefault from "./ChatMessageElementDefault"
import ChatMessageElementPinboard from "./ChatMessageElementPinboard"
import { DisplayStyle } from "./ChatPage"
import { ChatMessageItem } from "./ChatPageMessageList"

interface ChatItemProps {
    chatMessageItem: ChatMessageItem
    displayStyle?: DisplayStyle
    setShowContactModal: (show: boolean) => void
    setTargetId: (id: string) => void

    isPublic?: boolean
}

const ChatMessageElement: React.FC<ChatItemProps> = (props) => {
    return props.displayStyle === DisplayStyle.PINBOARD ? (
        <ChatMessageElementPinboard chatMessageItem={props.chatMessageItem} />
    ) : (
        <ChatMessageElementDefault
            chatMessageItem={props.chatMessageItem}
            dark={props.displayStyle === DisplayStyle.DEFAULT_DARK}
            setTargetId={props.setTargetId}
            setShowContactModal={props.setShowContactModal}
            isPublic={props.isPublic}
        />
    )
}

export default ChatMessageElement
