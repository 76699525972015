import { Tooltip } from "react-bootstrap"
import { CustomOverlayTrigger } from "../../../ui/CustomOverlayTrigger"
import { ControlBarContainer, ControlButtonRoot, ControlButtons } from "./ControlBar.styled"
import { Camera, Chat, Cog, Microphone, Phone, Sound } from "amazon-chime-sdk-component-library-react"
import { useControlBarContext } from "../../context/ControlBarContext"
import { useContext } from "react"
import { RaisedHandContext } from "../../ConferenceRoom/ConferenceRoom"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import { IconLowerHandControls, IconRaiseHandControls, IconStartScreenShare, IconStopScreenShare } from "../../../ui/Icons"
import branding from "../../../branding/branding"
import { useVideoContext } from "../../context/VideoContext"
import { useAudioContext } from "../../context/AudioContext"

function ControlBar() {
    const loggedInUser = useLoggedInState().user()?.profileId || ""
    const isHandRaised = useContext(RaisedHandContext)?.includes(loggedInUser)
    const { isVideoEnabled, isContentShareEnabled, toggleVideo, toggleContentShare } = useVideoContext()
    const { isMuted, isAudioOn, toggleMute, toggleAudio } = useAudioContext()
    const { toggleSettings, leave, raiseHand, showChat } = useControlBarContext()

    const microphoneIcon = <Microphone muted={isMuted()} color={!isMuted() ? "#202428" : "#fff"} width="28px" />
    const soundIcon = <Sound disabled={!isAudioOn()} color={isAudioOn() ? "#202428" : "#fff"} width="28px" />
    const cameraIcon = <Camera disabled={!isVideoEnabled()} color={isVideoEnabled() ? "#202428" : "#fff"} width="28px" />
    const screenShareIcon = !isContentShareEnabled() ? (
        <IconStartScreenShare stroke={"#fff"} fill={"#fff"} width="18px" height="18px" />
    ) : (
        <IconStopScreenShare stroke={"#202428"} fill={"#202428"} width="19px" height="19px" />
    )
    const settingsIcon = <Cog color="#fff" width="28px" />
    const handRaiseIcon = !isHandRaised ? (
        <IconRaiseHandControls stroke="#fff" fill="#fff" width="18px" height="18px" />
    ) : (
        <IconLowerHandControls stroke={"#202428"} fill={"#202428"} width="22px" height="22px" />
    )
    const chatIcon = <Chat color="#fff" width="28px" />
    const phoneIcon = <Phone color="#fff" width="28px" />
    return (
        <ControlBarContainer>
            <ControlButtons>
                <ControlButton
                    label={isMuted() ? branding.conferenceTexts.unmute : branding.conferenceTexts.mute}
                    active={!isMuted()}
                    onClick={toggleMute}
                    icon={microphoneIcon}
                />
                <ControlButton
                    label={!isAudioOn() ? branding.conferenceTexts.soundOff : branding.conferenceTexts.soundOn}
                    active={isAudioOn()}
                    onClick={toggleAudio}
                    icon={soundIcon}
                />
                <ControlButton
                    label={isVideoEnabled() ? branding.conferenceTexts.videoOn : branding.conferenceTexts.videoOff}
                    active={isVideoEnabled()}
                    onClick={toggleVideo}
                    icon={cameraIcon}
                />
                <ControlButton
                    label={
                        isContentShareEnabled()
                            ? branding.conferenceTexts.shareScreenStop
                            : branding.conferenceTexts.shareScreenStart
                    }
                    active={isContentShareEnabled()}
                    onClick={toggleContentShare}
                    icon={screenShareIcon}
                />
                <ControlButton label={branding.conferenceTexts.settings} onClick={toggleSettings} icon={settingsIcon} />
                <ControlButton
                    label={isHandRaised ? branding.conferenceTexts.lowerHand : branding.conferenceTexts.raiseHand}
                    onClick={raiseHand}
                    icon={handRaiseIcon}
                    active={isHandRaised}
                />
                <ControlButton label={branding.conferenceTexts.openChat} icon={chatIcon} onClick={showChat} />
                <ControlButton
                    label={branding.conferenceTexts.leave}
                    onClick={leave}
                    backgroundColor={"#E30613"}
                    icon={phoneIcon}
                />
            </ControlButtons>
        </ControlBarContainer>
    )
}

export enum ControlButtonSize {
    Small = "SMALL",
    Medium = "MEDIUM"
}

interface ControlButtonProps {
    label: string
    icon: any
    backgroundColor?: string
    onClick?: () => void
    size?: ControlButtonSize | null
    active?: boolean
}

export function ControlButton(props: ControlButtonProps) {
    return (
        <div>
            <CustomOverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="settings-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                        {props.label}
                    </Tooltip>
                }
            >
                <ControlButtonRoot
                    isActive={props.active}
                    size={props.size}
                    onClick={props.onClick}
                    backgroundColor={props.backgroundColor}
                >
                    {props.icon}
                </ControlButtonRoot>
            </CustomOverlayTrigger>
        </div>
    )
}

export default ControlBar
