import { Branding } from "../../branding/branding"
import { User } from "../../globalStates/LoggedInUser"
import {
    IconFacebookOriginal,
    IconInstagramOriginal,
    IconLinkedInOriginal,
    IconPinterestOrginal,
    IconTikTokOriginal,
    IconXOriginal,
    IconXingOriginal,
    IconYouTubeOriginal,
    VGIcon
} from "../../ui/Icons"
import { SocialMediaType } from "./MyProfilePageContentBranding"

interface SocialMediaItemType {
    labelText: string
    type: SocialMediaType
    value: string
    icon: VGIcon
}
export enum SocialMediaLabel {
    Facebook = "Facebook",
    X = "X",
    LinkedIn = "LinkedIn",
    Xing = "Xing",
    Instagram = "Instagram",
    YouTube = "YouTube",
    Pinterest = "Pinterest",
    TikTok = "TikTok"
}

export const getSocialMediaItemsConfig = (branding: Branding, user: User): SocialMediaItemType[] => {
    return [
        {
            labelText: SocialMediaLabel.Facebook,
            type: SocialMediaType.FACEBOOK,
            value: user.facebook ?? "",
            icon: IconFacebookOriginal({
                width: "20",
                height: "20"
            })
        },
        {
            labelText: SocialMediaLabel.X,
            type: SocialMediaType.TWITTER,
            value: user.twitter ?? "",
            icon: IconXOriginal({
                width: "20",
                height: "20"
            })
        },
        {
            labelText: SocialMediaLabel.LinkedIn,
            type: SocialMediaType.LINKEDIN,
            value: user.linkedIn ?? "",
            icon: IconLinkedInOriginal({
                width: "20",
                height: "20"
            })
        },
        {
            labelText: SocialMediaLabel.Xing,
            type: SocialMediaType.XING,
            value: user.xing ?? "",
            icon: IconXingOriginal({
                width: "20",
                height: "20"
            })
        },
        {
            labelText: SocialMediaLabel.Instagram,
            type: SocialMediaType.INSTAGRAM,
            value: user.instagram ?? "",
            icon: IconInstagramOriginal({
                width: "20",
                height: "20"
            })
        },
        {
            labelText: SocialMediaLabel.YouTube,
            type: SocialMediaType.YOUTUBE,
            value: user.youTube ?? "",
            icon: IconYouTubeOriginal({
                width: "20",
                height: "20"
            })
        },
        {
            labelText: SocialMediaLabel.Pinterest,
            type: SocialMediaType.PINTEREST,
            value: user.pinterest ?? "",
            icon: IconPinterestOrginal({
                width: "20",
                height: "20"
            })
        },
        {
            labelText: SocialMediaLabel.TikTok,
            type: SocialMediaType.TIKTOK,
            value: user.tiktok ?? "",
            icon: IconTikTokOriginal({
                width: "20",
                height: "20"
            })
        }
    ]
}

// method for repalcing youTube & linkedIn socialMediaKey for updateValue inside function getSocialMediaFields()
export const mapSocialMediaKey = (type: SocialMediaType) => {
    switch (type) {
        case SocialMediaType.YOUTUBE:
            return "youTube"
        case SocialMediaType.LINKEDIN:
            return "linkedIn"
        default:
            return type.toLowerCase()
    }
}
