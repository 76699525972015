import branding from "../../../branding/branding"
import { IconBookmark, IconBookmarkFilled } from "../../../ui/Icons"
import { OnlyBookmarksButtonRoot } from "./OnlyBookmarksButton.styled"

interface OnlyBookmarksButtonProps {
    updateBookmarkFilter: () => void
    bookmarkFilter: boolean
}

export const OnlyBookmarksButton: React.FunctionComponent<OnlyBookmarksButtonProps> = (props: OnlyBookmarksButtonProps) => {
    return (
        <OnlyBookmarksButtonRoot onClick={() => props.updateBookmarkFilter()}>
            {props.bookmarkFilter
                ? IconBookmarkFilled({ fill: branding.sideIconBar.sideIconColorDark, width: "15", height: "15" })
                : IconBookmark({ fill: branding.sideIconBar.sideIconColorDark, width: "15", height: "15" })}
        </OnlyBookmarksButtonRoot>
    )
}
