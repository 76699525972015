import styled from "styled-components"
import branding from "../../../branding/branding"

export const LocalVideoRoot = styled.div<{ isLocalTileVisibleToUser: boolean }>`
    font-family: ${branding.font1};
    width: 200px;
    height: ${(props) => (props.isLocalTileVisibleToUser ? "150px" : "0px")};
    position: absolute;
    right: 15px;
    bottom: 115px;
    background-color: #37383a;
    border: ${(props) => (props.isLocalTileVisibleToUser ? "1px solid #000" : "none")};
    z-index: 10;
    overflow: hidden;
`
