/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const _emptyQuery = /* GraphQL */ `
  query _emptyQuery {
    _emptyQuery
  }
`;
export const batchGetUserPresence = /* GraphQL */ `
  query BatchGetUserPresence($listIds: [String]) {
    batchGetUserPresence(listIds: $listIds) {
      conversations {
        nextToken
      }
      createdAt
      id
      incomingMeetings {
        nextToken
      }
      lastConnected
      name
      outgoingMeetings {
        nextToken
      }
      pictureUrl
      presenceStatus
      updatedAt
    }
  }
`;
export const byActionType = /* GraphQL */ `
  query ByActionType(
    $actionType: UserActionType
    $duration: ModelIntKeyConditionInput
    $filter: ModelUserActionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    byActionType(
      actionType: $actionType
      duration: $duration
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        actionType
        countryCode
        createdAt
        duration
        endTime
        id
        param
        sotName
        source
        startTime
        topic
        updatedAt
        userId
        userType
      }
      nextToken
    }
  }
`;
export const calendarEntryByOrganizationAndStatusSorted = /* GraphQL */ `
  query CalendarEntryByOrganizationAndStatusSorted(
    $filter: ModelCalendarEntryFilterInput
    $limit: Int
    $nextToken: String
    $organizationIdStatus: String
    $sortDirection: ModelSortDirection
    $start: ModelStringKeyConditionInput
  ) {
    calendarEntryByOrganizationAndStatusSorted(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      organizationIdStatus: $organizationIdStatus
      sortDirection: $sortDirection
      start: $start
    ) {
      items {
        createdAt
        description
        end
        id
        isVirtual
        locationActionParam
        locationActionType
        locationName
        organizationId
        organizationIdStatus
        start
        status
        title
        topicName
        updatedAt
        userId
        userPoolName
      }
      nextToken
    }
  }
`;
export const calendarEntryParticipationByUserAndStatusSorted = /* GraphQL */ `
  query CalendarEntryParticipationByUserAndStatusSorted(
    $filter: ModelCalendarEntryParticipationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $start: ModelStringKeyConditionInput
    $userIdStatus: String!
  ) {
    calendarEntryParticipationByUserAndStatusSorted(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      start: $start
      userIdStatus: $userIdStatus
    ) {
      items {
        calendarEntryId
        createdAt
        id
        start
        status
        statusUpdateMessage
        updatedAt
        userId
        userIdStatus
      }
      nextToken
    }
  }
`;
export const calendarEntryParticipationByUserSorted = /* GraphQL */ `
  query CalendarEntryParticipationByUserSorted(
    $filter: ModelCalendarEntryParticipationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $start: ModelStringKeyConditionInput
    $userId: String!
  ) {
    calendarEntryParticipationByUserSorted(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      start: $start
      userId: $userId
    ) {
      items {
        calendarEntryId
        createdAt
        id
        start
        status
        statusUpdateMessage
        updatedAt
        userId
        userIdStatus
      }
      nextToken
    }
  }
`;
export const getCalendarEntry = /* GraphQL */ `
  query GetCalendarEntry($id: ID!) {
    getCalendarEntry(id: $id) {
      createdAt
      description
      end
      id
      isVirtual
      locationActionParam
      locationActionType
      locationName
      organizationId
      organizationIdStatus
      participants {
        nextToken
      }
      start
      status
      title
      topicName
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userPoolName
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      createdAt
      description
      id
      memberIdsHash
      members {
        nextToken
      }
      messages {
        nextToken
        startedAt
      }
      mostRecentMessage
      mostRecentMessageCreatedAt
      name
      type
      updatedAt
      userId
    }
  }
`;
export const getConversationsByMembers = /* GraphQL */ `
  query GetConversationsByMembers(
    $filter: ModelConversationFilterInput
    $limit: Int
    $memberId1: ID!
    $memberId2: ID!
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    getConversationsByMembers(
      filter: $filter
      limit: $limit
      memberId1: $memberId1
      memberId2: $memberId2
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      nextToken
    }
  }
`;
export const getCurrentLocationCounts = /* GraphQL */ `
  query GetCurrentLocationCounts($id: ID!) {
    getCurrentLocationCounts(id: $id) {
      cnt
      createdAt
      id
      updatedAt
    }
  }
`;
export const getMeeting = /* GraphQL */ `
  query GetMeeting($id: ID!) {
    getMeeting(id: $id) {
      createdAt
      end
      id
      participants {
        nextToken
      }
      start
      updatedAt
    }
  }
`;
export const getMeetingRoomAttendance = /* GraphQL */ `
  query GetMeetingRoomAttendance($id: ID!) {
    getMeetingRoomAttendance(id: $id) {
      chimeMeetingId
      createdAt
      id
      updatedAt
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      _deleted
      _lastChangedAt
      _version
      author {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      authorId
      content
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isSent
      members
      sotName
      updatedAt
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      actionParam
      actionType
      content
      createdAt
      displayGroup
      id
      read
      type
      updatedAt
      userId
      userIdDisplayGroup
    }
  }
`;
export const getRaisedHand = /* GraphQL */ `
  query GetRaisedHand($id: ID!) {
    getRaisedHand(id: $id) {
      chimeMeetingId
      createdAt
      id
      updatedAt
    }
  }
`;
export const getUnreadCounter = /* GraphQL */ `
  query GetUnreadCounter($id: ID!) {
    getUnreadCounter(id: $id) {
      contacts
      conversations
      createdAt
      id
      notifications
      requests
      schedules
      updatedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      conversations {
        nextToken
      }
      createdAt
      id
      incomingMeetings {
        nextToken
      }
      lastConnected
      name
      outgoingMeetings {
        nextToken
      }
      pictureUrl
      presenceStatus
      updatedAt
    }
  }
`;
export const getUserAction = /* GraphQL */ `
  query GetUserAction($id: ID!) {
    getUserAction(id: $id) {
      actionType
      countryCode
      createdAt
      duration
      endTime
      id
      param
      sotName
      source
      startTime
      topic
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userType
    }
  }
`;
export const getUserConversation = /* GraphQL */ `
  query GetUserConversation($id: ID!) {
    getUserConversation(id: $id) {
      conversation {
        createdAt
        description
        id
        memberIdsHash
        mostRecentMessage
        mostRecentMessageCreatedAt
        name
        type
        updatedAt
        userId
      }
      conversationId
      createdAt
      id
      isChatOpen
      isMuted
      lastReadMessageCreatedAt
      mostRecentMessageCreatedAt
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
    }
  }
`;
export const getUserSession = /* GraphQL */ `
  query GetUserSession($id: ID!) {
    getUserSession(id: $id) {
      countryCode
      createdAt
      id
      location
      locationLevel1
      locationLevel2
      locationLevel3
      queryHelper
      sotName
      source
      time
      topic
      ttl
      updatedAt
      user {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      userId
      userType
    }
  }
`;
export const listCurrentLocationCountss = /* GraphQL */ `
  query ListCurrentLocationCountss(
    $filter: ModelCurrentLocationCountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurrentLocationCountss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        cnt
        createdAt
        id
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMeetingRoomAttendances = /* GraphQL */ `
  query ListMeetingRoomAttendances(
    $filter: ModelMeetingRoomAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingRoomAttendances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        chimeMeetingId
        createdAt
        id
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMeetings = /* GraphQL */ `
  query ListMeetings(
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        end
        id
        start
        updatedAt
      }
      nextToken
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        actionParam
        actionType
        content
        createdAt
        displayGroup
        id
        read
        type
        updatedAt
        userId
        userIdDisplayGroup
      }
      nextToken
    }
  }
`;
export const listRaisedHands = /* GraphQL */ `
  query ListRaisedHands(
    $filter: ModelRaisedHandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRaisedHands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        chimeMeetingId
        createdAt
        id
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUserActions = /* GraphQL */ `
  query ListUserActions(
    $filter: ModelUserActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        actionType
        countryCode
        createdAt
        duration
        endTime
        id
        param
        sotName
        source
        startTime
        topic
        updatedAt
        userId
        userType
      }
      nextToken
    }
  }
`;
export const listUserSessions = /* GraphQL */ `
  query ListUserSessions(
    $filter: ModelUserSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        countryCode
        createdAt
        id
        location
        locationLevel1
        locationLevel2
        locationLevel3
        queryHelper
        sotName
        source
        time
        topic
        ttl
        updatedAt
        userId
        userType
      }
      nextToken
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        lastConnected
        name
        pictureUrl
        presenceStatus
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByConversationId = /* GraphQL */ `
  query MessagesByConversationId(
    $conversationId: ID!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    messagesByConversationId(
      conversationId: $conversationId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        _deleted
        _lastChangedAt
        _version
        authorId
        content
        conversationId
        createdAt
        id
        isSent
        members
        sotName
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const notificationsByUserId = /* GraphQL */ `
  query NotificationsByUserId(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userId: ID
  ) {
    notificationsByUserId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      userId: $userId
    ) {
      items {
        actionParam
        actionType
        content
        createdAt
        displayGroup
        id
        read
        type
        updatedAt
        userId
        userIdDisplayGroup
      }
      nextToken
    }
  }
`;
export const notificationsByUserIdAndDisplayGroupSorted = /* GraphQL */ `
  query NotificationsByUserIdAndDisplayGroupSorted(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userIdDisplayGroup: String
  ) {
    notificationsByUserIdAndDisplayGroupSorted(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      userIdDisplayGroup: $userIdDisplayGroup
    ) {
      items {
        actionParam
        actionType
        content
        createdAt
        displayGroup
        id
        read
        type
        updatedAt
        userId
        userIdDisplayGroup
      }
      nextToken
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $conversationId: ID!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMessageFilterInput
    $lastSync: AWSTimestamp
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    syncMessages(
      conversationId: $conversationId
      createdAt: $createdAt
      filter: $filter
      lastSync: $lastSync
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        _deleted
        _lastChangedAt
        _version
        authorId
        content
        conversationId
        createdAt
        id
        isSent
        members
        sotName
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userActionsByRoom = /* GraphQL */ `
  query UserActionsByRoom(
    $filter: ModelUserActionFilterInput
    $limit: Int
    $nextToken: String
    $param: String
    $sortDirection: ModelSortDirection
    $startTime: ModelStringKeyConditionInput
  ) {
    userActionsByRoom(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      param: $param
      sortDirection: $sortDirection
      startTime: $startTime
    ) {
      items {
        actionType
        countryCode
        createdAt
        duration
        endTime
        id
        param
        sotName
        source
        startTime
        topic
        updatedAt
        userId
        userType
      }
      nextToken
    }
  }
`;
export const userBusyTimes = /* GraphQL */ `
  query UserBusyTimes(
    $end: AWSDateTime!
    $limit: Int
    $nextToken: String
    $start: AWSDateTime!
    $userId: ID!
  ) {
    userBusyTimes(
      end: $end
      limit: $limit
      nextToken: $nextToken
      start: $start
      userId: $userId
    ) {
      items {
        end
        start
        userId
      }
      nextToken
    }
  }
`;
export const userConversationsByUser = /* GraphQL */ `
  query UserConversationsByUser(
    $filter: ModelUserConversationFilterInput
    $lastReadMessageCreatedAt: ModelStringKeyConditionInput
    $limit: Int
    $mostRecentMessageCreatedAt: ModelStringKeyConditionInput
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userId: ID!
  ) {
    userConversationsByUser(
      filter: $filter
      lastReadMessageCreatedAt: $lastReadMessageCreatedAt
      limit: $limit
      mostRecentMessageCreatedAt: $mostRecentMessageCreatedAt
      nextToken: $nextToken
      sortDirection: $sortDirection
      userId: $userId
    ) {
      items {
        conversationId
        createdAt
        id
        isChatOpen
        isMuted
        lastReadMessageCreatedAt
        mostRecentMessageCreatedAt
        updatedAt
        userId
      }
      nextToken
    }
  }
`;
export const userConversationsByUserAndConversation = /* GraphQL */ `
  query UserConversationsByUserAndConversation(
    $conversationId: ModelIDKeyConditionInput
    $filter: ModelUserConversationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userId: ID!
  ) {
    userConversationsByUserAndConversation(
      conversationId: $conversationId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      userId: $userId
    ) {
      items {
        conversationId
        createdAt
        id
        isChatOpen
        isMuted
        lastReadMessageCreatedAt
        mostRecentMessageCreatedAt
        updatedAt
        userId
      }
      nextToken
    }
  }
`;
export const userSessionsByLocation = /* GraphQL */ `
  query UserSessionsByLocation(
    $filter: ModelUserSessionFilterInput
    $limit: Int
    $locationLevel2: ModelStringKeyConditionInput
    $nextToken: String
    $sortDirection: ModelSortDirection
    $sotName: String
  ) {
    userSessionsByLocation(
      filter: $filter
      limit: $limit
      locationLevel2: $locationLevel2
      nextToken: $nextToken
      sortDirection: $sortDirection
      sotName: $sotName
    ) {
      items {
        countryCode
        createdAt
        id
        location
        locationLevel1
        locationLevel2
        locationLevel3
        queryHelper
        sotName
        source
        time
        topic
        ttl
        updatedAt
        userId
        userType
      }
      nextToken
    }
  }
`;
