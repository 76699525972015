import { useState, useEffect } from "react"
import * as React from "react"
import styled from "styled-components"
import branding from "../branding/branding"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { DatePickerRootTemplate } from "../contentArea/detailPages/components/StyledComponents"
import {
    IconCalendarEntry,
    IconArrowUpSettings,
    IconArrowDownSettings,
    IconProgramArrowLeft,
    IconProgramArrowRight,
    IconChevronLeft,
    IconChevronRight,
    IconArrowUpLangSwitcher,
    IconArrowDownLangSwitcher
} from "./Icons"
import moment, { Moment } from "moment"
import {
    getNumberOfItemsInLastRow,
    createTimeSlots,
    createMapNotAvailableSlotsByDay,
    isSlotDisabled,
    generateMeetingDates,
    generateMeetingDatesMobile
} from "../helper/MeetingSlotsHelper"
import { device } from "../utils/Device"
import { useLanguageState } from "../globalStates/LanguageState"
import useWindowDimensions from "./WindowDimensionsHook"
import {
    isToday,
    isBeforeToday,
    getIndexOfInitialDayToShow,
    momentWithoutTimezoneFromTimezonedMoment,
    isNotIncludedInRange
} from "../utils/DateUtils"
import { getCalendarEntriesAcceptedBetweenDates } from "../backendServices/GraphQLServices"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { Contact } from "../backendServices/Types"
import { useAppState } from "../globalStates/AppState"
import { useGuestModal } from "./modals/GuestModal"
import CalendarEntryModal2 from "./modals/calendarEntry/CalendarEntryModal2"
import { CalendarEntryModalViewMode } from "./modals/calendarEntry/ModalFunctions"

/* #region  TYPES */
export interface TimeSlotType {
    label: string
    value: Moment
    disabled: boolean
}

export interface MeetingSlotsDay {
    id: number
    date: Date
    dayLabel: string
    dateLabel: string
    slots: TimeSlotType[]
}

/* #endregion */

/* #region  GLOBAL STYLES */
const MeetingSlotsWrapper = styled.div`
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
    background: #fff;
    padding: 20px 15px;
`
const SectionTitleTemplate = styled.div`
    font-family: ${branding.font1};
    font-size: 18px;
    color: ${branding.personDetailPageContent.meetingSlotsSection.titleColor};
    font-weight: bold;

    @media ${device.mobile} {
        font-size: 16px;
    }
`

const DateSectionTitle = styled(SectionTitleTemplate)``

const TimeSectionTitle = styled(SectionTitleTemplate)`
    @media ${device.laptop} {
        display: none;
    }
`
/* #endregion */

/* #region  DATE PICKER SECTION */
const DatePickerSection = styled.div`
    margin-bottom: 25px;

    @media ${device.laptop} {
        margin-bottom: 0px;
    }
`
const DatePickerRoot = styled(DatePickerRootTemplate)`
    width: 100%;
    margin-top: 10px;

    & .react-datepicker-wrapper,
    & .react-datepicker__input-container,
    & input {
        width: 100%;
    }

    & .react-datepicker__triangle {
        display: none;
    }

    & .react-datepicker-popper {
        // page padding: left 17px, right 15px = 32px
        // parent padding: left and right 15px = 30px
        // total = 62px
        width: calc(100% - 62px);
        transform: translateX(31px); // half of the 62px
    }

    & .react-datepicker,
    & .react-datepicker__month-container {
        width: 100%;
        font-family: ${branding.font1};
    }

    & .react-datepicker__day-name,
    & .react-datepicker__day {
        width: 35px;
    }

    @media ${device.laptop} {
        display: none;
    }
`

const CustomInputWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid ${branding.personDetailPageContent.meetingSlotsSection.datePickerInputBorderColor};
    border-radius: 5px;
    height: 35px;
    padding: 0 15px;
`
const CustomInputCalendarIconWrapper = styled.div`
    flex: 0 10%;
`
const CustomInputLabelWrapper = styled.div`
    flex: 0 80%;
    padding-left: 20px;
    font-family: ${branding.font1};
    font-size: 14px;
    color: ${branding.personDetailPageContent.meetingSlotsSection.datePickerInputTextColor};
`
const CustomInputArrowIconWrapper = styled.div`
    flex: 0 10%;
`

interface CustomInputDatePickerProps {
    isDatePickerOpened: boolean
    id?: string | undefined
    placeholder?: string | undefined
    value: Date
    onChange?: ((event: React.FormEvent<HTMLDivElement>) => void) | undefined
    onClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined
    setIsDatePickerOpened: (opened: boolean) => void
}

const CustomInputDatePicker = (props: CustomInputDatePickerProps) => {
    const formattedDate: string = moment(props.value, "MM/DD/YYYY").format("YYYY-MM-DD")

    return (
        <CustomInputWrapper
            id={props.id}
            onChange={props.onChange}
            placeholder={props.placeholder}
            onClick={(e: any) => {
                props.setIsDatePickerOpened(!props.isDatePickerOpened)
                if (props.onClick) props.onClick(e)
            }}
        >
            <CustomInputCalendarIconWrapper>{IconCalendarEntry({ fill: "#727272" })}</CustomInputCalendarIconWrapper>
            <CustomInputLabelWrapper>
                {isToday(moment(formattedDate)) ? "Today" : moment(formattedDate).format("DD.MM.YYYY")}
            </CustomInputLabelWrapper>
            <CustomInputArrowIconWrapper>
                {props.isDatePickerOpened
                    ? IconArrowUpSettings({ fill: "#727272", width: "12", height: "12" })
                    : IconArrowDownSettings({ fill: "#727272", width: "12", height: "12" })}
            </CustomInputArrowIconWrapper>
        </CustomInputWrapper>
    )
}

/* #endregion */

/* #region  TIME SECTION */

/* #region  CUSTOM ARROW COMPONENT */
const ArrowBox = styled.div`
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${branding.personDetailPageContent.meetingSlotsSection.mobileArrowBoxBgColor};
    border: 1px solid ${branding.personDetailPageContent.meetingSlotsSection.mobileArrowBoxBorderColor};
    cursor: pointer;

    &.left {
        border-radius: 5px 0px 0px 5px;
    }

    &.right {
        border-radius: 0px 5px 5px 0px;
    }

    &.disabled {
        background: ${branding.personDetailPageContent.meetingSlotsSection.mobileArrowBoxBgColorDisabled};
        border: 1px solid ${branding.personDetailPageContent.meetingSlotsSection.mobileArrowBoxBorderColorDisabled};
        pointer-events: none;
    }

    @media ${device.laptop} {
        display: none;
    }
`

const SliderArrow = styled.div`
    display: none;
    position: absolute;
    top: 35px;
    cursor: pointer;

    &.left {
        left: -20px;
    }

    &.right {
        right: -20px;
    }

    &.disabled {
        pointer-events: none;
    }

    @media ${device.laptop} {
        display: block;
    }
`
interface CustomArrowProps {
    layout: "mobile" | "desktop"
    side: "left" | "right"
    isDisabled: boolean
    onClick: () => void
}
const CustomArrow = (props: CustomArrowProps) => {
    const isLeft = props.side === "left"
    const className = `${props.side} ${props.isDisabled ? "disabled" : ""}`
    const key = `${props.layout} ${props.side}`
    const mobileArrowColor = props.isDisabled
        ? branding.personDetailPageContent.meetingSlotsSection.mobileArrowIconColorDisabled
        : branding.personDetailPageContent.meetingSlotsSection.mobileArrowIconColor
    const desktopArrowColor = props.isDisabled
        ? branding.personDetailPageContent.meetingSlotsSection.desktopArrowIconColorDisabled
        : branding.personDetailPageContent.meetingSlotsSection.desktopArrowIconColor

    const Wrapper = props.layout === "mobile" ? ArrowBox : SliderArrow
    const children =
        props.layout === "mobile" ? (
            <React.Fragment key={key}>
                {isLeft ? IconProgramArrowLeft({ fill: mobileArrowColor }) : IconProgramArrowRight({ fill: mobileArrowColor })}
            </React.Fragment>
        ) : (
            <React.Fragment key={key}>
                {isLeft
                    ? IconChevronLeft({ fill: desktopArrowColor, width: "16", height: "16" })
                    : IconChevronRight({ fill: desktopArrowColor, width: "16", height: "16" })}
            </React.Fragment>
        )

    return (
        <Wrapper className={className} onClick={() => !props.isDisabled && props.onClick()}>
            {children}
        </Wrapper>
    )
}
/* #endregion */

const TimePickerSection = styled.div`
    position: relative;
`

const TimePickerSectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
`

const ArrowsWrapper = styled.div`
    display: flex;
`

const TimeSlotsContainer = styled.div<{ lastRowItems: number }>`
    width: 100%;

    @media ${device.laptop} {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-start;

        &::after {
            content: "";
            flex: ${(props) => (props.lastRowItems === 2 ? "0 calc(50% - 5px)" : "0 calc(25% - 10px)")};
        }
    }
`

const DailyTimeSlotsWrapper = styled.div<{ lastRowItems: number }>`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    user-select: none;

    &::after {
        content: "";
        flex: ${(props) => (props.lastRowItems === 2 ? "0 calc(50% - 5px)" : "0 calc(25% - 10px)")};

        @media ${device.laptop} {
            content: unset;
        }
    }

    @media ${device.laptop} {
        flex-wrap: nowrap;
        flex-direction: column;
        flex: 0 calc((100% / 7) - 10px);
        display: block;
    }
`

const TimeSlot = styled.div<{ disabled: boolean }>`
    height: 35px;
    border: 1px solid ${branding.personDetailPageContent.meetingSlotsSection.timeSlotBorderColor};
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${branding.font1};
    font-size: 14px;
    line-height: 16px;
    background-color: ${(props) =>
        props.disabled
            ? branding.personDetailPageContent.meetingSlotsSection.timeSlotBgColorDisabled
            : branding.personDetailPageContent.meetingSlotsSection.timeSlotBgColor};
    color: ${(props) =>
        props.disabled
            ? branding.personDetailPageContent.meetingSlotsSection.timeSlotColorDisabled
            : branding.personDetailPageContent.meetingSlotsSection.timeSlotColor};
    flex: 0 calc(25% - 10px);
    user-select: none;
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};

    &:hover {
        background-color: ${(props) =>
            props.disabled
                ? branding.personDetailPageContent.meetingSlotsSection.timeSlotBgColorDisabled
                : branding.personDetailPageContent.meetingSlotsSection.timeSlotHoverBgColor};
        color: ${(props) =>
            props.disabled
                ? branding.personDetailPageContent.meetingSlotsSection.timeSlotColorDisabled
                : branding.personDetailPageContent.meetingSlotsSection.timeSlotHoverColor};
    }

    @media ${device.laptop} {
        flex: unset;
        margin-bottom: 10px;
    }
`
const SliderHeaderItem = styled.div`
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;

    & span {
        font-family: ${branding.font1};
        font-size: 14px;
        color: ${branding.personDetailPageContent.meetingSlotsSection.timeTableHeaderColor};
    }

    & span:first-child {
        font-weight: bold;
        margin-bottom: 5px;
    }

    @media ${device.laptop} {
        display: flex;
    }
`
const ShowMoreLabel = styled.span`
    font-family: ${branding.font1};
    font-size: 12px;
    color: ${branding.personDetailPageContent.meetingSlotsSection.showMoreButtonColor};
    margin-right: 10px;
`

const ShowMoreButton = styled.div`
    display: none;
    width: 100%;
    height: 35px;
    justify-content: center;
    align-items: center;
    background: ${branding.personDetailPageContent.meetingSlotsSection.showMoreButtonBgColor};
    border: 1px solid ${branding.personDetailPageContent.meetingSlotsSection.showMoreButtonBorderColor};
    border-radius: 5px;
    cursor: pointer;
    user-select: none;

    &:hover {
        background: ${branding.personDetailPageContent.meetingSlotsSection.showMoreButtonBgHoverColor};

        & ${ShowMoreLabel} {
            color: ${branding.personDetailPageContent.meetingSlotsSection.showMoreButtonHoverColor};
        }

        & svg {
            path {
                stroke: ${branding.personDetailPageContent.meetingSlotsSection.showMoreButtonIconHoverColor};
            }
        }
    }

    @media ${device.laptop} {
        display: flex;
    }
`
interface TimeSlotsHandlerProps {
    userId: string
    meetingSlotsTable: MeetingSlotsDay[]
    meetingDaysLength: number
    timeSlotsLength: number
    currentPageDays: number
    daysToDisplay: number
    setCurrentPageDays: (page: number) => void
    onTimeSlotClick: (time: Moment) => void
}
const TimeSlotsHandler = (props: TimeSlotsHandlerProps) => {
    const { isMobile } = useWindowDimensions()
    const showMoreButtonIconColor = branding.personDetailPageContent.meetingSlotsSection.showMoreButtonIconColor
    const lastRowItems = getNumberOfItemsInLastRow(moment())
    const TIME_SLOTS_TO_DISPLAY = isMobile ? 16 : 5
    const [currentPageTimeSlots, setCurrentPageTimeSlots] = useState(0)
    const [viewMoreActivated, setViewMoreActivated] = useState(false)

    return (
        <TimePickerSection>
            {/* FOR MOBILE DEVICES */}
            <TimePickerSectionHeader>
                <TimeSectionTitle>Choose a time</TimeSectionTitle>
                <ArrowsWrapper>
                    <CustomArrow
                        layout="mobile"
                        side="left"
                        isDisabled={currentPageTimeSlots === 0}
                        onClick={() =>
                            currentPageTimeSlots > 0 && setCurrentPageTimeSlots((prev) => prev - TIME_SLOTS_TO_DISPLAY)
                        }
                    />
                    <CustomArrow
                        layout="mobile"
                        side="right"
                        isDisabled={currentPageTimeSlots + TIME_SLOTS_TO_DISPLAY >= props.timeSlotsLength}
                        onClick={() =>
                            currentPageTimeSlots + TIME_SLOTS_TO_DISPLAY < props.timeSlotsLength &&
                            setCurrentPageTimeSlots((prev) => prev + TIME_SLOTS_TO_DISPLAY)
                        }
                    />
                </ArrowsWrapper>
            </TimePickerSectionHeader>

            {/* FOR DESKTOP DEVICES */}
            {props.currentPageDays > 0 && (
                <CustomArrow
                    layout="desktop"
                    side="left"
                    isDisabled={props.currentPageDays === 0}
                    onClick={() => props.setCurrentPageDays(props.currentPageDays - 1)}
                />
            )}
            {props.currentPageDays + props.daysToDisplay < props.meetingDaysLength && (
                <CustomArrow
                    layout="desktop"
                    side="right"
                    isDisabled={props.currentPageDays + props.daysToDisplay >= props.meetingDaysLength}
                    onClick={() => props.setCurrentPageDays(props.currentPageDays + 1)}
                />
            )}

            <TimeSlotsContainer lastRowItems={lastRowItems}>
                {props.meetingSlotsTable
                    .slice(props.currentPageDays, props.currentPageDays + props.daysToDisplay)
                    .map((day, i) => {
                        return (
                            <DailyTimeSlotsWrapper key={i} lastRowItems={lastRowItems}>
                                <SliderHeaderItem>
                                    <span>{day.dayLabel}</span>
                                    <span>{day.dateLabel}</span>
                                </SliderHeaderItem>
                                {day.slots
                                    .slice(
                                        currentPageTimeSlots,
                                        viewMoreActivated
                                            ? props.timeSlotsLength - 1
                                            : currentPageTimeSlots + TIME_SLOTS_TO_DISPLAY
                                    )
                                    .map((time, j) => {
                                        return (
                                            <TimeSlot
                                                key={j}
                                                disabled={time.disabled}
                                                onClick={() => {
                                                    !time.disabled && props.onTimeSlotClick(time.value)
                                                }}
                                            >
                                                {time.label}
                                            </TimeSlot>
                                        )
                                    })}
                            </DailyTimeSlotsWrapper>
                        )
                    })}
            </TimeSlotsContainer>

            {props.timeSlotsLength > TIME_SLOTS_TO_DISPLAY && (
                <ShowMoreButton onClick={() => setViewMoreActivated(!viewMoreActivated)}>
                    <ShowMoreLabel>
                        {viewMoreActivated
                            ? branding.personDetailPageContent.meetingSlotsSection.showMoreButtonActiveLabel
                            : branding.personDetailPageContent.meetingSlotsSection.showMoreButtonInactiveLabel}
                    </ShowMoreLabel>
                    <span>
                        {viewMoreActivated
                            ? IconArrowUpLangSwitcher({ fill: showMoreButtonIconColor, width: "12", height: "12" })
                            : IconArrowDownLangSwitcher({ fill: showMoreButtonIconColor, width: "12", height: "12" })}
                    </span>
                </ShowMoreButton>
            )}
        </TimePickerSection>
    )
}

/* #endregion */

interface MeetingSlotsProps {
    user: Contact
}
const MeetingSlots: React.FC<MeetingSlotsProps> = (props) => {
    // global state variables
    const userState = useLoggedInState()
    const loggedInUser = userState.user()
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const { isMobile } = useWindowDimensions()
    const { timezone } = useAppState()

    // helpers
    const DAYS_TO_DISPLAY = isMobile ? 1 : 7
    const meetingDays = generateMeetingDates()
    const meetingDaysMobile = generateMeetingDatesMobile()
    const timeSlots = createTimeSlots(moment())
    const participantUserFullName = `${props.user.firstName} ${props.user.lastName}`
    const myUserFullName = `${loggedInUser?.firstName} ${loggedInUser?.lastName}`
    const meetingTitle = branding.personDetailPageContent.meetingSlotsSection.predefinedMeetingTitleTemplate
        .replace("${user}", participantUserFullName)
        .replace("${me}", myUserFullName)

    // local states
    const [isDatePickerOpened, setIsDatePickerOpened] = useState(false)
    const [selectedDate, setSelectedDate] = useState(
        new Date(moment(branding.personDetailPageContent.meetingSlotsSection.startDate).format())
    )
    const [showRequestMeetingModal, setShowRequestMeetingModal] = useState<boolean>(false)
    const { showGuestModal, GuestModal } = useGuestModal()
    const [meetingDateTime, setMeetingDateTime] = useState<Moment | undefined>()
    const [currentPageDays, setCurrentPageDays] = useState(-1)
    const [meetingSlotsTable, setMeetingSlotsTable] = useState<MeetingSlotsDay[]>([])
    const [initialLoad, setInitialLoad] = useState(true)
    const [refreshKey, setRefreshKey] = useState(0)

    const onTimeSlotClick = (meetingTime: Moment) => {
        if (userState.user() && userState.user()?.type === "guest" && userState.user()?.invitingOrganization) {
            showGuestModal()
            return
        }
        setMeetingDateTime(meetingTime)
        setShowRequestMeetingModal(true)
    }
    useEffect(() => {
        let tempTable: MeetingSlotsDay[] = []
        meetingDays.forEach((day, index) => {
            const existingSlots = meetingSlotsTable.filter((x) => moment(x.date).isSame(day))[0]?.slots
            const currentDateTime = momentWithoutTimezoneFromTimezonedMoment(moment(), timezone)
            tempTable.push({
                id: index,
                date: moment(day).toDate(),
                dayLabel: moment(day).locale(lang).format("dddd"),
                dateLabel: moment(day).locale(lang).format("DD. MMM"),
                slots:
                    initialLoad && existingSlots
                        ? existingSlots
                        : timeSlots.map((slot, i) => {
                              const hours = parseInt(slot.split(":")[0])
                              const minutes = parseInt(slot.split(":")[1])
                              const slotDateTime = moment(day).set("hours", hours).set("minutes", minutes).tz(timezone)
                              return {
                                  label: slot,
                                  value: slotDateTime,
                                  disabled:
                                      isNotIncludedInRange(slotDateTime) ||
                                      isBeforeToday(slotDateTime) ||
                                      (isToday(slotDateTime) &&
                                          slotDateTime.isSameOrBefore(currentDateTime, "h") &&
                                          slotDateTime.isSameOrBefore(currentDateTime, "m"))
                              }
                          })
            })
        })
        setMeetingSlotsTable(tempTable)
        const indexTemp = getIndexOfInitialDayToShow(meetingDays.map((d) => moment(d)))
        const initialIndexToShow =
            meetingDays.length - indexTemp < DAYS_TO_DISPLAY ? meetingDays.length - DAYS_TO_DISPLAY : indexTemp
        setCurrentPageDays(indexTemp !== -1 ? initialIndexToShow : 0)

        // this triggers load of user meetings when another user detail page is opened
        setRefreshKey((prev) => prev + 1)
    }, [props.user.id, lang]) // eslint-disable-line

    useEffect(() => {
        // when another user detail page is opened,
        // meeting slots table needs to be reverted to initial state
        setInitialLoad(true)
    }, [props.user.id])

    useEffect(() => {
        if (!initialLoad) {
            const index = isMobile
                ? meetingSlotsTable.findIndex((x) => moment(x.date).isSame(selectedDate, "day"))
                : meetingSlotsTable.findIndex((x) => isToday(moment(x.date)))
            setCurrentPageDays(index !== -1 ? index : 0)
        }
    }, [selectedDate, isMobile]) // eslint-disable-line

    const loadUserMeetings = (userId: string, firstDate: Date, secondDate: Date, nextToken?: string) => {
        getCalendarEntriesAcceptedBetweenDates(userId, firstDate, secondDate, nextToken).then((resp) => {
            const notAvailableSlotsByDay = createMapNotAvailableSlotsByDay(resp?.items?.map((x) => x.calendarEntry))
            const daysWithNotAvailableSlots = Object.keys(notAvailableSlotsByDay)
            const tempTable = [...meetingSlotsTable]
            tempTable
                .filter((day) => daysWithNotAvailableSlots.includes(moment(day.date).format("YYYY-MM-DD")))
                .forEach((day) => {
                    day.slots.forEach((slot) => {
                        const currentSlot = notAvailableSlotsByDay[moment(day.date).format("YYYY-MM-DD")].find((x: any) => {
                            return isSlotDisabled(slot.value, x.start, x.end)
                        })

                        if (currentSlot) slot.disabled = true
                    })
                })
            setMeetingSlotsTable(tempTable)
            if (initialLoad) setInitialLoad(false)

            return resp?.nextToken
        })
    }

    useEffect(() => {
        if (meetingSlotsTable.length > 0) {
            const firstDate = initialLoad
                ? meetingSlotsTable[currentPageDays]?.date
                : moment(meetingSlotsTable[currentPageDays + DAYS_TO_DISPLAY - 1]?.date)
                      .subtract(24, "hours")
                      .toDate()

            const secondDateIndex =
                currentPageDays + DAYS_TO_DISPLAY >= meetingSlotsTable.length
                    ? meetingSlotsTable.length - 1
                    : currentPageDays + DAYS_TO_DISPLAY
            const secondDate = moment(meetingSlotsTable[secondDateIndex]?.date).add(24, "hours").toDate()

            if (firstDate && secondDate) {
                let nextToken: any
                do {
                    nextToken = loadUserMeetings(props.user.id, firstDate, secondDate, nextToken)
                } while (nextToken)
            }
        }
    }, [currentPageDays, refreshKey]) // eslint-disable-line

    return (
        <MeetingSlotsWrapper>
            {/* DATE PICKER SECTION */}
            <DatePickerSection>
                <DateSectionTitle>{branding.personDetailPageContent.meetingSlotsSection.title}</DateSectionTitle>
                {/* FOR MOBILE DEVICES */}
                <DatePickerRoot>
                    <DatePicker
                        open={isDatePickerOpened}
                        includeDates={meetingDaysMobile.map((day) => moment(day).toDate())}
                        selected={selectedDate}
                        disabledKeyboardNavigation={true}
                        popperPlacement="bottom"
                        onChange={(date: any) => setSelectedDate(date)}
                        customInput={
                            <CustomInputDatePicker
                                isDatePickerOpened={isDatePickerOpened}
                                setIsDatePickerOpened={setIsDatePickerOpened}
                                value={selectedDate}
                            />
                        }
                    />
                </DatePickerRoot>
            </DatePickerSection>

            {/* TIME PICKER SECTION */}
            <TimeSlotsHandler
                userId={props.user.id}
                meetingDaysLength={meetingDays.length}
                meetingSlotsTable={meetingSlotsTable}
                timeSlotsLength={timeSlots.length}
                currentPageDays={currentPageDays}
                daysToDisplay={DAYS_TO_DISPLAY}
                setCurrentPageDays={setCurrentPageDays}
                onTimeSlotClick={onTimeSlotClick}
            />

            {/* MODALS */}
            {showRequestMeetingModal && (
                <CalendarEntryModal2
                    viewMode={CalendarEntryModalViewMode.CREATE}
                    sotUser={[
                        {
                            id: props.user.id,
                            firstName: props.user.firstName,
                            lastName: props.user.lastName,
                            logoUrl: props.user.logoUrl,
                            userId: props.user.sotUserId
                        }
                    ]}
                    predefinedTitle={meetingTitle}
                    predefinedStartTime={meetingDateTime?.toDate()}
                    meetingSlotsTable={[...meetingSlotsTable]}
                    timeSlotInterval={branding.personDetailPageContent.meetingSlotsSection.timeSlotIntervalMinutes}
                    close={() => {
                        setShowRequestMeetingModal(false)
                    }}
                />
            )}
            <GuestModal />
        </MeetingSlotsWrapper>
    )
}

export default MeetingSlots
