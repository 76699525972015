import { getIdFromUrl, getTypeFromUrl, getNameFromUrl } from "./ChatPageMessageList"
import { DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import { trackSelectContent } from "../utils/GTMTracking"
import { capitalizeFirstLetter } from "../utils/StringUtils"
import { isPostEventPhase } from "../utils/EventPhaseChecker"
import { DetailNavText } from "../contentArea/detailPages/DetailNavLink"

function checkIfInAppSharedUrl(urlString: string): boolean {
    const currentMainUrl = window.location.origin

    if (
        urlString.includes(currentMainUrl + "/person/") ||
        urlString.includes(currentMainUrl + "/company/") ||
        urlString.includes(currentMainUrl + "/eventdate/") ||
        urlString.includes(currentMainUrl + "/joboffer/") ||
        urlString.includes(currentMainUrl + "/product/") ||
        urlString.includes(currentMainUrl + "/trademark/") ||
        urlString.includes(currentMainUrl + "/news/")
    ) {
        return true
    }

    return false
}

function checkIfVirtualCafeUrlInPostEventPhase(urlString: string): boolean {
    if (!isPostEventPhase) {
        return false
    }

    const currentMainUrl = window.location.origin

    if (urlString.includes(currentMainUrl + "/meeting/") || urlString.includes(currentMainUrl + "/meetings/")) {
        return true
    }

    return false
}

const ComponentDecorator = (href: string, text: string, key: number) => (
    <>
        {checkIfInAppSharedUrl(text) ? (
            <DetailNavLink
                id={getIdFromUrl(text)}
                type={getTypeFromUrl(text)}
                name={getNameFromUrl(text)}
                onClick={() => {
                    trackSelectContent("Person", "Details", capitalizeFirstLetter(getTypeFromUrl(text)), "chat")
                }}
            >
                <DetailNavText>{text}</DetailNavText>
            </DetailNavLink>
        ) : (
            <a
                href={checkIfVirtualCafeUrlInPostEventPhase(text) ? " " : href}
                key={key}
                style={{
                    userSelect: checkIfVirtualCafeUrlInPostEventPhase(text) ? "none" : "auto",
                    pointerEvents: checkIfVirtualCafeUrlInPostEventPhase(text) ? "none" : "auto",
                    color: "#141DE9"
                }}
                target="_blank"
                rel="noopener noreferrer"
            >
                {text}
            </a>
        )}
    </>
)

export default ComponentDecorator
