import styled from "styled-components"
import branding from "../../branding/branding"

export interface badgeWrapperProps {
    bsize: number
}

export const BadgeWrapperWithStatus = styled.div<badgeWrapperProps>`
    min-width: ${(props) => props.bsize}px;
    width: ${(props) => props.bsize}px;
    height: ${(props) => props.bsize}px;
    border: 1px solid;
    border-radius: 50%;
    padding: 2px 0px 0px 2px;
    border-color: transparent;

    &.visitor {
        border-color: transparent;
    }

    &.speaker {
        border-color: ${branding.avatarBranding.borderColorSpeaker};
    }

    &.exhibitor {
        border-color: ${branding.avatarBranding.borderColorExhibitor};
    }

    &.none {
        border: none;
    }
`

export const AvatarContainer = styled.div<{ containerWidth?: string }>`
    width: ${(props) => props.containerWidth ?? "55px"};
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
`

export const BadgeWrapperWithIcon = styled.div<{ wrapperWidth?: string; wrapperMarginTop?: string; wrapperMarginLeft?: string }>`
    width: ${(props) => (props.wrapperWidth ? props.wrapperWidth : "55px")};
    height: 20px;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: start;
    margin-top: ${(props) => (props.wrapperMarginTop ? props.wrapperMarginTop : "-5px")};
    margin-left: ${(props) => (props.wrapperMarginLeft ? props.wrapperMarginLeft : "0px")};
    border: 0px solid transparent !important;
`
export const IconWrapper = styled.span`
    position: absolute;
    left: -2px;
`
