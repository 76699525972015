import { createState, State, useState } from "@hookstate/core"
import { useRosterState } from "amazon-chime-sdk-component-library-react"

import { getRaisedHandsByChimeMeetingId, insertRaisedHand, removeRaisedHand } from "../../backendServices/GraphQLServices"
import { useMeetingController } from "./MeetingController"
import { useAppState } from "../../globalStates/AppState"
import { ChatConversationParam } from "../../communicationArea/ChatPage"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { ConversationType } from "../../API"
import { ChatType } from "../enums/ChatType"
import { getChatType } from "../utils/getChatType"
import { AttendeeData } from "../../backendServices/MeetingServices"

interface StateValues {}

const getStartValues = (): StateValues => {
    return {}
}

export interface ControlBarContext {
    toggleSettings: () => void
    leave: () => void
    raiseHand: () => void
    showChat: () => void
}
const state = createState<StateValues>(getStartValues())

const useStateWrapper = (state: State<StateValues>): ControlBarContext => {
    const { isAudioVideoSettingsV2Open, setIsAudioVideoSettingsV2Open } = useAppState()

    const meetingController = useMeetingController()
    const { setIsRosterOpen, setIsMeetingChatShown, setChatsSubtab } = useAppState()
    const { roster } = useRosterState()
    const profileId = useLoggedInState().user()?.profileId
    return {
        toggleSettings: () => {
            setIsAudioVideoSettingsV2Open(!isAudioVideoSettingsV2Open)
        },
        leave: async () => {
            meetingController.leaveMeeting()
        },
        raiseHand: async () => {
            const raisedHandsResponse = await getRaisedHandsByChimeMeetingId(meetingController.getExternalMeetingId() as string)
            let isMyHandRaised = false
            if (raisedHandsResponse) {
                isMyHandRaised =
                    raisedHandsResponse.data.listRaisedHands.items.filter(
                        (raisedHand: any) => raisedHand.id === meetingController.getExternalUserId()
                    ).length > 0
            }
            if (!isMyHandRaised) {
                insertRaisedHand(
                    meetingController.getExternalMeetingId() as string,
                    meetingController.getExternalUserId() as string
                )
            } else {
                removeRaisedHand(meetingController.getExternalUserId() as string)
            }
        },
        showChat: async () => {
            switch (meetingController.getMeetingKind()) {
                case "call":
                    let conversationId = meetingController.getExternalMeetingId()?.slice(3) || ""
                    let chatType = await getChatType(meetingController.getMeetingKind(), roster, conversationId)
                    let interlocutorId = Object.values(roster).filter((attendee: AttendeeData) => {
                        return attendee.id !== profileId
                    })[0]?.externalUserId

                    if (interlocutorId && chatType !== ChatType.CALL_GROUP) {
                        setChatsSubtab(ChatConversationParam.privateConversationByOpponentId(interlocutorId))
                    } else {
                        ChatConversationParam.conversationByConversationId(ConversationType.GROUP, conversationId)
                    }
                    break
                // logic here
                case "virtualCafe":
                case "showroom":
                case "breakout":
                case "conferenceroom":
                case "greenroom":
                case "roundtable":
                    setIsRosterOpen(true)
                    setIsMeetingChatShown(true)
                    break
                // logic here
                default:
                    break
            }
        }
    }
}

export const useControlBarContext = (): ControlBarContext => useStateWrapper(useState(state))
