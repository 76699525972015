import moment from "moment"
import { useEffect, useState } from "react"
import * as React from "react"
import { NavLink, useHistory, useParams } from "react-router-dom"
import styled from "styled-components"
import { AttendeeData, ChannelResponse, getChannelInfo, listAttendeeInfos } from "../backendServices/MeetingServices"
import { EventDate } from "../backendServices/Types"
import branding from "../branding/branding"
import { calcConversationId } from "../communicationArea/ChatPage"
import { getExternalMeetingId, useChimeContext } from "../conference/context/ChimeContext"
import { useAppState } from "../globalStates/AppState"
import { useLanguageState } from "../globalStates/LanguageState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import Breadcrumb from "../navigationArea/Breadcrumb"
import { programPageRoute } from "../navigationArea/RoutePaths"
import TopBar from "../navigationArea/TopBar"
import { calcBreadcrumbLocations } from "../tracking/RouteTracker"
import BackendError from "../ui/BackendError"
import CenteredLoader from "../ui/CenteredLoader"
import { accessPresenceState, EventType } from "../ui/PresenceIndicator"
import { usePrivacyPolicyModal } from "../ui/modals/PrivacyPolicyModal"
import { hasAccessToOrganization } from "../utils/UserUtils"
import { getMinutesBeforeSwitchingToTheNextEvent, momentWithoutTimezoneFromTimezonedMoment } from "../utils/DateUtils"
// import { IconRoundTable } from "../ui/Icons";
import TopBannerSharedState from "./advertisingBanner/TopBannerSharedState"
import { EventDateDetailBody } from "./detailPages/eventDateDetail/EventDateDetailPageContent"
import GuestUserBanner from "./guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "./guestUserBanner/GuestUserBannerSharedState"
import Schedule from "./program/Schedule"
import { MeetingStatusCode } from "../conference/enums/MeetingStatusCode"
import { BackendServiceError } from "../backendServices/BackendServicesUtils"
import { EventDateChannelFirstDetailsResponse, loadChannelFirstEventDate } from "../backendServices/EventdateServices"
import { format } from "date-fns"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import { MeetingStatus, useMeetingManager } from "amazon-chime-sdk-component-library-react"

const VideoPageContentRoot = styled.div<{ adjustHeight: number }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - ${(props) => props.adjustHeight + "px"});
    max-height: calc(100% - ${(props) => props.adjustHeight + "px"});

    .ScrollbarsCustom-Content {
        padding: 0 !important;
    }
`

interface VideoPageContentProps {}

function ContentSwitch(props: {
    channelId?: string
    channelAlias?: string
    streamUrl: string
    eventDate?: EventDate
    nextEventDate?: EventDate
    calculatedChange: number
    comingUpVisible?: boolean
    breakoutDisabled?: boolean
    guestBannerHeight: number
    adjustHeight: number
    participants: number
}) {
    let eventDateDetailContent = null

    if (props.eventDate) {
        eventDateDetailContent = (
            <EventDateDetailBody
                eventDate={props.eventDate}
                adjustHeight={props.adjustHeight}
                isChannelPage={true}
                streamingUrl={props.streamUrl}
                joinBreakoutDisabled={props.breakoutDisabled}
                breakoutRoomParticipants={props.participants}
                comingUpSectionVisible={props.comingUpVisible}
                nextEventDate={props.nextEventDate}
                calculatedChange={props.calculatedChange}
            />
        )
    } else {
        eventDateDetailContent = <CenteredLoader />
    }

    //if (props.channelId && props.eventDate?.organizationId) {
    if (props.channelId) {
        return (
            <VideoPageContentRoot adjustHeight={props.adjustHeight}>
                {props.eventDate && eventDateDetailContent}
            </VideoPageContentRoot>
        )
    } else {
        return <Schedule category="masterclass" guestBannerHeight={props.guestBannerHeight} listViewMode={false} />
    }
}

export function calculateEnd(currentEventEndDate: string, timezone: any) {
    return moment(moment(currentEventEndDate)).diff(momentWithoutTimezoneFromTimezonedMoment(moment(), timezone))
}

export function calculateBreakoutRemainingTime(currentEventEndDate: string, breakoutTime: number, timezone: any) {
    return moment(moment(currentEventEndDate))
        .subtract(breakoutTime)
        .diff(momentWithoutTimezoneFromTimezonedMoment(moment(), timezone))
}

export function calculateChange(currentEventEndDate: string, nextEventStartDate: string) {
    const changeDiff = moment(moment(nextEventStartDate)).diff(moment(currentEventEndDate))
    if (changeDiff <= 10 * 60 * 1000) {
        return changeDiff / 2
    } else {
        return changeDiff - getMinutesBeforeSwitchingToTheNextEvent() * 60 * 1000
    }
}

const VideoPageContent: React.FC<VideoPageContentProps> = (props) => {
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const locations = calcBreadcrumbLocations()
    const chime = useChimeContext()
    const meetingManager = useMeetingManager()
    const loggedUser = useLoggedInState().user()
    const { channelId }: any = useParams()
    const [eventDate, setEventDate] = useState<EventDate>()
    const [error, setError] = useState<string>()
    const history = useHistory()
    const [breakoutDisabled, setBreakoutDisabled] = useState<boolean>(true)
    const [participants, setParticipants] = useState<number>(0)
    const timezone = useAppState().timezone

    //add banner state
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
    const { topBarRef, setTopBarRef } = TopBannerSharedState()
    const adjustHeight =
        (guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0) +
        (topBarRef && topBarRef.current ? topBarRef.current.clientHeight : 0) +
        60

    const [streamUrl, setStreamUrl] = useState<string>()
    const [channelName, setChannelName] = useState<string>("")

    const [nextEventDate, setNextEventDate] = useState<EventDate>()

    const [calculatedChange, setCalculatedChange] = useState<number>(0)
    const [comingUpVisible, setComingUpVisible] = useState<boolean>(false)

    //privacy modal
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()

    useEffect(() => {
        appState.setCurrentMobileBreadcrumb(branding.programPageContent.scheduleTabTitle)
        // eslint-disable-next-line
    }, [lang])

    useEffect(() => {
        if (eventDate?.organizationId) showPrivacyPolicyModal({ id: eventDate.organizationId, ...eventDate.organizationConsent })
    }, [eventDate?.organizationId]) //eslint-disable-line

    const randomUrl = (urls: string[]) => {
        return urls[Math.floor(Math.random() * urls.length)]
    }

    const appState = useAppState()

    useEffect(() => {
        const loadChannel = (eventDate?: EventDate) => {
            getChannelInfo(channelId).then((data) => {
                if ((data as BackendServiceError).httpStatus) {
                    // TODO ERROR
                } else {
                    data = data as ChannelResponse
                    const streamUrl = data.redundantUrl ? randomUrl([data.url, data.redundantUrl]) : data.url
                    const channelName = data.name ?? "Channel"

                    appState.setVodEventDate(null)
                    if (appState.liveStreamChannel?.url !== streamUrl) {
                        // reset videoPlayerStatus when new livestream is loaded
                        appState.setVideoPlayerStatus({
                            timeOffsetliveStream: 0,
                            isPaused: true
                        })
                    }
                    appState.setLiveStreamChannel({
                        id: data.channelId,
                        url: streamUrl,
                        eventDate: eventDate
                    })
                    setStreamUrl(streamUrl)
                    setChannelName(channelName)
                }
            })
        }

        function loadData() {
            loadChannelFirstEventDate(channelId, getMinutesBeforeSwitchingToTheNextEvent() * 60 * 1000)
                .then((data) => {
                    if ((data as BackendServiceError).httpStatus) {
                        const backendError = data as BackendServiceError
                        if (backendError.httpStatus === 501) {
                            // no current event date
                            loadChannel()
                        } else {
                            setError(backendError.httpStatusText)
                        }
                    } else {
                        const resp = data as EventDateChannelFirstDetailsResponse
                        loadChannel(resp.currentEventDate)
                        setEventDate(resp.currentEventDate)

                        if (resp.currentEventDate && !resp.nextEventDate) {
                            const remainingCurrentEventDate = calculateEnd(resp.currentEventDate?.enddatetime, timezone)
                            setComingUpVisible(false)
                            if (remainingCurrentEventDate > 0) {
                                remainingCurrentEventDateTimer = window.setTimeout(() => {
                                    loadData()
                                }, remainingCurrentEventDate)
                            }
                        } else if (resp.currentEventDate && resp.nextEventDate) {
                            setNextEventDate(resp.nextEventDate)
                            const currentEventDateEndTime = resp.currentEventDate.enddatetime

                            let remainingCurrentEventDate = calculateEnd(currentEventDateEndTime, timezone)
                            if (remainingCurrentEventDate > 0) {
                                const changeExecution =
                                    calculateChange(currentEventDateEndTime, resp.nextEventDate!.startdatetime) +
                                    remainingCurrentEventDate
                                if (moment().isBefore(moment(resp.currentEventDate.startdatetime))) {
                                    setComingUpVisible(false)
                                    remainingCurrentEventDate = moment(moment(resp.currentEventDate.startdatetime)).diff(
                                        momentWithoutTimezoneFromTimezonedMoment(moment(), timezone)
                                    )
                                } else {
                                    setComingUpVisible(true)

                                    setCalculatedChange(changeExecution)
                                }
                                remainingCurrentEventDateTimer = window.setTimeout(() => {
                                    // Trigger on event end
                                    const remainingCurrentEventDateTime = calculateEnd(currentEventDateEndTime, timezone)
                                    const timeToLive =
                                        calculateChange(currentEventDateEndTime, resp.nextEventDate!.startdatetime) +
                                        remainingCurrentEventDateTime
                                    setCalculatedChange(timeToLive)
                                    setComingUpVisible(true)
                                    changeExecutionTimer = window.setTimeout(() => {
                                        //Trigger data load
                                        loadData()
                                        setComingUpVisible(true)
                                    }, timeToLive)
                                }, remainingCurrentEventDate)
                            } else {
                                const changeExecution =
                                    calculateChange(currentEventDateEndTime, resp.nextEventDate!.startdatetime) -
                                    Math.abs(remainingCurrentEventDate)
                                setCalculatedChange(changeExecution)
                                setComingUpVisible(true)
                                changeExecutionTimer = window.setTimeout(() => {
                                    loadData()
                                    setComingUpVisible(true)
                                }, changeExecution)
                            }
                        }
                    }
                })
                .catch((err) => setError(err.message))
        }

        let remainingCurrentEventDateTimer: number
        let changeExecutionTimer: number

        accessPresenceState.updateMyPresence(EventType.EVENT_BEGIN)

        loadData()
        return () => {
            clearTimeout(remainingCurrentEventDateTimer)
            clearTimeout(changeExecutionTimer)
            // dont change presence only if currently in call
            const useConferenceRoomV2 = branding.configuration.useConferenceRoomV2
            const meetingStatus = useConferenceRoomV2 ? meetingManager.meetingStatus : chime.getMeetingStatus().meetingStatusCode
            if (
                (useConferenceRoomV2 && meetingStatus !== MeetingStatus.Succeeded) ||
                (!useConferenceRoomV2 && meetingStatus !== MeetingStatusCode.Succeeded) // when chimeV1 is deprecated, we can remove this line and use only meeting status from the meetingManager
            ) {
                accessPresenceState.updateMyPresence(EventType.EVENT_END)
            }
        }

        // eslint-disable-next-line
    }, [channelId, lang])

    useEffect(() => {
        appState.setLiveStreamChannel(Object.assign({}, appState.liveStreamChannel, { eventDate: eventDate }))
        // eslint-disable-next-line
    }, [eventDate])

    useEffect(() => {
        let moderatorCheckInterval: number
        const pollingTime = branding.videoPageContentBranding.pollingTime

        if (eventDate) {
            listAttendeeInfos(getExternalMeetingId(eventDate.id, "breakout")).then((data) => {
                if (data as AttendeeData[]) {
                    if ((data as AttendeeData[]).find((att) => att.role === "moderator")) {
                        setBreakoutDisabled(false)
                    } else {
                        setBreakoutDisabled(true)
                    }
                    setParticipants((data as AttendeeData[]).filter((participant) => participant.role !== "moderator").length)
                }
            })
            if (pollingTime && pollingTime > 0) {
                moderatorCheckInterval = window.setInterval(() => {
                    listAttendeeInfos(getExternalMeetingId(eventDate.id, "breakout")).then((data) => {
                        if (data as AttendeeData[]) {
                            if ((data as AttendeeData[]).find((att) => att.role === "moderator")) {
                                setBreakoutDisabled(false)
                            } else {
                                setBreakoutDisabled(true)
                            }
                            setParticipants(
                                (data as AttendeeData[]).filter((participant) => participant.role !== "moderator").length
                            )
                        }
                    })
                }, pollingTime)
            }
        } else {
            setBreakoutDisabled(true)
        }

        return () => {
            if (moderatorCheckInterval) clearInterval(moderatorCheckInterval)
        }
    }, [eventDate]) //eslint-disable-line

    const organizationId = eventDate?.organizationId
    if (
        eventDate &&
        (!loggedUser ||
            (loggedUser.type === "guest" && (!organizationId || !hasAccessToOrganization(loggedUser, organizationId))))
    ) {
        history.push(programPageRoute) // TODO better behaviour for guests with missing access (show dialog)
        setTimeout(() => appState.setLiveStreamChannel(null), 500) // Quick fix, directly setting to null leads to infinite rerender // TODO real solution
    }

    return (
        <>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar setRef={setTopBarRef} />
            {channelName && (
                <div style={{ marginTop: "0px", borderBottom: branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9" }}>
                    <Breadcrumb
                        breadcrumb={[{ to: locations[0], name: channelName }]}
                        classification="Details"
                        contentType="Channel"
                    />
                </div>
            )}

            {!error && streamUrl && (
                <ContentSwitch
                    channelId={calcConversationId(channelId)}
                    channelAlias={channelId}
                    eventDate={eventDate}
                    nextEventDate={nextEventDate}
                    streamUrl={streamUrl}
                    guestBannerHeight={
                        guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0
                    }
                    breakoutDisabled={breakoutDisabled}
                    calculatedChange={calculatedChange}
                    comingUpVisible={comingUpVisible}
                    adjustHeight={adjustHeight}
                    participants={participants}
                />
            )}
            {error && (
                <div style={{ marginTop: "25%" }}>
                    <BackendError />
                </div>
            )}
            <PrivacyModal />
        </>
    )
}

interface JoinBreakoutProps {
    eventDate: EventDate
    disabled?: boolean
    participants: number
}

export const JoinBreakout: React.FC<JoinBreakoutProps> = (props) => {
    const logedInUser = useLoggedInState().user()
    const meetingRoutePrefix = branding.configuration.useConferenceRoomV2 ? "meetingV2" : "meeting"

    function isBreakoutDisabled() {
        const amIPartOfOrga =
            (logedInUser?.organizations?.map((o) => o.id)?.find((orgaId) => orgaId === props.eventDate.organizationId) ??
                false) !== false
        const amISpeaker = props.eventDate.persons.map((p) => p.userId)?.indexOf(logedInUser?.profileId!) > -1 ?? false

        return props.disabled && !amIPartOfOrga && !amISpeaker
    }

    return (
        <JoinBreakoutRoot>
            {/* <JoinBreakoutIcon>{IconRoundTable({ width: "25", height: "25", fill: branding.mainInfoColor })}</JoinBreakoutIcon>
            <JoinBreakoutMessage>{branding.videoPageContentBranding.joinBreakoutMessage}</JoinBreakoutMessage> */}
            <JoinBreakoutIcon></JoinBreakoutIcon>
            <JoinBreakoutMessage></JoinBreakoutMessage>
            <JoinBreakoutButtonArea>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    <div>
                        <JoinBreakoutButton
                            to={`/${meetingRoutePrefix}/br_${props.eventDate.id}/createorjoin`}
                            disabled={isBreakoutDisabled()}
                        >
                            {` ${branding.videoPageContentBranding.joinBreakoutButtonText} (${props.participants} / ${branding.configuration.breakoutParticipantLimit}) `}
                        </JoinBreakoutButton>
                        {/* <JoinBreakoutParticipantsCounter> {`(${props.participants} / ${branding.configuration.breakoutParticipantLimit}) `}{branding.videoPageContentBranding.participantsCounterText}</JoinBreakoutParticipantsCounter> */}
                    </div>
                </div>
            </JoinBreakoutButtonArea>
        </JoinBreakoutRoot>
    )
}

interface ComingUpProps {
    nextEventDate?: EventDate
    calculatedChange: number
}

export const ComingUp: React.FC<ComingUpProps> = (props) => {
    const [millisecondsLeft, setMillisecondsLeft] = useState<number>(props.calculatedChange)

    const [time, setTime] = useState<string | null>(null)

    const lang = useLanguageState().getLanguage()

    function getTimeString(millisecondsLeft: number) {
        if (millisecondsLeft >= 172800000 && props.nextEventDate) {
            // checks if the next eventdate is in 2 or more days
            return branding.videoPageContentBranding.startingInXDaysText
                .replace(
                    "${date}",
                    format(moment(props.nextEventDate?.date).toDate(), branding.eventTiming.eventDaysFormatPatternEvtDetailPage, {
                        locale: lang === "de" ? de : en
                    })
                )
                .replace("${time}", props.nextEventDate?.start || "")
        } else if (millisecondsLeft >= 86400000 && props.nextEventDate) {
            // checks if the next eventdate is in more than 24 hours, but less than 2 days
            return branding.videoPageContentBranding.startingTomorrowText.replace("${time}", props.nextEventDate?.start || "")
        } else {
            setTime(
                moment(millisecondsLeft - 1000)
                    .utc()
                    .format("HH:mm:ss")
            )
            return moment(millisecondsLeft - 1000)
                .utc()
                .format("HH:mm:ss")
        }
    }

    useEffect(() => {
        setTime(getTimeString(millisecondsLeft))
        // eslint-disable-next-line
    }, [lang])

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(getTimeString(millisecondsLeft))
            setMillisecondsLeft(millisecondsLeft - 1000)
        }, 1000)

        return () => clearInterval(interval)
    })

    if (time === null) return null

    return (
        <ComingUpRoot style={{ borderTop: "none" }}>
            {/* <ComingUpIcon /> */}
            <ComingUpMessage>
                <span>{branding.videoPageContentBranding.comingUpText}</span>{" "}
                <ComingUpSpanMessage
                    style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                        whiteSpace: "pre-line",
                        wordBreak: "break-word",
                        maxWidth: "80%"
                    }}
                >
                    {" "}
                    {props.nextEventDate?.name || ""}{" "}
                </ComingUpSpanMessage>
            </ComingUpMessage>
            <ComingUpButtonArea>
                <ComingUpMessage>{time}</ComingUpMessage>
            </ComingUpButtonArea>
        </ComingUpRoot>
    )
}

const ComingUpRoot = styled.div`
    display: flex;
    flex: 1;
    flex-flow: row;
    /* border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"}; */
    border-right: none;
    /* justify-content: space-between; */
    /* align-items: center; */
`
const ComingUpMessage = styled.div`
    display: flex;
    position: absolute;
    /* flex-flow: row; */
    font-family: ${branding.font1};
    font-size: 16px;
    padding: 0px 0px;

    /* flex: 0 0 18%; */
    @media (max-width: 2000px) {
        max-width: 700px;
    }
    @media (max-width: 1850px) {
        max-width: 500px;
        font-size: 14px;
    }

    @media (max-width: 1500px) {
        max-width: 400px;
        font-size: 14px;
    }

    @media (max-width: 1250px) {
        max-width: 300px;
        font-size: 12px;
    }
    @media (max-width: 1110px) {
        max-width: 250px;
        font-size: 12px;
    }
`
const ComingUpSpanMessage = styled.div`
    margin-left: 5px;
    font-weight: bold;
    white-space: pre-line;
    word-break: break-word;
    @media (max-width: 1980px) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`
const ComingUpButtonArea = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 0 0 99%;
    flex-flow: column-reverse;

    /* 
    @media(max-width: 1550px)
    {
        flex: 0 0 75%;
    }
    @media(max-width: 1350px)
    {
        flex: 0 0 70%;
    } */
    /* flex: 0 0 97%; */
    /* margin-top: 8px; */
`
// const ComingUpIcon = styled.div`
//     display: flex;
//     flex: 0 0 15%;
// `

const JoinBreakoutRoot = styled.div`
    display: flex;
    flex: 1;
    flex-flow: row;
    /* border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"}; */
    border-right: none;
    /* justify-content: space-between; */
    /* align-items: center; */
    padding-top: 25px;
    margin-bottom: -75px;
`

const JoinBreakoutMessage = styled.div`
    display: flex;
    flex-flow: row;
    font-family: ${branding.font1};
    font-size: 16px;
    padding: 0 25px;
    flex: 0 0 33%;
`
const JoinBreakoutButtonArea = styled.div`
    display: flex;
    flex-flow: column;
    flex: 0 0 49%;
    z-index: 100;
    top: 0;
    /* margin-top: 8px; */
`

const JoinBreakoutButton = styled(NavLink)<{ disabled?: boolean }>`
    width: 200px;
    display: flex;
    border: 1px solid ${branding.videoPageContentBranding.joinBreakoutButtonTextColor};
    border-radius: 5px;
    height: 35px;
    justify-content: center;
    align-items: center;
    pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
    opacity: ${(props) => (props.disabled ? "50%" : "100%")};
    font-family: ${branding.font1};
    background-color: ${branding.videoPageContentBranding.joinBreakoutButtonColor};
    color: ${branding.videoPageContentBranding.joinBreakoutButtonTextColor};
    font-size: 12px;

    &:hover,
    &:active,
    &:link,
    &:focus {
        text-decoration: none;
        color: ${branding.videoPageContentBranding.joinBreakoutButtonTextColor};
    }
`

const JoinBreakoutIcon = styled.div`
    display: flex;
`

// const JoinBreakoutParticipantsCounter = styled.div`
//     display: flex;
//     flex-flow: row;
//     text-align: center;
//     justify-content: center;
//     font-family: ${branding.font1};
//     font-size: 12px;
//     margin: 0 auto;
// `

export default VideoPageContent
