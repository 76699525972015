import { API } from "aws-amplify"
import { useEffect, useState } from "react"
import * as React from "react"
import { Tooltip } from "react-bootstrap"
import styled from "styled-components"
import { buildGraphqlArgs, getUnreadCounterByUser, UnreadObject } from "../backendServices/GraphQLServices"
import branding from "../branding/branding"
import { communicationCenterSelectedTab, defaultLogger as logger, useAppState } from "../globalStates/AppState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { onUpdateUnreadCounterById } from "../graphql/subscriptions"
import { AvatarWithPresenceState } from "../ui/avatarWithPresenceState/AvatarWithPresenceState"
import CenteredLoader from "../ui/CenteredLoader"
import { IconCCChats, IconCCNetworking, IconCCNotifications, IconCCSchedule, IconNetworkingToggleClose } from "../ui/Icons"
import ChatsTab from "./ChatsTab"
import { ContactEntryType } from "./ContactEntry"
import NetworkingTab from "./NetworkingTab"
import NotificationsTab from "./NotificationsTab"
import ScheduleTab, { ScheduleListType } from "./ScheduleTab"
import SettingsTab from "./settings/SettingsTab"
import { CustomOverlayTrigger } from "../ui/CustomOverlayTrigger"
import { Placement } from "react-bootstrap/esm/Overlay"
import { trackSelectContent } from "../utils/GTMTracking"

/* &>* height: 59px because there is a border of 1px and we need to match the 60px of the topbar */
const NavigationAreaRoot = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    color: ${branding.primaryColor ?? "black"};
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    min-height: 60px;
    max-width: 100%;

    & > * {
        height: 59px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .networkingCollapsed & {
        border-bottom: 1px solid hsla(0, 0%, 0%, 0%);
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        & > * {
            flex-grow: 0;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
`

const CommunicationAreaNavigationItem = styled.div<{ disabled?: boolean }>`
    cursor: ${(props) => (props.disabled ? "none" : "pointer")};
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
    max-width: 40px;
    width: 20px;
    margin-left: 25px;
    text-align: center;
    border: none;
    background: none;
`

const CommunicationAreaNavigationItemAvatar = styled.div`
    cursor: pointer;
    max-width: 40px;
    width: 20px;
    margin-left: 25px;
    margin-right: 20px;
    text-align: center;
`

const FairLogo = styled.img`
    width: 30px;
    height: 30px;
    min-width: 30px;
    margin-bottom: 80px;
    margin-left: 15px;
    object-fit: contain;
    cursor: pointer;
`

const DummyFlexItem = styled.div`
    display: none;
    flex-grow: 1 !important;
    .networkingCollapsed & {
        display: block;
    }
`
const AvatarRootArea = styled.div`
    padding-left: 22px;
`

const CommunicationAreaRoot = styled.div`
    width: 320px;
    min-width: 320px;

    & input::placeholder {
        color: ${branding.communicationAreaSearchPlaceholderColor ?? "black"};
    }

    /* transition: width 0.3s ease-in-out, min-width 0.3s ease-in-out; */

    &:not(.active) {
        min-width: 60px;
        width: 60px;
    }

    display: flex;
    flex-direction: column;

    font-size: 0.9rem;
    background-color: #fff;

    .chatlist-scrollref {
        background-color: ${branding.chatListBackgroundCommunicationAreaColor ?? "white"} !important;
    }
`

export enum NetworkingListType {
    RELEVANT,
    REQUESTS,
    CONTACTS
}

interface CommunicationAreaProps {
    reloadScheduleList: boolean
    setReloadScheduleList: (value: boolean) => void
}

const CommunicationArea: React.FunctionComponent<CommunicationAreaProps> = (props) => {
    const appState = useAppState()
    const [unreadArray, setUnreadArray] = useState({ requests: 0, contacts: 0, conversations: 0, schedules: 0 })
    const userLink = useLoggedInState()
    const loggedInUserId = userLink.user()?.profileId

    function newUnreadObject(unread: UnreadObject) {
        return {
            requests: unread.requests ? unread.requests : 0,
            contacts: unread.contacts ? unread.contacts : 0,
            conversations: unread.conversations ? unread.conversations : 0,
            schedules: unread.schedules && unread.schedules > 0 ? unread.schedules : 0
        }
    }

    useEffect(() => {
        let params = new URLSearchParams(window.location.search)
        let communicationArea = params.get("communicationArea")
        let type = params.get("type") // Used to navigate instantly to schedule type area in case for email link visiting

        if (communicationArea && type) {
            if (communicationArea === "PEOPLE") {
                switch (type) {
                    case "NETWORKING":
                        appState.setPeopleSubtab(NetworkingListType.RELEVANT)
                        break
                    case "REQUESTS":
                        appState.setPeopleSubtab(NetworkingListType.REQUESTS)
                        break
                    default:
                        appState.setPeopleSubtab(NetworkingListType.CONTACTS)
                        break
                }
            } else if (communicationArea === "SCHEDULE") {
                switch (type) {
                    case "PENDING":
                        appState.setSchedulesSubtab(ScheduleListType.PENDING)
                        break
                    case "DECLINED":
                        appState.setSchedulesSubtab(ScheduleListType.DECLINED)
                        break
                    default:
                        appState.setSchedulesSubtab(ScheduleListType.CONFIRMED)
                        break
                }
            }
        }
        ;(async () => {
            if (loggedInUserId) {
                const unreadRecord = (await getUnreadCounterByUser(loggedInUserId)) as UnreadObject
                if (!unreadRecord) {
                    logger.warn(
                        "## Error, only in Dev Environment: can't find users unread record because the unreadTable is not valid initialized! -> use https://dmexco-int-event-cloud.com/rest/admin/userpool/DMEXCO/filldynamodbusertable with your environment, look in readme whithin messe.backend"
                    )
                    return
                }
                setUnreadArray(newUnreadObject(unreadRecord))

                const subscriptionUpdate = (
                    (await API.graphql(buildGraphqlArgs(onUpdateUnreadCounterById, { id: loggedInUserId }))) as any
                ).subscribe({
                    next: (resp: any) => {
                        const values = resp.value.data?.onUpdateUnreadCounterById
                        if (values) {
                            setUnreadArray(newUnreadObject(values))
                        }
                    }
                })
                return () => {
                    try {
                        subscriptionUpdate.unsubscribe()
                    } catch (error: any) {
                        logger.error({
                            message: "CommunicationArea.tsx Unsubscribe failed",
                            errorMessage: error.message,
                            errorStack: error.stack
                        })
                    }
                }
            }
        })()
        //eslint-disable-next-line
    }, [loggedInUserId])

    return (
        <div style={{ height: "100%", borderLeft: branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9" }}>
            <CommunicationAreaRoot
                style={{ height: appState.isCommunicationCenterOpen() ? "95%" : "100%" }}
                className={appState.isCommunicationCenterOpen() ? "active" : "networkingCollapsed"}
            >
                <NavigationArea unreadArray={unreadArray} />
                {appState.isCommunicationCenterOpen() && (
                    <Content
                        unreadArray={unreadArray}
                        reloadScheduleList={props.reloadScheduleList}
                        setReloadScheduleList={props.setReloadScheduleList}
                    />
                )}
                {!appState.isCommunicationCenterOpen() && branding.communicationArea.showFairLogo && (
                    <FairLogo src="/branding/applicationMedia/organizerlogo_closed.png" />
                )}
                <TabFooter />
            </CommunicationAreaRoot>
        </div>
    )
}

const IconContainer = styled.button<{ margin?: string }>`
    display: inline-block;
    position: relative;
    margin-left: ${(props) => props.margin};
    border: none;
    background: none;
`

const BadgeUnreadCounter = styled.span<{ visible?: boolean }>`
    position: relative;
    bottom: 12px;
    right: 10px;
    background-color: ${branding.communicationArea.badgeUnreadCounterBgColor ?? "#000"};
    border-radius: 50%;
    margin-left: 1px;
    border: 1px solid ${branding.communicationArea.badgeUnreadCounterBorderColor ?? "#fff"};
    font-size: 10px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: ${(props) => (props.visible ? "hidden" : "visible")};
`

const NetworkingTabWrapper = styled.div`
    z-index: 0;
`

interface UnReadArrayProps {
    unreadArray: {
        requests: number
        contacts: number
        conversations: number
        schedules: number
    }
}

const NavigationArea: React.FC<UnReadArrayProps> = (props) => {
    const userState = useLoggedInState()
    const appState = useAppState()
    const eventKey = appState.communicationCenterSelectedTab
    const tooltipPlacement: Placement = appState.isCommunicationCenterOpen() ? "bottom" : "left"

    if (appState.communicationCenterSelectedTab === undefined) {
        appState.setPeopleSubtab()
    }

    const itemClasses = (targetEventKey: communicationCenterSelectedTab) => {
        return targetEventKey === eventKey ? "selected" : ""
    }

    function isDisplayMode(mode: communicationCenterSelectedTab): boolean {
        if (!appState.isCommunicationCenterOpen()) {
            return true
        }

        return appState.communicationCenterSelectedTab === mode
    }

    return (
        <NavigationAreaRoot
            onClick={() => {
                if (!appState.isCommunicationCenterOpen()) {
                    appState.toggleNetworking()
                }
            }}
        >
            {!appState.isCommunicationCenterOpen() && (
                <CustomOverlayTrigger
                    key={("settings-" + tooltipPlacement) as string}
                    placement={tooltipPlacement}
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="settings-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                            {branding.communicationArea.settingsTitle}
                        </Tooltip>
                    }
                >
                    <CommunicationAreaNavigationItemAvatar
                        style={{ marginBottom: "25px" }}
                        className={itemClasses(communicationCenterSelectedTab.SETTINGS)}
                        onClick={() => {
                            appState.setCommunicationCenterSelectedTab(communicationCenterSelectedTab.SETTINGS)
                        }}
                    >
                        <AvatarRootArea>
                            <AvatarWithPresenceState
                                avatarSize={30}
                                badgeSize={10}
                                badgeRight={25}
                                badgeTop={18}
                                showAvatarBadge={true}
                                userId={userState.user()?.profileId}
                                initPresenceByList={false}
                                // userType={userState.user()?.type}
                                content={{
                                    pictureUrl: userState.user()?.logoUrl,
                                    alt: [userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ")
                                }}
                            ></AvatarWithPresenceState>
                        </AvatarRootArea>
                    </CommunicationAreaNavigationItemAvatar>
                </CustomOverlayTrigger>
            )}

            <CustomOverlayTrigger
                key={("people-" + tooltipPlacement) as string}
                placement={tooltipPlacement}
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="people-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                        {branding.communicationArea.peopleTabTitle}
                    </Tooltip>
                }
            >
                <CommunicationAreaNavigationItem
                    disabled={branding.communicationArea.disableCCNetworkingIcon}
                    className={itemClasses(communicationCenterSelectedTab.NETWORKING)}
                    onClick={() => {
                        appState.setCommunicationCenterSelectedTab(communicationCenterSelectedTab.NETWORKING)

                        trackSelectContent(branding.communicationArea.peopleTabTitle, "Enter", "Networking")
                    }}
                >
                    <IconContainer
                        disabled={branding.communicationArea.disableCCNetworkingIcon}
                        margin={
                            !appState.isCommunicationCenterOpen() &&
                            (props.unreadArray.requests > 0 || props.unreadArray.contacts > 0)
                                ? "-5px"
                                : !appState.isCommunicationCenterOpen() &&
                                  (props.unreadArray.requests === 0 || props.unreadArray.contacts === 0)
                                ? "-30px"
                                : appState.isCommunicationCenterOpen() &&
                                  (props.unreadArray.requests > 0 || props.unreadArray.contacts > 0)
                                ? "20px"
                                : "0px"
                        }
                    >
                        {IconCCNetworking({
                            fill:
                                isDisplayMode(communicationCenterSelectedTab.NETWORKING) &&
                                appState.isCommunicationCenterOpen() &&
                                !branding.communicationArea.disableCCNetworkingIcon
                                    ? branding.activeIconColor
                                    : branding.communicationArea.inactiveItemColor
                        })}
                    </IconContainer>
                    {props.unreadArray.requests + props.unreadArray.contacts <= 0 ? null : (
                        <h6 style={{ fontSize: "14px" }}>
                            <BadgeUnreadCounter
                                visible={branding.communicationArea.disableCCNetworkingIcon}
                                className="badge badge-pill badge-dark"
                                style={{
                                    backgroundColor: branding.communicationArea.newNotificationColor
                                }}
                            >
                                {props.unreadArray.requests + props.unreadArray.contacts}
                            </BadgeUnreadCounter>
                        </h6>
                    )}
                </CommunicationAreaNavigationItem>
            </CustomOverlayTrigger>

            <CustomOverlayTrigger
                key={("chat-" + tooltipPlacement) as string}
                placement={tooltipPlacement}
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="chat-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                        {branding.communicationArea.chatTabTitle}
                    </Tooltip>
                }
            >
                <CommunicationAreaNavigationItem
                    disabled={branding.communicationArea.disableCCChatIcon}
                    className={itemClasses(communicationCenterSelectedTab.CHATS)}
                    onClick={() => {
                        if (appState.communicationCenterSelectedTab === communicationCenterSelectedTab.CHATS) {
                            appState.setChatsSubtab(null)
                        } else {
                            appState.setChatsSubtab()
                        }

                        trackSelectContent(branding.communicationArea.chatTabTitle, "Enter", "Chat")
                    }}
                >
                    <IconContainer
                        disabled={branding.communicationArea.disableCCChatIcon}
                        margin={
                            !appState.isCommunicationCenterOpen() && props.unreadArray.conversations > 0
                                ? "-5px"
                                : !appState.isCommunicationCenterOpen() && props.unreadArray.conversations <= 0
                                ? "-30px"
                                : appState.isCommunicationCenterOpen() && props.unreadArray.conversations > 0
                                ? "20px"
                                : "0px"
                        }
                    >
                        {IconCCChats({
                            fill:
                                isDisplayMode(communicationCenterSelectedTab.CHATS) &&
                                appState.isCommunicationCenterOpen() &&
                                !branding.communicationArea.disableCCChatIcon
                                    ? branding.activeIconColor
                                    : branding.sideIconBar.inactiveItemColor
                        })}
                    </IconContainer>
                    {props.unreadArray.conversations <= 0 ? null : (
                        <h6>
                            <BadgeUnreadCounter
                                visible={branding.communicationArea.disableCCChatIcon}
                                className="badge badge-pill badge-dark"
                                style={{
                                    backgroundColor: branding.communicationArea.newNotificationColor
                                }}
                            >
                                {props.unreadArray.conversations}
                            </BadgeUnreadCounter>
                        </h6>
                    )}
                </CommunicationAreaNavigationItem>
            </CustomOverlayTrigger>

            <CustomOverlayTrigger
                key={("schedule-" + tooltipPlacement) as string}
                placement={tooltipPlacement}
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="schedule-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                        {branding.communicationArea.scheduleTabTitle}
                    </Tooltip>
                }
            >
                <CommunicationAreaNavigationItem
                    disabled={branding.communicationArea.disableCCMeetingIcon}
                    className={itemClasses(communicationCenterSelectedTab.SCHEDULE)}
                    onClick={() => {
                        appState.setCommunicationCenterSelectedTab(communicationCenterSelectedTab.SCHEDULE)

                        trackSelectContent(branding.communicationArea.scheduleTabTitle, "Enter", "Schedule")
                    }}
                >
                    <IconContainer
                        disabled={branding.communicationArea.disableCCMeetingIcon}
                        margin={
                            !appState.isCommunicationCenterOpen() && props.unreadArray.schedules > 0
                                ? "-5px"
                                : !appState.isCommunicationCenterOpen() && props.unreadArray.schedules === 0
                                ? "-30px"
                                : appState.isCommunicationCenterOpen() && props.unreadArray.schedules > 0
                                ? "20px"
                                : "0px"
                        }
                    >
                        {IconCCSchedule({
                            fill:
                                isDisplayMode(communicationCenterSelectedTab.SCHEDULE) &&
                                appState.isCommunicationCenterOpen() &&
                                !branding.communicationArea.disableCCMeetingIcon
                                    ? branding.activeIconColor
                                    : branding.sideIconBar.inactiveItemColor
                        })}
                    </IconContainer>
                    {props.unreadArray.schedules <= 0 ? null : (
                        <h6>
                            <BadgeUnreadCounter
                                visible={branding.communicationArea.disableCCMeetingIcon}
                                className="badge badge-pill badge-dark"
                                style={{
                                    backgroundColor: branding.communicationArea.newNotificationColor
                                }}
                            >
                                {props.unreadArray.schedules}
                            </BadgeUnreadCounter>
                        </h6>
                    )}
                </CommunicationAreaNavigationItem>
            </CustomOverlayTrigger>

            <CustomOverlayTrigger
                key={("notifications-" + tooltipPlacement) as string}
                placement={tooltipPlacement}
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="notifications-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                        {branding.communicationArea.notificationsTabTitle}
                    </Tooltip>
                }
            >
                <CommunicationAreaNavigationItem
                    disabled={branding.communicationArea.disableCCNotificationIcon}
                    className={itemClasses(communicationCenterSelectedTab.NOTIFICATIONS)}
                    onClick={() => {
                        appState.setCommunicationCenterSelectedTab(communicationCenterSelectedTab.NOTIFICATIONS)

                        trackSelectContent(branding.communicationArea.notificationsTabTitle, "Enter", "Notification")
                    }}
                >
                    <IconContainer
                        disabled={branding.communicationArea.disableCCNotificationIcon}
                        margin={!appState.isCommunicationCenterOpen() ? "-26px" : "0px"}
                    >
                        {IconCCNotifications({
                            fill:
                                isDisplayMode(communicationCenterSelectedTab.NOTIFICATIONS) &&
                                appState.isCommunicationCenterOpen() &&
                                !branding.communicationArea.disableCCNotificationIcon
                                    ? branding.activeIconColor
                                    : branding.sideIconBar.inactiveItemColor
                        })}
                    </IconContainer>
                </CommunicationAreaNavigationItem>
            </CustomOverlayTrigger>

            <DummyFlexItem />

            {appState.isCommunicationCenterOpen() && (
                <CustomOverlayTrigger
                    key={("settings-" + tooltipPlacement) as string}
                    placement={tooltipPlacement}
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="settings-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                            {branding.communicationArea.settingsTitle}
                        </Tooltip>
                    }
                >
                    <CommunicationAreaNavigationItemAvatar
                        className={itemClasses(communicationCenterSelectedTab.SETTINGS)}
                        onClick={() => {
                            appState.setCommunicationCenterSelectedTab(communicationCenterSelectedTab.SETTINGS)

                            trackSelectContent(branding.communicationArea.notificationsTabTitle, "Enter", "Settings")
                        }}
                    >
                        <AvatarRootArea>
                            <AvatarWithPresenceState
                                avatarSize={30}
                                badgeSize={10}
                                badgeRight={25}
                                badgeTop={18}
                                showAvatarBadge={true}
                                userId={userState.user()?.profileId}
                                initPresenceByList={false}
                                content={{
                                    pictureUrl: userState.user()?.logoUrl,
                                    alt: [userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ")
                                }}
                            ></AvatarWithPresenceState>
                        </AvatarRootArea>
                    </CommunicationAreaNavigationItemAvatar>
                </CustomOverlayTrigger>
            )}
        </NavigationAreaRoot>
    )
}

interface ContentProps {
    unreadArray: {
        requests: number
        contacts: number
        conversations: number
        schedules: number
    }
    reloadScheduleList: boolean
    setReloadScheduleList: (value: boolean) => void
}

const Content: React.FunctionComponent<ContentProps> = (props) => {
    const appState = useAppState()

    switch (appState.communicationCenterSelectedTab) {
        case communicationCenterSelectedTab.NETWORKING:
            return (
                <NetworkingTabWrapper>
                    <NetworkingTab requests={props.unreadArray.requests} contacts={props.unreadArray.contacts} />
                </NetworkingTabWrapper>
            )
        case communicationCenterSelectedTab.CHATS:
            return <ChatsTab conversations={props.unreadArray.conversations} />
        case communicationCenterSelectedTab.SCHEDULE:
            return (
                <ScheduleTab
                    schedules={props.unreadArray.schedules}
                    reloadScheduleList={props.reloadScheduleList}
                    setReloadScheduleList={props.setReloadScheduleList}
                />
            )
        case communicationCenterSelectedTab.NOTIFICATIONS:
            return <NotificationsTab />
        case communicationCenterSelectedTab.SETTINGS:
            return <SettingsTab />
        default:
            return null
    }
}

export const SearchBarWidth = "290px"

export const TabContentArea = styled.div`
    margin-top: 15px;
    width: 100%;
`

export const TabEmpty = styled.div`
    padding-top: 30px;
    margin-left: 7%;
    margin-right: 7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: ${branding.font1};
    font-size: 15px;
    color: ${branding.mainInfoColor ?? "black"};
`

export const MultiSwitchRoot = styled.div`
    z-index: 0;
    margin-top: 20px;
`

export const TabRoot = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 80%;
    flex-grow: 1;
    .person-details {
        color: ${branding.mainInfoColor ?? "black"};
    }
    .expanded {
        color: ${branding.primaryColor ?? "black"};
    }
`

export const SubTabRoot = styled.div<{ tabType?: ContactEntryType }>`
    display: flex;
    flex-direction: column;
    //padding-top: 5px;
    height: ${(props) => (props.tabType === ContactEntryType.CONTACT ? "calc(100vh - 246px)" : "calc(100vh - 215px)")};
    overflow-x: hidden;

    & .ScrollbarsCustom-Content {
        padding-bottom: 40px !important;
        min-height: 0 !important;
    }

    & .ScrollbarsCustom-TrackY {
        height: ${(props) => (props.tabType === ContactEntryType.CONTACT ? "calc(100% - 60px)" : "calc(100% - 20px)")} !important;
    }
`

export const NextPageLoader = styled(CenteredLoader)`
    height: 120px;
    min-height: 120px;
`

export default CommunicationArea

/*********************************************************************************************
 * Profile image
 **********************************************************************************************/
const ProfileImageContainer = styled.div`
    padding-bottom: 10px;
    padding-right: 4px;
    margin-top: 0px;

    & .profile-img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #f7f7fa;
    }
`

const ProfileImageBiggerContainer = styled.div`
    padding-bottom: 10px;
    padding-right: 4px;
    margin-top: 0px;

    & .profile-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #f7f7fa;
    }
`

const TabFooterRoot = styled.div`
    position: fixed;
    background: #fff;
    height: 60px;
    width: 350px;
    bottom: 0;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    border-top: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    z-index: 10;
`

const NetworkingToggleRoot = styled.div`
    cursor: pointer;
    float: left;
    max-width: 40px;
    width: 20px;
    text-align: center;
    margin-top: 15px;
    margin-left: 20px;
`

const NetworkingToggleIconFooter = styled.div`
    z-index: 2;
    transform: rotate(0deg);
    transition: transform 0.8s, right 0.3s ease-in-out;
    background-color: hsla(0, 0%, 100%, 0%);
    color: ${branding.mainInfoColor ?? "black"};

    &:hover .NavItem {
        fill: gray;
    }
    .networkingCollapsed & {
        transform: rotate(180deg) scale(1);
    }
`

const FooterLogo = styled.img`
    position: relative;
    height: ${branding.communicationArea.organizerLogoHeight ?? "20px"};
    margin-top: ${branding.communicationArea.organizerLogoMarginTop ?? "15px"};
    margin-left: ${branding.communicationArea.organizerLogoMarginLeft ?? "80px"};
    object-fit: contain;
`

export const TabFooter: React.FunctionComponent = () => {
    const appState = useAppState()
    const organizerLogo: string = "/branding/applicationMedia/organizer_logo.png"

    return (
        <TabFooterRoot>
            <NetworkingToggleRoot onClick={appState.toggleNetworking}>
                <NetworkingToggleIconFooter>
                    {IconNetworkingToggleClose({ fill: branding.sideIconBar.sideIconColorDark })}
                </NetworkingToggleIconFooter>
            </NetworkingToggleRoot>
            <FooterLogo className="cc-footer-logo" src={organizerLogo} />
        </TabFooterRoot>
    )
}

interface ProfileImageProps {
    imageUrl: string
    profileId: string
    biggerContainer: boolean
    setLogoUrl?: Function
}

export function ProfileImage(props: ProfileImageProps) {
    const [imagePreview] = useState("")
    const [imageLoading] = useState(false)

    return props.biggerContainer ? (
        <ProfileImageBiggerContainer>
            <div className="profile-img" style={{ backgroundImage: `url("${imagePreview || props.imageUrl}")` }}>
                {imageLoading && <CenteredLoader />}
            </div>
        </ProfileImageBiggerContainer>
    ) : (
        <ProfileImageContainer>
            <div className="profile-img" style={{ backgroundImage: `url("${imagePreview || props.imageUrl}")` }}>
                {imageLoading && <CenteredLoader />}
            </div>
        </ProfileImageContainer>
    )
}
