import * as React from "react"
import styled from "styled-components"
import branding from "../branding/branding"
import AvatarDefaultContent from "./AvatarDefaultContent"
import { detect } from "detect-browser"
import { EntityType } from "../backendServices/Types"
import { getEntityInitials } from "../communicationAreaV2/helpers/GlobalFunctions"

export const AvatarStyled = styled.div<{ src?: string; size: number; backgroundSize?: string }>`
    position: relative;
    background-image: url("${(p) => p.src}");
    background-size: ${(props) => props.backgroundSize ?? "contain"};
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${(props) => (props.src ? "transparent" : "lightgrey")};
    padding: 0;
    width: ${(p) => p.size}px;
    max-width: 100%;
    height: ${(p) => p.size}px;
    text-align: center;
    line-height: ${(p) => p.size}px;
    color: white;
    font-weight: normal;
    font-size: ${(p) => p.size / 2.2}px;
    border-radius: 50%;

    .image {
        width: 100%;
        object-fit: ${(props) => props.backgroundSize ?? "contain"};
        -webkit-mask-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1));
        border-radius: 50%;
    }

    .overlay {
        width: 100%;
        position: absolute;
        object-fit: ${(props) => props.backgroundSize ?? "contain"};
        left: 0px;
        top: 0px;
        image-rendering: pixelated;
        -webkit-mask-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
        border-radius: 50%;
    }
`
const StatusContainer = styled.div<{ badgesize: number; badgetop: number; badgeright: number; badgeLeft?: number }>`
    width: ${(p) => p.badgesize}px;
    height: ${(p) => p.badgesize}px;
    position: absolute;
    right: ${(p) => p.badgeright}px;
    top: ${(p) => p.badgetop}px;
    left: ${(p) => p.badgeLeft}px;
    border: solid 1px white;
    border-radius: 100%;
    background-clip: content-box;
    background-color: #bdbdbd;
    color: white;
`

interface AvatarProps {
    src?: string
    size?: number
    alt: string
    badgesize?: number
    badgeTop?: number
    badgeRight?: number
    badgeLeft?: number
    showBadge?: boolean
    className?: string
    backgroundSize?: string
    type?: EntityType
    onClick?: () => void
}

export function encodePictureUrl(pictureUrl: string | null | undefined) {
    if (pictureUrl) {
        return pictureUrl.replace(branding.configuration.sotName, encodeURI(branding.configuration.sotName))
    }
}

export const AvatarWithDefault: React.FC<AvatarProps> = React.memo((props) => {
    const size = props.size ?? 40
    const badgesize = props.badgesize ?? 15
    const badgetop = props.badgeTop ?? 22
    const badgeright = props.badgeRight ?? 18
    const srcLength = props.src ? props.src.length : 0
    const initialsLength = props.type === "networking_user" ? 2 : 1

    const browser = detect()

    const isChromium = browser?.name === "chrome" || browser?.name === "edge-chromium"

    return (
        <AvatarStyled
            {...props}
            src={encodePictureUrl(props.src)}
            size={size}
            className={props.className + " avatar-styled"}
            backgroundSize={props.backgroundSize}
            onClick={() => {
                if (props.onClick) {
                    props.onClick!()
                }
            }}
        >
            {srcLength > 0
                ? ""
                : getEntityInitials(props.alt, initialsLength) || <AvatarDefaultContent width={size} height={size} />}
            {props.showBadge && <StatusContainer badgesize={badgesize} badgetop={badgetop} badgeright={badgeright} />}

            {srcLength > 0 && isChromium && size < 60 && (
                <>
                    <img className="image" src={props.src || ""} width={props.size + "px"} height={props.size + "px"} alt=" " />

                    <img className="overlay" src={props.src || ""} width={props.size + "px"} height={props.size + "px"} alt=" " />
                </>
            )}
        </AvatarStyled>
    )
})
