import { useEffect, useState } from "react"
import { IconCloseParticipants } from "../../../ui/Icons"
import {
    StyledSearchPersonInput,
    SearchPersonsContainer,
    InvitedPersonsContainer,
    InvitedPersonItem,
    InvitePersonsButton,
    RemoveInvitedPersonButton,
    MaxAttendeesMessage,
    AttendeesRoot
} from "./RosterAttendees.styled"
import { useRosterState, MeetingStatus, useMeetingStatus } from "amazon-chime-sdk-component-library-react"
import { useMeetingController } from "../../context/MeetingController"
import { Contact } from "../../../backendServices/Types"
import { useCallback } from "react"
import AvatarWithPresenceState from "../../../ui/avatarWithPresenceState/AvatarWithPresenceState"
import { CustomOverlayTrigger } from "../../../ui/CustomOverlayTrigger"
import { Tooltip } from "react-bootstrap"
import branding from "../../../branding/branding"
import { useMeetingInvitation } from "../../context/MeetingInvitation"
import Recorder from "../Recorder/Recorder"
import { InvitationType } from "../../../API"
import RosterAttendeesGroup from "../RosterAtteendeesGroup/RosterAttendeesGroup"

export type AttendeeRole = "recorder" | "member" | "moderator" | "viewer"

export enum KickOrBanMode {
    NONE,
    KICK,
    BAN
}

export interface AttendeeData {
    chimeAttendeeId: string
    id?: string
    name?: string
    role?: AttendeeRole
    avatarUrl?: string
    position?: string
    positionDe?: string
    connectionStatus?: string
    company?: string
    userType?: string
}

function Attendees() {
    const { roster } = useRosterState()
    const [attendees, setAttendees] = useState(Object.values(roster))
    const [participants, setParticipants] = useState<AttendeeData[]>([])
    const [moderators, setModerators] = useState<AttendeeData[]>([])
    const [recorder, setRecorder] = useState<AttendeeData[]>([])
    const [invitedPersons, setInvitedPersons] = useState<Contact[]>([])
    const meetingController = useMeetingController()
    const meetingInvitation = useMeetingInvitation()
    const queryParams: any = window.location.search
    const debugMode = queryParams.includes("debug=true") ? true : false
    const meetingStatus = useMeetingStatus()

    useEffect(() => {
        setAttendees(Object.values(roster))
    }, [roster])

    useEffect(() => {
        setModerators(attendees.filter((participant: AttendeeData) => participant.role === "moderator"))
        setParticipants(attendees.filter((participant: AttendeeData) => participant.role === "member"))
        setRecorder(attendees.filter((participant: AttendeeData) => participant.role === "recorder"))
        // eslint-disable-next-line
    }, [roster, attendees])

    const onPersonSelected = useCallback(
        (person: Contact) => {
            const personAddedAlready = invitedPersons.find((x) => x.id === person.id) || null
            if (person && personAddedAlready === null) {
                setInvitedPersons((invitedPersons) => [...invitedPersons, person])
            }
        },
        [invitedPersons]
    )

    const sendInvites = useCallback(async () => {
        // cut the the first three chars from meeting id if the user is already in the meeting
        const meetingId =
            meetingStatus === MeetingStatus.Succeeded
                ? meetingController.getExternalMeetingId()?.slice(3)
                : meetingController.getExternalMeetingId()

        invitedPersons.map((person) => {
            meetingInvitation.sendInvite(person.id, InvitationType.AUDIO, {
                meetingId: meetingId || "",
                meetingKind: meetingController.getMeetingKind() || "virtualCafe"
            })
            return null
        })
        setInvitedPersons([])
        // eslint-disable-next-line
    }, [invitedPersons])

    return (
        <AttendeesRoot>
            {meetingController.getMeetingKind() !== "roundtable" && (
                <SearchPersonsContainer>
                    {attendees.length + invitedPersons.length >= meetingController.getMaxAttendees() ? (
                        <MaxAttendeesMessage>{branding.conferenceTexts.maxAttendees}</MaxAttendeesMessage>
                    ) : (
                        <StyledSearchPersonInput
                            topSearch="0"
                            shortenPersonInfo={true}
                            placeholder={branding.conferenceTexts.searchForParticipantsPlaceholder}
                            personClicked={onPersonSelected}
                            disableIds={[
                                ...invitedPersons.map((person: Contact) => person.id),
                                ...attendees.map((attendee: AttendeeData) => attendee?.id || "")
                            ]}
                        />
                    )}
                    {invitedPersons.length ? (
                        <>
                            <InvitedPersonsContainer>
                                {invitedPersons?.map((person: Contact) => {
                                    return (
                                        <InvitedPersonItem key={person.id}>
                                            <CustomOverlayTrigger
                                                key={person.id}
                                                placement="bottom"
                                                overlay={
                                                    <Tooltip id={`tooltip`} style={{ fontFamily: branding.font1 }}>
                                                        <strong>{person.firstName}</strong>
                                                    </Tooltip>
                                                }
                                            >
                                                <div>
                                                    <AvatarWithPresenceState
                                                        userId={person.id}
                                                        avatarSize={55}
                                                        badgeSize={15}
                                                        showAvatarBadge={true}
                                                        badgeRight={0.1}
                                                        badgeTop={37}
                                                        content={{
                                                            pictureUrl: person.logoUrl,
                                                            alt: `${person.firstName} ${person.lastName}`
                                                        }}
                                                    />
                                                </div>
                                            </CustomOverlayTrigger>
                                            <RemoveInvitedPersonButton
                                                onClick={() =>
                                                    setInvitedPersons(
                                                        invitedPersons.filter(
                                                            (invitedPerson: Contact) => invitedPerson.id !== person.id
                                                        )
                                                    )
                                                }
                                            >
                                                {IconCloseParticipants({ fill: branding.sideIconBar.sideIconColorLight })}
                                            </RemoveInvitedPersonButton>
                                        </InvitedPersonItem>
                                    )
                                })}
                            </InvitedPersonsContainer>
                            <InvitePersonsButton onClick={sendInvites}>
                                {branding.conferenceTexts.addParticipantsText}
                            </InvitePersonsButton>
                        </>
                    ) : null}
                </SearchPersonsContainer>
            )}
            {meetingController.getMeetingKind() === "greenroom" && (meetingController.getIsMod() || debugMode) && (
                <Recorder recorderAvailable={recorder.length > 0} />
            )}
            {moderators?.length > 0 && (
                <RosterAttendeesGroup
                    attendees={moderators}
                    title={branding.roster.moderatorsSectionTitle}
                    groupType={"moderator"}
                />
            )}
            {participants?.length > 0 && (
                <RosterAttendeesGroup
                    attendees={participants}
                    title={branding.roster.participantsSectionTitle}
                    groupType={"member"}
                />
            )}
        </AttendeesRoot>
    )
}

export default Attendees
