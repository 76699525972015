import { FC } from "react"
import {
    StyledAvatar,
    SuggestionNewsExternalIndicator,
    SuggestionRow,
    SuggestionSubtitle,
    SuggestionText,
    SuggestionTitle
} from "./SuggestBox"
import { Suggestion } from "../backendServices/Types"
import { IconNewsLink } from "../ui/Icons"
import branding from "../branding/branding"
import useEntityLogoUrl from "../hooks/useEntityLogoUrl"

interface SuggestBoxEntityLogoProps {
    suggestion: Suggestion
}

const SuggestBoxEntity: FC<SuggestBoxEntityLogoProps> = (props) => {
    const entityLogoUrl = useEntityLogoUrl(props.suggestion.icon)

    return (
        <SuggestionRow>
            {entityLogoUrl && props.suggestion.type !== "person" && props.suggestion.type !== "networking_user" ? ( // we don't show the placeholder if the entity is person or networking_user
                <StyledAvatar alt={props.suggestion.title} src={entityLogoUrl} size={40} />
            ) : (
                <StyledAvatar
                    alt={props.suggestion.title}
                    src={props.suggestion.icon ?? ""}
                    type={props.suggestion.type}
                    size={40}
                />
            )}
            <SuggestionText>
                <SuggestionTitle>
                    {props.suggestion.title}
                    {props.suggestion.additionalData && props.suggestion.additionalData?.externalUrl && (
                        <SuggestionNewsExternalIndicator>
                            {IconNewsLink({
                                fill: branding.newsPageContent.newsTilesLayoutDateTimeColor,
                                height: "10",
                                width: "10"
                            })}
                        </SuggestionNewsExternalIndicator>
                    )}
                </SuggestionTitle>
                <SuggestionSubtitle>{props.suggestion.subtitle}</SuggestionSubtitle>
            </SuggestionText>
        </SuggestionRow>
    )
}

export default SuggestBoxEntity
