import { VideoFrameBuffer, VideoFrameProcessor, CanvasVideoFrameBuffer } from "amazon-chime-sdk-js"
import { SelfieSegmentation, Results } from "@mediapipe/selfie_segmentation"
import "@mediapipe/selfie_segmentation/selfie_segmentation.js"
import "@mediapipe/selfie_segmentation/selfie_segmentation.binarypb"
import "@mediapipe/selfie_segmentation/selfie_segmentation.tflite"

export type BackgroundType = "choose" | "blur" | "none"

export class VirtualBackgroundProcessor implements VideoFrameProcessor {
    /* #region  Members */
    background = new Image()
    activeEffect = "both"
    selfiSegmentation = new SelfieSegmentation({
        locateFile: (file) => {
            return `/virtualBackground/${file}`
        }
    })
    processBackground = false
    blur = false
    canvasBackground = document.createElement("canvas")

    lightWrapCanvas = document.createElement("canvas")

    // Canvas where the person is drawn, with everything else transparent
    personCanvas = document.createElement("canvas")
    personCanvasCtx = this.personCanvas!.getContext("2d")

    // Canvas where the result is drawn
    targetCanvas = document.createElement("canvas")
    targetCanvasCtx = this.targetCanvas!.getContext("2d")
    canvasVideoFrameBuffer = new CanvasVideoFrameBuffer(this.targetCanvas!)

    /* #endregion */

    /* #region Constructor and Deconstructor */
    constructor() {
        this.background.src = ""
        this.background.crossOrigin = "anonymous"
        this.selfiSegmentation.onResults(this.onResults)
        this.selfiSegmentation.setOptions({ modelSelection: 1 })
        this.selfiSegmentation.initialize()
    }

    async destroy() {
        //  this.targetCanvasCtx = null
        //  this.canvasVideoFrameBuffer.destroy()
        return
    }
    /* #endregion */

    /* #region  Setters */
    setBackground(backgroundType: BackgroundType, src: string | null) {
        switch (backgroundType) {
            case "none":
                this.processBackground = false
                this.blur = false
                this.background.src = ""
                break
            case "blur":
                this.processBackground = true
                this.blur = true
                this.background.src = ""
                break
            case "choose":
                if (!src) {
                    break
                }
                this.processBackground = true
                this.blur = false
                this.background.src = src
                break
        }
    }
    /* #endregion */

    /* #region  Process Video Frames */

    async process(buffers: VideoFrameBuffer[]) {
        // No frames? Nothing to do!
        if (!this.processBackground || buffers.length === 0 || !buffers[0].asCanvasElement) {
            return Promise.resolve(buffers)
        }

        // If the image we want to work with doesn't have a height or width, return it unproccessed
        const canvas = buffers[0].asCanvasElement()!
        const frameWidth = canvas!.width
        const frameHeight = canvas!.height
        if (frameWidth === 0 || frameHeight === 0) {
            return Promise.resolve(buffers)
        }

        let errorOccurred = false
        for (const f of buffers) {
            if (errorOccurred) {
                break // Exit the loop if an error has occurred
            }
            try {
                // @ts-ignore
                const canvas = f.asCanvasElement() as HTMLCanvasElement
                await this.selfiSegmentation.send({ image: canvas })
            } catch (err) {
                console.log("Exception:: ", err)
                errorOccurred = true
            }
        }
        buffers[0] = this.canvasVideoFrameBuffer
        return Promise.resolve(buffers)
    }
    /* #endregion */

    onResults = (results: Results) => {
        if (!this.targetCanvasCtx || !this.personCanvasCtx) return

        this.targetCanvas.width = results.image.width
        this.targetCanvas.height = results.image.height
        this.personCanvas.width = results.image.width
        this.personCanvas.height = results.image.height

        // Clear canvases
        this.targetCanvasCtx.save()
        this.personCanvasCtx.save()

        this.clearRect(this.targetCanvasCtx)
        this.personCanvasCtx.clearRect(0, 0, this.targetCanvas.width, this.targetCanvas.height)

        // Draw the mask. This will paint a read blob of the person with everything else transparent
        this.personCanvasCtx.globalCompositeOperation = "source-over"
        this.personCanvasCtx.drawImage(results.segmentationMask, 0, 0, this.targetCanvas.width, this.targetCanvas.height)

        // Now we draw the source image. With "source-in", we only take those pixel which aren't transparent.
        // This way we only get the source blob of the person, the rest transparent
        this.personCanvasCtx.globalCompositeOperation = "source-in"
        this.personCanvasCtx.drawImage(results.image, 0, 0, this.targetCanvas.width, this.targetCanvas.height)

        if (this.blur) {
            // Draw the source image to the target canvas with a blur filter
            const prevFilter = this.targetCanvasCtx.filter
            this.targetCanvasCtx.filter = "blur(25px)"
            this.targetCanvasCtx.drawImage(results.image, 0, 0, this.targetCanvas.width, this.targetCanvas.height)
            this.targetCanvasCtx.filter = prevFilter
        } else if (this.background.src !== "") {
            // Draw the selected image to the target canvas
            this.background.height = this.targetCanvas.height
            // This line will draw the choosen background image in a "cover" mode
            this.drawImageCovered(this.targetCanvasCtx, this.background)
        }

        // draw the person with transparent background onto the target
        this.targetCanvasCtx.drawImage(this.personCanvas, 0, 0, this.targetCanvas.width, this.targetCanvas.height)

        this.targetCanvasCtx.restore()
        this.personCanvasCtx.restore()
    }

    /**
     * By Ken Fyrstenberg Nilsen
     *
     * drawImageProp(context, image [, x, y, width, height [,offsetX, offsetY]])
     *
     * If image and context are only arguments rectangle will equal canvas
     *
     * @see https://stackoverflow.com/questions/21961839/simulation-background-size-cover-in-canvas
     */
    drawImageCovered(
        ctx: CanvasRenderingContext2D,
        img: HTMLImageElement,
        x?: number,
        y?: number,
        w?: number,
        h?: number,
        offsetX?: number,
        offsetY?: number
    ) {
        if (arguments.length === 2) {
            x = y = 0
            w = ctx.canvas.width
            h = ctx.canvas.height
        }

        // default offset is center
        offsetX = typeof offsetX === "number" ? offsetX : 0.5
        offsetY = typeof offsetY === "number" ? offsetY : 0.5

        // keep bounds [0.0, 1.0]
        if (offsetX < 0) offsetX = 0
        if (offsetY < 0) offsetY = 0
        if (offsetX > 1) offsetX = 1
        if (offsetY > 1) offsetY = 1

        let iw = img.naturalWidth,
            ih = img.naturalHeight,
            r = Math.min(w! / iw, h! / ih),
            nw = iw * r, // new prop. width
            nh = ih * r, // new prop. height
            cx,
            cy,
            cw,
            ch,
            ar = 1

        // decide which gap to fill
        if (nw < w!) ar = w! / nw
        if (Math.abs(ar - 1) < 1e-14 && nh < h!) ar = h! / nh // updated
        nw *= ar
        nh *= ar

        // calc source rectangle
        cw = iw / (nw / w!)
        ch = ih / (nh / h!)

        cx = (iw - cw) * offsetX
        cy = (ih - ch) * offsetY

        // make sure source rectangle is valid
        if (cx < 0) cx = 0
        if (cy < 0) cy = 0
        if (cw > iw) cw = iw
        if (ch > ih) ch = ih

        // fill image in dest. rectangle
        ctx.drawImage(img, cx, cy, cw, ch, x!, y!, w!, h!)
    }

    clearRect(ctx: CanvasRenderingContext2D) {
        let strokeStyle = ctx.strokeStyle
        let fillStyle = ctx.fillStyle
        ctx.strokeStyle = "#fff"
        ctx.fillStyle = "#fff"
        ctx.fillRect(0, 0, this.targetCanvas.width, this.targetCanvas.height)
        ctx.strokeStyle = strokeStyle
        ctx.fillStyle = fillStyle
    }
}
