import { useRouteMatch } from "react-router-dom"
import { meetingPageRoute } from "../../RoutePaths"
import { trackSelectContent } from "../../../utils/GTMTracking"
import TrackingLink from "../../../tracking/NavigationSource"
import { buildDetailLink } from "../../../contentArea/detailPages/DetailNavLink"
import { CustomOverlayTrigger } from "../../../ui/CustomOverlayTrigger"
import { Tooltip } from "react-bootstrap"
import branding from "../../../branding/branding"
import { ListItemIconContainer, SponsorLogo } from "../SideIconBar.styled"
import { SponsorItemProps } from "../SideIconBar"

const SponsorGridItem: React.FunctionComponent<SponsorItemProps> = (props: SponsorItemProps) => {
    let isMeetingPage = useRouteMatch(meetingPageRoute)

    return (
        <div
            onClick={() => {
                trackSelectContent(props.sponsor.name, "Top", "Sponsor")
            }}
        >
            <TrackingLink
                doClick={(e: any) => props.onClick(e)}
                to={buildDetailLink(props.sponsor.id, props.sponsor.name, "organization")}
                style={{ textDecoration: "none" }}
                src="SIDEBAR"
            >
                {props.isNavOpen && (
                    <CustomOverlayTrigger
                        trigger={["focus", "click", "hover"]}
                        placement="top"
                        delay={{ show: 0, hide: 0 }}
                        overlay={
                            <Tooltip
                                id={props.sponsor.id}
                                show={!props.isNavOpen}
                                style={{ color: branding.mainInfoColor, pointerEvents: "none", fontFamily: branding.font1 }}
                            >
                                {props.sponsor.name}
                            </Tooltip>
                        }
                    >
                        <ListItemIconContainer
                            style={{
                                background: isMeetingPage ? branding.sideIconBar.backgroundColorForTopSponsors : "#fff",
                                width: "40px",
                                height: "40px",
                                lineHeight: "40px"
                            }}
                        >
                            <SponsorLogo pictureUrl={props.sponsor.pictureURL} />
                        </ListItemIconContainer>
                    </CustomOverlayTrigger>
                )}
                {!props.isNavOpen && (
                    <ListItemIconContainer
                        style={{
                            background: isMeetingPage ? branding.sideIconBar.backgroundColorForTopSponsors : "#fff",
                            width: "40px",
                            height: "40px",
                            lineHeight: "40px"
                        }}
                    >
                        <SponsorLogo pictureUrl={props.sponsor.pictureURL} />
                    </ListItemIconContainer>
                )}
            </TrackingLink>
        </div>
    )
}

export default SponsorGridItem
