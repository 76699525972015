import { createState, State, useState } from "@hookstate/core"
import { API } from "aws-amplify"
import { useHistory } from "react-router"
import { InvitationType, InviteStatus } from "../../API"
import {
    buildGraphqlArgs,
    ensureChatConversation,
    ensureMeeting,
    getMeetingHistoryEntries,
    inviteToMeeting,
    MeetingParticipant,
    PresenceType,
    updateMeetingInvite
} from "../../backendServices/GraphQLServices"
import { MeetingKind } from "../../backendServices/MeetingServices"
import branding from "../../branding/branding"
import { useAppState } from "../../globalStates/AppState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { onMeetingInviteChangedLight } from "../../graphql/ownSubscriptions"
import { onMeetingInviteReceived } from "../../graphql/subscriptions"
import { accessPresenceState } from "../../ui/PresenceIndicator"
import { generateMeetingURL } from "../utils/generateMeetingURL"
import { defaultLogger as logger } from "../../globalStates/AppState"

const CALL_TIMEOUT = 60000

interface StateValues {
    outgoingInvites: MeetingParticipant[]
    incomingInvites: MeetingParticipant[]
    participationHistory: MeetingParticipant[]
    invitationType: InvitationType | null
    acceptanceType: InvitationType | null
}

const getStartValues = (): StateValues => {
    return {
        outgoingInvites: [],
        incomingInvites: [],
        participationHistory: [],
        invitationType: null,
        acceptanceType: null
    }
}

export interface MeetingInvitation {
    subscribeToCalls: (id: string) => Promise<void>
    acceptInvite: (id: string, invitationType?: InvitationType) => Promise<void>
    sendInvite: (
        hailedId: string,
        invitationType: InvitationType,
        meetingData?: { meetingId: string; meetingKind: MeetingKind; meetingTitle?: string }
    ) => Promise<void>
    declineInvite: (id: string) => Promise<void>
    cancelInvite: (id: string) => void
    getOutGoingInvites: () => MeetingParticipant[]
    getIncomingInvites: () => MeetingParticipant[]
    getInvitationType: () => InvitationType | null
    getAcceptanceType: () => InvitationType | null
    resetInvitationTypes: () => void
}

let activeCalls: string[] = []
let handleOutgoingInviteTimer: number
let incomingCallSubscription: any

const useWrapState = (meeting: State<StateValues>) => {
    const loggedInUser = useLoggedInState()
    const history = useHistory()
    const appState = useAppState()

    const addToHistoryFromInvite = (invite: MeetingParticipant) => {
        state.participationHistory.set((prevState) => {
            prevState.push(invite)
            return prevState
        })
    }

    function removeUserFromActiveCall(userId: string) {
        const index = activeCalls.indexOf(userId)
        if (index > -1) activeCalls.splice(index, 1)
    }

    return {
        subscribeToCalls: async (id: string) => {
            // Subscribe to incoming calls
            // TODO use correct type
            if (incomingCallSubscription) incomingCallSubscription.unsubscribe()
            incomingCallSubscription = (
                API.graphql(buildGraphqlArgs(onMeetingInviteReceived, { inviteeId: id })) as any
            ).subscribe({
                next: (resp: any) => {
                    const meetingInvite = resp.value.data.onMeetingInviteReceived
                    if (meetingInvite.inviter === id || accessPresenceState.getMyPresence() === PresenceType.DONOTDISTURB) return
                    activeCalls.push(meetingInvite.inviter)
                    meeting.incomingInvites.set((prevState) => {
                        prevState.push(meetingInvite)
                        return prevState
                    })
                    // Subscribe to incoming call changes, to react to cancelation
                    // TODO use correct type
                    const onInviteChangedListener = (
                        API.graphql(buildGraphqlArgs(onMeetingInviteChangedLight, { id: meetingInvite.id })) as any
                    ).subscribe({
                        next: (resp: any) => {
                            const meetingInvitee: MeetingParticipant = resp.value.data.onMeetingInviteChanged
                            switch (meetingInvitee.status) {
                                case InviteStatus.ACCEPTED:
                                    if (window.sessionStorage.getItem("currentTabCall")) {
                                        history.push("/meetingV2/" + meetingInvitee.meeting.id)
                                    }
                                    break
                                case InviteStatus.CANCELED || InviteStatus.TIMEOUT:
                                    window.sessionStorage.removeItem("currentTabCall")
                                    setTimeout(() => {
                                        appState.setMissedCallNotification(
                                            true,
                                            meetingInvitee.inviter.id,
                                            meetingInvitee.inviter.name
                                        )
                                    }, 2000)

                                    break
                                default:
                                    window.sessionStorage.removeItem("currentTabCall")
                                    break
                            }
                            // Remove call from current call list
                            meeting.set((prevState) => {
                                let idToRemove = 0
                                for (; idToRemove < prevState.incomingInvites.length; idToRemove++) {
                                    if (prevState.incomingInvites[idToRemove].id === meetingInvitee.id) {
                                        break
                                    }
                                }
                                prevState.incomingInvites.splice(idToRemove, 1)
                                return prevState
                            })
                            removeUserFromActiveCall(meetingInvitee.id)
                            onInviteChangedListener.unsubscribe()
                            addToHistoryFromInvite(meetingInvitee)
                        }
                    })
                }
            })
            const invites = await getMeetingHistoryEntries(id)
            if (invites) {
                state.participationHistory.set(invites)
            }
        },
        acceptInvite: async (id: string, invitationType?: InvitationType) => {
            // This sets the ACCEPTED meeting settings
            if (invitationType && invitationType === InvitationType.VIDEO) {
                state.merge({ invitationType: InvitationType.VIDEO, acceptanceType: InvitationType.VIDEO })
            } else state.merge({ invitationType: InvitationType.AUDIO, acceptanceType: InvitationType.AUDIO })

            updateMeetingInvite(id, InviteStatus.ACCEPTED)
        },
        sendInvite: async (
            hailedId: string,
            invitationType: InvitationType,
            meetingData?: { meetingId: string; meetingKind: MeetingKind; meetingTitle?: string }
        ) => {
            if (!hailedId) return

            if (invitationType && invitationType === InvitationType.VIDEO) {
                state.merge({ invitationType: InvitationType.VIDEO })
            }

            if (invitationType && invitationType === InvitationType.AUDIO) {
                state.merge({ invitationType: InvitationType.AUDIO })
            }

            let alreadyHailing = false
            for (const otherId of activeCalls) {
                if (otherId === hailedId) {
                    alreadyHailing = true
                    break
                }
            }
            if (alreadyHailing) return
            activeCalls.push(hailedId)

            const ensureMeetingPromise = async (externalMeetingId: string) => {
                const result = await ensureMeeting(externalMeetingId)
                if (!result) {
                    removeUserFromActiveCall(hailedId)
                    throw new Error("failure while starting call")
                }
            }

            const ensureConversationPromise = async (externalMeetingId: string) => {
                const participantLimit =
                    meetingData?.meetingKind === "calenderEntry" ? branding.configuration.calendarEntryParticipantLimit : 10
                const chatConversationAndHailedParticipationExists = await ensureChatConversation(
                    externalMeetingId,
                    loggedInUser.user()!.profileId,
                    hailedId,
                    participantLimit
                )
                if (!chatConversationAndHailedParticipationExists) {
                    removeUserFromActiveCall(hailedId)
                    throw new Error("failure while creating chat conversation (call might be full)")
                }
            }

            const externalMeetingId: string =
                meetingData?.meetingId && meetingData.meetingKind
                    ? generateMeetingURL(meetingData?.meetingKind, meetingData?.meetingId)
                    : generateMeetingURL("call", undefined)

            const ensures =
                !meetingData || meetingData.meetingKind === "call"
                    ? [ensureMeetingPromise(externalMeetingId), ensureConversationPromise(externalMeetingId)]
                    : [ensureMeetingPromise(externalMeetingId)]
            await Promise.all(ensures)

            const outgoingInvite = await inviteToMeeting(
                loggedInUser.user()!.profileId,
                hailedId,
                externalMeetingId,
                invitationType,
                meetingData?.meetingTitle
            )
            if (outgoingInvite) {
                if (invitationType !== InvitationType.NOTIFY)
                    // This is used for the "Meeting Started", and we don't show the notification for the caller
                    // If it the Invitation type is kind of AUDIO or VIDEO, we show the notification to the caller
                    meeting.set((prevState) => {
                        prevState.outgoingInvites.push(outgoingInvite)
                        return prevState
                    })

                // Listen for updates on our invite
                // TODO use correct type
                const onInviteChangedListener = (
                    API.graphql(buildGraphqlArgs(onMeetingInviteChangedLight, { id: outgoingInvite.id })) as any
                ).subscribe({
                    next: (resp: any) => {
                        const call: MeetingParticipant = resp.value.data.onMeetingInviteChanged
                        switch (call.status) {
                            // We accepted the invite
                            case InviteStatus.ACCEPTED:
                                // If it is an invitation, we don't need to switch the room, because we are the inviter and wee should stay in the same room
                                // but when it is a call, when the user answers, we switch to the room with the call.meeting.id
                                if (invitationType !== InvitationType.NOTIFY) history.push("/meetingV2/" + call.meeting.id)
                                break
                            // Timeout or other party canceled the invite
                            default:
                                break
                        }
                        meeting.set((prevState) => {
                            let idToRemove = 0
                            for (; idToRemove < prevState.outgoingInvites.length; idToRemove++) {
                                if (prevState.outgoingInvites[idToRemove].id === outgoingInvite.id) {
                                    break
                                }
                            }
                            prevState.outgoingInvites.splice(idToRemove, 1)
                            return prevState
                        })
                        removeUserFromActiveCall(outgoingInvite.invitee.id)
                        // Either we get an affirmative reaction or a negative, in either case we won't need to listen any longer to this invite
                        // => Unsubscribe and clear timeoutimer if set
                        if (handleOutgoingInviteTimer) clearTimeout(handleOutgoingInviteTimer)
                        onInviteChangedListener.unsubscribe()
                        addToHistoryFromInvite(outgoingInvite)
                    }
                })
                if (handleOutgoingInviteTimer) clearTimeout(handleOutgoingInviteTimer)
                handleOutgoingInviteTimer = window.setTimeout(() => {
                    updateMeetingInvite(outgoingInvite.id, InviteStatus.TIMEOUT)
                }, CALL_TIMEOUT)
            } else {
                removeUserFromActiveCall(hailedId)
                logger.error("failure while starting call")
            }
        },
        declineInvite: async (id: string) => {
            updateMeetingInvite(id, InviteStatus.DECLINED)
        },
        cancelInvite: (id: string) => {
            updateMeetingInvite(id, InviteStatus.CANCELED)
        },
        getOutGoingInvites: () => {
            return meeting.value.outgoingInvites
        },
        getIncomingInvites: () => {
            return meeting.value.incomingInvites
        },
        getInvitationType: () => {
            return meeting.value.invitationType
        },
        getAcceptanceType: () => {
            return meeting.value.acceptanceType
        },
        resetInvitationTypes: () => {
            state.merge({ acceptanceType: null, invitationType: null })
        }
    }
}

const state = createState<StateValues>(getStartValues())

export const useMeetingInvitation = (): MeetingInvitation => useWrapState(useState(state))
