import { useEffect, useState } from "react"
import { useLanguageState } from "../globalStates/LanguageState"
import branding from "../branding/branding"
import { MessageRow, MineAvatarWrapper, TheirAvatarWrapper } from "./ChatPageMessageList.styled"
import { AvatarWithDefault } from "../ui/AvatarWithDefault"
import { MessageElementContentDefault } from "./MessageElementContentDefault"
import { ChatMessageItem } from "./ChatPageMessageList"

const ChatMessageElementDefault: React.FC<{
    chatMessageItem: ChatMessageItem
    dark: boolean
    setShowContactModal: (show: boolean) => void
    setTargetId: (id: string) => void
    isPublic?: boolean
}> = (props) => {
    const chatMessageItem = props.chatMessageItem
    const showAuthorAvatar = !chatMessageItem.isMine && chatMessageItem.authorPictureUrl !== undefined // if authorPictureUrl is null => avatar url still unknown & being queried
    const className = (props.chatMessageItem.isMine ? "mine" : "their") + (props.dark ? " dark" : "")
    const lang = useLanguageState().getLanguage()

    const [loaded, setLoaded] = useState<boolean>(false)

    const [systemMessageUserName, setSystemMessageUserName] = useState<string>("")
    const [systemMessage, setSystemMessage] = useState<string | undefined>()

    function createSystemMessage() {
        let name = props.chatMessageItem.content.split("/")[1]
        setSystemMessageUserName(name)

        if (props.chatMessageItem.content.startsWith("systemAdded")) {
            setSystemMessage(branding.chatBranding.participantJoinedMessageText)
        } else if (props.chatMessageItem.content.startsWith("systemDeleted")) {
            setSystemMessage(branding.chatBranding.participantRemovedMessageText)
        } else if (props.chatMessageItem.content.startsWith("systemExited")) {
            setSystemMessage(branding.chatBranding.participantLeftMessageText)
        }
    }

    useEffect(() => {
        createSystemMessage()
        setLoaded(true)
        // eslint-disable-next-line
    }, [props.chatMessageItem, lang])

    if (!loaded) {
        return null
    }

    if (systemMessage) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "10px",
                    color: branding.sideIconBar.sideIconColorDark,
                    marginTop: "10px"
                }}
            >
                <div style={{ display: "inline-block", fontWeight: "bold", marginRight: "3px" }}>{systemMessageUserName}</div>
                <div style={{ display: "inline-block" }}>{systemMessage}</div>
            </div>
        )
    }

    if (showAuthorAvatar) {
        return (
            <MessageRow className={className + " withAvatar"}>
                <TheirAvatarWrapper
                    onClick={() => {
                        props.setTargetId(chatMessageItem.authorId)
                        props.setShowContactModal(true)
                    }}
                >
                    <AvatarWithDefault
                        size={40}
                        src={chatMessageItem.authorPictureUrl ?? undefined}
                        alt={chatMessageItem.authorName ?? "#"}
                        backgroundSize="cover"
                    />
                </TheirAvatarWrapper>
                <MessageElementContentDefault
                    className={className}
                    {...chatMessageItem}
                    marginLeft={props.isPublic ? "65px" : "15px"}
                    marginRight={"15px"}
                />
            </MessageRow>
        )
    }
    return (
        <MessageRow className={className}>
            <MessageElementContentDefault
                className={className}
                {...chatMessageItem}
                marginLeft={"15px"}
                marginRight={props.isPublic ? "60px" : "15px"}
            />
            {props.isPublic && (
                <MineAvatarWrapper
                    onClick={() => {
                        props.setTargetId(chatMessageItem.authorId)
                        props.setShowContactModal(true)
                    }}
                >
                    <AvatarWithDefault
                        size={40}
                        src={chatMessageItem.authorPictureUrl ?? undefined}
                        alt={chatMessageItem.authorName ?? "#"}
                        backgroundSize="cover"
                    />
                </MineAvatarWrapper>
            )}
        </MessageRow>
    )
}

export default ChatMessageElementDefault
