import { detect } from "detect-browser"

export function disableFirefoxPip() {
    /**This is bad practice. But i don't know a better solution for now. */
    const browserCheckResult = detect()
    const isFirefox = browserCheckResult && browserCheckResult.type === "browser" && browserCheckResult.name === "firefox"
    if (!isFirefox) return
    const videoEl: any = document.querySelectorAll("video")
    if (videoEl) {
        videoEl.forEach((el: HTMLVideoElement) => {
            el.setAttribute("disablePictureInPicture", "true")
        })
    }
}
