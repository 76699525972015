import { IconTimeZone } from "../../Icons"
import branding from "../../../branding/branding"
import styled from "styled-components"
import { useAppState } from "../../../globalStates/AppState"
import { getFormattedTimeZoneName } from "../../../utils/DateUtils"

export const TimezoneText = () => {
    const { timezone } = useAppState()
    const timeZoneName = getFormattedTimeZoneName(timezone)

    return (
        <TimeZoneTextRoot>
            {IconTimeZone({ fill: "#727272", height: "15px", width: "15px" })}
            <Text>{timeZoneName}</Text>
        </TimeZoneTextRoot>
    )
}

export const TimeZoneTextRoot = styled.div`
    padding-top: 3px;
    padding-left: 4px;
`
export const Text = styled.span`
    width: 100%;
    color: #727272;
    font-size: 12px;
    font-family: ${branding.font1};
    font-weight: 400;
    line-height: 17px;
    height: 35px;
    width: 100%;
    padding: 1rem;
`

export default TimezoneText
