import { useEffect, useRef, useState } from "react"
import * as React from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import styled from "styled-components"
import { ModalType } from "../backendServices/Types"
import branding from "../branding/branding"
import { useChimeContext } from "../conference/context/ChimeContext"
import { useMeetingContext } from "../conference/context/MeetingContext"
import { buildDetailLink } from "../contentArea/detailPages/DetailNavLink"
import { useAppState } from "../globalStates/AppState"
import { useFavoriteState } from "../globalStates/Favorites"
import { useLanguageState } from "../globalStates/LanguageState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { meetingPageRoute } from "../navigationArea/RoutePaths"
import { AvatarWithDefault } from "../ui/AvatarWithDefault"
import { AvatarWithPresenceState } from "../ui/avatarWithPresenceState/AvatarWithPresenceState"
import BadgesContainer from "../ui/BadgesContainer"
import ContextMenu, { HoverButton } from "../ui/ContextMenu"
import { IconCheckmark, IconIgnore } from "../ui/Icons"
import { Action, CommunicationModals, createActions } from "./CommunicationOptions"
import { useContactState } from "./ContactState"
import { format } from "date-fns"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import moment from "moment"
import { useRosterContext } from "../conference/context/RosterContext"
import { BackendServiceError } from "../backendServices/BackendServicesUtils"
import { doConnectAction, loadUserData, setConnectIsViewed, UserResponse } from "../backendServices/SeriesOfTopicsUserServices"
import { useMeetingInvitation } from "../conferenceV2/context/MeetingInvitation"
import {
    ContactEntryRoot,
    PersonDataRoot,
    AvatarContainer,
    PersonDetailsContainer,
    PersonName,
    PersonPosition,
    MenuButtonContainer,
    UnreadMarker,
    HoverRoot,
    ContactEntryContainer,
    ContactEntryPersonPosition
} from "./ContactEntry.styled"

import { getUserTitle } from "./helpers/GlobalFunctions"
import { useMeetingController } from "../conferenceV2/context/MeetingController"

export enum ContactEntryType {
    RELEVANT,
    REQUEST,
    CONTACT,
    BLOCKED,
    ROSTER,
    PARTICIPANTS,
    GROUP_CHAT
}

interface ContactEntryProps {
    contact: any
    isIncomingRequest?: boolean
    hideMenu?: boolean
    withoutPresence?: boolean
    setReload?: () => void
    additionalActions?: Action[]
    disabled?: boolean
    className?: string // needed for outside styled styling
    entryType: ContactEntryType
    useOnlyLastNameForRoute?: boolean
}

const ContactEntry: React.FunctionComponent<ContactEntryProps> = (props) => {
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const [person, setPerson] = useState(props.contact)
    const history = useHistory()
    const [modalType, setModalType] = useState<ModalType>("none")
    const [collapsed, setCollapsed] = useState<boolean>(true)
    const [isBookmarked, setIsBookmarked] = useState<boolean>(false)
    const userLink = useLoggedInState()
    const loggedInUserId = userLink.user()?.profileId || ""
    const favoriteState = useFavoriteState()
    const appState = useAppState()
    const meeting = useMeetingContext()
    const chime = useChimeContext()
    const roster = useRosterContext()
    const contactState = useContactState()
    const isMeetingPage = useRouteMatch(meetingPageRoute)

    const [connectionStatus, setConnectionStatus] = useState("")

    const [newMessage, setNewMessage] = useState(false)

    const meetingInvitation = useMeetingInvitation()
    const meetingController = useMeetingController()
    const isReportButtonHidden = true

    async function loadUser(userId: string) {
        const response = await loadUserData({ targetProfileId: userId, loggedInUserId: loggedInUserId })
        if ((response as BackendServiceError).httpStatus) {
            return
        } else if (response as UserResponse) {
            setPerson((response as UserResponse).content)
        }
    }

    function checkIfBookmarked(): boolean {
        return favoriteState.is("person", person.id) || favoriteState.is("sotuser", person.id)
    }

    const personName = [person.firstName, person.lastName].filter(Boolean).join(" ")

    const connectionCreated: string = person.created ?? ""

    const hourFormat = lang === "en" ? "hh:mm A" : "HH:mm"

    const scrollRef = useRef<HTMLDivElement>(null)

    const scrollToBottom = (animate: boolean) => {
        setTimeout(() => {
            scrollRef?.current?.scrollIntoView({ behavior: animate ? "smooth" : "auto" })
        }, 200)
    }
    const menuCallback: (param: { bookmarkChanged?: boolean; modalType?: ModalType }) => void = ({
        bookmarkChanged,
        modalType
    }) => {
        if (bookmarkChanged) {
            if (props.setReload) {
                props.setReload()
            }
        }
        if (modalType) {
            setModalType(modalType)
        }
    }

    const onClick = React.useCallback(() => {
        const userProfileId = userLink.user()?.profileId
        if (userProfileId && newMessage) {
            setConnectIsViewed({ profileId: userProfileId, viewedProfiles: [person.id] }).then(() => setNewMessage(false))
            setNewMessage(!newMessage)
        }
    }, [userLink, newMessage, person.id]) // Dependencies

    const avatar = props.withoutPresence ? (
        <AvatarWithDefault size={50} alt={personName} src={person.logoUrl} backgroundSize="cover" />
    ) : (
        <AvatarWithPresenceState
            avatarSize={56}
            badgeSize={15}
            showAvatarBadge={true}
            badgeRight={0.1}
            badgeTop={37}
            userId={person.id}
            userType={person.type}
            wrapperMarginTop={"-3px"}
            content={{ pictureUrl: person.logoUrl, alt: personName }}
        ></AvatarWithPresenceState>
    )
    const otherBadges: string[] = []
    if (person.type === "press") {
        otherBadges.push(branding.press)
    }

    if (person.type === "staff" && branding.myProfilePageContent.showExhibitorBadge) {
        otherBadges.push(branding.globalSearchResultPage.exhibitorEntityTitle)
    }

    const detailLink = React.useMemo(() => {
        return buildDetailLink(person.id, (props.useOnlyLastNameForRoute ? "" : person.firstName) + "_" + person.lastName, "user")
    }, [person.id, person.firstName, person.lastName, props.useOnlyLastNameForRoute])

    useEffect(() => {
        setPerson(props.contact)
        if (props.entryType === ContactEntryType.GROUP_CHAT) {
            loadUser(props.contact.id)
        }
        // eslint-disable-next-line
    }, [props.contact])

    useEffect(() => {
        if (
            props.entryType === ContactEntryType.CONTACT &&
            person.myConnectionStatus === "CONNECTED" &&
            !person.myConnectionViewed
        ) {
            setNewMessage(true)
        }
    }, [newMessage, person, props.entryType])

    useEffect(() => {
        setIsBookmarked(checkIfBookmarked())

        setConnectionStatus(contactState.getConnectionStatus(person.id))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setConnectionStatus(contactState.getConnectionStatus(person.id))
        // eslint-disable-next-line
    }, [person, contactState])

    useEffect(() => {
        setIsBookmarked(checkIfBookmarked())
        // eslint-disable-next-line
    }, [person, favoriteState])

    return (
        <ContactEntryRoot className={props.className} ref={scrollRef} onClick={() => history.push(detailLink)}>
            <HoverRoot className={props.disabled ? "disabled" : ""}>
                {props.isIncomingRequest && person.created && (
                    <ContactEntryPersonPosition>
                        {format(moment(connectionCreated).toDate(), branding.eventTiming.eventDaysFormatPatternNewsPage, {
                            locale: lang === "de" ? de : en
                        })}{" "}
                        {moment(connectionCreated).format(hourFormat)}
                    </ContactEntryPersonPosition>
                )}
                <ContactEntryContainer className={props.disabled ? "disabled" : ""}>
                    <PersonDataRoot
                        color={isMeetingPage ? branding.secondaryColor ?? "white" : branding.mainInfoColor ?? "black"}
                        onClick={onClick}
                    >
                        <AvatarContainer>
                            {newMessage === true && <UnreadMarker />}
                            {avatar}
                        </AvatarContainer>
                        <PersonDetailsContainer>
                            <PersonName className="person-name">{personName}</PersonName>
                            <PersonPosition className="person-position">
                                {getUserTitle(person.position, person.positionDe, person.company, person.organization)}
                            </PersonPosition>

                            <BadgesContainer
                                topics={person.topics}
                                otherBadges={otherBadges}
                                categoryBadges={person.categoryBadges}
                                communicationArea
                            />
                        </PersonDetailsContainer>
                    </PersonDataRoot>
                    <MenuButtonContainer>
                        {props.hideMenu || props.isIncomingRequest ? null : (
                            <HoverButton
                                onClick={() => {
                                    setCollapsed(!collapsed)
                                    scrollToBottom(true)
                                }}
                                selected={!collapsed}
                            />
                        )}
                    </MenuButtonContainer>
                </ContactEntryContainer>
            </HoverRoot>
            <ContextMenu
                collapsed={collapsed}
                items={() =>
                    createActions(
                        userLink.user(),
                        person,
                        favoriteState,
                        contactState,
                        appState,
                        meeting,
                        chime,
                        meetingInvitation,
                        meetingController,
                        isReportButtonHidden,
                        roster,
                        isBookmarked,
                        connectionStatus,
                        menuCallback,
                        props.additionalActions
                    )
                }
            />
            <CommunicationModals
                show={modalType}
                contact={person}
                removePosition={true}
                onWithdrawRequest={() => {
                    if (props.setReload) {
                        props.setReload()
                    }
                }}
                onHide={() => setModalType("none")}
            />
            {props.isIncomingRequest && <IncomingRequestMenu {...props} />}

            <Divider />
        </ContactEntryRoot>
    )
}

export const MemoizedContactEntry = React.memo(ContactEntry)

export const Divider = styled.div`
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
`

export { ContactEntry }

const RequestMessage = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-align: center;
    font-size: 12px;
    color: black;
    width: 100%;
    padding: 10px 10px 0 10px;
    font-style: italic;

    &.longStringWithoutSpaces {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
`

const IncomingRequestMenu: React.FunctionComponent<ContactEntryProps> = (props) => {
    const userLink = useLoggedInState()
    const loggedInUserId = userLink.user()?.profileId
    const [isRequestSending, setIsRequestSending] = useState<boolean>(false)

    function isLongStringWithoutSpaces(): boolean {
        const text = props.contact.requestMessage

        if (text && text.length >= 47 && !text.slice(0, 47).includes(" ")) {
            return true
        }

        return false
    }

    function connectActionHandler(action: string, message: string | null) {
        setIsRequestSending(true)
        doConnectAction({
            profileId: loggedInUserId as string,
            targetProfileId: props.contact.id,
            message: message,
            action: action
        })
            .then((response: any) => {
                if (response.content) {
                    if (props.setReload) {
                        props.setReload()
                    }
                }
                setIsRequestSending(false)
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                setIsRequestSending(false)
            })
    }

    return (
        <>
            <RequestMessage className={isLongStringWithoutSpaces() ? "longStringWithoutSpaces" : ""}>
                {props.contact.requestMessage ? props.contact.requestMessage : null}
            </RequestMessage>
            <ContextMenu
                collapsed={false}
                items={() => [
                    {
                        title: branding.communicationArea.requestAcceptTitle,
                        icon: IconCheckmark({ fill: branding.sideIconBar.sideIconColorDark }),
                        disabled: isRequestSending,
                        onClick: () => connectActionHandler("accept", null)
                    },
                    {
                        title: branding.communicationArea.requestIgnoreTitle,
                        icon: IconIgnore({ fill: branding.sideIconBar.sideIconColorDark }),
                        disabled: isRequestSending,
                        onClick: () => connectActionHandler("ignore", null)
                    }
                ]}
            />
        </>
    )
}
