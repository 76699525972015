import moment from "moment"
import { useEffect, useState } from "react"
import { useLanguageState } from "../globalStates/LanguageState"
import { trackSelectContent } from "../utils/GTMTracking"
import { capitalizeFirstLetter } from "../utils/StringUtils"
import { AvatarWithDefault } from "../ui/AvatarWithDefault"
import { MessageContent, MessageElement, MessageStatus } from "./ChatPageMessageList.styled"
import InView from "react-intersection-observer"
import { ChatMessageItem } from "./ChatPageMessageList"
import Linkify from "react-linkify"
import ComponentDecorator from "./ComponentDecorator"
import {
    AvatarWrapperPinboard,
    PinboardChatMessageAuthor,
    PinboardChatMessageHeader,
    PinboardChatMessageTime,
    PinboardMessageRow
} from "./ChatMessageElementPinboard.styled"

const ChatMessageElementPinboard: React.FC<{ chatMessageItem: ChatMessageItem }> = (props) => {
    const chatMessageItem = props.chatMessageItem
    const [timeString, setTimeString] = useState(moment(chatMessageItem.timestamp).fromNow())
    const [isVisible, setIsVisible] = useState(false)
    const lang = useLanguageState().getLanguage()

    moment.locale(lang)

    useEffect(() => {
        // Update the timestamp every 10 sec
        setInterval(() => {
            setTimeString(moment(chatMessageItem.timestamp).fromNow())
        }, 10000)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setTimeString(moment(chatMessageItem.timestamp).fromNow())

        if (!isVisible) return
        const timePassed = new Date().getTime() - chatMessageItem.timestamp.getTime() // difference in milli seconds
        if (timePassed < 1000 * 60 * 60 * 24) {
            if (timePassed <= 0) {
                // if this system time is lower than the system time of the participants mashine
                const adaptTimestamp = new Date(chatMessageItem.timestamp.getTime() + (timePassed - 1000))
                setTimeString(moment(adaptTimestamp).fromNow())
            } else {
                setTimeString(moment(chatMessageItem.timestamp).fromNow())
            }
        }
    }, [isVisible, chatMessageItem.timestamp, lang])

    return (
        <PinboardMessageRow>
            <AvatarWrapperPinboard
                type="user"
                id={props.chatMessageItem.authorId}
                name={props.chatMessageItem.authorName ?? "#"}
                onClick={() => {
                    trackSelectContent("Person", "Details", capitalizeFirstLetter("User"), "Chat")
                }}
            >
                <AvatarWithDefault
                    size={25}
                    src={chatMessageItem.authorPictureUrl ?? undefined}
                    alt={chatMessageItem.authorName ?? "#"}
                    backgroundSize="cover"
                />
            </AvatarWrapperPinboard>
            <MessageElement marginLeft="15px" marginRight="0">
                <PinboardChatMessageHeader>
                    <PinboardChatMessageAuthor
                        type="user"
                        id={props.chatMessageItem.authorId}
                        name={props.chatMessageItem.authorName ?? "#"}
                    >
                        {chatMessageItem.authorName ?? "#"}
                    </PinboardChatMessageAuthor>
                    <InView onChange={setIsVisible}>
                        <PinboardChatMessageTime>{timeString}</PinboardChatMessageTime>
                    </InView>
                </PinboardChatMessageHeader>
                <MessageContent style={{ whiteSpace: "normal" }}>
                    <Linkify componentDecorator={ComponentDecorator}>{chatMessageItem.content}</Linkify>
                </MessageContent>
                <MessageStatus />
            </MessageElement>
        </PinboardMessageRow>
    )
}

export default ChatMessageElementPinboard
