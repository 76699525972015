import React from "react"
import { InvitePerson } from "../../../backendServices/Types"
import { AvatarWithDefault } from "../../AvatarWithDefault"
import {
    AvatarBackground,
    GreyBackground,
    HideSelectorBehindIcons,
    InvitedPersonBackground,
    ScheduledMeetingTime
} from "./CalendarEntryModal.styled"
import { convertTimeIntoWidth, getPositionLeft } from "./ModalFunctions"
import { Obligations } from "./CalendarEntryModal2"

interface InvitedPersonAvailabilityProps {
    contact: InvitePerson
    label: string
    startTime: Date | null
    timezone?: string
    meetingTimes: Obligations
}

const InvitedPersonAvailability = (props: InvitedPersonAvailabilityProps) => {
    return (
        <InvitedPersonBackground>
            <AvatarBackground>
                <AvatarWithDefault size={30} src={props.contact.logoUrl} alt={props.label} backgroundSize="cover" />
                <HideSelectorBehindIcons />
            </AvatarBackground>
            <GreyBackground />
            {props.meetingTimes?.userBusyTimes.items?.map((item) => {
                return (
                    <ScheduledMeetingTime
                        key={item.userId}
                        width={convertTimeIntoWidth(item.start, item.end) + "px"}
                        left={getPositionLeft(item.start) + "px"}
                    />
                )
            })}
        </InvitedPersonBackground>
    )
}

export default InvitedPersonAvailability
