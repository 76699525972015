import { useEffect, useRef, useState } from "react"
import * as React from "react"
import { useRouteMatch } from "react-router-dom"
import branding from "../../branding/branding"
import { useAppState } from "../../globalStates/AppState"
import { IconMyFair, IconMyFairBlackWhite } from "../../ui/Icons"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import { meetingPageRoute } from "../RoutePaths"
import useComponentVisible from "../useComponentVisible"
import { Exhibitor } from "../../backendServices/Types"
import { SideIconBarRoot, StyledDrawer } from "./SideIconBar.styled"
import MenuToolbarLayout from "./menuToolbarLayout/MenuToolbarLayout"
import MenuDrawerLayout from "./menuDrawerLayout/MenuDrawerLayout"

export interface SponsorItemProps {
    sponsor: Exhibitor
    onClick: (e: any) => void
    isNavOpen: boolean
}

interface SideIconBarProps {
    navToggle: (open: boolean) => void
    isNavOpen: boolean
    receptionPageToggle: (route: string) => void
    setTrigger: (trigger: number) => void
    myToggle: string
}

const SideIconBar: React.FC<SideIconBarProps> = (props) => {
    const [contentAreaScrollable, setContentAreaScrollable] = useState(false)
    const [hideOnScrollEnd, setHideOnScrollEnd] = useState(false)

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

    let isMeetingPage = useRouteMatch(meetingPageRoute)
    const foregroundColor = isMeetingPage ? "#fff" : branding.primaryColor
    const backgroundColor = isMeetingPage ? branding.mainInfoColor : "#fff"
    const logo = isMeetingPage
        ? "/branding/applicationMedia/navbarLogoWhite.png"
        : "/branding/applicationMedia/rsz_navbarlogo.png"
    const logo_second = isMeetingPage
        ? "/branding/applicationMedia/navbarLogoWhite2.png"
        : "/branding/applicationMedia/rsz_second_logo.png"
    const myIcon = isMeetingPage ? IconMyFairBlackWhite({ stroke: "white" }) : IconMyFair({ fill: "#fff" })
    const scrollRef = useRef<HTMLDivElement>(null)
    const appState = useAppState()

    useEffect(() => {
        if (isComponentVisible) {
            handleDrawerOpen()
        } else {
            handleDrawerClose()
        }
        // eslint-disable-next-line
    }, [isComponentVisible])

    return (
        <SideIconBarRoot
            ref={scrollRef}
            foregroundColor={foregroundColor}
            backgroundColor={backgroundColor}
            isMeetingPage={isMeetingPage}
        >
            <div className="d-flex">
                <StyledDrawer
                    ref={ref}
                    onClick={() => {
                        !props.isNavOpen ? handleDrawerOpen() : handleDrawerClose()
                    }}
                    backgroundColor={backgroundColor}
                    className={props.isNavOpen ? "drawerOpen" : "drawerClose"}
                >
                    <MenuToolbarLayout
                        contentAreaScrollable={contentAreaScrollable}
                        hideOnScrollEnd={hideOnScrollEnd}
                        isNavOpen={props.isNavOpen}
                        isMeetingPage={isMeetingPage}
                        logo={props.isNavOpen && branding.sideIconBar.hasSecondLogoIcon ? logo_second : logo}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDrawerClose={handleDrawerClose}
                    />

                    <ContentScrollContainer
                        handleScroll={onScroll}
                        isVerticallyScrollable={setContentAreaScrollable}
                        adjustForHeaderWith={props.isNavOpen ? "135px" : "175px"}
                    >
                        <MenuDrawerLayout
                            isNavOpen={props.isNavOpen}
                            logo={logo}
                            receptionPageToggle={props.receptionPageToggle}
                            myToggle={props.myToggle}
                            myIcon={myIcon}
                            setTrigger={props.setTrigger}
                            contentAreaScrollable={contentAreaScrollable}
                            hideOnScrollEnd={hideOnScrollEnd}
                        />
                    </ContentScrollContainer>
                </StyledDrawer>
            </div>
        </SideIconBarRoot>
    )

    function onScroll(scrollValues: any) {
        const scrollHeight = scrollValues.contentScrollHeight
        const clientHeight = scrollValues.clientHeight
        const scrollTop = scrollValues.scrollTop

        if (scrollHeight < scrollTop) {
            setHideOnScrollEnd(scrollHeight <= clientHeight + scrollTop)
        }
    }

    function handleDrawerOpen() {
        appState.setIsNavBarOpen(true)
        props.navToggle(true)
        setIsComponentVisible(true)
        if (scrollRef && scrollRef.current)
            setContentAreaScrollable(scrollRef.current.scrollHeight > scrollRef.current.clientHeight)
    }

    function handleDrawerClose() {
        appState.setIsNavBarOpen(false)
        props.navToggle(false)
        setIsComponentVisible(false)
    }
}

export default SideIconBar
