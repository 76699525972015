import { createState, State, useState } from "@hookstate/core"
import { Persistence } from "@hookstate/persistence"
import { ChatConversationParam } from "../communicationArea/ChatPage"
import { ScheduleListType } from "../communicationArea/ScheduleTab"
import { NetworkingListType } from "../communicationArea/CommunicationArea"
import { NotificationListType } from "../communicationArea/NotificationsTab"
import { getEnvironment, getEnvironmentShort } from "../environments"

import "setimmediate" // winston needs this, so it needs to be imported right before winston is imported
import winston from "winston"
import { EventDate } from "../backendServices/Types"
import "moment-timezone"
import { MyScheduleTypeFilter } from "../contentArea/program/ProgramPageContent"
import { ViewMode } from "../ui/CrsTabs"
import { ScheduleListTypeV2 } from "../communicationAreaV2/tabs/schedulesTab/SchedulesTab"
import getBranding from "../BrandingConfiguration"
import moment from "moment"

interface AppState {
    pliu: string | undefined // previously logged in user id: this is cryptic on purpose, because it's visible in browser local storage after logout
    networkingOpen: boolean
    communicationCenterSelectedTab: communicationCenterSelectedTab
    communicationCenterSelectedSubtab?: any
    communicationCenterSelectedSubtabs: { [mode: string]: any }
    forceLoadListFunction?: () => void
    showTourNotification: boolean
    showMissedCallNotification: boolean
    missedCallNotificationName: string
    missedCallNotificationId: string
    showMeetingReminder: boolean
    meetingReminderName: string
    meetingTimeBefore: string
    isMyHandRaised: boolean
    isDontShowModalClicked: boolean
    vodEventDate: EventDate | null
    vodEventDateTemporary: EventDate | null
    liveStreamingChannel: LiveStreamingChannel | null
    temporaryLiveStreamChannel: LiveStreamingChannel | null
    videoPlayerStatus: VideoPlayerStatus | null
    isMeetingChatShown: boolean | undefined
    usingEventTimeZone: boolean
    /**
     * current selected sidebar item/location (e.g. home, program, showfloor...)
     */
    currentItem: string
    /**
     * Last tab item in the app which was selected by the user.
     * Value is updated every time when user clicks on some CrsTab item.
     */
    lastVisitedTab: string | null
    isRosterOpen: boolean | null
    isNavBarOpen: boolean | null
    currentMobileBreadcrumb: string
    isAudioVideoSettingsOpen: boolean
    isAudioVideoSettingsV2Open: boolean
    isPreMeetingScreenOpen: boolean
    roundTableCountdownString: string
    roundTableStatus: RoundTableStatus | null
    myScheduleStatus: MyScheduleStatus
    isOnExhibitorsPageContentOrGlobalSearchResultPageContent: boolean
    entityLogoPlaceholderExists: boolean
}

export interface AppStateContextMethods {
    setVodEventDate: (newVodEventDate: EventDate | null) => void
    setVodEventDateTemporary: (newVodEventDate: EventDate | null) => void
    vodEventDateTemporary: EventDate | null
    vodEventDate: EventDate | null
    setLoggedInUserId: (newUserId: string | undefined) => void
    loggedInUserId: string | undefined
    setLiveStreamChannel: (newLiveStreamingChannel: LiveStreamingChannel | null) => void
    liveStreamChannel: LiveStreamingChannel | null
    setTemporaryLiveStreamChannel: (newLiveStreamingChannel: LiveStreamingChannel | null) => void
    temporaryLiveStreamChannel: LiveStreamingChannel | null
    videoPlayerStatus: VideoPlayerStatus | null
    setVideoPlayerStatus: (newVideoPlayerStatus: VideoPlayerStatus | null) => void
    isCommunicationCenterOpen: () => boolean
    toggleNetworking: () => void
    communicationCenterSelectedTab: communicationCenterSelectedTab
    communicationCenterSelectedSubtab: any
    setCommunicationCenterSelectedTab: (mode: communicationCenterSelectedTab) => void
    setPeopleSubtab: (param?: NetworkingListType) => void
    setChatsSubtab: (param?: ChatConversationParam | null) => void
    setSchedulesSubtab: (param?: ScheduleListType | ScheduleListTypeV2) => void
    setNotificationsSubtab: (param: NotificationListType) => void
    setSettingsSubtab: () => void
    setCommunicationCenterForceLoadListFunction: (forceLoadListFunction: () => void) => void
    showTourNotification: boolean
    setShowTourNotification: (show: boolean) => void
    showMissedCallNotification: boolean
    missedCallNotificationName: string
    missedCallNotificationId: string
    setMissedCallNotification: (show: boolean, id: string, name: string) => void
    showMeetingReminder: boolean
    meetingReminderName: string
    meetingTimeBefore: string
    setMeetingReminder: (show: boolean, name: string, timeBefore: string) => void
    isMyHandRaised: boolean
    setIsMyHandRaised: (raise: boolean) => void
    isDontShowModalClicked: boolean
    setIsDontShowModalClicked: (raise: boolean) => void
    isMeetingChatShown: boolean | undefined
    setIsMeetingChatShown: (show: boolean) => void
    timezone: string
    setTimeZone: (tz: string) => void
    resetTimeZoneToDefault: () => void
    currentItem: string
    setCurrentItem: (currentItem: string) => void
    currentMobileBreadcrumb: string
    setCurrentMobileBreadcrumb: (currentMobileBreadcrumb: string) => void
    lastVisitedTab: string | null
    setLastVisitedTab: (lastVisitedTab: string | null) => void
    myScheduleStatus: MyScheduleStatus
    updateMyScheduleStatus: (myScheduleStatus: MyScheduleStatus) => void
    isRosterOpen: boolean | null
    setIsRosterOpen: (isRosterOpen: boolean) => void
    isNavBarOpen: boolean | null
    setIsNavBarOpen: (isNavBarOpen: boolean) => void
    isAudioVideoSettingsOpen: boolean
    setIsAudioVideoSettingsOpen: (isAudioVideoSettingsOpen: boolean) => void
    isAudioVideoSettingsV2Open: boolean
    setIsAudioVideoSettingsV2Open: (isAudioVideoSettingsOpen: boolean) => void
    isPreMeetingScreenOpen: boolean
    setIsPreMeetingScreenOpen: (isPreMeetingScreenOpen: boolean) => void
    roundTableCountdownString: string
    setRoundTableCountdownString: (roundTableCountdownString: string) => void
    roundTableStatus: RoundTableStatus | null
    setRoundTableStatus: (roundTableStatus: any) => void
    isOnExhibitorsPageContentOrGlobalSearchResultPageContent: boolean
    setIsOnExhibitorsPageContentOrGlobalSearchResultPageContent: (value: boolean) => void
    entityLogoPlaceholderExists: boolean
    setEntityLogoPlaceholderExists: (value: boolean) => void

    reset: () => void
}

export enum communicationCenterSelectedTab {
    NETWORKING,
    CHATS,
    SCHEDULE,
    NOTIFICATIONS,
    SETTINGS
}

type RoundTableStatus = {
    roundTableId: string
    isOver: boolean
    countDown?: number
}

export interface VideoPlayerStatus {
    volume?: number
    isMuted?: boolean
    isPaused?: boolean
    audioTrack?: string // spoken language of audio channel
    timeOffsetliveStream?: number // difference between live-current-time and time seeked by user, for static files live-current-time is end of timeline
    timeOffsetVOD?: number // time passed: how much of the video has user previously watched
}

export interface MyScheduleStatus {
    viewMode?: ViewMode
    day?: number
    filter?: MyScheduleTypeFilter | null
    searchTerm?: string
}

interface LiveStreamingChannel {
    id: string
    url: string
    eventDate?: EventDate
}

const defaultValues: () => AppState = () => {
    return {
        pliu: undefined,
        networkingOpen: true,
        communicationCenterSelectedTab: communicationCenterSelectedTab.NETWORKING,
        communicationCenterSelectedSubtabs: {},
        showTourNotification: true,
        showMissedCallNotification: false,
        missedCallNotificationName: "",
        missedCallNotificationId: "",
        showMeetingReminder: true,
        meetingReminderName: "",
        meetingTimeBefore: "",
        isMyHandRaised: false,
        isDontShowModalClicked: false,
        vodEventDate: null,
        vodEventDateTemporary: null,
        liveStreamingChannel: null,
        temporaryLiveStreamChannel: null,
        videoPlayerStatus: null,
        liveStreamingEventDate: null,
        isMeetingChatShown: undefined,
        usingEventTimeZone: getBranding().configuration.useEventTimeZoneAsDefault,
        suggestSearchVisible: false,
        currentItem: "home",
        lastVisitedTab: null,
        isRosterOpen: null,
        isNavBarOpen: null,
        currentMobileBreadcrumb: "",
        isAudioVideoSettingsOpen: false,
        isAudioVideoSettingsV2Open: false,
        isPreMeetingScreenOpen: false,
        roundTableCountdownString: "-:-",
        roundTableStatus: null,
        myScheduleStatus: { viewMode: ViewMode.TILES },
        isOnExhibitorsPageContentOrGlobalSearchResultPageContent: false,
        entityLogoPlaceholderExists: false
    }
}

const customLogFormat = winston.format.printf(({ level, message, errorMessage, errorStack }) => {
    return `{environment: ${getEnvironment()}} ${level}: ${message} ${errorMessage} ${errorStack}`
})

// Log only to remote on int/stage/live
// Log only error log to remote
const remoteLogger =
    getEnvironmentShort() !== "dev"
        ? new winston.transports.Http({
              ssl: true,
              host: `logging${getEnvironmentShort() === "live" ? "" : "-" + getEnvironmentShort()}.event-cloud.com`,
              port: 443,
              path: "/",
              level: "error",
              handleExceptions: true
          })
        : undefined

let logTargets: winston.transport[] = []
if (remoteLogger !== undefined) {
    logTargets = logTargets.concat(remoteLogger)
}
// Log info and up to console
logTargets = logTargets.concat(new winston.transports.Console({ level: "info", handleExceptions: true }))

export const defaultLogger = winston.createLogger({
    level: "info",
    format: winston.format.combine(customLogFormat, winston.format.prettyPrint()),
    transports: logTargets,
    handleExceptions: true,
    exitOnError: false
})

const useWrapState = (state: State<AppState>): AppStateContextMethods => {
    state.attach(Persistence("virtualGuide-app"))
    const eventTimeZoneName = getBranding().configuration.eventTimeZone ?? "Europe/Berlin"

    const setDisplay = (mode: communicationCenterSelectedTab, param?: any | null, forceUpdate?: boolean) => {
        state.set((prevState) => {
            prevState.networkingOpen = true
            const communicationCenterDisplayDidChange =
                prevState.communicationCenterSelectedTab !== mode || prevState.communicationCenterSelectedSubtab !== param
            prevState.communicationCenterSelectedTab = mode
            if (param === undefined) {
                // use param previously used for this mode
                prevState.communicationCenterSelectedSubtab = prevState.communicationCenterSelectedSubtabs
                    ? prevState.communicationCenterSelectedSubtabs[mode]
                    : undefined
            } else if (param === null) {
                // don't use any param
                prevState.communicationCenterSelectedSubtab = undefined
                if (prevState.communicationCenterSelectedSubtabs) prevState.communicationCenterSelectedSubtabs[mode] = undefined
            } else {
                prevState.communicationCenterSelectedSubtab = param
                if (!prevState.communicationCenterSelectedSubtabs)
                    // for existing local storage values not yet containing this field
                    prevState.communicationCenterSelectedSubtabs = {}
                prevState.communicationCenterSelectedSubtabs[mode] = param
            }
            if (forceUpdate && !communicationCenterDisplayDidChange && prevState.forceLoadListFunction !== undefined) {
                prevState.forceLoadListFunction()
            }

            return prevState
        })
    }

    return {
        setLoggedInUserId: (newUserId: string | undefined) => {
            return state.set((prevState) => {
                prevState.pliu = newUserId
                return prevState
            })
        },
        loggedInUserId: state.get().pliu,

        setVodEventDate: (newVodEventDate: EventDate | null) => {
            return state.set((prevState) => {
                prevState.vodEventDate = newVodEventDate
                return prevState
            })
        },
        vodEventDate: state.get().vodEventDate,

        setVodEventDateTemporary: (newVodEventDate: EventDate | null) => {
            return state.set((prevState) => {
                prevState.vodEventDateTemporary = newVodEventDate
                return prevState
            })
        },
        vodEventDateTemporary: state.get().vodEventDateTemporary,

        setLiveStreamChannel: (newLiveStreamingChannel: LiveStreamingChannel | null) => {
            return state.set((prevState) => {
                prevState.liveStreamingChannel = newLiveStreamingChannel
                return prevState
            })
        },
        liveStreamChannel: state.get().liveStreamingChannel,
        setTemporaryLiveStreamChannel: (newLiveStreamingChannel: LiveStreamingChannel | null) => {
            return state.set((prevState) => {
                prevState.temporaryLiveStreamChannel = newLiveStreamingChannel
                return prevState
            })
        },
        temporaryLiveStreamChannel: state.get().temporaryLiveStreamChannel,
        setVideoPlayerStatus: (newVideoPlayerStatus: VideoPlayerStatus | null) => {
            state.videoPlayerStatus.merge(newVideoPlayerStatus)
        },
        videoPlayerStatus: state.get().videoPlayerStatus,
        isCommunicationCenterOpen: () => {
            const element = document.getElementById("hubspot-messages-iframe-container")
            if (element)
                element.style.cssText += state.get().networkingOpen
                    ? ";right: 340px !important;bottom: 60px !important;"
                    : ";right: 80px !important;bottom: 60px !important;"
            return state.get().networkingOpen
        },
        toggleNetworking: () => {
            return state.set((prevState) => {
                prevState.networkingOpen = !prevState.networkingOpen
                return prevState
            })
        },
        communicationCenterSelectedTab: state.get().communicationCenterSelectedTab,
        communicationCenterSelectedSubtab: state.get().communicationCenterSelectedSubtab,
        setCommunicationCenterSelectedTab: (mode: communicationCenterSelectedTab) => {
            setDisplay(mode)
        },
        setPeopleSubtab: (param?: NetworkingListType) => {
            setDisplay(communicationCenterSelectedTab.NETWORKING, param)
        },
        setChatsSubtab: (param?: ChatConversationParam | null) => {
            setDisplay(communicationCenterSelectedTab.CHATS, param)
        },
        setSchedulesSubtab: (param?: ScheduleListType | ScheduleListTypeV2) => {
            setDisplay(communicationCenterSelectedTab.SCHEDULE, param, true)
        },
        setNotificationsSubtab: (param: NotificationListType) => {
            setDisplay(communicationCenterSelectedTab.NOTIFICATIONS, param)
        },
        setSettingsSubtab: () => {
            setDisplay(communicationCenterSelectedTab.SETTINGS)
        },
        setCommunicationCenterForceLoadListFunction: (forceLoadListFunction: () => void) => {
            return state.set((prevState) => {
                prevState.forceLoadListFunction = forceLoadListFunction
                return prevState
            })
        },
        showTourNotification: state.get().showTourNotification,
        setShowTourNotification: (show: boolean) => {
            return state.set((prevState) => {
                prevState.showTourNotification = show
                return prevState
            })
        },
        showMissedCallNotification: state.get().showMissedCallNotification,
        missedCallNotificationName: state.get().missedCallNotificationName,
        missedCallNotificationId: state.get().missedCallNotificationId,

        setMissedCallNotification: (show: boolean, id: string, name: string) => {
            return state.set((prevState) => {
                prevState.missedCallNotificationId = id
                prevState.missedCallNotificationName = name
                prevState.showMissedCallNotification = show
                return prevState
            })
        },

        showMeetingReminder: state.get().showMeetingReminder,
        meetingReminderName: state.get().meetingReminderName,
        meetingTimeBefore: state.get().meetingTimeBefore,

        setMeetingReminder: (show: boolean, name: string, timeBefore: string) => {
            return state.set((prevState) => {
                prevState.meetingReminderName = name
                prevState.showMeetingReminder = show
                prevState.meetingTimeBefore = timeBefore
                return prevState
            })
        },

        isMyHandRaised: state.get().isMyHandRaised,

        setIsMyHandRaised: (raise: boolean) => {
            return state.set((prevState) => {
                prevState.isMyHandRaised = raise
                return prevState
            })
        },

        isDontShowModalClicked: state.get().isDontShowModalClicked,

        setIsDontShowModalClicked: (raise: boolean) => {
            return state.set((prevState) => {
                prevState.isDontShowModalClicked = raise
                return prevState
            })
        },

        isMeetingChatShown: state.get().isMeetingChatShown,

        setIsMeetingChatShown: (show: boolean) => {
            return state.set((prevState) => {
                prevState.isMeetingChatShown = show
                return prevState
            })
        },

        timezone: state.get().usingEventTimeZone ? eventTimeZoneName : moment.tz.guess(true),

        setTimeZone: (timeZone: string) => {
            return state.set((prevState) => {
                prevState.usingEventTimeZone = timeZone === eventTimeZoneName
                return prevState
            })
        },

        resetTimeZoneToDefault: () => {
            return state.set((prevState) => {
                prevState.usingEventTimeZone = defaultValues().usingEventTimeZone
                return prevState
            })
        },

        /**
         * current selected sidebar item/location (e.g. home, program, showfloor...)
         */
        currentItem: state.get().currentItem,

        /**
         * Set the current sidebar item/location (e.g. home, program, showfloor...)
         * @param currentItem - represents id of the sidebar item
         */
        setCurrentItem: (currentItem: string) => {
            return state.set((prevState) => {
                prevState.currentItem = currentItem
                return prevState
            })
        },

        currentMobileBreadcrumb: state.get().currentMobileBreadcrumb,

        setCurrentMobileBreadcrumb: (currentMobileBreadcrumb: string) => {
            return state.set((prevState) => {
                prevState.currentMobileBreadcrumb = currentMobileBreadcrumb
                return prevState
            })
        },

        /**
         * Last tab item in the app which was selected by the user.
         * Value is updated every time when user clicks on some CrsTab item.
         */
        lastVisitedTab: state.get().lastVisitedTab,

        /**
         * Set the last visited tab item.
         * @param lastVisitedTab - represents id of the tab
         */
        setLastVisitedTab: (lastVisitedTab: string | null) => {
            return state.set((prevState) => {
                prevState.lastVisitedTab = lastVisitedTab
                return prevState
            })
        },

        myScheduleStatus: state.get().myScheduleStatus,

        updateMyScheduleStatus: (myScheduleStatus: MyScheduleStatus) => {
            state.myScheduleStatus.merge(myScheduleStatus)
        },

        isRosterOpen: state.get().isRosterOpen,

        setIsRosterOpen: (isRosterOpen: boolean) => {
            return state.set((prevState) => {
                prevState.isRosterOpen = isRosterOpen
                return prevState
            })
        },

        isNavBarOpen: state.get().isNavBarOpen,

        setIsNavBarOpen: (isNavBarOpen: boolean) => {
            return state.set((prevState) => {
                prevState.isNavBarOpen = isNavBarOpen
                return prevState
            })
        },
        isAudioVideoSettingsOpen: state.get().isAudioVideoSettingsOpen,

        setIsAudioVideoSettingsOpen: (isAudioVideoSettingsOpen: boolean) => {
            return state.set((prevState) => {
                prevState.isAudioVideoSettingsOpen = isAudioVideoSettingsOpen
                return prevState
            })
        },

        isAudioVideoSettingsV2Open: state.get().isAudioVideoSettingsV2Open,

        setIsAudioVideoSettingsV2Open: (isAudioVideoSettingsV2Open: boolean) => {
            return state.set((prevState) => {
                prevState.isAudioVideoSettingsV2Open = isAudioVideoSettingsV2Open
                return prevState
            })
        },

        isPreMeetingScreenOpen: state.get().isPreMeetingScreenOpen,

        setIsPreMeetingScreenOpen: (isPreMeetingScreenOpen: boolean) => {
            return state.set((prevState) => {
                prevState.isPreMeetingScreenOpen = isPreMeetingScreenOpen
                return prevState
            })
        },

        roundTableCountdownString: state.get().roundTableCountdownString,

        setRoundTableCountdownString: (roundTableCountdownString: string) => {
            return state.set((prevState) => {
                prevState.roundTableCountdownString = roundTableCountdownString
                return prevState
            })
        },

        roundTableStatus: state.get().roundTableStatus,

        setRoundTableStatus: (roundTableStatus: any) => {
            return state.set((prevState) => {
                prevState.roundTableStatus = roundTableStatus
                return prevState
            })
        },

        isOnExhibitorsPageContentOrGlobalSearchResultPageContent:
            state.get().isOnExhibitorsPageContentOrGlobalSearchResultPageContent,

        setIsOnExhibitorsPageContentOrGlobalSearchResultPageContent: (value: boolean) => {
            return state.set((prevState) => {
                prevState.isOnExhibitorsPageContentOrGlobalSearchResultPageContent = value
                return prevState
            })
        },

        entityLogoPlaceholderExists: state.get().entityLogoPlaceholderExists,
        setEntityLogoPlaceholderExists: (value: boolean) => {
            return state.set((prevState) => {
                prevState.entityLogoPlaceholderExists = value
                return prevState
            })
        },

        reset: () => {
            return state.set((prevState) => {
                prevState = defaultValues()
                return prevState
            })
        }
    }
}
const state = createState(defaultValues())
export const useAppState = (): AppStateContextMethods => useWrapState(useState(state))
