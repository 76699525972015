import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { getUserBusyTimes, UserBusyTime, UserBusyTimes } from "../../../backendServices/GraphQLServices"
import { InvitePerson } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { useAppState } from "../../../globalStates/AppState"
import { IconChevronLeftSolid, IconChevronRightSolid, IconClose } from "../../Icons"
import {
    Agenda,
    AgendaItem,
    AvailabilityLayout,
    AvailabilityMainContent,
    AvailabilityWrapper,
    Box,
    BusyBox,
    ChevronLeftWrapper,
    ChevronRightWrapper,
    CloseButton,
    HoursSlider,
    IconsHeader,
    MainContentTitle,
    MeetingSelector,
    ResizeButton,
    ShowTimezone,
    Status,
    TimeSlot,
    UsersAvailablilityWrapper
} from "./CalendarEntryModal.styled"
import InvitedPersonAvailability from "./InvitedPersonAvailability"
import {
    calculateDivWidth,
    calculatePositionLeft,
    CalendarEntryModalViewMode,
    createPersonLabel,
    generateHoursInterval,
    getPositionLeft
} from "./ModalFunctions"
import moment from "moment"
import { Obligations } from "./CalendarEntryModal2"
import { getFormattedTimeZoneName } from "../../../utils/DateUtils"

interface AvailabilityCalendarProps {
    setOpenAvailabilityCalendar: Dispatch<SetStateAction<boolean>>
    openAvailabilityCalendar: boolean
    closeModal: () => void
    contactsInvited: InvitePerson[]
    startTime: Date | null
    setStartTime: Dispatch<SetStateAction<Date | null>>
    endTime: Date | null
    setEndTime: Dispatch<SetStateAction<Date | null>>
    date: Date | null
    setDate: Dispatch<SetStateAction<Date | null>>
    setFilteredBusyUsers: Dispatch<SetStateAction<UserBusyTime[] | undefined>>
    filteredBusyUsers?: UserBusyTime[]
    meetings: Obligations[]
    setMeetings: Dispatch<SetStateAction<Obligations[]>>
    times: UserBusyTime[]
    setTimes?: Dispatch<SetStateAction<UserBusyTime[]>>
    positionLeft: number
    timer: number
    viewMode?: CalendarEntryModalViewMode
}

const interval = 30 //minutes interval
const startDate = 60 * 0 // start time in minutes
const endDate = 60 * 24 // end time in minutes

function dateWithUTCIncrasedByTimezone(timeZone: string, date?: Date, dateYMD?: Date) {
    if (date && timeZone) {
        const dateMoment = moment(date).tz(timeZone)
        dateMoment.set("year", dateYMD ? dateYMD.getFullYear() : date.getFullYear())
        dateMoment.set("month", dateYMD ? dateYMD.getMonth() : date.getMonth())
        dateMoment.set("date", dateYMD ? dateYMD.getDate() : date.getDate())
        dateMoment.set("hour", date.getHours())
        dateMoment.set("minute", date.getMinutes())
        return dateMoment.toDate()
    }
}

const AvailabilityCalendar = (props: AvailabilityCalendarProps) => {
    const strings = branding.calendarEntryModalPageContent
    const hours = generateHoursInterval(startDate, endDate, interval)
    const sliderRef = useRef<HTMLDivElement>(null)
    const { timezone } = useAppState()

    function dateModification(selectedDate: Date) {
        selectedDate.setHours(0)
        selectedDate.setMinutes(0)
        selectedDate.setSeconds(0)
        const modificatedDate = dateWithUTCIncrasedByTimezone(timezone, selectedDate)
        return modificatedDate
    }

    const startTimeOfDay = dateModification(props.date!)
    const endTimeOfDay = new Date(startTimeOfDay!.getTime() + 24 * 60 * 60 * 1000)
    const [mergedTimes, setMergedTimes] = useState<Date[]>([])
    const uniqueTimes = mergedTimes.filter((value, id, array) => array.indexOf(value) === id) // variable used for removing duplicate elements
    const [width, setWidth] = useState(calculatePositionLeft(props.startTime, props.positionLeft, "start"))
    let temp = new Date()
    const meetingSelectorRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const [isResizing, setIsResizing] = useState<boolean>(false)
    const [initialLeft, setInitialLeft] = useState<number>(0)
    const [initialRight, setInitialRight] = useState<number>(0)
    const [positionLeft, setPositionLeft] = useState<number>(0)
    const [resizeSide, setResizeSide] = useState<"left" | "right" | null>(null)
    const [isLeftChevronVisible, setIsLeftChevronVisible] = useState(false)
    const [isRightChevronVisible, setIsRightChevronVisible] = useState(true)

    useEffect(() => {
        if (isResizing === false)
            sliderRef.current?.scrollTo(calculatePositionLeft(props.startTime, props.positionLeft, "start") - 90, 0)
        setPositionLeft(calculatePositionLeft(props.startTime, props.positionLeft, "start"))
        //eslint-disable-next-line
    }, [calculatePositionLeft(props.startTime, props.positionLeft, "start")])

    useEffect(() => {
        async function loadObligations() {
            const meetings: Obligations[] = []
            let nextToken

            for await (let contact of props.contactsInvited) {
                const response = await getUserBusyTimes(contact.id, startTimeOfDay!, endTimeOfDay, nextToken)
                const eventDatesTimezoneTransformed =
                    contact.eventDates?.map((ed) => {
                        ed.dateTimeStart = moment.tz(moment(ed.dateTimeStart).format(), timezone).format()
                        ed.dateTimeEnd = moment.tz(moment(ed.dateTimeEnd).format(), timezone).format()
                        return ed
                    }) ?? []
                meetings.push({
                    userId: contact.id,
                    userBusyTimes: (response as UserBusyTimes) || [],
                    eventDates: eventDatesTimezoneTransformed
                })
            }
            props.setMeetings(meetings)
        }

        loadObligations()
        //eslint-disable-next-line
    }, [props.contactsInvited, props.date, timezone])

    useEffect(() => {
        setMergedTimes([])
        props.setTimes?.([])
        props.meetings?.map((meet) =>
            //eslint-disable-next-line
            meet.userBusyTimes.items?.map((item) => {
                setMergedTimes((mergedTimes) => [...mergedTimes, item.start as Date])
                props.setTimes!((times) => [...times, item as unknown as UserBusyTime])
            })
        )

        //eslint-disable-next-line
    }, [props.meetings])

    function checkMeetingSelector() {
        let positionLeftArray: number[] = uniqueTimes?.map((time) => getPositionLeft(time))
        if (
            positionLeftArray?.some((el) => el + "px" === calculatePositionLeft(props.startTime, props.positionLeft) + "px") &&
            uniqueTimes?.some((el) => new Date(el).getDate() === props.date?.getDate())
        ) {
            return true
        } else if (
            props.times?.some(
                (el) =>
                    getPositionLeft(el.start) < calculatePositionLeft(props.startTime, props.positionLeft) &&
                    getPositionLeft(el.end) > calculatePositionLeft(props.startTime, props.positionLeft)
            ) &&
            uniqueTimes?.some((el) => new Date(el).getDate() === props.date?.getDate())
        )
            return true
        else if (
            props.times?.some(
                (el) =>
                    getPositionLeft(el.start) > calculatePositionLeft(props.startTime, props.positionLeft) &&
                    calculatePositionLeft(props.startTime, props.positionLeft) + width > getPositionLeft(el.start)
            ) &&
            uniqueTimes?.some((el) => new Date(el).getDate() === props.date?.getDate())
        )
            return true
        else return false
    }

    useEffect(() => {
        if (isResizing) {
            document.addEventListener("mousemove", handleMouseMove)
            document.addEventListener("mouseup", handleMouseUp)
        }
        return () => {
            document.removeEventListener("mousemove", handleMouseMove)
            document.removeEventListener("mouseup", handleMouseUp)
        }
        //eslint-disable-next-line
    }, [isResizing])

    const handleMouseDown = (event: any, side: "left" | "right") => {
        setIsResizing(true)
        setResizeSide(side)
        side === "left" ? setInitialLeft(event.clientX) : setInitialRight(event.clientX)
    }

    const handleMouseMove = (event: any) => {
        if (!isResizing || resizeSide === null) return

        const initialValue = resizeSide === "left" ? initialLeft : initialRight
        const containerWidth = containerRef?.current!.offsetWidth
        const deltaX = initialValue - event.clientX
        const resizeIncrement = 45
        const resizeDelta = Math.floor(deltaX / resizeIncrement) * resizeIncrement

        let newWidth = Math.max(0, resizeSide === "left" ? width + resizeDelta : width - resizeDelta)

        if (newWidth <= 45) {
            newWidth = 45
        }

        if (resizeSide === "left") {
            //prevent user from going over 00:00 when sliding left
            const startHours = props.startTime?.getHours()!
            const newStartHours = Math.floor((calculatePositionLeft(props.endTime, props.positionLeft, "end") - newWidth) / 180)
            let newStartMinutes = ((calculatePositionLeft(props.endTime, props.positionLeft, "end") - newWidth) / 180) % 1

            if (newStartMinutes === 0) {
                newStartMinutes = 0
            } else if (newStartMinutes === 0.25) {
                newStartMinutes = 15
            } else if (newStartMinutes === 0.5) {
                newStartMinutes = 30
            } else {
                newStartMinutes = 45
            }

            //we want to ensure that user doesn't expand the selector beyond the current time
            const newTime: Date = props.date ? new Date(props.date!) : new Date()
            newTime.setHours(newStartHours)
            newTime.setMinutes(newStartMinutes)

            if ((startHours > 0 || newStartHours >= 0) && !moment(newTime).isBefore(moment(new Date()))) {
                setWidth(newWidth)

                setInitialLeft(event.clientX)
            }
        } else {
            //prevent user going over 00:00 (the maximum right point) when sliding right
            if (calculatePositionLeft(props.startTime, props.positionLeft, "start") + newWidth <= containerWidth) {
                setWidth(newWidth)

                setInitialRight(event.clientX)
            }
        }
    }

    const handleMouseUp = () => {
        setIsResizing(false)
    }

    useEffect(() => {
        if (resizeSide === null) return

        let calculateMinutesFromWidth =
            resizeSide === "left"
                ? calculatePositionLeft(props.endTime, props.positionLeft, "end") - width
                : calculatePositionLeft(props.startTime, props.positionLeft, "start") + width

        let number = Math.floor(calculateMinutesFromWidth / 180)

        if (number < 0) number = 23

        let decimalNumber = (calculateMinutesFromWidth / 180) % 1

        let decimalNumberInMinutes

        if (decimalNumber === 0) {
            decimalNumberInMinutes = 0
        } else if (decimalNumber === 0.25) {
            decimalNumberInMinutes = 15
        } else if (decimalNumber === 0.5) {
            decimalNumberInMinutes = 30
        } else {
            decimalNumberInMinutes = 45
        }

        if (resizeSide === "right" && props.startTime?.getHours()! >= 0 && props.startTime?.getMinutes()! > 0) {
            temp.setDate(temp.getDate() + 1)
        }

        temp.setHours(number)
        temp.setMinutes(decimalNumberInMinutes)

        resizeSide === "left" ? props.setStartTime(temp) : props.setEndTime(temp)

        //eslint-disable-next-line
    }, [initialLeft, initialRight, width])

    useEffect(() => {
        setWidth(calculateDivWidth(props.timer))
        //eslint-disable-next-line
    }, [calculateDivWidth(props.timer)])

    const handleScrollVisibility = () => {
        if (sliderRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current
            const isLeftVisible = scrollLeft > 0
            const isRightVisible = scrollLeft < scrollWidth - clientWidth

            if (isLeftChevronVisible !== isLeftVisible) {
                setIsLeftChevronVisible(isLeftVisible)
            }

            if (isRightChevronVisible !== isRightVisible) {
                setIsRightChevronVisible(isRightVisible)
            }
        }
    }

    const handleHorizontalScroll = (element: HTMLDivElement | null, speed: number, distance: number, step: number) => {
        let scrollAmount = 0
        const sliderTimer = setInterval(() => {
            if (element !== null) element.scrollLeft += step

            scrollAmount += Math.abs(step)
            if (scrollAmount >= distance) {
                clearInterval(sliderTimer)
                handleScrollVisibility()
            }
        }, speed)
    }

    return (
        <AvailabilityLayout>
            <IconsHeader>
                <CloseButton onClick={() => props.closeModal()}>
                    <IconClose fill={branding.recommendModal.closeIconColor} width="15px" height="15px" />
                </CloseButton>
            </IconsHeader>
            <AvailabilityMainContent>
                <MainContentTitle>{branding.calendarEntryModalPageContent.availabilityTitle}</MainContentTitle>
                <ShowTimezone>{getFormattedTimeZoneName(timezone)}</ShowTimezone>
            </AvailabilityMainContent>

            {isLeftChevronVisible && (
                <ChevronLeftWrapper onClick={() => handleHorizontalScroll(sliderRef.current, 25, 100, -10)}>
                    <IconChevronLeftSolid width="10" height="10" fill="#727272" />
                </ChevronLeftWrapper>
            )}
            {isRightChevronVisible && (
                <ChevronRightWrapper onClick={() => handleHorizontalScroll(sliderRef.current, 25, 100, 10)}>
                    <IconChevronRightSolid width="10" height="10" fill="#727272" />
                </ChevronRightWrapper>
            )}

            <UsersAvailablilityWrapper
                ref={sliderRef}
                onScroll={() => {
                    handleScrollVisibility()
                }}
            >
                <HoursSlider>
                    {hours.map((time, index) => {
                        return <TimeSlot key={index}>{time}</TimeSlot>
                    })}
                </HoursSlider>

                <AvailabilityWrapper ref={containerRef}>
                    <MeetingSelector
                        ref={meetingSelectorRef}
                        width={width + "px"}
                        left={positionLeft + "px"}
                        checkBackgroundColor={checkMeetingSelector()}
                    >
                        <ResizeButton
                            width={calculateDivWidth(props.timer) === 0}
                            borderColor={checkMeetingSelector()}
                            onMouseDown={
                                props.viewMode !== CalendarEntryModalViewMode.VIEW
                                    ? (e: any) => handleMouseDown(e, "left")
                                    : undefined
                            }
                        />

                        <ResizeButton
                            width={calculateDivWidth(props.timer) === 0}
                            borderColor={checkMeetingSelector()}
                            right={true}
                            onMouseDown={
                                props.viewMode !== CalendarEntryModalViewMode.VIEW
                                    ? (e: any) => handleMouseDown(e, "right")
                                    : undefined
                            }
                        />
                    </MeetingSelector>
                    {props.contactsInvited?.map((contact, index) => (
                        <InvitedPersonAvailability
                            key={contact.id}
                            contact={contact}
                            label={createPersonLabel(contact)}
                            startTime={props.startTime}
                            meetingTimes={props.meetings[index]}
                        />
                    ))}
                </AvailabilityWrapper>
            </UsersAvailablilityWrapper>

            <Agenda>
                <AgendaItem>
                    <Box checkBackgroundColor={checkMeetingSelector()} />
                    <Status>{strings.meetingSlot}</Status>
                </AgendaItem>
                <AgendaItem>
                    <BusyBox />
                    <Status>{strings.busySlot}</Status>
                </AgendaItem>
            </Agenda>
        </AvailabilityLayout>
    )
}

export default AvailabilityCalendar
