import { useEffect, useState } from "react"
import { isNullOrEmpty } from "../communicationAreaV2/helpers/GlobalFunctions"
import branding from "../branding/branding"
import { entityPlaceholderLogoUrl } from ".."
import { useAppState } from "../globalStates/AppState"

export default function useEntityLogoUrl(entityLogoUrl?: string) {
    const [finalLogoUrl, setFinalLogoUrl] = useState<string | null>(null)

    const placeholderLogoFileExists = useAppState().entityLogoPlaceholderExists

    useEffect(() => {
        // first check redundant but needed to satisfy the compiler
        if (entityLogoUrl && !isNullOrEmpty(entityLogoUrl)) {
            // own custom logo is always prioritized, doesn't matter if placeholders are enabled in the branding or not
            setFinalLogoUrl(entityLogoUrl)
            // if own logo is null, check if the toggle for placeholders is enabled
        } else if (branding.configuration.usePlaceholderAvatarForEntities && placeholderLogoFileExists) {
            setFinalLogoUrl(entityPlaceholderLogoUrl)
        } else {
            // if we got nothing to work with, return null and let the parent component handle the fallback on it's own
            // current fallback: if this hook returns null, render the entity initials, usually with an elvis operator
            setFinalLogoUrl(null)
        }
    }, [entityLogoUrl, placeholderLogoFileExists])

    return finalLogoUrl
}
