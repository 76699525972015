import { createState, State, useState } from "@hookstate/core"
import { MeetingStatus, useMeetingManager } from "amazon-chime-sdk-component-library-react"
import { UserMediaItem } from "../../backendServices/UserMediaServices"
import { LogoPositions } from "../enums/LogoPosition"
import { useAudioContext } from "./AudioContext"
import { useVideoContext } from "./VideoContext"
import { useEffect } from "react"

export enum PreMeetingSettingType {
    AUDIOINPUTSTATE = "AUDIOINPUTSTATE",
    VIDEOINPUTSTATE = "VIDEOINPUTSTATE",
    LOGOURL = "LOGOURL",
    LOGOPOSITION = "LOGOPOSITION",
    BACKGROUNDURL = "BACKGROUNDURL",
    BLUR = "BLUR"
}

export interface PreMeetingSettingsObjectType {
    audioInputState: boolean
    videoInputState: boolean
    logo: UserMediaItem | null
    logoPosition: LogoPositions
    background: UserMediaItem | null
    blurState: boolean
}

export const preMeetingSettingsKey = "virtualGuide-PreMeetingSettings"

interface StateValues {
    meetingSettingsState: PreMeetingSettingsObjectType | undefined
}

const getStartValues = (): StateValues => {
    return {
        meetingSettingsState: undefined
    }
}

export interface PreMeetingSettingContext {
    applyStoredAudioSettings: () => Promise<void>
    applyStoredVideoSettings: () => Promise<void>
    storeSetting: (setting: PreMeetingSettingType, value: any) => void
    getSetting: (setting: PreMeetingSettingType) => boolean
}
const state = createState<StateValues>(getStartValues())

const useStateWrapper = (state: State<StateValues>): PreMeetingSettingContext => {
    const { setSelectedBackground, setSelectedLogo, setLogoPosition, toggleBlur, getIsBlurActive } = useVideoContext()
    const { isMuted, toggleMute } = useAudioContext()
    const settingsObjectFromLocalStorage = JSON.parse(
        localStorage.getItem(preMeetingSettingsKey) || "{}"
    ) as PreMeetingSettingsObjectType

    useEffect(() => {
        state.merge({ meetingSettingsState: settingsObjectFromLocalStorage })
        // eslint-disable-next-line
    }, [])
    const meetingManager = useMeetingManager()

    return {
        applyStoredAudioSettings: async () => {
            // 1. Load audio input settings and apply
            if (
                settingsObjectFromLocalStorage.hasOwnProperty("audioInputState") &&
                settingsObjectFromLocalStorage.audioInputState === false &&
                !isMuted()
            ) {
                toggleMute()
            } else if (
                settingsObjectFromLocalStorage.hasOwnProperty("audioInputState") &&
                settingsObjectFromLocalStorage.audioInputState === true &&
                isMuted()
            ) {
                toggleMute()
            }
        },
        applyStoredVideoSettings: async () => {
            // Load logo url and apply
            if (
                meetingManager.meetingStatus === MeetingStatus.Succeeded ||
                meetingManager.meetingStatus === MeetingStatus.Loading
            ) {
                const logo = settingsObjectFromLocalStorage?.logo
                if (logo) {
                    setSelectedLogo(logo)
                }
                // Load logo position and apply
                const logoPosition: LogoPositions = settingsObjectFromLocalStorage.logoPosition
                if (logoPosition) {
                    setLogoPosition(logoPosition)
                }
                //  Load background url and apply
                const background = settingsObjectFromLocalStorage?.background
                if (background) {
                    setSelectedBackground(background)
                }

                // Load background blur state and apply
                const backgroundBlurState: boolean = settingsObjectFromLocalStorage?.blurState
                if (!getIsBlurActive() && backgroundBlurState) {
                    toggleBlur()
                } else if (getIsBlurActive() && !settingsObjectFromLocalStorage.blurState) {
                    toggleBlur()
                }
            }
        },
        storeSetting: (setting: PreMeetingSettingType, value: any) => {
            const settingsObjectFromLocalStorage = JSON.parse(
                localStorage.getItem(preMeetingSettingsKey) || "{}"
            ) as PreMeetingSettingsObjectType
            let settingsObjectFromLocalStorageCopy = structuredClone(settingsObjectFromLocalStorage)

            switch (setting) {
                case PreMeetingSettingType.AUDIOINPUTSTATE:
                    settingsObjectFromLocalStorageCopy.audioInputState = value
                    break
                case PreMeetingSettingType.VIDEOINPUTSTATE:
                    settingsObjectFromLocalStorageCopy.videoInputState = value
                    break
                default:
            }

            localStorage.setItem(preMeetingSettingsKey, JSON.stringify(settingsObjectFromLocalStorageCopy))
            state.merge({ meetingSettingsState: settingsObjectFromLocalStorageCopy })
        },
        getSetting: (setting: PreMeetingSettingType) => {
            switch (setting) {
                case PreMeetingSettingType.AUDIOINPUTSTATE:
                    return state.value.meetingSettingsState?.audioInputState ?? false
                case PreMeetingSettingType.VIDEOINPUTSTATE:
                    return state.value.meetingSettingsState?.videoInputState ?? false
            }
            return false
        }
    }
}

export const usePreMeetingSettingContext = (): PreMeetingSettingContext => useStateWrapper(useState(state))
