import { useCallback, useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { IconClose, IconSettingsAudio, IconSettingsBackground, IconSettingsLogo, IconSettingsVideo } from "../../../ui/Icons"
import SettingsSwitcher, { SettingsSwitcherItem } from "../SettingsSwitcher/SettingsSwitcher"
import { MeetingStatus } from "amazon-chime-sdk-component-library-react"
import AudioSettings from "../AudioSettings/AudioSettings"
import VideoSettings from "../VideoSettings/VideoSettings"
import BackgroundSettings from "../BackgroundSettings/BackgroundSettings"
import LogoSettings from "../LogoSettings/LogoSettings"
import { useMeetingManager } from "amazon-chime-sdk-component-library-react"
import { useHistory } from "react-router-dom"
import {
    ButtonsContainer,
    CloseIconContainer,
    ErrorMessageContainer,
    SettingsBackground,
    SettingsFixedBottomContainer,
    SettingsModal,
    Title
} from "./Settings.styled"
import { useAppState } from "../../../globalStates/AppState"
import { useMeetingController } from "../../context/MeetingController"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import { useMeetingReadinessCheckContext } from "../../context/MeetingReadinessCheck"
import branding from "../../../branding/branding"
import PrimaryButton from "../Shared/PrimaryButton"
import SecondaryButton from "../Shared/SecondaryButton"
import { useDevicesV2 } from "../../hooks/useDevices"
import { PreMeetingSettingType, usePreMeetingSettingContext } from "../../context/PreMeetingSettingsContext"
import { useVideoContext } from "../../context/VideoContext"

export interface ConferenceRoomUserSettings {
    isBlurActive: boolean
    selectedBackgroundURL: string | null
    customUploadedBackgroundURL: string | null
    audioInputDeviceId: string | null
    audioOutputDeviceId: string | null
}

function SettingsV2() {
    const [activeItem, setActiveItem] = useState<string>("audio")
    const history = useHistory()
    const meetingManager = useMeetingManager()
    const appState = useAppState()
    const meetingController = useMeetingController()
    const userId = useLoggedInState().user()?.profileId
    const meetingReadynessCheck = useMeetingReadinessCheckContext()
    const [hasMicrophonePermission, setHasMicrophonePermission] = useState(true)
    const devices = useDevicesV2()
    const currentPathRef = useRef(history.location.pathname)
    const preMeetingSettingsContext = usePreMeetingSettingContext()
    const videoContext = useVideoContext()

    useEffect(() => {
        devices.ensureDevices().then(() => {
            setHasMicrophonePermission(devices.hasAudioInputPermission())
        })
        // eslint-disable-next-line
    }, [devices])

    useEffect(() => {
        ;(async () => {
            if (devices.currentAudioInputDevice()?.value)
                await meetingManager.audioVideo?.startAudioInput(devices.currentAudioInputDevice()?.value as string)
            if (devices.currentAudioOutputDevice()?.value)
                await meetingManager.audioVideo?.chooseAudioOutput(devices.currentAudioOutputDevice()?.value as string)
        })()
    }, [devices, meetingManager])

    useEffect(() => {
        const unlisten = history.listen((newLocation) => {
            const previousPath = currentPathRef.current
            const newPath = newLocation.pathname
            if (previousPath !== newPath) {
                appState.setIsAudioVideoSettingsV2Open(false)
            }
            currentPathRef.current = newPath
        })
        return () => unlisten()
        // eslint-disable-next-line
    }, [history])

    const settingsItems: SettingsSwitcherItem[] = [
        {
            icon: <IconSettingsAudio fill="#fff" width="16px" height="16px" />,
            label: branding.audioVideoSettings.tabTitleAudio,
            key: "audio"
        },
        {
            icon: <IconSettingsVideo fill="#fff" width="18px" height="18px" />,
            label: branding.audioVideoSettings.tabTitleVideo,
            key: "video"
        },
        {
            icon: <IconSettingsLogo fill="#fff" width="18px" height="18px" />,
            label: branding.audioVideoSettings.tabTitleLogo,
            key: "logo"
        },
        {
            icon: <IconSettingsBackground fill="#fff" width="18px" height="18px" />,
            label: branding.audioVideoSettings.tabTitleBackground,
            key: "background"
        }
    ]

    const generateButtonText = useCallback(() => {
        if (meetingReadynessCheck.getIsCheckActive()) {
            return branding.audioVideoSettings.applySettingsButtonLabel
        } else {
            if (meetingController.getInitMeetingError()) {
                return branding.audioVideoSettings.reloadSettingsButtonLabel
            } else if (!meetingController.getIsMeetingActive()) {
                return branding.audioVideoSettings.joinMeetingSettingsButtonLabel
            } else if (meetingController.getIsMeetingActive()) {
                return branding.audioVideoSettings.applySettingsButtonLabel
            } else if (meetingManager.meetingStatus === MeetingStatus.Loading) {
                return branding.audioVideoSettings.loadingMeetingSettingsButtonLabel
            } else if (!meetingController.getIsMeetingActive()) {
                return branding.audioVideoSettings.applySettingsButtonLabel
            } else {
                return branding.audioVideoSettings.applySettingsButtonLabel
            }
        }
        // eslint-disable-next-line
    }, [meetingManager.meetingStatus, meetingController.getInitMeetingError(), meetingReadynessCheck.getIsCheckActive()])

    useEffect(() => {
        //  if no meeting is active, we initialize a "meeting readiness meeting", which allows us
        //  to have the video stream even not in a meeting
        if (
            !meetingController.getIsMeetingActive() &&
            !meetingController.getExternalMeetingId() &&
            !history.location.pathname.includes("/meetingV2/")
        ) {
            meetingReadynessCheck.startCheck(`cl_MEETING-READINESS-CHECK_${userId}`, userId || "")

            return () => {
                ;(async () => {
                    await meetingReadynessCheck.stopCheck()
                })()
            }
        }
        //eslint-disable-next-line
    }, [meetingController.getIsMeetingActive()])

    return (
        <SettingsBackground>
            <SettingsModal>
                <Container>
                    <Row>
                        <Col className="p-0">
                            {(meetingManager.meetingStatus === MeetingStatus.Succeeded ||
                                !window.location.pathname.includes("meetingV2")) && (
                                <CloseIconContainer
                                    onClick={() => {
                                        appState.setIsAudioVideoSettingsV2Open(false)
                                        if (meetingManager.meetingStatus !== MeetingStatus.Succeeded) {
                                            meetingManager.meetingSession?.audioVideo.stopVideoInput()
                                            meetingManager.meetingSession?.audioVideo.stopAudioInput()
                                        }
                                    }}
                                >
                                    {IconClose({ fill: "#fff", width: "10", height: "10" })}
                                </CloseIconContainer>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-0">
                            <Title>{branding.conferenceTexts.settings}</Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="p-0">
                            <SettingsSwitcher
                                setActiveItem={(key: string) => setActiveItem(key)}
                                settingsItems={settingsItems}
                                activeItem={activeItem}
                            />
                        </Col>
                        <Col md={8} className="pr-0">
                            {activeItem === "audio" && <AudioSettings />}
                            {activeItem === "video" && <VideoSettings />}
                            {activeItem === "background" && <BackgroundSettings />}
                            {activeItem === "logo" && <LogoSettings />}
                        </Col>
                    </Row>

                    <Row>
                        <SettingsFixedBottomContainer>
                            {meetingController.getInitMeetingError() && (
                                <ErrorMessageContainer>
                                    <Row>
                                        <Col className="p-0" md={4}></Col>
                                        <Col className="p-0" md={8}>
                                            {branding.audioVideoSettings.initMeetingError}
                                        </Col>
                                    </Row>
                                </ErrorMessageContainer>
                            )}

                            {!hasMicrophonePermission && (
                                <ErrorMessageContainer>
                                    <Row>
                                        <Col className="p-0" md={4}></Col>
                                        <Col className="p-0" md={8}>
                                            {branding.audioVideoSettings.microphonePermissionMessage}
                                        </Col>
                                    </Row>
                                </ErrorMessageContainer>
                            )}

                            <ButtonsContainer>
                                {hasMicrophonePermission &&
                                    meetingManager.meetingStatus !== MeetingStatus.Succeeded &&
                                    window.location.pathname.includes("meetingV2") && (
                                        <PrimaryButton
                                            text={generateButtonText()}
                                            onClickHandler={async () => {
                                                if (meetingController.getInitMeetingError()) {
                                                    window.location.reload()
                                                } else if (
                                                    !meetingController.getIsMeetingActive() &&
                                                    meetingController.getExternalMeetingId()
                                                ) {
                                                    meetingController.startMeeting()
                                                    if (
                                                        preMeetingSettingsContext.getSetting(
                                                            PreMeetingSettingType.VIDEOINPUTSTATE
                                                        )
                                                    )
                                                        videoContext.toggleVideo()
                                                }

                                                appState.setIsAudioVideoSettingsV2Open(false)
                                            }}
                                        />
                                    )}

                                {meetingManager.meetingStatus !== MeetingStatus.Succeeded &&
                                    window.location.pathname.includes("meetingV2") && (
                                        <SecondaryButton
                                            text={branding.audioVideoSettings.cancelMeetingSettingsButtonLabel}
                                            onClickHandler={() => {
                                                if (meetingManager.meetingStatus !== MeetingStatus.Succeeded) {
                                                    history.push("/")
                                                    appState.setIsAudioVideoSettingsV2Open(false)
                                                    meetingManager.meetingSession?.audioVideo.stopVideoInput()
                                                    meetingManager.meetingSession?.audioVideo.stopAudioInput()
                                                } else {
                                                    appState.setIsAudioVideoSettingsV2Open(false)
                                                }
                                            }}
                                        />
                                    )}
                            </ButtonsContainer>
                        </SettingsFixedBottomContainer>
                    </Row>
                </Container>
            </SettingsModal>
        </SettingsBackground>
    )
}

export default SettingsV2
