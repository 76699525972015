/**
 * Docs for background processor for future improvements
 * https://aws.github.io/amazon-chime-sdk-js/modules/backgroundfilter_video_processor.html
 * */
import { createState, State, useState } from "@hookstate/core"
import {
    useBackgroundBlur,
    useBackgroundReplacement,
    useContentShareControls,
    useContentShareState,
    useLocalVideo,
    useMeetingManager,
    useVideoInputs
} from "amazon-chime-sdk-component-library-react"
import {
    BackgroundReplacementVideoFrameProcessor,
    ConsoleLogger,
    DefaultVideoTransformDevice,
    LogLevel
} from "amazon-chime-sdk-js"
import { useEffect } from "react"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import {
    deleteUserMedia,
    getPresignedUrlWithHeaders,
    getUserMedia,
    PresignedURLServiceSuccessResponse,
    uploadUserMedia,
    UserMediaItem,
    UserMediaPurpose,
    UserMediaUploadServiceSuccessResponse
} from "../../backendServices/UserMediaServices"
import branding from "../../branding/branding"
import { getEnvironment } from "../../environments"
import { useLoggedInState, UserOrganization } from "../../globalStates/LoggedInUser"
import { VideoInputStorageKey } from "../hooks/useDevices"
import { preMeetingSettingsKey, PreMeetingSettingsObjectType } from "./PreMeetingSettingsContext"
import { LogoProcessor } from "../processor/LogoProcessor"
import { LogoPositions } from "../enums/LogoPosition"
import {
    addEffectsToMeeting,
    enableBlur,
    enableReplacement,
    pauseEffects,
    setReplacementImage
} from "../processor/BackgroundFilter"
import { VideoInputsStorageKey } from "../../conference/hooks/useDevices"

const defaultPosition = "topleft"
let logoProcessor: LogoProcessor = new LogoProcessor(defaultPosition)
let logger: ConsoleLogger = new ConsoleLogger("Video Transformer", LogLevel.OFF)
let videoTransformDevice: DefaultVideoTransformDevice | undefined
let lastVideoElement: HTMLVideoElement | undefined

interface StateValues {
    backgroundGalleryItems: UserMediaItem[]
    selectedBackgroundUrl: string | undefined
    isBlurActive: boolean
    customUploadedBackgrounds: UserMediaItem[]
    isLoading: boolean
    logoGalleryItems: UserMediaItem[]
    customUploadedLogos: UserMediaItem[]
    selectedLogoUrl: string | undefined
    logoProcessor: LogoProcessor | null
    selectedLogoPosition: LogoPositions
    processors: LogoProcessor | BackgroundReplacementVideoFrameProcessor | null
    isLogoProcessorActive: boolean
    errorMessage: string | undefined
}

const getStartValues = (): StateValues => {
    return {
        backgroundGalleryItems: [], // TODO: branding.audioVideoSettings.galleryItemList,
        selectedBackgroundUrl: undefined,
        isBlurActive: false,
        customUploadedBackgrounds: [],
        isLoading: false,
        customUploadedLogos: [],
        logoGalleryItems: [],
        selectedLogoUrl: undefined,
        logoProcessor: new LogoProcessor(LogoPositions.TOP_LEFT),
        selectedLogoPosition: LogoPositions.TOP_LEFT,
        processors: null,
        isLogoProcessorActive: false,
        errorMessage: undefined
    }
}

const state = createState<StateValues>(getStartValues())

export interface VideoContext {
    getBackgroundGalleryItems: () => UserMediaItem[]
    getSelectedBackground: () => string | undefined
    getCustomUploadedBackgrounds: () => UserMediaItem[]
    setSelectedBackground: (background: UserMediaItem) => void
    uploadBackground: (e: any) => void
    removeBackground: () => Promise<void>
    deleteCustomBackground: (userMediaUrl: string) => Promise<void>
    toggleBlur: () => Promise<void>
    getIsBlurActive: () => boolean
    setSelectedLogo: (logo: UserMediaItem) => void
    uploadLogo: (e: any) => void
    getLogoGalleryItems: () => UserMediaItem[]
    getCustomUploadedLogos: () => UserMediaItem[]
    getSelectedLogo: () => string | undefined
    removeLogo: () => void
    setLogoPosition: (pos: LogoPositions) => void
    getLogoPosition: () => LogoPositions
    getIsLogoProcessorActive: () => boolean
    deleteCustomUploadedLogo: (userMediaUrl: string) => Promise<void>
    updateVideoInput: () => Promise<void>
    startVideoPreview: (videoElement: HTMLVideoElement) => void
    stopVideoPreview: (videoElement: HTMLVideoElement) => void
    getErrorMessage: () => string | undefined
    resetErrorMessage: () => void
    isVideoEnabled: () => boolean
    toggleVideo: () => void
    toggleContentShare: () => void
    isContentShareEnabled: () => boolean
}

const useStateWrapper = (state: State<StateValues>): VideoContext => {
    const imageUploadLimitMessage = branding.audioVideoSettings.userMediaUploadFileUploadLimitExceededErrorMessage
    const somethingWentWrongMessage = branding.audioVideoSettings.userMediaUploadGenericErrorMessage
    const imageToLargeMessage = branding.audioVideoSettings.userMediaUploadFileToLargeErrorMessage
    const { isBackgroundReplacementSupported } = useBackgroundReplacement()
    const { isBackgroundBlurSupported } = useBackgroundBlur()
    const { toggleContentShare } = useContentShareControls()
    const { isVideoEnabled, toggleVideo } = useLocalVideo()
    const { isLocalUserSharing } = useContentShareState()
    const loggedInUser = useLoggedInState().user()
    const { selectedDevice } = useVideoInputs()
    const meetingManager = useMeetingManager()

    useEffect(() => {
        let errorMessageTimeOut: undefined | ReturnType<typeof setTimeout>
        if (state.value.errorMessage) {
            errorMessageTimeOut = setTimeout(() => {
                resetErrorMessage()
            }, 5000)
        }
        return () => {
            clearTimeout(errorMessageTimeOut)
        }
        //eslint-disable-next-line
    }, [state.value.errorMessage])

    useEffect(() => {
        ;(async () => {
            let uploadedBackgrounds: UserMediaItem[] = [],
                defaultBackgrounds: UserMediaItem[] = branding.audioVideoSettings.galleryItemList.map((background: string) => {
                    return { id: "", url: background, purpose: UserMediaPurpose.BACKGROUND }
                }),
                uploadedLogos: UserMediaItem[] = [],
                defaultLogos: UserMediaItem[] = (loggedInUser?.organizations || []).map((userOrganization: UserOrganization) => {
                    return {
                        id: userOrganization.id,
                        purpose: UserMediaPurpose.LOGO,
                        url: userOrganization?.logo
                    } as UserMediaItem
                }),
                devEnviromentLogos = [
                    {
                        id: "organizer-logo",
                        purpose: UserMediaPurpose.LOGO,
                        url: "/branding/applicationMedia/organizerlogo_closed.png"
                    }
                ]

            const userMedia: UserMediaItem[] | BackendServiceError = await getUserMedia(loggedInUser?.profileId || "")
            if (Array.isArray(userMedia)) {
                uploadedLogos = userMedia.filter((item: UserMediaItem) => item.purpose === UserMediaPurpose.LOGO)
                uploadedBackgrounds = userMedia.filter((item: UserMediaItem) => item.purpose === UserMediaPurpose.BACKGROUND)
            }
            if (getEnvironment() === "dev") {
                state.merge({
                    logoGalleryItems: uploadedLogos.concat(devEnviromentLogos),
                    customUploadedLogos: uploadedLogos,
                    backgroundGalleryItems: uploadedBackgrounds.concat(defaultBackgrounds),
                    customUploadedBackgrounds: uploadedBackgrounds
                })
            } else {
                const userConnectedOrganizationsLogos: UserMediaItem[] = defaultLogos
                state.merge({
                    logoGalleryItems: (uploadedLogos as UserMediaItem[]).concat(userConnectedOrganizationsLogos),
                    customUploadedLogos: uploadedLogos as UserMediaItem[],
                    backgroundGalleryItems: uploadedBackgrounds.concat(defaultBackgrounds),
                    customUploadedBackgrounds: uploadedBackgrounds as UserMediaItem[]
                })
            }
            // eslint-disable-next-line
        })()

        // eslint-disable-next-line
    }, [])

    const resetErrorMessage = () => {
        state.merge({ errorMessage: undefined })
    }

    const handleMediaUpload = (e: any, purpose: UserMediaPurpose) => {
        state.set((prevState) => {
            prevState.errorMessage = undefined
            return prevState
        })
        document.body.style.cursor = "wait"
        let { files } = e.target
        let images: any = [],
            fileReaders: any = []
        let isCancel = false
        if (files.length) {
            files.forEach((file: any) => {
                const fileReader = new FileReader()
                fileReaders.push(fileReader)
                fileReader.onload = async (e: any) => {
                    const { result } = e.target
                    if (result) {
                        images.push(result)
                        getPresignedUrlWithHeaders(purpose, loggedInUser?.profileId || "").then(
                            async (response: PresignedURLServiceSuccessResponse | BackendServiceError) => {
                                if ("endpointUrl" in response) {
                                    const fields = response.fields
                                    const uploadUrl = response.endpointUrl
                                    let formData = new FormData()
                                    for (const key in fields) {
                                        if (fields.hasOwnProperty(key)) {
                                            formData.append(key, fields[key])
                                        }
                                    }
                                    formData.append("Content-Type", file.type)
                                    formData.append("file", file)
                                    let userMediaList: UserMediaItem[] = []
                                    try {
                                        const uploadResult = await uploadUserMedia(formData, uploadUrl)
                                        if ((uploadResult as unknown as UserMediaUploadServiceSuccessResponse).ok) {
                                            setTimeout(async () => {
                                                ;(userMediaList = (await getUserMedia(
                                                    loggedInUser?.profileId || ""
                                                )) as UserMediaItem[]).filter((item: UserMediaItem) => item.purpose === purpose)

                                                if (images.length === files.length && !isCancel) {
                                                    // store logo
                                                    if (purpose === UserMediaPurpose.LOGO) {
                                                        state.set((prevState) => {
                                                            prevState.logoGalleryItems = [
                                                                {
                                                                    id: userMediaList[0].id,
                                                                    url: response.userMediaUrl,
                                                                    purpose: purpose
                                                                },
                                                                ...prevState.logoGalleryItems
                                                            ]
                                                            prevState.customUploadedLogos = [
                                                                {
                                                                    id: userMediaList[0].id,
                                                                    url: response.userMediaUrl,
                                                                    purpose: purpose
                                                                },
                                                                ...prevState.customUploadedLogos
                                                            ]
                                                            return prevState
                                                        })
                                                    }
                                                    if (purpose === UserMediaPurpose.BACKGROUND) {
                                                        // store
                                                        state.set((prevState) => {
                                                            prevState.backgroundGalleryItems = [
                                                                {
                                                                    id: userMediaList[0].id,
                                                                    url: response.userMediaUrl,
                                                                    purpose: purpose
                                                                },
                                                                ...prevState.backgroundGalleryItems
                                                            ]
                                                            prevState.customUploadedBackgrounds = [
                                                                {
                                                                    id: userMediaList[0].id,
                                                                    url: response.userMediaUrl,
                                                                    purpose: purpose
                                                                },
                                                                ...prevState.customUploadedBackgrounds
                                                            ]
                                                            return prevState
                                                        })
                                                    }
                                                }
                                                document.body.style.cursor = "default"
                                            }, 3000) // because we wait for the backend to process upload
                                        } else {
                                            document.body.style.cursor = "default"
                                            state.set((prevState) => {
                                                prevState.errorMessage = imageToLargeMessage

                                                return prevState
                                            })
                                        }
                                    } catch (error: any) {
                                        document.body.style.cursor = "default"
                                        state.set((prevState) => {
                                            prevState.errorMessage = somethingWentWrongMessage

                                            return prevState
                                        })
                                    }
                                } else {
                                    document.body.style.cursor = "default"
                                    state.set((prevState) => {
                                        prevState.errorMessage = imageUploadLimitMessage

                                        return prevState
                                    })
                                }
                            }
                        )
                    }
                }
                fileReader.readAsDataURL(file)
            })
        }
        return () => {
            isCancel = true
            fileReaders.forEach((fileReader: any) => {
                if (fileReader.readyState === 1) {
                    fileReader.abort()
                }
            })
        }
    }

    async function initVideoWithEffects(deviceID: string, backGroundUrl?: string) {
        const localStorageItems = localStorage.getItem(VideoInputsStorageKey)
        if (!localStorageItems) return
        const mediaDevices = JSON.parse(localStorageItems) as MediaDeviceInfo[]
        const mediaDevice = mediaDevices.find((device) => device.deviceId === deviceID)
        meetingManager.meetingSession &&
            mediaDevice &&
            (await addEffectsToMeeting(mediaDevice, meetingManager.meetingSession, logger, logoProcessor).then((result) => {
                videoTransformDevice = result
            }))
    }

    const setVideoInput = async (backGroundUrl?: string) => {
        const deviceId = localStorage.getItem(VideoInputStorageKey)
        if (!deviceId) {
            await Promise.all([meetingManager.stopVideoInputDevice(), videoTransformDevice?.stop()])
            videoTransformDevice = undefined
        } else {
            await initVideoWithEffects(deviceId, backGroundUrl)
        }
    }

    return {
        toggleVideo: async () => {
            document.body.style.cursor = "progress" // because the camera takes sometimes a lot of time to turn on
            await toggleVideo()
            if (!isVideoEnabled) {
                setVideoInput().then(() => {
                    meetingManager.audioVideo?.startLocalVideoTile()
                })
            }
            document.body.style.cursor = "default"
        },
        updateVideoInput: async () => {
            await setVideoInput().then(() => {
                lastVideoElement && meetingManager.audioVideo?.startVideoPreviewForVideoInput(lastVideoElement)
            })
        },
        startVideoPreview: (videoElement: HTMLVideoElement) => {
            setVideoInput().then(() => {
                meetingManager.audioVideo?.startVideoPreviewForVideoInput(videoElement)
                lastVideoElement = videoElement
            })
        },
        setSelectedBackground: async (background: UserMediaItem) => {
            if (state.value.isLoading || selectedDevice === undefined || !isBackgroundReplacementSupported || !background.url)
                return
            try {
                state.merge({ isLoading: true })
                document.body.style.cursor = "wait"
                enableReplacement(logger).then(() => {
                    setReplacementImage(background.url, logger)
                })
            } catch (e: any) {
                console.error("Error trying to apply Background image", e)
            } finally {
                const localStoragePreMeetingSettings = JSON.parse(localStorage.getItem(preMeetingSettingsKey) || "{}")
                let localStoragePreMeetingSettingsCopy: PreMeetingSettingsObjectType | null =
                    structuredClone(localStoragePreMeetingSettings)
                if (localStoragePreMeetingSettingsCopy && background) {
                    localStoragePreMeetingSettingsCopy.background = background
                    localStoragePreMeetingSettingsCopy.blurState = false
                }

                localStorage.setItem(preMeetingSettingsKey, JSON.stringify(localStoragePreMeetingSettingsCopy))
                state.merge({ isLoading: false, selectedBackgroundUrl: background.url, isBlurActive: false })
                document.body.style.cursor = "default"
            }
        },
        getBackgroundGalleryItems: () => {
            return state.value.backgroundGalleryItems
        },
        getSelectedBackground: () => {
            return state.value.selectedBackgroundUrl
        },
        getCustomUploadedBackgrounds: () => {
            return state.value.customUploadedBackgrounds
        },
        uploadBackground: (e: any) => {
            handleMediaUpload(e, UserMediaPurpose.BACKGROUND)
        },
        removeBackground: async () => {
            if (state.value.isLoading) return
            try {
                state.merge({ isLoading: true })
                pauseEffects(logger)
            } catch (e: any) {
                console.error("Error while trying removing background ", e)
            } finally {
                state.merge({ selectedBackgroundUrl: undefined, isBlurActive: false, isLoading: false })
                const localStoragePreMeetingSettings = JSON.parse(localStorage.getItem(preMeetingSettingsKey) || "{}")
                let localStoragePreMeetingSettingsCopy: PreMeetingSettingsObjectType | null =
                    structuredClone(localStoragePreMeetingSettings)
                if (localStoragePreMeetingSettingsCopy) {
                    localStoragePreMeetingSettingsCopy.background = null
                    localStoragePreMeetingSettingsCopy.blurState = false
                }

                localStorage.setItem(preMeetingSettingsKey, JSON.stringify(localStoragePreMeetingSettingsCopy))
            }
        },
        toggleBlur: async () => {
            if (state.value.isLoading || selectedDevice === undefined || !isBackgroundBlurSupported) return
            try {
                state.merge({ isLoading: true })
                document.body.style.cursor = "wait"
                if (!state.value.isBlurActive) {
                    enableBlur(logger)
                } else {
                    pauseEffects(logger)
                }
            } catch (e: any) {
                console.error("Error while trying to apply blur ", e)
            } finally {
                const localStoragePreMeetingSettings = JSON.parse(localStorage.getItem(preMeetingSettingsKey) || "{}")
                let localStoragePreMeetingSettingsCopy: PreMeetingSettingsObjectType | null =
                    structuredClone(localStoragePreMeetingSettings)
                if (localStoragePreMeetingSettingsCopy) {
                    localStoragePreMeetingSettingsCopy.blurState = !state.value.isBlurActive
                    localStoragePreMeetingSettingsCopy.background = null
                }

                localStorage.setItem(preMeetingSettingsKey, JSON.stringify(localStoragePreMeetingSettingsCopy))

                state.merge({ isBlurActive: !state.value.isBlurActive, isLoading: false, selectedBackgroundUrl: undefined })
                document.body.style.cursor = "default"
            }
        },
        deleteCustomBackground: async (userMediaUrl: string) => {
            if (state.value.isLoading || !selectedDevice) return
            document.body.style.cursor = "wait"
            const userMediaIdToDelete = state.value.backgroundGalleryItems.filter((galleryItem: UserMediaItem) => {
                return galleryItem.url === userMediaUrl
            })[0].id
            try {
                const response = await deleteUserMedia(userMediaIdToDelete)
                if ("ok" in response) {
                    state.merge({ selectedLogoUrl: undefined })
                    state.set((prevState) => {
                        prevState.backgroundGalleryItems = prevState.backgroundGalleryItems.filter(
                            (galleryItem: UserMediaItem) => {
                                return galleryItem.url !== userMediaUrl
                            }
                        )
                        return prevState
                    })
                    if (userMediaUrl === state.value.selectedBackgroundUrl) {
                        pauseEffects(logger)
                        const localStoragePreMeetingSettings = JSON.parse(localStorage.getItem(preMeetingSettingsKey) || "{}")
                        let localStoragePreMeetingSettingsCopy: PreMeetingSettingsObjectType | null =
                            structuredClone(localStoragePreMeetingSettings)
                        if (localStoragePreMeetingSettingsCopy) {
                            localStoragePreMeetingSettingsCopy.background = null
                        }
                        localStorage.setItem(preMeetingSettingsKey, JSON.stringify(localStoragePreMeetingSettingsCopy))
                    }
                } else {
                    state.merge({ errorMessage: somethingWentWrongMessage })
                }
            } catch (error: any) {
                state.merge({ errorMessage: somethingWentWrongMessage })
            }
            document.body.style.cursor = "default"
            state.merge({ isLoading: false })
        },
        getIsBlurActive: () => {
            return state.value.isBlurActive
        },
        setSelectedLogo: (logo: UserMediaItem) => {
            if (state.value.isLoading || selectedDevice === undefined || !isBackgroundReplacementSupported || !logo.url) return
            try {
                state.merge({ isLoading: true })
                document.body.style.cursor = "wait"
                logoProcessor.setPosition(defaultPosition)
                logoProcessor.setSrc(logo.url)
            } catch (e: any) {
                console.error("Error while trying to apply logo image ", e)
            } finally {
                document.body.style.cursor = "default"
                state.merge({ isLoading: false, selectedLogoUrl: logo.url })
                const localStoragePreMeetingSettings = JSON.parse(localStorage.getItem(preMeetingSettingsKey) || "{}")
                let localStoragePreMeetingSettingsCopy: PreMeetingSettingsObjectType | null =
                    structuredClone(localStoragePreMeetingSettings)
                if (localStoragePreMeetingSettingsCopy && logo) {
                    localStoragePreMeetingSettingsCopy.logo = logo
                }
                localStorage.setItem(preMeetingSettingsKey, JSON.stringify(localStoragePreMeetingSettingsCopy))
            }
        },
        uploadLogo: async (e: any) => {
            handleMediaUpload(e, UserMediaPurpose.LOGO)
        },
        getLogoGalleryItems: () => {
            return state.value.logoGalleryItems
        },
        getCustomUploadedLogos: () => {
            return state.value.customUploadedLogos
        },
        getSelectedLogo: () => {
            return state.value.selectedLogoUrl
        },
        removeLogo: () => {
            logoProcessor.setPosition(defaultPosition)
            logoProcessor.setSrc(null)

            const localStoragePreMeetingSettings = JSON.parse(localStorage.getItem(preMeetingSettingsKey) || "{}")
            let localStoragePreMeetingSettingsCopy: PreMeetingSettingsObjectType | null =
                structuredClone(localStoragePreMeetingSettings)
            if (localStoragePreMeetingSettingsCopy) {
                localStoragePreMeetingSettingsCopy.logo = null
            }

            localStorage.setItem(preMeetingSettingsKey, JSON.stringify(localStoragePreMeetingSettingsCopy))
            state.merge({ selectedLogoUrl: undefined })
        },
        setLogoPosition: (pos: LogoPositions) => {
            if (state.value.isLoading || selectedDevice === undefined || !isBackgroundReplacementSupported) return
            logoProcessor.setPosition(pos)
            const localStoragePreMeetingSettings = JSON.parse(localStorage.getItem(preMeetingSettingsKey) || "{}")
            let localStoragePreMeetingSettingsCopy: PreMeetingSettingsObjectType | null =
                structuredClone(localStoragePreMeetingSettings)
            if (localStoragePreMeetingSettingsCopy) localStoragePreMeetingSettingsCopy.logoPosition = pos
            localStorage.setItem(preMeetingSettingsKey, JSON.stringify(localStoragePreMeetingSettingsCopy))
        },
        getLogoPosition: () => {
            return state.value.selectedLogoPosition
        },
        getIsLogoProcessorActive: () => {
            return state.value.isLogoProcessorActive
        },
        deleteCustomUploadedLogo: async (userMediaUrl: string) => {
            if (state.value.isLoading || !selectedDevice) return
            document.body.style.cursor = "wait"

            const userMediaIdToDelete = state.value.logoGalleryItems.filter((galleryItem: UserMediaItem) => {
                return galleryItem.url === userMediaUrl
            })[0].id
            try {
                const response = await deleteUserMedia(userMediaIdToDelete)
                if ("ok" in response) {
                    if (userMediaUrl === state.value.selectedLogoUrl) {
                        state.merge({ isLoading: true })
                        logoProcessor.setPosition(defaultPosition)
                        logoProcessor.setSrc(null)
                    }
                    state.merge({ isLoading: false, selectedLogoUrl: undefined })
                    state.set((prevState) => {
                        prevState.logoGalleryItems = prevState.logoGalleryItems.filter((galleryItem: UserMediaItem) => {
                            return galleryItem.url !== userMediaUrl
                        })
                        return prevState
                    })
                    const localStoragePreMeetingSettings = JSON.parse(localStorage.getItem(preMeetingSettingsKey) || "{}")
                    let localStoragePreMeetingSettingsCopy: PreMeetingSettingsObjectType | null =
                        structuredClone(localStoragePreMeetingSettings)
                    if (localStoragePreMeetingSettingsCopy) {
                        localStoragePreMeetingSettingsCopy.logo = null
                    }

                    localStorage.setItem(preMeetingSettingsKey, JSON.stringify(localStoragePreMeetingSettingsCopy))
                } else {
                    state.merge({ errorMessage: somethingWentWrongMessage })
                }
            } catch (error: any) {
                state.merge({ errorMessage: imageToLargeMessage })
            }

            document.body.style.cursor = "default"
        },
        stopVideoPreview: async (videoElement: HTMLVideoElement) => {
            meetingManager.audioVideo?.stopVideoPreviewForVideoInput(videoElement)
        },
        getErrorMessage: () => {
            return state.value.errorMessage
        },
        resetErrorMessage: resetErrorMessage,
        isVideoEnabled: () => {
            return isVideoEnabled
        },
        toggleContentShare: () => {
            toggleContentShare()
        },
        isContentShareEnabled: () => {
            return isLocalUserSharing
        }
    }
}

export const useVideoContext = (): VideoContext => useStateWrapper(useState(state))
