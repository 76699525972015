import { VideoFxConfig, DefaultVideoTransformDevice, Logger, VideoFxProcessor, MeetingSession } from "amazon-chime-sdk-js"
import { LogoProcessor } from "./LogoProcessor"

let videoTransformDevice: DefaultVideoTransformDevice | undefined = undefined
let videoFxProcessor: VideoFxProcessor | undefined = undefined

const videoFxConfig: VideoFxConfig = {
    backgroundBlur: {
        isEnabled: false,
        strength: "high"
    },
    backgroundReplacement: {
        isEnabled: false,
        backgroundImageURL: "space.jpg",
        defaultColor: undefined
    }
}

export const addEffectsToMeeting = async (
    videoInputDevice: MediaDeviceInfo,
    meetingSession: MeetingSession,
    logger: Logger,
    logoProcessor: LogoProcessor
): Promise<DefaultVideoTransformDevice | undefined> => {
    if (videoTransformDevice) {
        const innerDevice: any = videoTransformDevice.getInnerDevice()
        const deviceId = innerDevice.deviceId
        if (videoInputDevice.deviceId !== deviceId) {
            videoTransformDevice = videoTransformDevice.chooseNewInnerDevice(videoInputDevice.deviceId)
        }
        await meetingSession.audioVideo.startVideoInput(videoTransformDevice)
        return videoTransformDevice
    }
    try {
        videoFxProcessor = await VideoFxProcessor.create(logger, videoFxConfig)
    } catch (error: any) {
        logger.error(error.toString())
        return undefined
    }

    try {
        videoTransformDevice = new DefaultVideoTransformDevice(logger, videoInputDevice, [videoFxProcessor, logoProcessor])

        await meetingSession.audioVideo.startVideoInput(videoTransformDevice)
        return videoTransformDevice
    } catch (error: any) {
        logger.error(error.toString())
        return undefined
    }
}

export const enableReplacement = async (logger: Logger) => {
    videoFxConfig.backgroundBlur.isEnabled = false
    videoFxConfig.backgroundReplacement.isEnabled = true
    await updateVideoFxConfig(videoFxConfig, logger)
}

export const enableBlur = async (logger: Logger) => {
    videoFxConfig.backgroundReplacement.isEnabled = false
    videoFxConfig.backgroundBlur.isEnabled = true
    await updateVideoFxConfig(videoFxConfig, logger)
}

export const pauseEffects = async (logger: Logger) => {
    videoFxConfig.backgroundReplacement.isEnabled = false
    videoFxConfig.backgroundBlur.isEnabled = false
    await updateVideoFxConfig(videoFxConfig, logger)
}

export const setReplacementImage = async (newImageUrl: string, logger: Logger) => {
    videoFxConfig.backgroundReplacement.backgroundImageURL = newImageUrl
    videoFxConfig.backgroundReplacement.defaultColor = undefined
    await updateVideoFxConfig(videoFxConfig, logger)
}

// export const setReplacementDefaultColor = async (newHexColor: string, logger: Logger) => {
//     videoFxConfig.backgroundReplacement.defaultColor = newHexColor
//     videoFxConfig.backgroundReplacement.backgroundImageURL = undefined
//     await updateVideoFxConfig(videoFxConfig, logger)
// }

export const updateVideoFxConfig = async (config: VideoFxConfig, logger: Logger) => {
    try {
        videoFxProcessor && (await videoFxProcessor.setEffectConfig(videoFxConfig))
    } catch (error: any) {
        logger.error(error.toString())
    }
}

// export const turnOffEffects = async (meetingSession: MeetingSession) => {
//     const innerDevice = await videoTransformDevice?.intrinsicDevice()
//     await videoTransformDevice?.stop()
//     videoTransformDevice = undefined
//     videoFxProcessor = undefined
//     innerDevice && (await meetingSession.audioVideo.startVideoInput(innerDevice))
// }
