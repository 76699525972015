/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelCalendarEntryConditionInput = {
  and?: Array< ModelCalendarEntryConditionInput | null > | null,
  description?: ModelStringInput | null,
  end?: ModelStringInput | null,
  isVirtual?: ModelBooleanInput | null,
  locationActionParam?: ModelStringInput | null,
  locationActionType?: ModelStringInput | null,
  locationName?: ModelStringInput | null,
  not?: ModelCalendarEntryConditionInput | null,
  or?: Array< ModelCalendarEntryConditionInput | null > | null,
  organizationId?: ModelStringInput | null,
  organizationIdStatus?: ModelStringInput | null,
  start?: ModelStringInput | null,
  status?: ModelCalendarEntryParticipationStatusInput | null,
  title?: ModelStringInput | null,
  topicName?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  userPoolName?: ModelStringInput | null,
};

export type ModelStringInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  _null = "_null",
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
}


export type ModelSizeInput = {
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  le?: number | null,
  lt?: number | null,
  ne?: number | null,
};

export type ModelBooleanInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  eq?: boolean | null,
  ne?: boolean | null,
};

export type ModelCalendarEntryParticipationStatusInput = {
  eq?: CalendarEntryParticipationStatus | null,
  ne?: CalendarEntryParticipationStatus | null,
};

export enum CalendarEntryParticipationStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  REQUESTED = "REQUESTED",
}


export type ModelIDInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  in?: Array< string | null > | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  size?: ModelSizeInput | null,
};

export type CreateCalendarEntryInput = {
  description?: string | null,
  end: string,
  id?: string | null,
  isVirtual?: boolean | null,
  locationActionParam?: string | null,
  locationActionType?: string | null,
  locationName?: string | null,
  organizationId?: string | null,
  start: string,
  status?: CalendarEntryParticipationStatus | null,
  title: string,
  topicName: string,
  userId: string,
  userPoolName: string,
};

export type CalendarEntry = {
  __typename: "CalendarEntry",
  createdAt: string,
  description?: string | null,
  end: string,
  id: string,
  isVirtual?: boolean | null,
  locationActionParam?: string | null,
  locationActionType?: string | null,
  locationName?: string | null,
  organizationId?: string | null,
  organizationIdStatus?: string | null,
  participants?: ModelCalendarEntryParticipationConnection | null,
  start: string,
  status?: CalendarEntryParticipationStatus | null,
  title: string,
  topicName: string,
  updatedAt: string,
  user: User,
  userId: string,
  userPoolName: string,
};

export type ModelCalendarEntryParticipationConnection = {
  __typename: "ModelCalendarEntryParticipationConnection",
  items?:  Array<CalendarEntryParticipation | null > | null,
  nextToken?: string | null,
};

export type CalendarEntryParticipation = {
  __typename: "CalendarEntryParticipation",
  calendarEntry?: CalendarEntry | null,
  calendarEntryId: string,
  createdAt: string,
  id: string,
  start: string,
  status: CalendarEntryParticipationStatus,
  statusUpdateMessage?: string | null,
  updatedAt: string,
  user: User,
  userId: string,
  userIdStatus: string,
};

export type User = {
  __typename: "User",
  conversations?: ModelUserConversationConnection | null,
  createdAt: string,
  id: string,
  incomingMeetings?: ModelMeetingParticipantConnection | null,
  lastConnected: string,
  name?: string | null,
  outgoingMeetings?: ModelMeetingParticipantConnection | null,
  pictureUrl?: string | null,
  presenceStatus: string,
  updatedAt: string,
};

export type ModelUserConversationConnection = {
  __typename: "ModelUserConversationConnection",
  items?:  Array<UserConversation | null > | null,
  nextToken?: string | null,
};

export type UserConversation = {
  __typename: "UserConversation",
  conversation: Conversation,
  conversationId: string,
  createdAt: string,
  id: string,
  isChatOpen?: boolean | null,
  isMuted?: boolean | null,
  lastReadMessageCreatedAt?: string | null,
  mostRecentMessageCreatedAt?: string | null,
  updatedAt: string,
  user: User,
  userId: string,
};

export type Conversation = {
  __typename: "Conversation",
  createdAt: string,
  description?: string | null,
  id: string,
  memberIdsHash?: string | null,
  members?: ModelUserConversationConnection | null,
  messages?: ModelMessageConnection | null,
  mostRecentMessage?: string | null,
  mostRecentMessageCreatedAt?: string | null,
  name?: string | null,
  type?: ConversationType | null,
  updatedAt?: string | null,
  userId?: string | null,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items?:  Array<Message | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Message = {
  __typename: "Message",
  _deleted?: boolean | null,
  _lastChangedAt?: number | null,
  _version?: number | null,
  author?: User | null,
  authorId: string,
  content: string,
  conversation?: Conversation | null,
  conversationId: string,
  createdAt: string,
  id: string,
  isSent?: boolean | null,
  members?: Array< string | null > | null,
  sotName?: string | null,
  updatedAt: string,
};

export enum ConversationType {
  CALENDARENTRY = "CALENDARENTRY",
  CALL = "CALL",
  GROUP = "GROUP",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}


export type ModelMeetingParticipantConnection = {
  __typename: "ModelMeetingParticipantConnection",
  items:  Array<MeetingParticipant | null >,
  nextToken?: string | null,
};

export type MeetingParticipant = {
  __typename: "MeetingParticipant",
  created: string,
  createdAt: string,
  id: string,
  invitationType?: InvitationType | null,
  invitee: User,
  inviteeId: string,
  inviter: User,
  inviterId: string,
  meeting: Meeting,
  meetingId: string,
  meetingTitle?: string | null,
  status: InviteStatus,
  updatedAt: string,
};

export enum InvitationType {
  AUDIO = "AUDIO",
  NOTIFY = "NOTIFY",
  VIDEO = "VIDEO",
}


export type Meeting = {
  __typename: "Meeting",
  createdAt: string,
  end?: string | null,
  id: string,
  participants?: ModelMeetingParticipantConnection | null,
  start: string,
  updatedAt: string,
};

export enum InviteStatus {
  ACCEPTED = "ACCEPTED",
  CANCELED = "CANCELED",
  DECLINED = "DECLINED",
  INVITING = "INVITING",
  TIMEOUT = "TIMEOUT",
}


export type ModelCalendarEntryParticipationConditionInput = {
  and?: Array< ModelCalendarEntryParticipationConditionInput | null > | null,
  calendarEntryId?: ModelIDInput | null,
  not?: ModelCalendarEntryParticipationConditionInput | null,
  or?: Array< ModelCalendarEntryParticipationConditionInput | null > | null,
  start?: ModelStringInput | null,
  status?: ModelCalendarEntryParticipationStatusInput | null,
  userId?: ModelIDInput | null,
  userIdStatus?: ModelStringInput | null,
};

export type CreateCalendarEntryParticipationInput = {
  calendarEntryId: string,
  id?: string | null,
  start: string,
  status: CalendarEntryParticipationStatus,
  userId: string,
};

export type CreateCalendarEntryWithParticipantsInput = {
  description?: string | null,
  end: string,
  id?: string | null,
  isVirtual?: boolean | null,
  locationActionParam?: string | null,
  locationActionType?: string | null,
  locationName?: string | null,
  organizationId?: string | null,
  otherParticipantIds?: Array< string | null > | null,
  start: string,
  status?: CalendarEntryParticipationStatus | null,
  title: string,
  topicName: string,
  userId: string,
  userPoolName: string,
};

export type ModelConversationConditionInput = {
  and?: Array< ModelConversationConditionInput | null > | null,
  description?: ModelStringInput | null,
  memberIdsHash?: ModelStringInput | null,
  mostRecentMessage?: ModelStringInput | null,
  mostRecentMessageCreatedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  not?: ModelConversationConditionInput | null,
  or?: Array< ModelConversationConditionInput | null > | null,
  type?: ModelConversationTypeInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
};

export type ModelConversationTypeInput = {
  eq?: ConversationType | null,
  ne?: ConversationType | null,
};

export type CreateConversationInput = {
  description?: string | null,
  id?: string | null,
  memberIdsHash?: string | null,
  mostRecentMessage?: string | null,
  mostRecentMessageCreatedAt?: string | null,
  name?: string | null,
  type?: ConversationType | null,
  updatedAt?: string | null,
  userId?: string | null,
};

export type ModelMeetingConditionInput = {
  and?: Array< ModelMeetingConditionInput | null > | null,
  end?: ModelStringInput | null,
  not?: ModelMeetingConditionInput | null,
  or?: Array< ModelMeetingConditionInput | null > | null,
  start?: ModelStringInput | null,
};

export type CreateMeetingInput = {
  end?: string | null,
  id?: string | null,
  start: string,
};

export type ModelMeetingParticipantConditionInput = {
  and?: Array< ModelMeetingParticipantConditionInput | null > | null,
  created?: ModelStringInput | null,
  invitationType?: ModelInvitationTypeInput | null,
  inviteeId?: ModelIDInput | null,
  inviterId?: ModelIDInput | null,
  meetingId?: ModelIDInput | null,
  meetingTitle?: ModelStringInput | null,
  not?: ModelMeetingParticipantConditionInput | null,
  or?: Array< ModelMeetingParticipantConditionInput | null > | null,
  status?: ModelInviteStatusInput | null,
};

export type ModelInvitationTypeInput = {
  eq?: InvitationType | null,
  ne?: InvitationType | null,
};

export type ModelInviteStatusInput = {
  eq?: InviteStatus | null,
  ne?: InviteStatus | null,
};

export type CreateMeetingParticipantInput = {
  created: string,
  id?: string | null,
  invitationType?: InvitationType | null,
  inviteeId: string,
  inviterId: string,
  meetingId: string,
  meetingTitle?: string | null,
  status: InviteStatus,
};

export type ModelMeetingRoomAttendanceConditionInput = {
  and?: Array< ModelMeetingRoomAttendanceConditionInput | null > | null,
  chimeMeetingId?: ModelStringInput | null,
  not?: ModelMeetingRoomAttendanceConditionInput | null,
  or?: Array< ModelMeetingRoomAttendanceConditionInput | null > | null,
};

export type CreateMeetingRoomAttendanceInput = {
  chimeMeetingId?: string | null,
  id?: string | null,
};

export type MeetingRoomAttendance = {
  __typename: "MeetingRoomAttendance",
  chimeMeetingId?: string | null,
  createdAt: string,
  id?: string | null,
  updatedAt: string,
};

export type ModelMessageConditionInput = {
  _deleted?: ModelBooleanInput | null,
  _lastChangedAt?: ModelStringInput | null,
  _version?: ModelSizeInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  authorId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  conversationId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  isSent?: ModelBooleanInput | null,
  members?: ModelStringInput | null,
  not?: ModelMessageConditionInput | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  sotName?: ModelStringInput | null,
};

export type CreateMessageInput = {
  _deleted?: boolean | null,
  _lastChangedAt?: number | null,
  _version?: number | null,
  authorId: string,
  content: string,
  conversationId: string,
  createdAt?: string | null,
  id?: string | null,
  isSent?: boolean | null,
  members?: Array< string | null > | null,
  sotName?: string | null,
};

export type ModelNotificationConditionInput = {
  actionParam?: ModelStringInput | null,
  actionType?: ModelStringInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  displayGroup?: ModelNotificationDisplayGroupInput | null,
  not?: ModelNotificationConditionInput | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  read?: ModelBooleanInput | null,
  type?: ModelNotificationTypeInput | null,
  userIdDisplayGroup?: ModelStringInput | null,
};

export type ModelNotificationDisplayGroupInput = {
  eq?: NotificationDisplayGroup | null,
  ne?: NotificationDisplayGroup | null,
};

export enum NotificationDisplayGroup {
  CALL = "CALL",
  MEETING = "MEETING",
  NETWORKING = "NETWORKING",
  SYSTEM = "SYSTEM",
}


export type ModelNotificationTypeInput = {
  eq?: NotificationType | null,
  ne?: NotificationType | null,
};

export enum NotificationType {
  BACKOFFICE = "BACKOFFICE",
  CALENDARENTRY = "CALENDARENTRY",
  LOCAL = "LOCAL",
  MESSAGE = "MESSAGE",
  NETWORKING = "NETWORKING",
  PLAINTEXT = "PLAINTEXT",
}


export type CreateNotificationInput = {
  actionParam?: string | null,
  actionType?: string | null,
  content: string,
  createdAt?: string | null,
  displayGroup?: NotificationDisplayGroup | null,
  id?: string | null,
  read?: boolean | null,
  type: NotificationType,
  userId: string,
  userIdDisplayGroup?: string | null,
};

export type Notification = {
  __typename: "Notification",
  actionParam?: string | null,
  actionType?: string | null,
  content: string,
  createdAt: string,
  displayGroup?: NotificationDisplayGroup | null,
  id: string,
  read?: boolean | null,
  type: NotificationType,
  updatedAt: string,
  userId: string,
  userIdDisplayGroup?: string | null,
};

export type ModelRaisedHandConditionInput = {
  and?: Array< ModelRaisedHandConditionInput | null > | null,
  chimeMeetingId?: ModelStringInput | null,
  not?: ModelRaisedHandConditionInput | null,
  or?: Array< ModelRaisedHandConditionInput | null > | null,
};

export type CreateRaisedHandInput = {
  chimeMeetingId?: string | null,
  id?: string | null,
};

export type RaisedHand = {
  __typename: "RaisedHand",
  chimeMeetingId?: string | null,
  createdAt: string,
  id?: string | null,
  updatedAt: string,
};

export type ModelUnreadCounterConditionInput = {
  and?: Array< ModelUnreadCounterConditionInput | null > | null,
  contacts?: ModelIntInput | null,
  conversations?: ModelIntInput | null,
  not?: ModelUnreadCounterConditionInput | null,
  notifications?: ModelIntInput | null,
  or?: Array< ModelUnreadCounterConditionInput | null > | null,
  requests?: ModelIntInput | null,
  schedules?: ModelIntInput | null,
};

export type ModelIntInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  le?: number | null,
  lt?: number | null,
  ne?: number | null,
};

export type CreateUnreadCounterInput = {
  contacts?: number | null,
  conversations?: number | null,
  id?: string | null,
  notifications?: number | null,
  requests?: number | null,
  schedules?: number | null,
};

export type UnreadCounter = {
  __typename: "UnreadCounter",
  contacts?: number | null,
  conversations?: number | null,
  createdAt: string,
  id: string,
  notifications?: number | null,
  requests?: number | null,
  schedules?: number | null,
  updatedAt: string,
};

export type ModelUserConditionInput = {
  and?: Array< ModelUserConditionInput | null > | null,
  lastConnected?: ModelStringInput | null,
  name?: ModelStringInput | null,
  not?: ModelUserConditionInput | null,
  or?: Array< ModelUserConditionInput | null > | null,
  pictureUrl?: ModelStringInput | null,
  presenceStatus?: ModelStringInput | null,
};

export type CreateUserInput = {
  id?: string | null,
  lastConnected: string,
  name?: string | null,
  pictureUrl?: string | null,
  presenceStatus: string,
};

export type ModelUserActionConditionInput = {
  actionType?: ModelUserActionTypeInput | null,
  and?: Array< ModelUserActionConditionInput | null > | null,
  countryCode?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  endTime?: ModelStringInput | null,
  not?: ModelUserActionConditionInput | null,
  or?: Array< ModelUserActionConditionInput | null > | null,
  param?: ModelStringInput | null,
  sotName?: ModelStringInput | null,
  source?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  topic?: ModelStringInput | null,
  userType?: ModelStringInput | null,
};

export type ModelUserActionTypeInput = {
  eq?: UserActionType | null,
  ne?: UserActionType | null,
};

export enum UserActionType {
  CALL = "CALL",
  CHAT = "CHAT",
  NAVIGATION = "NAVIGATION",
  STREAM = "STREAM",
  VODWATCHTIME = "VODWATCHTIME",
}


export type CreateUserActionInput = {
  actionType: UserActionType,
  countryCode?: string | null,
  duration?: number | null,
  endTime?: string | null,
  id?: string | null,
  param: string,
  sotName: string,
  source?: string | null,
  startTime: string,
  topic: string,
  userId: string,
  userType?: string | null,
};

export type UserAction = {
  __typename: "UserAction",
  actionType: UserActionType,
  countryCode?: string | null,
  createdAt: string,
  duration?: number | null,
  endTime?: string | null,
  id: string,
  param: string,
  sotName: string,
  source?: string | null,
  startTime: string,
  topic: string,
  updatedAt: string,
  user: User,
  userId: string,
  userType?: string | null,
};

export type ModelUserConversationConditionInput = {
  and?: Array< ModelUserConversationConditionInput | null > | null,
  conversationId?: ModelIDInput | null,
  isChatOpen?: ModelBooleanInput | null,
  isMuted?: ModelBooleanInput | null,
  lastReadMessageCreatedAt?: ModelStringInput | null,
  mostRecentMessageCreatedAt?: ModelStringInput | null,
  not?: ModelUserConversationConditionInput | null,
  or?: Array< ModelUserConversationConditionInput | null > | null,
  userId?: ModelIDInput | null,
};

export type CreateUserConversationInput = {
  conversationId: string,
  id?: string | null,
  isChatOpen?: boolean | null,
  isMuted?: boolean | null,
  lastReadMessageCreatedAt?: string | null,
  mostRecentMessageCreatedAt?: string | null,
  userId: string,
};

export type ModelUserSessionConditionInput = {
  and?: Array< ModelUserSessionConditionInput | null > | null,
  countryCode?: ModelStringInput | null,
  location?: ModelStringInput | null,
  locationLevel1?: ModelStringInput | null,
  locationLevel2?: ModelStringInput | null,
  locationLevel3?: ModelStringInput | null,
  not?: ModelUserSessionConditionInput | null,
  or?: Array< ModelUserSessionConditionInput | null > | null,
  queryHelper?: ModelStringInput | null,
  sotName?: ModelStringInput | null,
  source?: ModelStringInput | null,
  time?: ModelStringInput | null,
  topic?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  userType?: ModelStringInput | null,
};

export type CreateUserSessionInput = {
  countryCode?: string | null,
  id?: string | null,
  location: string,
  locationLevel1: string,
  locationLevel2?: string | null,
  locationLevel3?: string | null,
  queryHelper: string,
  sotName: string,
  source?: string | null,
  time?: string | null,
  topic: string,
  ttl?: number | null,
  userId: string,
  userType?: string | null,
};

export type UserSession = {
  __typename: "UserSession",
  countryCode?: string | null,
  createdAt: string,
  id: string,
  location: string,
  locationLevel1: string,
  locationLevel2?: string | null,
  locationLevel3?: string | null,
  queryHelper: string,
  sotName: string,
  source?: string | null,
  time?: string | null,
  topic: string,
  ttl?: number | null,
  updatedAt: string,
  user: User,
  userId: string,
  userType?: string | null,
};

export type DeleteCalendarEntryInput = {
  id: string,
};

export type DeleteCalendarEntryParticipationInput = {
  id: string,
};

export type DeleteMeetingRoomAttendanceInput = {
  id?: string | null,
};

export type DeleteMessageInput = {
  _version: number,
  id: string,
};

export type DeleteNotificationInput = {
  id: string,
};

export type DeleteRaisedHandInput = {
  id: string,
};

export type DeleteUnreadCounterInput = {
  id: string,
};

export type DeleteUserInput = {
  id: string,
};

export type DeleteUserActionInput = {
  id: string,
};

export type DeleteUserConversationInput = {
  id: string,
};

export type DeleteUserSessionInput = {
  id: string,
};

export type UpdateCalendarEntryInput = {
  description?: string | null,
  end?: string | null,
  id: string,
  isVirtual?: boolean | null,
  locationActionParam?: string | null,
  locationActionType?: string | null,
  locationName?: string | null,
  start?: string | null,
  status?: CalendarEntryParticipationStatus | null,
  title?: string | null,
  topicName?: string | null,
  userId?: string | null,
  userPoolName?: string | null,
};

export type UpdateCalendarEntryParticipationInput = {
  id: string,
  start?: string | null,
  status?: CalendarEntryParticipationStatus | null,
  statusUpdateMessage?: string | null,
};

export type UpdateCalendarEntryWithParticipantsInput = {
  description?: string | null,
  end?: string | null,
  id: string,
  isVirtual?: boolean | null,
  locationActionParam?: string | null,
  locationActionType?: string | null,
  locationName?: string | null,
  participantIds?: Array< string | null > | null,
  start?: string | null,
  status?: CalendarEntryParticipationStatus | null,
  title?: string | null,
  topicName?: string | null,
  userId?: string | null,
  userPoolName?: string | null,
};

export type UpdateConversationInput = {
  description?: string | null,
  id: string,
  memberIdsHash?: string | null,
  mostRecentMessage?: string | null,
  mostRecentMessageCreatedAt?: string | null,
  name?: string | null,
  type?: ConversationType | null,
  updatedAt?: string | null,
};

export type UpdateMeetingParticipantInput = {
  id: string,
  invitationType?: InvitationType | null,
  meetingTitle?: string | null,
  status?: InviteStatus | null,
};

export type UpdateMeetingRoomAttendanceInput = {
  chimeMeetingId?: string | null,
  id: string,
};

export type UpdateMessageInput = {
  _deleted?: boolean | null,
  _lastChangedAt?: number | null,
  _version?: number | null,
  authorId?: string | null,
  content?: string | null,
  conversationId?: string | null,
  createdAt?: string | null,
  id: string,
  isSent?: boolean | null,
  members?: Array< string | null > | null,
  sotName?: string | null,
};

export type UpdateNotificationInput = {
  actionParam?: string | null,
  actionType?: string | null,
  content?: string | null,
  createdAt?: string | null,
  displayGroup?: NotificationDisplayGroup | null,
  id: string,
  read?: boolean | null,
  type?: NotificationType | null,
  userId?: string | null,
  userIdDisplayGroup?: string | null,
};

export type UpdateRaisedHandInput = {
  chimeMeetingId?: string | null,
  id: string,
};

export type UpdateUnreadCounterInput = {
  contacts?: number | null,
  conversations?: number | null,
  id: string,
  notifications?: number | null,
  requests?: number | null,
  schedules?: number | null,
};

export type UpdateUserInput = {
  id: string,
  lastConnected?: string | null,
  name?: string | null,
  pictureUrl?: string | null,
  presenceStatus?: string | null,
};

export type UpdateUserActionInput = {
  actionType?: UserActionType | null,
  countryCode?: string | null,
  duration?: number | null,
  endTime?: string | null,
  id: string,
  param?: string | null,
  sotName?: string | null,
  source?: string | null,
  startTime?: string | null,
  topic?: string | null,
  userId?: string | null,
  userType?: string | null,
};

export type UpdateUserConversationInput = {
  id: string,
  isChatOpen?: boolean | null,
  isMuted?: boolean | null,
  lastReadMessageCreatedAt?: string | null,
  mostRecentMessageCreatedAt?: string | null,
};

export type UpdateUserSessionInput = {
  countryCode?: string | null,
  id: string,
  location?: string | null,
  locationLevel1?: string | null,
  locationLevel2?: string | null,
  locationLevel3?: string | null,
  queryHelper?: string | null,
  sotName?: string | null,
  source?: string | null,
  time?: string | null,
  topic?: string | null,
  ttl?: number | null,
  userId?: string | null,
  userType?: string | null,
};

export type ModelConversationConnection = {
  __typename: "ModelConversationConnection",
  items?:  Array<Conversation | null > | null,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelCalendarEntryParticipationFilterInput = {
  and?: Array< ModelCalendarEntryParticipationFilterInput | null > | null,
  calendarEntryId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  not?: ModelCalendarEntryParticipationFilterInput | null,
  or?: Array< ModelCalendarEntryParticipationFilterInput | null > | null,
  start?: ModelStringInput | null,
  status?: ModelCalendarEntryParticipationStatusInput | null,
  userId?: ModelIDInput | null,
  userIdStatus?: ModelStringInput | null,
};

export type CurrentLocationCounts = {
  __typename: "CurrentLocationCounts",
  cnt: number,
  createdAt: string,
  id: string,
  updatedAt: string,
};

export type ModelIntKeyConditionInput = {
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  le?: number | null,
  lt?: number | null,
};

export type ModelUserActionFilterInput = {
  actionType?: ModelUserActionTypeInput | null,
  and?: Array< ModelUserActionFilterInput | null > | null,
  countryCode?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  endTime?: ModelStringInput | null,
  id?: ModelIDInput | null,
  not?: ModelUserActionFilterInput | null,
  or?: Array< ModelUserActionFilterInput | null > | null,
  param?: ModelStringInput | null,
  sotName?: ModelStringInput | null,
  source?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  topic?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  userType?: ModelStringInput | null,
};

export type ModelUserActionConnection = {
  __typename: "ModelUserActionConnection",
  items:  Array<UserAction | null >,
  nextToken?: string | null,
};

export type ModelCalendarEntryFilterInput = {
  and?: Array< ModelCalendarEntryFilterInput | null > | null,
  description?: ModelStringInput | null,
  end?: ModelStringInput | null,
  id?: ModelIDInput | null,
  isVirtual?: ModelBooleanInput | null,
  locationActionParam?: ModelStringInput | null,
  locationActionType?: ModelStringInput | null,
  locationName?: ModelStringInput | null,
  not?: ModelCalendarEntryFilterInput | null,
  or?: Array< ModelCalendarEntryFilterInput | null > | null,
  organizationId?: ModelStringInput | null,
  organizationIdStatus?: ModelStringInput | null,
  start?: ModelStringInput | null,
  status?: ModelCalendarEntryParticipationStatusInput | null,
  title?: ModelStringInput | null,
  topicName?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  userPoolName?: ModelStringInput | null,
};

export type ModelCalendarEntryConnection = {
  __typename: "ModelCalendarEntryConnection",
  items?:  Array<CalendarEntry | null > | null,
  nextToken?: string | null,
};

export type ModelConversationFilterInput = {
  and?: Array< ModelConversationFilterInput | null > | null,
  description?: ModelStringInput | null,
  id?: ModelIDInput | null,
  memberIdsHash?: ModelStringInput | null,
  mostRecentMessage?: ModelStringInput | null,
  mostRecentMessageCreatedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  not?: ModelConversationFilterInput | null,
  or?: Array< ModelConversationFilterInput | null > | null,
  type?: ModelConversationTypeInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
};

export type ModelCurrentLocationCountsFilterInput = {
  and?: Array< ModelCurrentLocationCountsFilterInput | null > | null,
  cnt?: ModelIntInput | null,
  id?: ModelIDInput | null,
  not?: ModelCurrentLocationCountsFilterInput | null,
  or?: Array< ModelCurrentLocationCountsFilterInput | null > | null,
};

export type ModelCurrentLocationCountsConnection = {
  __typename: "ModelCurrentLocationCountsConnection",
  items:  Array<CurrentLocationCounts | null >,
  nextToken?: string | null,
};

export type ModelMeetingRoomAttendanceFilterInput = {
  and?: Array< ModelMeetingRoomAttendanceFilterInput | null > | null,
  chimeMeetingId?: ModelStringInput | null,
  id?: ModelStringInput | null,
  not?: ModelMeetingRoomAttendanceFilterInput | null,
  or?: Array< ModelMeetingRoomAttendanceFilterInput | null > | null,
};

export type ModelMeetingRoomAttendanceConnection = {
  __typename: "ModelMeetingRoomAttendanceConnection",
  items?:  Array<MeetingRoomAttendance | null > | null,
  nextToken?: string | null,
};

export type ModelMeetingFilterInput = {
  and?: Array< ModelMeetingFilterInput | null > | null,
  end?: ModelStringInput | null,
  id?: ModelIDInput | null,
  not?: ModelMeetingFilterInput | null,
  or?: Array< ModelMeetingFilterInput | null > | null,
  start?: ModelStringInput | null,
};

export type ModelMeetingConnection = {
  __typename: "ModelMeetingConnection",
  items:  Array<Meeting | null >,
  nextToken?: string | null,
};

export type ModelNotificationFilterInput = {
  actionParam?: ModelStringInput | null,
  actionType?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  displayGroup?: ModelNotificationDisplayGroupInput | null,
  id?: ModelIDInput | null,
  not?: ModelNotificationFilterInput | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  read?: ModelBooleanInput | null,
  type?: ModelNotificationTypeInput | null,
  userId?: ModelIDInput | null,
  userIdDisplayGroup?: ModelStringInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
};

export type ModelRaisedHandFilterInput = {
  and?: Array< ModelRaisedHandFilterInput | null > | null,
  chimeMeetingId?: ModelStringInput | null,
  id?: ModelStringInput | null,
  not?: ModelRaisedHandFilterInput | null,
  or?: Array< ModelRaisedHandFilterInput | null > | null,
};

export type ModelRaisedHandConnection = {
  __typename: "ModelRaisedHandConnection",
  items:  Array<RaisedHand | null >,
  nextToken?: string | null,
};

export type ModelUserSessionFilterInput = {
  and?: Array< ModelUserSessionFilterInput | null > | null,
  countryCode?: ModelStringInput | null,
  id?: ModelIDInput | null,
  location?: ModelStringInput | null,
  locationLevel1?: ModelStringInput | null,
  locationLevel2?: ModelStringInput | null,
  locationLevel3?: ModelStringInput | null,
  not?: ModelUserSessionFilterInput | null,
  or?: Array< ModelUserSessionFilterInput | null > | null,
  queryHelper?: ModelStringInput | null,
  sotName?: ModelStringInput | null,
  source?: ModelStringInput | null,
  time?: ModelStringInput | null,
  topic?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  userId?: ModelIDInput | null,
  userType?: ModelStringInput | null,
};

export type ModelUserSessionConnection = {
  __typename: "ModelUserSessionConnection",
  items:  Array<UserSession | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  and?: Array< ModelUserFilterInput | null > | null,
  id?: ModelIDInput | null,
  lastConnected?: ModelStringInput | null,
  name?: ModelStringInput | null,
  not?: ModelUserFilterInput | null,
  or?: Array< ModelUserFilterInput | null > | null,
  pictureUrl?: ModelStringInput | null,
  presenceStatus?: ModelStringInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelMessageFilterInput = {
  _deleted?: ModelBooleanInput | null,
  _lastChangedAt?: ModelStringInput | null,
  _version?: ModelSizeInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  authorId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  conversationId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  isSent?: ModelBooleanInput | null,
  members?: ModelStringInput | null,
  not?: ModelMessageFilterInput | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  sotName?: ModelStringInput | null,
};

export type UserBusyTimes = {
  __typename: "UserBusyTimes",
  items?:  Array<UserBusyTime | null > | null,
  nextToken?: string | null,
};

export type UserBusyTime = {
  __typename: "UserBusyTime",
  end: string,
  start: string,
  userId: string,
};

export type ModelUserConversationFilterInput = {
  and?: Array< ModelUserConversationFilterInput | null > | null,
  conversationId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  isChatOpen?: ModelBooleanInput | null,
  isMuted?: ModelBooleanInput | null,
  lastReadMessageCreatedAt?: ModelStringInput | null,
  mostRecentMessageCreatedAt?: ModelStringInput | null,
  not?: ModelUserConversationFilterInput | null,
  or?: Array< ModelUserConversationFilterInput | null > | null,
  userId?: ModelIDInput | null,
};

export type ModelIDKeyConditionInput = {
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
};

export type _emptyMutationMutation = {
  _emptyMutation?: string | null,
};

export type CreateCalendarEntryMutationVariables = {
  condition?: ModelCalendarEntryConditionInput | null,
  input: CreateCalendarEntryInput,
};

export type CreateCalendarEntryMutation = {
  createCalendarEntry?:  {
    __typename: "CalendarEntry",
    createdAt: string,
    description?: string | null,
    end: string,
    id: string,
    isVirtual?: boolean | null,
    locationActionParam?: string | null,
    locationActionType?: string | null,
    locationName?: string | null,
    organizationId?: string | null,
    organizationIdStatus?: string | null,
    participants?:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      nextToken?: string | null,
    } | null,
    start: string,
    status?: CalendarEntryParticipationStatus | null,
    title: string,
    topicName: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userPoolName: string,
  } | null,
};

export type CreateCalendarEntryParticipationMutationVariables = {
  condition?: ModelCalendarEntryParticipationConditionInput | null,
  input: CreateCalendarEntryParticipationInput,
};

export type CreateCalendarEntryParticipationMutation = {
  createCalendarEntryParticipation?:  {
    __typename: "CalendarEntryParticipation",
    calendarEntry?:  {
      __typename: "CalendarEntry",
      createdAt: string,
      description?: string | null,
      end: string,
      id: string,
      isVirtual?: boolean | null,
      locationActionParam?: string | null,
      locationActionType?: string | null,
      locationName?: string | null,
      organizationId?: string | null,
      organizationIdStatus?: string | null,
      start: string,
      status?: CalendarEntryParticipationStatus | null,
      title: string,
      topicName: string,
      updatedAt: string,
      userId: string,
      userPoolName: string,
    } | null,
    calendarEntryId: string,
    createdAt: string,
    id: string,
    start: string,
    status: CalendarEntryParticipationStatus,
    statusUpdateMessage?: string | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userIdStatus: string,
  } | null,
};

export type CreateCalendarEntryWithParticipantsMutationVariables = {
  condition?: ModelCalendarEntryConditionInput | null,
  input: CreateCalendarEntryWithParticipantsInput,
};

export type CreateCalendarEntryWithParticipantsMutation = {
  createCalendarEntryWithParticipants?:  {
    __typename: "CalendarEntry",
    createdAt: string,
    description?: string | null,
    end: string,
    id: string,
    isVirtual?: boolean | null,
    locationActionParam?: string | null,
    locationActionType?: string | null,
    locationName?: string | null,
    organizationId?: string | null,
    organizationIdStatus?: string | null,
    participants?:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      nextToken?: string | null,
    } | null,
    start: string,
    status?: CalendarEntryParticipationStatus | null,
    title: string,
    topicName: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userPoolName: string,
  } | null,
};

export type CreateConversationMutationVariables = {
  condition?: ModelConversationConditionInput | null,
  input: CreateConversationInput,
};

export type CreateConversationMutation = {
  createConversation?:  {
    __typename: "Conversation",
    createdAt: string,
    description?: string | null,
    id: string,
    memberIdsHash?: string | null,
    members?:  {
      __typename: "ModelUserConversationConnection",
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    mostRecentMessage?: string | null,
    mostRecentMessageCreatedAt?: string | null,
    name?: string | null,
    type?: ConversationType | null,
    updatedAt?: string | null,
    userId?: string | null,
  } | null,
};

export type CreateMeetingMutationVariables = {
  condition?: ModelMeetingConditionInput | null,
  input: CreateMeetingInput,
};

export type CreateMeetingMutation = {
  createMeeting?:  {
    __typename: "Meeting",
    createdAt: string,
    end?: string | null,
    id: string,
    participants?:  {
      __typename: "ModelMeetingParticipantConnection",
      nextToken?: string | null,
    } | null,
    start: string,
    updatedAt: string,
  } | null,
};

export type CreateMeetingParticipantMutationVariables = {
  condition?: ModelMeetingParticipantConditionInput | null,
  input: CreateMeetingParticipantInput,
};

export type CreateMeetingParticipantMutation = {
  createMeetingParticipant?:  {
    __typename: "MeetingParticipant",
    created: string,
    createdAt: string,
    id: string,
    invitationType?: InvitationType | null,
    invitee:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    inviteeId: string,
    inviter:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    inviterId: string,
    meeting:  {
      __typename: "Meeting",
      createdAt: string,
      end?: string | null,
      id: string,
      start: string,
      updatedAt: string,
    },
    meetingId: string,
    meetingTitle?: string | null,
    status: InviteStatus,
    updatedAt: string,
  } | null,
};

export type CreateMeetingRoomAttendanceMutationVariables = {
  condition?: ModelMeetingRoomAttendanceConditionInput | null,
  input: CreateMeetingRoomAttendanceInput,
};

export type CreateMeetingRoomAttendanceMutation = {
  createMeetingRoomAttendance?:  {
    __typename: "MeetingRoomAttendance",
    chimeMeetingId?: string | null,
    createdAt: string,
    id?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateMessageMutationVariables = {
  condition?: ModelMessageConditionInput | null,
  input: CreateMessageInput,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    _deleted?: boolean | null,
    _lastChangedAt?: number | null,
    _version?: number | null,
    author?:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    } | null,
    authorId: string,
    content: string,
    conversation?:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    } | null,
    conversationId: string,
    createdAt: string,
    id: string,
    isSent?: boolean | null,
    members?: Array< string | null > | null,
    sotName?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateNotificationMutationVariables = {
  condition?: ModelNotificationConditionInput | null,
  input: CreateNotificationInput,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    actionParam?: string | null,
    actionType?: string | null,
    content: string,
    createdAt: string,
    displayGroup?: NotificationDisplayGroup | null,
    id: string,
    read?: boolean | null,
    type: NotificationType,
    updatedAt: string,
    userId: string,
    userIdDisplayGroup?: string | null,
  } | null,
};

export type CreateRaisedHandMutationVariables = {
  condition?: ModelRaisedHandConditionInput | null,
  input: CreateRaisedHandInput,
};

export type CreateRaisedHandMutation = {
  createRaisedHand?:  {
    __typename: "RaisedHand",
    chimeMeetingId?: string | null,
    createdAt: string,
    id?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateUnreadCounterMutationVariables = {
  condition?: ModelUnreadCounterConditionInput | null,
  input: CreateUnreadCounterInput,
};

export type CreateUnreadCounterMutation = {
  createUnreadCounter?:  {
    __typename: "UnreadCounter",
    contacts?: number | null,
    conversations?: number | null,
    createdAt: string,
    id: string,
    notifications?: number | null,
    requests?: number | null,
    schedules?: number | null,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  condition?: ModelUserConditionInput | null,
  input: CreateUserInput,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    conversations?:  {
      __typename: "ModelUserConversationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    id: string,
    incomingMeetings?:  {
      __typename: "ModelMeetingParticipantConnection",
      nextToken?: string | null,
    } | null,
    lastConnected: string,
    name?: string | null,
    outgoingMeetings?:  {
      __typename: "ModelMeetingParticipantConnection",
      nextToken?: string | null,
    } | null,
    pictureUrl?: string | null,
    presenceStatus: string,
    updatedAt: string,
  } | null,
};

export type CreateUserActionMutationVariables = {
  condition?: ModelUserActionConditionInput | null,
  input: CreateUserActionInput,
};

export type CreateUserActionMutation = {
  createUserAction?:  {
    __typename: "UserAction",
    actionType: UserActionType,
    countryCode?: string | null,
    createdAt: string,
    duration?: number | null,
    endTime?: string | null,
    id: string,
    param: string,
    sotName: string,
    source?: string | null,
    startTime: string,
    topic: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userType?: string | null,
  } | null,
};

export type CreateUserConversationMutationVariables = {
  condition?: ModelUserConversationConditionInput | null,
  input: CreateUserConversationInput,
};

export type CreateUserConversationMutation = {
  createUserConversation?:  {
    __typename: "UserConversation",
    conversation:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    },
    conversationId: string,
    createdAt: string,
    id: string,
    isChatOpen?: boolean | null,
    isMuted?: boolean | null,
    lastReadMessageCreatedAt?: string | null,
    mostRecentMessageCreatedAt?: string | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
  } | null,
};

export type CreateUserSessionMutationVariables = {
  condition?: ModelUserSessionConditionInput | null,
  input: CreateUserSessionInput,
};

export type CreateUserSessionMutation = {
  createUserSession?:  {
    __typename: "UserSession",
    countryCode?: string | null,
    createdAt: string,
    id: string,
    location: string,
    locationLevel1: string,
    locationLevel2?: string | null,
    locationLevel3?: string | null,
    queryHelper: string,
    sotName: string,
    source?: string | null,
    time?: string | null,
    topic: string,
    ttl?: number | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userType?: string | null,
  } | null,
};

export type DeleteCalendarEntryMutationVariables = {
  condition?: ModelCalendarEntryConditionInput | null,
  input: DeleteCalendarEntryInput,
};

export type DeleteCalendarEntryMutation = {
  deleteCalendarEntry?:  {
    __typename: "CalendarEntry",
    createdAt: string,
    description?: string | null,
    end: string,
    id: string,
    isVirtual?: boolean | null,
    locationActionParam?: string | null,
    locationActionType?: string | null,
    locationName?: string | null,
    organizationId?: string | null,
    organizationIdStatus?: string | null,
    participants?:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      nextToken?: string | null,
    } | null,
    start: string,
    status?: CalendarEntryParticipationStatus | null,
    title: string,
    topicName: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userPoolName: string,
  } | null,
};

export type DeleteCalendarEntryParticipationMutationVariables = {
  condition?: ModelCalendarEntryParticipationConditionInput | null,
  input: DeleteCalendarEntryParticipationInput,
};

export type DeleteCalendarEntryParticipationMutation = {
  deleteCalendarEntryParticipation?:  {
    __typename: "CalendarEntryParticipation",
    calendarEntry?:  {
      __typename: "CalendarEntry",
      createdAt: string,
      description?: string | null,
      end: string,
      id: string,
      isVirtual?: boolean | null,
      locationActionParam?: string | null,
      locationActionType?: string | null,
      locationName?: string | null,
      organizationId?: string | null,
      organizationIdStatus?: string | null,
      start: string,
      status?: CalendarEntryParticipationStatus | null,
      title: string,
      topicName: string,
      updatedAt: string,
      userId: string,
      userPoolName: string,
    } | null,
    calendarEntryId: string,
    createdAt: string,
    id: string,
    start: string,
    status: CalendarEntryParticipationStatus,
    statusUpdateMessage?: string | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userIdStatus: string,
  } | null,
};

export type DeleteMeetingRoomAttendanceMutationVariables = {
  condition?: ModelMeetingRoomAttendanceConditionInput | null,
  input: DeleteMeetingRoomAttendanceInput,
};

export type DeleteMeetingRoomAttendanceMutation = {
  deleteMeetingRoomAttendance?:  {
    __typename: "MeetingRoomAttendance",
    chimeMeetingId?: string | null,
    createdAt: string,
    id?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteMessageMutationVariables = {
  condition?: ModelMessageConditionInput | null,
  input: DeleteMessageInput,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    _deleted?: boolean | null,
    _lastChangedAt?: number | null,
    _version?: number | null,
    author?:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    } | null,
    authorId: string,
    content: string,
    conversation?:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    } | null,
    conversationId: string,
    createdAt: string,
    id: string,
    isSent?: boolean | null,
    members?: Array< string | null > | null,
    sotName?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  condition?: ModelNotificationConditionInput | null,
  input: DeleteNotificationInput,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    actionParam?: string | null,
    actionType?: string | null,
    content: string,
    createdAt: string,
    displayGroup?: NotificationDisplayGroup | null,
    id: string,
    read?: boolean | null,
    type: NotificationType,
    updatedAt: string,
    userId: string,
    userIdDisplayGroup?: string | null,
  } | null,
};

export type DeleteRaisedHandMutationVariables = {
  condition?: ModelRaisedHandConditionInput | null,
  input: DeleteRaisedHandInput,
};

export type DeleteRaisedHandMutation = {
  deleteRaisedHand?:  {
    __typename: "RaisedHand",
    chimeMeetingId?: string | null,
    createdAt: string,
    id?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteUnreadCounterMutationVariables = {
  condition?: ModelUnreadCounterConditionInput | null,
  input: DeleteUnreadCounterInput,
};

export type DeleteUnreadCounterMutation = {
  deleteUnreadCounter?:  {
    __typename: "UnreadCounter",
    contacts?: number | null,
    conversations?: number | null,
    createdAt: string,
    id: string,
    notifications?: number | null,
    requests?: number | null,
    schedules?: number | null,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  condition?: ModelUserConditionInput | null,
  input: DeleteUserInput,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    conversations?:  {
      __typename: "ModelUserConversationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    id: string,
    incomingMeetings?:  {
      __typename: "ModelMeetingParticipantConnection",
      nextToken?: string | null,
    } | null,
    lastConnected: string,
    name?: string | null,
    outgoingMeetings?:  {
      __typename: "ModelMeetingParticipantConnection",
      nextToken?: string | null,
    } | null,
    pictureUrl?: string | null,
    presenceStatus: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserActionMutationVariables = {
  condition?: ModelUserActionConditionInput | null,
  input: DeleteUserActionInput,
};

export type DeleteUserActionMutation = {
  deleteUserAction?:  {
    __typename: "UserAction",
    actionType: UserActionType,
    countryCode?: string | null,
    createdAt: string,
    duration?: number | null,
    endTime?: string | null,
    id: string,
    param: string,
    sotName: string,
    source?: string | null,
    startTime: string,
    topic: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userType?: string | null,
  } | null,
};

export type DeleteUserConversationMutationVariables = {
  condition?: ModelUserConversationConditionInput | null,
  input: DeleteUserConversationInput,
};

export type DeleteUserConversationMutation = {
  deleteUserConversation?:  {
    __typename: "UserConversation",
    conversation:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    },
    conversationId: string,
    createdAt: string,
    id: string,
    isChatOpen?: boolean | null,
    isMuted?: boolean | null,
    lastReadMessageCreatedAt?: string | null,
    mostRecentMessageCreatedAt?: string | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
  } | null,
};

export type DeleteUserSessionMutationVariables = {
  condition?: ModelUserSessionConditionInput | null,
  input: DeleteUserSessionInput,
};

export type DeleteUserSessionMutation = {
  deleteUserSession?:  {
    __typename: "UserSession",
    countryCode?: string | null,
    createdAt: string,
    id: string,
    location: string,
    locationLevel1: string,
    locationLevel2?: string | null,
    locationLevel3?: string | null,
    queryHelper: string,
    sotName: string,
    source?: string | null,
    time?: string | null,
    topic: string,
    ttl?: number | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userType?: string | null,
  } | null,
};

export type UpdateCalendarEntryMutationVariables = {
  condition?: ModelCalendarEntryConditionInput | null,
  input: UpdateCalendarEntryInput,
};

export type UpdateCalendarEntryMutation = {
  updateCalendarEntry?:  {
    __typename: "CalendarEntry",
    createdAt: string,
    description?: string | null,
    end: string,
    id: string,
    isVirtual?: boolean | null,
    locationActionParam?: string | null,
    locationActionType?: string | null,
    locationName?: string | null,
    organizationId?: string | null,
    organizationIdStatus?: string | null,
    participants?:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      nextToken?: string | null,
    } | null,
    start: string,
    status?: CalendarEntryParticipationStatus | null,
    title: string,
    topicName: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userPoolName: string,
  } | null,
};

export type UpdateCalendarEntryParticipationMutationVariables = {
  condition?: ModelCalendarEntryParticipationConditionInput | null,
  input: UpdateCalendarEntryParticipationInput,
};

export type UpdateCalendarEntryParticipationMutation = {
  updateCalendarEntryParticipation?:  {
    __typename: "CalendarEntryParticipation",
    calendarEntry?:  {
      __typename: "CalendarEntry",
      createdAt: string,
      description?: string | null,
      end: string,
      id: string,
      isVirtual?: boolean | null,
      locationActionParam?: string | null,
      locationActionType?: string | null,
      locationName?: string | null,
      organizationId?: string | null,
      organizationIdStatus?: string | null,
      start: string,
      status?: CalendarEntryParticipationStatus | null,
      title: string,
      topicName: string,
      updatedAt: string,
      userId: string,
      userPoolName: string,
    } | null,
    calendarEntryId: string,
    createdAt: string,
    id: string,
    start: string,
    status: CalendarEntryParticipationStatus,
    statusUpdateMessage?: string | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userIdStatus: string,
  } | null,
};

export type UpdateCalendarEntryWithParticipantsMutationVariables = {
  condition?: ModelCalendarEntryConditionInput | null,
  input: UpdateCalendarEntryWithParticipantsInput,
};

export type UpdateCalendarEntryWithParticipantsMutation = {
  updateCalendarEntryWithParticipants?:  {
    __typename: "CalendarEntry",
    createdAt: string,
    description?: string | null,
    end: string,
    id: string,
    isVirtual?: boolean | null,
    locationActionParam?: string | null,
    locationActionType?: string | null,
    locationName?: string | null,
    organizationId?: string | null,
    organizationIdStatus?: string | null,
    participants?:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      nextToken?: string | null,
    } | null,
    start: string,
    status?: CalendarEntryParticipationStatus | null,
    title: string,
    topicName: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userPoolName: string,
  } | null,
};

export type UpdateConversationMutationVariables = {
  condition?: ModelConversationConditionInput | null,
  input: UpdateConversationInput,
};

export type UpdateConversationMutation = {
  updateConversation?:  {
    __typename: "Conversation",
    createdAt: string,
    description?: string | null,
    id: string,
    memberIdsHash?: string | null,
    members?:  {
      __typename: "ModelUserConversationConnection",
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    mostRecentMessage?: string | null,
    mostRecentMessageCreatedAt?: string | null,
    name?: string | null,
    type?: ConversationType | null,
    updatedAt?: string | null,
    userId?: string | null,
  } | null,
};

export type UpdateMeetingParticipantMutationVariables = {
  condition?: ModelMeetingParticipantConditionInput | null,
  input: UpdateMeetingParticipantInput,
};

export type UpdateMeetingParticipantMutation = {
  updateMeetingParticipant?:  {
    __typename: "MeetingParticipant",
    created: string,
    createdAt: string,
    id: string,
    invitationType?: InvitationType | null,
    invitee:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    inviteeId: string,
    inviter:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    inviterId: string,
    meeting:  {
      __typename: "Meeting",
      createdAt: string,
      end?: string | null,
      id: string,
      start: string,
      updatedAt: string,
    },
    meetingId: string,
    meetingTitle?: string | null,
    status: InviteStatus,
    updatedAt: string,
  } | null,
};

export type UpdateMeetingRoomAttendanceMutationVariables = {
  condition?: ModelMeetingRoomAttendanceConditionInput | null,
  input: UpdateMeetingRoomAttendanceInput,
};

export type UpdateMeetingRoomAttendanceMutation = {
  updateMeetingRoomAttendance?:  {
    __typename: "MeetingRoomAttendance",
    chimeMeetingId?: string | null,
    createdAt: string,
    id?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateMessageMutationVariables = {
  condition?: ModelMessageConditionInput | null,
  input: UpdateMessageInput,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    _deleted?: boolean | null,
    _lastChangedAt?: number | null,
    _version?: number | null,
    author?:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    } | null,
    authorId: string,
    content: string,
    conversation?:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    } | null,
    conversationId: string,
    createdAt: string,
    id: string,
    isSent?: boolean | null,
    members?: Array< string | null > | null,
    sotName?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  condition?: ModelNotificationConditionInput | null,
  input: UpdateNotificationInput,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    actionParam?: string | null,
    actionType?: string | null,
    content: string,
    createdAt: string,
    displayGroup?: NotificationDisplayGroup | null,
    id: string,
    read?: boolean | null,
    type: NotificationType,
    updatedAt: string,
    userId: string,
    userIdDisplayGroup?: string | null,
  } | null,
};

export type UpdateRaisedHandMutationVariables = {
  condition?: ModelRaisedHandConditionInput | null,
  input: UpdateRaisedHandInput,
};

export type UpdateRaisedHandMutation = {
  updateRaisedHand?:  {
    __typename: "RaisedHand",
    chimeMeetingId?: string | null,
    createdAt: string,
    id?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateUnreadCounterMutationVariables = {
  condition?: ModelUnreadCounterConditionInput | null,
  input: UpdateUnreadCounterInput,
};

export type UpdateUnreadCounterMutation = {
  updateUnreadCounter?:  {
    __typename: "UnreadCounter",
    contacts?: number | null,
    conversations?: number | null,
    createdAt: string,
    id: string,
    notifications?: number | null,
    requests?: number | null,
    schedules?: number | null,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  condition?: ModelUserConditionInput | null,
  input: UpdateUserInput,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    conversations?:  {
      __typename: "ModelUserConversationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    id: string,
    incomingMeetings?:  {
      __typename: "ModelMeetingParticipantConnection",
      nextToken?: string | null,
    } | null,
    lastConnected: string,
    name?: string | null,
    outgoingMeetings?:  {
      __typename: "ModelMeetingParticipantConnection",
      nextToken?: string | null,
    } | null,
    pictureUrl?: string | null,
    presenceStatus: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserActionMutationVariables = {
  condition?: ModelUserActionConditionInput | null,
  input: UpdateUserActionInput,
};

export type UpdateUserActionMutation = {
  updateUserAction?:  {
    __typename: "UserAction",
    actionType: UserActionType,
    countryCode?: string | null,
    createdAt: string,
    duration?: number | null,
    endTime?: string | null,
    id: string,
    param: string,
    sotName: string,
    source?: string | null,
    startTime: string,
    topic: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userType?: string | null,
  } | null,
};

export type UpdateUserConversationMutationVariables = {
  condition?: ModelUserConversationConditionInput | null,
  input: UpdateUserConversationInput,
};

export type UpdateUserConversationMutation = {
  updateUserConversation?:  {
    __typename: "UserConversation",
    conversation:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    },
    conversationId: string,
    createdAt: string,
    id: string,
    isChatOpen?: boolean | null,
    isMuted?: boolean | null,
    lastReadMessageCreatedAt?: string | null,
    mostRecentMessageCreatedAt?: string | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
  } | null,
};

export type UpdateUserSessionMutationVariables = {
  condition?: ModelUserSessionConditionInput | null,
  input: UpdateUserSessionInput,
};

export type UpdateUserSessionMutation = {
  updateUserSession?:  {
    __typename: "UserSession",
    countryCode?: string | null,
    createdAt: string,
    id: string,
    location: string,
    locationLevel1: string,
    locationLevel2?: string | null,
    locationLevel3?: string | null,
    queryHelper: string,
    sotName: string,
    source?: string | null,
    time?: string | null,
    topic: string,
    ttl?: number | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userType?: string | null,
  } | null,
};

export type CreateCalendarEntryLightMutationVariables = {
  input: CreateCalendarEntryInput,
  condition?: ModelCalendarEntryConditionInput | null,
};

export type CreateCalendarEntryLightMutation = {
  createCalendarEntry?:  {
    __typename: "CalendarEntry",
    id: string,
    title: string,
    description?: string | null,
    start: string,
    end: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      name?: string | null,
      pictureUrl?: string | null,
    },
    participants?:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      items?:  Array< {
        __typename: "CalendarEntryParticipation",
        id: string,
        status: CalendarEntryParticipationStatus,
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          name?: string | null,
          pictureUrl?: string | null,
        },
      } | null > | null,
    } | null,
    locationName?: string | null,
    locationActionType?: string | null,
    locationActionParam?: string | null,
    isVirtual?: boolean | null,
  } | null,
};

export type UpdateCalendarEntryLightMutationVariables = {
  input: UpdateCalendarEntryInput,
  condition?: ModelCalendarEntryConditionInput | null,
};

export type UpdateCalendarEntryLightMutation = {
  updateCalendarEntry?:  {
    __typename: "CalendarEntry",
    id: string,
    title: string,
    description?: string | null,
    start: string,
    end: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      name?: string | null,
      pictureUrl?: string | null,
    },
    participants?:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      items?:  Array< {
        __typename: "CalendarEntryParticipation",
        id: string,
        status: CalendarEntryParticipationStatus,
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          name?: string | null,
          pictureUrl?: string | null,
        },
      } | null > | null,
    } | null,
    locationName?: string | null,
    locationActionType?: string | null,
    locationActionParam?: string | null,
    isVirtual?: boolean | null,
  } | null,
};

export type CreateMessageLightMutationVariables = {
  input: CreateMessageInput,
};

export type CreateMessageLightMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    authorId: string,
    content: string,
    conversationId: string,
    isSent?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserConversationLightMutationVariables = {
  input: UpdateUserConversationInput,
  condition?: ModelUserConversationConditionInput | null,
};

export type UpdateUserConversationLightMutation = {
  updateUserConversation?:  {
    __typename: "UserConversation",
    userId: string,
    isMuted?: boolean | null,
    lastReadMessageCreatedAt?: string | null,
    conversation:  {
      __typename: "Conversation",
      id: string,
      name?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
    },
  } | null,
};

export type DeleteCalendarEntryParticipationByIdMutationVariables = {
  input: DeleteCalendarEntryParticipationInput,
  condition?: ModelCalendarEntryParticipationConditionInput | null,
};

export type DeleteCalendarEntryParticipationByIdMutation = {
  deleteCalendarEntryParticipation?:  {
    __typename: "CalendarEntryParticipation",
    id: string,
    userId: string,
  } | null,
};

export type CreateMeetingLightMutationVariables = {
  input: CreateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type CreateMeetingLightMutation = {
  createMeeting?:  {
    __typename: "Meeting",
    id: string,
  } | null,
};

export type CreateUserConversationLightMutationVariables = {
  condition?: ModelUserConversationConditionInput | null,
  input: CreateUserConversationInput,
};

export type CreateUserConversationLightMutation = {
  createUserConversation?:  {
    __typename: "UserConversation",
    id: string,
    userId: string,
    conversationId: string,
    isMuted?: boolean | null,
    isChatOpen?: boolean | null,
    mostRecentMessageCreatedAt?: string | null,
    lastReadMessageCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMeetingParticipantLightMutationVariables = {
  condition?: ModelMeetingParticipantConditionInput | null,
  input: CreateMeetingParticipantInput,
};

export type CreateMeetingParticipantLightMutation = {
  createMeetingParticipant?:  {
    __typename: "MeetingParticipant",
    id: string,
    meetingId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      start: string,
      end?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    inviterId: string,
    inviteeId: string,
    status: InviteStatus,
    created: string,
    createdAt: string,
    updatedAt: string,
    invitationType?: InvitationType | null,
    meetingTitle?: string | null,
    inviter:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    invitee:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type UpdateMeetingParticipantLightMutationVariables = {
  condition?: ModelMeetingParticipantConditionInput | null,
  input: UpdateMeetingParticipantInput,
};

export type UpdateMeetingParticipantLightMutation = {
  updateMeetingParticipant?:  {
    __typename: "MeetingParticipant",
    id: string,
    meetingId: string,
    inviterId: string,
    inviteeId: string,
    status: InviteStatus,
    created: string,
    createdAt: string,
    updatedAt: string,
    inviter:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    invitee:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    meeting:  {
      __typename: "Meeting",
      id: string,
      start: string,
      end?: string | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type UpdateUserSessionLightMutationVariables = {
  input: UpdateUserSessionInput,
  condition?: ModelUserSessionConditionInput | null,
};

export type UpdateUserSessionLightMutation = {
  updateUserSession?:  {
    __typename: "UserSession",
    id: string,
    userId: string,
    sotName: string,
    topic: string,
    countryCode?: string | null,
    userType?: string | null,
    location: string,
    locationLevel1: string,
    locationLevel2?: string | null,
    locationLevel3?: string | null,
    ttl?: number | null,
    time?: string | null,
    source?: string | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type CreateCalendarEntryParticipationLightMutationVariables = {
  condition?: ModelCalendarEntryParticipationConditionInput | null,
  input: CreateCalendarEntryParticipationInput,
};

export type CreateCalendarEntryParticipationLightMutation = {
  createCalendarEntryParticipation?:  {
    __typename: "CalendarEntryParticipation",
    id: string,
    userId: string,
    status: CalendarEntryParticipationStatus,
    start: string,
    userIdStatus: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      name?: string | null,
      pictureUrl?: string | null,
    },
  } | null,
};

export type DeleteCalendarEntryLightMutationVariables = {
  condition?: ModelCalendarEntryConditionInput | null,
  input: DeleteCalendarEntryInput,
};

export type DeleteCalendarEntryLightMutation = {
  deleteCalendarEntry?:  {
    __typename: "CalendarEntry",
    id: string,
    title: string,
    description?: string | null,
    start: string,
    end: string,
    userId: string,
    organizationId?: string | null,
    status?: CalendarEntryParticipationStatus | null,
    organizationIdStatus?: string | null,
    topicName: string,
    userPoolName: string,
    createdAt: string,
    updatedAt: string,
    locationName?: string | null,
    locationActionType?: string | null,
    locationActionParam?: string | null,
    isVirtual?: boolean | null,
  } | null,
};

export type UpdateCalendarEntryParticipationLightMutationVariables = {
  condition?: ModelCalendarEntryParticipationConditionInput | null,
  input: UpdateCalendarEntryParticipationInput,
};

export type UpdateCalendarEntryParticipationLightMutation = {
  updateCalendarEntryParticipation?:  {
    __typename: "CalendarEntryParticipation",
    id: string,
    calendarEntryId: string,
    userId: string,
    status: CalendarEntryParticipationStatus,
  } | null,
};

export type CreateConversationLightMutationVariables = {
  condition?: ModelConversationConditionInput | null,
  input: CreateConversationInput,
};

export type CreateConversationLightMutation = {
  createConversation?:  {
    __typename: "Conversation",
    id: string,
    type?: ConversationType | null,
    name?: string | null,
    description?: string | null,
    userId?: string | null,
  } | null,
};

export type CreateUserLightMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserLightMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
    name?: string | null,
    pictureUrl?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserLightMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserLightMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
    name?: string | null,
    pictureUrl?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserConversationLightMutationVariables = {
  condition?: ModelUserConversationConditionInput | null,
  input: DeleteUserConversationInput,
};

export type DeleteUserConversationLightMutation = {
  deleteUserConversation?:  {
    __typename: "UserConversation",
    id: string,
    userId: string,
    conversationId: string,
    isMuted?: boolean | null,
    isChatOpen?: boolean | null,
    mostRecentMessageCreatedAt?: string | null,
    lastReadMessageCreatedAt?: string | null,
  } | null,
};

export type UpdateConversationLightMutationVariables = {
  condition?: ModelConversationConditionInput | null,
  input: UpdateConversationInput,
};

export type UpdateConversationLightMutation = {
  updateConversation?:  {
    __typename: "Conversation",
    id: string,
    type?: ConversationType | null,
    name?: string | null,
    description?: string | null,
    userId?: string | null,
  } | null,
};

export type UpdateUserLightMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserLightMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
    name?: string | null,
    pictureUrl?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetPresenceByUserQueryVariables = {
  id: string,
};

export type GetPresenceByUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
  } | null,
};

export type GetMeetingLightQueryVariables = {
  id: string,
};

export type GetMeetingLightQuery = {
  getMeeting?:  {
    __typename: "Meeting",
    id: string,
  } | null,
};

export type GetMeetingsQueryVariables = {
  id: string,
  limit?: number | null,
};

export type GetMeetingsQuery = {
  getUser?:  {
    __typename: "User",
    outgoingMeetings?:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        invitee:  {
          __typename: "User",
          id: string,
          name?: string | null,
          pictureUrl?: string | null,
        },
        meeting:  {
          __typename: "Meeting",
          id: string,
          start: string,
          end?: string | null,
          participants?:  {
            __typename: "ModelMeetingParticipantConnection",
            items:  Array< {
              __typename: "MeetingParticipant",
              invitee:  {
                __typename: "User",
                id: string,
                name?: string | null,
                pictureUrl?: string | null,
              },
              inviter:  {
                __typename: "User",
                id: string,
                name?: string | null,
                pictureUrl?: string | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        status: InviteStatus,
        created: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    incomingMeetings?:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        inviter:  {
          __typename: "User",
          id: string,
          name?: string | null,
          pictureUrl?: string | null,
        },
        meeting:  {
          __typename: "Meeting",
          id: string,
          start: string,
          end?: string | null,
          participants?:  {
            __typename: "ModelMeetingParticipantConnection",
            items:  Array< {
              __typename: "MeetingParticipant",
              invitee:  {
                __typename: "User",
                id: string,
                name?: string | null,
                pictureUrl?: string | null,
              },
              inviter:  {
                __typename: "User",
                id: string,
                name?: string | null,
                pictureUrl?: string | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        status: InviteStatus,
        created: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetConversationsByMembersLightQueryVariables = {
  memberId1: string,
  memberId2: string,
};

export type GetConversationsByMembersLightQuery = {
  getConversationsByMembers?:  {
    __typename: "ModelConversationConnection",
    items?:  Array< {
      __typename: "Conversation",
      id: string,
      members?:  {
        __typename: "ModelUserConversationConnection",
        items?:  Array< {
          __typename: "UserConversation",
          id: string,
          userId: string,
          isMuted?: boolean | null,
        } | null > | null,
      } | null,
    } | null > | null,
  } | null,
};

export type UserConversationsByUserAndConversationLightQueryVariables = {
  userId: string,
  conversationId: string,
};

export type UserConversationsByUserAndConversationLightQuery = {
  userConversationsByUserAndConversation?:  {
    __typename: "ModelUserConversationConnection",
    items?:  Array< {
      __typename: "UserConversation",
      id: string,
      conversation:  {
        __typename: "Conversation",
        id: string,
        name?: string | null,
      },
      isMuted?: boolean | null,
    } | null > | null,
  } | null,
};

export type MessagesByConversationIdLightQueryVariables = {
  conversationId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesByConversationIdLightQuery = {
  messagesByConversationId?:  {
    __typename: "ModelMessageConnection",
    items?:  Array< {
      __typename: "Message",
      id: string,
      authorId: string,
      content: string,
      createdAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetConversationsQueryVariables = {
  userId: string,
  callLimit?: number | null,
  memberLimit?: number | null,
  nextToken?: string | null,
};

export type GetConversationsQuery = {
  userConversationsByUser?:  {
    __typename: "ModelUserConversationConnection",
    items?:  Array< {
      __typename: "UserConversation",
      id: string,
      isMuted?: boolean | null,
      conversation:  {
        __typename: "Conversation",
        id: string,
        type?: ConversationType | null,
        name?: string | null,
        userId?: string | null,
        mostRecentMessage?: string | null,
        members?:  {
          __typename: "ModelUserConversationConnection",
          items?:  Array< {
            __typename: "UserConversation",
            id: string,
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              name?: string | null,
              pictureUrl?: string | null,
            },
          } | null > | null,
        } | null,
      },
      mostRecentMessageCreatedAt?: string | null,
      lastReadMessageCreatedAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserConversationLightQueryVariables = {
  id: string,
};

export type GetUserConversationLightQuery = {
  getUserConversation?:  {
    __typename: "UserConversation",
    id: string,
    userId: string,
    conversationId: string,
    isMuted?: boolean | null,
    isChatOpen?: boolean | null,
    mostRecentMessageCreatedAt?: string | null,
    lastReadMessageCreatedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UserConversationListEntryQueryVariables = {
  userId: string,
  conversationId: string,
  memberLimit?: number | null,
};

export type UserConversationListEntryQuery = {
  userConversationsByUserAndConversation?:  {
    __typename: "ModelUserConversationConnection",
    items?:  Array< {
      __typename: "UserConversation",
      id: string,
      isMuted?: boolean | null,
      mostRecentMessageCreatedAt?: string | null,
      lastReadMessageCreatedAt?: string | null,
      conversation:  {
        __typename: "Conversation",
        id: string,
        type?: ConversationType | null,
        name?: string | null,
        mostRecentMessage?: string | null,
        members?:  {
          __typename: "ModelUserConversationConnection",
          items?:  Array< {
            __typename: "UserConversation",
            id: string,
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              name?: string | null,
              pictureUrl?: string | null,
            },
          } | null > | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetConversationByIdQueryVariables = {
  id: string,
  limit?: number | null,
};

export type GetConversationByIdQuery = {
  getConversation?:  {
    __typename: "Conversation",
    id: string,
    name?: string | null,
    description?: string | null,
    members?:  {
      __typename: "ModelUserConversationConnection",
      items?:  Array< {
        __typename: "UserConversation",
        id: string,
        isMuted?: boolean | null,
        user:  {
          __typename: "User",
          id: string,
          name?: string | null,
          pictureUrl?: string | null,
        },
      } | null > | null,
    } | null,
  } | null,
};

export type GetConversationNameAndTypeByIdQueryVariables = {
  id: string,
};

export type GetConversationNameAndTypeByIdQuery = {
  getConversation?:  {
    __typename: "Conversation",
    name?: string | null,
    type?: ConversationType | null,
  } | null,
};

export type GetConversationParticipationByIdQueryVariables = {
  id: string,
  membersLimit?: number | null,
};

export type GetConversationParticipationByIdQuery = {
  getConversation?:  {
    __typename: "Conversation",
    members?:  {
      __typename: "ModelUserConversationConnection",
      items?:  Array< {
        __typename: "UserConversation",
        userId: string,
      } | null > | null,
    } | null,
  } | null,
};

export type GetUserNameAndPictureQueryVariables = {
  id: string,
};

export type GetUserNameAndPictureQuery = {
  getUser?:  {
    __typename: "User",
    name?: string | null,
    pictureUrl?: string | null,
  } | null,
};

export type CalendarEntryParticipationByUserAndStatusLightQueryVariables = {
  userIdStatus: string,
  start?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCalendarEntryParticipationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CalendarEntryParticipationByUserAndStatusLightQuery = {
  calendarEntryParticipationByUserAndStatusSorted?:  {
    __typename: "ModelCalendarEntryParticipationConnection",
    items?:  Array< {
      __typename: "CalendarEntryParticipation",
      id: string,
      calendarEntryId: string,
      calendarEntry?:  {
        __typename: "CalendarEntry",
        id: string,
        title: string,
        description?: string | null,
        start: string,
        end: string,
        organizationId?: string | null,
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          name?: string | null,
          pictureUrl?: string | null,
        },
        participants?:  {
          __typename: "ModelCalendarEntryParticipationConnection",
          items?:  Array< {
            __typename: "CalendarEntryParticipation",
            id: string,
            status: CalendarEntryParticipationStatus,
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              name?: string | null,
              pictureUrl?: string | null,
            },
          } | null > | null,
        } | null,
        locationName?: string | null,
        locationActionType?: string | null,
        locationActionParam?: string | null,
        isVirtual?: boolean | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type CalendarEntryParticipationByUserLightQueryVariables = {
  userId: string,
  start?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCalendarEntryParticipationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CalendarEntryParticipationByUserLightQuery = {
  calendarEntryParticipationByUserSorted?:  {
    __typename: "ModelCalendarEntryParticipationConnection",
    items?:  Array< {
      __typename: "CalendarEntryParticipation",
      id: string,
      calendarEntryId: string,
      calendarEntry?:  {
        __typename: "CalendarEntry",
        id: string,
        title: string,
        description?: string | null,
        start: string,
        end: string,
        organizationId?: string | null,
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          name?: string | null,
          pictureUrl?: string | null,
        },
        participants?:  {
          __typename: "ModelCalendarEntryParticipationConnection",
          items?:  Array< {
            __typename: "CalendarEntryParticipation",
            id: string,
            status: CalendarEntryParticipationStatus,
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              name?: string | null,
              pictureUrl?: string | null,
            },
          } | null > | null,
        } | null,
        locationName?: string | null,
        locationActionType?: string | null,
        locationActionParam?: string | null,
        isVirtual?: boolean | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type getOnlineUsersQueryVariables = {
  id: string,
};

export type getOnlineUsersQuery = {
  getCurrentLocationCounts?:  {
    __typename: "CurrentLocationCounts",
    id: string,
    cnt: number,
  } | null,
};

export type usersInCallsInLoungeQueryVariables = {
  actionType?: UserActionType | null,
  duration?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type usersInCallsInLoungeQuery = {
  byActionType?:  {
    __typename: "ModelUserActionConnection",
    items:  Array< {
      __typename: "UserAction",
      id: string,
      param: string,
      user:  {
        __typename: "User",
        id: string,
        name?: string | null,
        pictureUrl?: string | null,
      },
    } | null >,
  } | null,
};

export type pageVisitsQueryVariables = {
  param?: string | null,
  startTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type pageVisitsQuery = {
  userActionsByRoom?:  {
    __typename: "ModelUserActionConnection",
    items:  Array< {
      __typename: "UserAction",
      startTime: string,
      duration?: number | null,
      endTime?: string | null,
      source?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCalendarEntryLightQueryVariables = {
  id: string,
  limit?: number | null,
};

export type GetCalendarEntryLightQuery = {
  getCalendarEntry?:  {
    __typename: "CalendarEntry",
    id: string,
    title: string,
    description?: string | null,
    start: string,
    end: string,
    status?: CalendarEntryParticipationStatus | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      name?: string | null,
      pictureUrl?: string | null,
    },
    participants?:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      items?:  Array< {
        __typename: "CalendarEntryParticipation",
        id: string,
        status: CalendarEntryParticipationStatus,
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          name?: string | null,
          pictureUrl?: string | null,
        },
      } | null > | null,
    } | null,
    locationName?: string | null,
    locationActionType?: string | null,
    locationActionParam?: string | null,
    isVirtual?: boolean | null,
  } | null,
};

export type ListCalendarEntryByOrganizationQueryVariables = {
  organizationIdStatus?: string | null,
  start?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCalendarEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCalendarEntryByOrganizationQuery = {
  calendarEntryByOrganizationAndStatusSorted?:  {
    __typename: "ModelCalendarEntryConnection",
    items?:  Array< {
      __typename: "CalendarEntry",
      id: string,
      title: string,
      description?: string | null,
      start: string,
      end: string,
      userId: string,
      organizationId?: string | null,
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        name?: string | null,
        pictureUrl?: string | null,
      },
      participants?:  {
        __typename: "ModelCalendarEntryParticipationConnection",
        items?:  Array< {
          __typename: "CalendarEntryParticipation",
          id: string,
          status: CalendarEntryParticipationStatus,
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            name?: string | null,
            pictureUrl?: string | null,
          },
        } | null > | null,
      } | null,
      locationName?: string | null,
      locationActionType?: string | null,
      locationActionParam?: string | null,
      isVirtual?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserLightQueryVariables = {
  id: string,
};

export type GetUserLightQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
    name?: string | null,
    pictureUrl?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type BatchGetUserPresenceLightQueryVariables = {
  listIds?: Array< string | null > | null,
};

export type BatchGetUserPresenceLightQuery = {
  batchGetUserPresence?:  Array< {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
  } | null > | null,
};

export type OnNotificationCreatedByLambdaSubscriptionVariables = {
  userId: string,
};

export type OnNotificationCreatedByLambdaSubscription = {
  onNotificationCreated?:  {
    __typename: "Notification",
    userId: string,
    type: NotificationType,
    content: string,
  } | null,
};

export type OnMessageCreatedLightSubscriptionVariables = {
  conversationId: string,
};

export type OnMessageCreatedLightSubscription = {
  onMessageCreated?:  {
    __typename: "Message",
    id: string,
    authorId: string,
    content: string,
    conversationId: string,
    isSent?: boolean | null,
    createdAt: string,
  } | null,
};

export type OnConversationUpdatedLightSubscriptionVariables = {
  id: string,
};

export type OnConversationUpdatedLightSubscription = {
  onConversationUpdated?:  {
    __typename: "Conversation",
    name?: string | null,
    description?: string | null,
  } | null,
};

export type OnUserConversationDeletedLightSubscriptionVariables = {
  userId: string,
};

export type OnUserConversationDeletedLightSubscription = {
  onUserConversationDeleted?:  {
    __typename: "UserConversation",
    id: string,
    conversationId: string,
  } | null,
};

export type OnUserConversationUpdatedLightSubscriptionVariables = {
  userId: string,
};

export type OnUserConversationUpdatedLightSubscription = {
  onUserConversationUpdated?:  {
    __typename: "UserConversation",
    conversation:  {
      __typename: "Conversation",
      id: string,
      name?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
    },
  } | null,
};

export type OnUserConversationCreatedByConversationLightSubscriptionVariables = {
  conversationId: string,
};

export type OnUserConversationCreatedByConversationLightSubscription = {
  onUserConversationCreatedByConversation?:  {
    __typename: "UserConversation",
    user:  {
      __typename: "User",
      id: string,
      name?: string | null,
      pictureUrl?: string | null,
    },
  } | null,
};

export type OnUserConversationDeletedByConversationLightSubscriptionVariables = {
  conversationId: string,
};

export type OnUserConversationDeletedByConversationLightSubscription = {
  onUserConversationDeletedByConversation?:  {
    __typename: "UserConversation",
    userId: string,
  } | null,
};

export type OnCalendarEntryParticipationCreatedLightSubscriptionVariables = {
  userId: string,
};

export type OnCalendarEntryParticipationCreatedLightSubscription = {
  onCalendarEntryParticipationCreated?:  {
    __typename: "CalendarEntryParticipation",
    id: string,
  } | null,
};

export type OnCalendarEntryParticipationDeletedLightSubscriptionVariables = {
  userId: string,
};

export type OnCalendarEntryParticipationDeletedLightSubscription = {
  onCalendarEntryParticipationDeleted?:  {
    __typename: "CalendarEntryParticipation",
    id: string,
  } | null,
};

export type OnMeetingInviteChangedLightSubscriptionVariables = {
  id: string,
};

export type OnMeetingInviteChangedLightSubscription = {
  onMeetingInviteChanged?:  {
    __typename: "MeetingParticipant",
    id: string,
    meetingId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      start: string,
      end?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    inviterId: string,
    inviteeId: string,
    status: InviteStatus,
    created: string,
    createdAt: string,
    updatedAt: string,
    inviter:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    invitee:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type _emptyQueryQuery = {
  _emptyQuery?: string | null,
};

export type BatchGetUserPresenceQueryVariables = {
  listIds?: Array< string | null > | null,
};

export type BatchGetUserPresenceQuery = {
  batchGetUserPresence?:  Array< {
    __typename: "User",
    conversations?:  {
      __typename: "ModelUserConversationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    id: string,
    incomingMeetings?:  {
      __typename: "ModelMeetingParticipantConnection",
      nextToken?: string | null,
    } | null,
    lastConnected: string,
    name?: string | null,
    outgoingMeetings?:  {
      __typename: "ModelMeetingParticipantConnection",
      nextToken?: string | null,
    } | null,
    pictureUrl?: string | null,
    presenceStatus: string,
    updatedAt: string,
  } | null > | null,
};

export type ByActionTypeQueryVariables = {
  actionType?: UserActionType | null,
  duration?: ModelIntKeyConditionInput | null,
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ByActionTypeQuery = {
  byActionType?:  {
    __typename: "ModelUserActionConnection",
    items:  Array< {
      __typename: "UserAction",
      actionType: UserActionType,
      countryCode?: string | null,
      createdAt: string,
      duration?: number | null,
      endTime?: string | null,
      id: string,
      param: string,
      sotName: string,
      source?: string | null,
      startTime: string,
      topic: string,
      updatedAt: string,
      userId: string,
      userType?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CalendarEntryByOrganizationAndStatusSortedQueryVariables = {
  filter?: ModelCalendarEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  organizationIdStatus?: string | null,
  sortDirection?: ModelSortDirection | null,
  start?: ModelStringKeyConditionInput | null,
};

export type CalendarEntryByOrganizationAndStatusSortedQuery = {
  calendarEntryByOrganizationAndStatusSorted?:  {
    __typename: "ModelCalendarEntryConnection",
    items?:  Array< {
      __typename: "CalendarEntry",
      createdAt: string,
      description?: string | null,
      end: string,
      id: string,
      isVirtual?: boolean | null,
      locationActionParam?: string | null,
      locationActionType?: string | null,
      locationName?: string | null,
      organizationId?: string | null,
      organizationIdStatus?: string | null,
      start: string,
      status?: CalendarEntryParticipationStatus | null,
      title: string,
      topicName: string,
      updatedAt: string,
      userId: string,
      userPoolName: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type CalendarEntryParticipationByUserAndStatusSortedQueryVariables = {
  filter?: ModelCalendarEntryParticipationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  start?: ModelStringKeyConditionInput | null,
  userIdStatus: string,
};

export type CalendarEntryParticipationByUserAndStatusSortedQuery = {
  calendarEntryParticipationByUserAndStatusSorted?:  {
    __typename: "ModelCalendarEntryParticipationConnection",
    items?:  Array< {
      __typename: "CalendarEntryParticipation",
      calendarEntryId: string,
      createdAt: string,
      id: string,
      start: string,
      status: CalendarEntryParticipationStatus,
      statusUpdateMessage?: string | null,
      updatedAt: string,
      userId: string,
      userIdStatus: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type CalendarEntryParticipationByUserSortedQueryVariables = {
  filter?: ModelCalendarEntryParticipationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  start?: ModelStringKeyConditionInput | null,
  userId: string,
};

export type CalendarEntryParticipationByUserSortedQuery = {
  calendarEntryParticipationByUserSorted?:  {
    __typename: "ModelCalendarEntryParticipationConnection",
    items?:  Array< {
      __typename: "CalendarEntryParticipation",
      calendarEntryId: string,
      createdAt: string,
      id: string,
      start: string,
      status: CalendarEntryParticipationStatus,
      statusUpdateMessage?: string | null,
      updatedAt: string,
      userId: string,
      userIdStatus: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCalendarEntryQueryVariables = {
  id: string,
};

export type GetCalendarEntryQuery = {
  getCalendarEntry?:  {
    __typename: "CalendarEntry",
    createdAt: string,
    description?: string | null,
    end: string,
    id: string,
    isVirtual?: boolean | null,
    locationActionParam?: string | null,
    locationActionType?: string | null,
    locationName?: string | null,
    organizationId?: string | null,
    organizationIdStatus?: string | null,
    participants?:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      nextToken?: string | null,
    } | null,
    start: string,
    status?: CalendarEntryParticipationStatus | null,
    title: string,
    topicName: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userPoolName: string,
  } | null,
};

export type GetConversationQueryVariables = {
  id: string,
};

export type GetConversationQuery = {
  getConversation?:  {
    __typename: "Conversation",
    createdAt: string,
    description?: string | null,
    id: string,
    memberIdsHash?: string | null,
    members?:  {
      __typename: "ModelUserConversationConnection",
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    mostRecentMessage?: string | null,
    mostRecentMessageCreatedAt?: string | null,
    name?: string | null,
    type?: ConversationType | null,
    updatedAt?: string | null,
    userId?: string | null,
  } | null,
};

export type GetConversationsByMembersQueryVariables = {
  filter?: ModelConversationFilterInput | null,
  limit?: number | null,
  memberId1: string,
  memberId2: string,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type GetConversationsByMembersQuery = {
  getConversationsByMembers?:  {
    __typename: "ModelConversationConnection",
    items?:  Array< {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCurrentLocationCountsQueryVariables = {
  id: string,
};

export type GetCurrentLocationCountsQuery = {
  getCurrentLocationCounts?:  {
    __typename: "CurrentLocationCounts",
    cnt: number,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type GetMeetingQueryVariables = {
  id: string,
};

export type GetMeetingQuery = {
  getMeeting?:  {
    __typename: "Meeting",
    createdAt: string,
    end?: string | null,
    id: string,
    participants?:  {
      __typename: "ModelMeetingParticipantConnection",
      nextToken?: string | null,
    } | null,
    start: string,
    updatedAt: string,
  } | null,
};

export type GetMeetingRoomAttendanceQueryVariables = {
  id: string,
};

export type GetMeetingRoomAttendanceQuery = {
  getMeetingRoomAttendance?:  {
    __typename: "MeetingRoomAttendance",
    chimeMeetingId?: string | null,
    createdAt: string,
    id?: string | null,
    updatedAt: string,
  } | null,
};

export type GetMessageQueryVariables = {
  id: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "Message",
    _deleted?: boolean | null,
    _lastChangedAt?: number | null,
    _version?: number | null,
    author?:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    } | null,
    authorId: string,
    content: string,
    conversation?:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    } | null,
    conversationId: string,
    createdAt: string,
    id: string,
    isSent?: boolean | null,
    members?: Array< string | null > | null,
    sotName?: string | null,
    updatedAt: string,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    actionParam?: string | null,
    actionType?: string | null,
    content: string,
    createdAt: string,
    displayGroup?: NotificationDisplayGroup | null,
    id: string,
    read?: boolean | null,
    type: NotificationType,
    updatedAt: string,
    userId: string,
    userIdDisplayGroup?: string | null,
  } | null,
};

export type GetRaisedHandQueryVariables = {
  id: string,
};

export type GetRaisedHandQuery = {
  getRaisedHand?:  {
    __typename: "RaisedHand",
    chimeMeetingId?: string | null,
    createdAt: string,
    id?: string | null,
    updatedAt: string,
  } | null,
};

export type GetUnreadCounterQueryVariables = {
  id: string,
};

export type GetUnreadCounterQuery = {
  getUnreadCounter?:  {
    __typename: "UnreadCounter",
    contacts?: number | null,
    conversations?: number | null,
    createdAt: string,
    id: string,
    notifications?: number | null,
    requests?: number | null,
    schedules?: number | null,
    updatedAt: string,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    conversations?:  {
      __typename: "ModelUserConversationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    id: string,
    incomingMeetings?:  {
      __typename: "ModelMeetingParticipantConnection",
      nextToken?: string | null,
    } | null,
    lastConnected: string,
    name?: string | null,
    outgoingMeetings?:  {
      __typename: "ModelMeetingParticipantConnection",
      nextToken?: string | null,
    } | null,
    pictureUrl?: string | null,
    presenceStatus: string,
    updatedAt: string,
  } | null,
};

export type GetUserActionQueryVariables = {
  id: string,
};

export type GetUserActionQuery = {
  getUserAction?:  {
    __typename: "UserAction",
    actionType: UserActionType,
    countryCode?: string | null,
    createdAt: string,
    duration?: number | null,
    endTime?: string | null,
    id: string,
    param: string,
    sotName: string,
    source?: string | null,
    startTime: string,
    topic: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userType?: string | null,
  } | null,
};

export type GetUserConversationQueryVariables = {
  id: string,
};

export type GetUserConversationQuery = {
  getUserConversation?:  {
    __typename: "UserConversation",
    conversation:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    },
    conversationId: string,
    createdAt: string,
    id: string,
    isChatOpen?: boolean | null,
    isMuted?: boolean | null,
    lastReadMessageCreatedAt?: string | null,
    mostRecentMessageCreatedAt?: string | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
  } | null,
};

export type GetUserSessionQueryVariables = {
  id: string,
};

export type GetUserSessionQuery = {
  getUserSession?:  {
    __typename: "UserSession",
    countryCode?: string | null,
    createdAt: string,
    id: string,
    location: string,
    locationLevel1: string,
    locationLevel2?: string | null,
    locationLevel3?: string | null,
    queryHelper: string,
    sotName: string,
    source?: string | null,
    time?: string | null,
    topic: string,
    ttl?: number | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userType?: string | null,
  } | null,
};

export type ListCurrentLocationCountssQueryVariables = {
  filter?: ModelCurrentLocationCountsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCurrentLocationCountssQuery = {
  listCurrentLocationCountss?:  {
    __typename: "ModelCurrentLocationCountsConnection",
    items:  Array< {
      __typename: "CurrentLocationCounts",
      cnt: number,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMeetingRoomAttendancesQueryVariables = {
  filter?: ModelMeetingRoomAttendanceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingRoomAttendancesQuery = {
  listMeetingRoomAttendances?:  {
    __typename: "ModelMeetingRoomAttendanceConnection",
    items?:  Array< {
      __typename: "MeetingRoomAttendance",
      chimeMeetingId?: string | null,
      createdAt: string,
      id?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListMeetingsQueryVariables = {
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsQuery = {
  listMeetings?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      createdAt: string,
      end?: string | null,
      id: string,
      start: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      actionParam?: string | null,
      actionType?: string | null,
      content: string,
      createdAt: string,
      displayGroup?: NotificationDisplayGroup | null,
      id: string,
      read?: boolean | null,
      type: NotificationType,
      updatedAt: string,
      userId: string,
      userIdDisplayGroup?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListRaisedHandsQueryVariables = {
  filter?: ModelRaisedHandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRaisedHandsQuery = {
  listRaisedHands?:  {
    __typename: "ModelRaisedHandConnection",
    items:  Array< {
      __typename: "RaisedHand",
      chimeMeetingId?: string | null,
      createdAt: string,
      id?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserActionsQueryVariables = {
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserActionsQuery = {
  listUserActions?:  {
    __typename: "ModelUserActionConnection",
    items:  Array< {
      __typename: "UserAction",
      actionType: UserActionType,
      countryCode?: string | null,
      createdAt: string,
      duration?: number | null,
      endTime?: string | null,
      id: string,
      param: string,
      sotName: string,
      source?: string | null,
      startTime: string,
      topic: string,
      updatedAt: string,
      userId: string,
      userType?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserSessionsQueryVariables = {
  filter?: ModelUserSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserSessionsQuery = {
  listUserSessions?:  {
    __typename: "ModelUserSessionConnection",
    items:  Array< {
      __typename: "UserSession",
      countryCode?: string | null,
      createdAt: string,
      id: string,
      location: string,
      locationLevel1: string,
      locationLevel2?: string | null,
      locationLevel3?: string | null,
      queryHelper: string,
      sotName: string,
      source?: string | null,
      time?: string | null,
      topic: string,
      ttl?: number | null,
      updatedAt: string,
      userId: string,
      userType?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MessagesByConversationIdQueryVariables = {
  conversationId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type MessagesByConversationIdQuery = {
  messagesByConversationId?:  {
    __typename: "ModelMessageConnection",
    items?:  Array< {
      __typename: "Message",
      _deleted?: boolean | null,
      _lastChangedAt?: number | null,
      _version?: number | null,
      authorId: string,
      content: string,
      conversationId: string,
      createdAt: string,
      id: string,
      isSent?: boolean | null,
      members?: Array< string | null > | null,
      sotName?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type NotificationsByUserIdQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  userId?: string | null,
};

export type NotificationsByUserIdQuery = {
  notificationsByUserId?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      actionParam?: string | null,
      actionType?: string | null,
      content: string,
      createdAt: string,
      displayGroup?: NotificationDisplayGroup | null,
      id: string,
      read?: boolean | null,
      type: NotificationType,
      updatedAt: string,
      userId: string,
      userIdDisplayGroup?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByUserIdAndDisplayGroupSortedQueryVariables = {
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  userIdDisplayGroup?: string | null,
};

export type NotificationsByUserIdAndDisplayGroupSortedQuery = {
  notificationsByUserIdAndDisplayGroupSorted?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      actionParam?: string | null,
      actionType?: string | null,
      content: string,
      createdAt: string,
      displayGroup?: NotificationDisplayGroup | null,
      id: string,
      read?: boolean | null,
      type: NotificationType,
      updatedAt: string,
      userId: string,
      userIdDisplayGroup?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SyncMessagesQueryVariables = {
  conversationId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelMessageFilterInput | null,
  lastSync?: number | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type SyncMessagesQuery = {
  syncMessages?:  {
    __typename: "ModelMessageConnection",
    items?:  Array< {
      __typename: "Message",
      _deleted?: boolean | null,
      _lastChangedAt?: number | null,
      _version?: number | null,
      authorId: string,
      content: string,
      conversationId: string,
      createdAt: string,
      id: string,
      isSent?: boolean | null,
      members?: Array< string | null > | null,
      sotName?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type UserActionsByRoomQueryVariables = {
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  param?: string | null,
  sortDirection?: ModelSortDirection | null,
  startTime?: ModelStringKeyConditionInput | null,
};

export type UserActionsByRoomQuery = {
  userActionsByRoom?:  {
    __typename: "ModelUserActionConnection",
    items:  Array< {
      __typename: "UserAction",
      actionType: UserActionType,
      countryCode?: string | null,
      createdAt: string,
      duration?: number | null,
      endTime?: string | null,
      id: string,
      param: string,
      sotName: string,
      source?: string | null,
      startTime: string,
      topic: string,
      updatedAt: string,
      userId: string,
      userType?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserBusyTimesQueryVariables = {
  end: string,
  limit?: number | null,
  nextToken?: string | null,
  start: string,
  userId: string,
};

export type UserBusyTimesQuery = {
  userBusyTimes?:  {
    __typename: "UserBusyTimes",
    items?:  Array< {
      __typename: "UserBusyTime",
      end: string,
      start: string,
      userId: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type UserConversationsByUserQueryVariables = {
  filter?: ModelUserConversationFilterInput | null,
  lastReadMessageCreatedAt?: ModelStringKeyConditionInput | null,
  limit?: number | null,
  mostRecentMessageCreatedAt?: ModelStringKeyConditionInput | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  userId: string,
};

export type UserConversationsByUserQuery = {
  userConversationsByUser?:  {
    __typename: "ModelUserConversationConnection",
    items?:  Array< {
      __typename: "UserConversation",
      conversationId: string,
      createdAt: string,
      id: string,
      isChatOpen?: boolean | null,
      isMuted?: boolean | null,
      lastReadMessageCreatedAt?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      updatedAt: string,
      userId: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type UserConversationsByUserAndConversationQueryVariables = {
  conversationId?: ModelIDKeyConditionInput | null,
  filter?: ModelUserConversationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  userId: string,
};

export type UserConversationsByUserAndConversationQuery = {
  userConversationsByUserAndConversation?:  {
    __typename: "ModelUserConversationConnection",
    items?:  Array< {
      __typename: "UserConversation",
      conversationId: string,
      createdAt: string,
      id: string,
      isChatOpen?: boolean | null,
      isMuted?: boolean | null,
      lastReadMessageCreatedAt?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      updatedAt: string,
      userId: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type UserSessionsByLocationQueryVariables = {
  filter?: ModelUserSessionFilterInput | null,
  limit?: number | null,
  locationLevel2?: ModelStringKeyConditionInput | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  sotName?: string | null,
};

export type UserSessionsByLocationQuery = {
  userSessionsByLocation?:  {
    __typename: "ModelUserSessionConnection",
    items:  Array< {
      __typename: "UserSession",
      countryCode?: string | null,
      createdAt: string,
      id: string,
      location: string,
      locationLevel1: string,
      locationLevel2?: string | null,
      locationLevel3?: string | null,
      queryHelper: string,
      sotName: string,
      source?: string | null,
      time?: string | null,
      topic: string,
      ttl?: number | null,
      updatedAt: string,
      userId: string,
      userType?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type _emptySubscriptionSubscription = {
  _emptySubscription?: string | null,
};

export type OnCalendarEntryParticipationCreatedSubscriptionVariables = {
  userId: string,
};

export type OnCalendarEntryParticipationCreatedSubscription = {
  onCalendarEntryParticipationCreated?:  {
    __typename: "CalendarEntryParticipation",
    calendarEntry?:  {
      __typename: "CalendarEntry",
      createdAt: string,
      description?: string | null,
      end: string,
      id: string,
      isVirtual?: boolean | null,
      locationActionParam?: string | null,
      locationActionType?: string | null,
      locationName?: string | null,
      organizationId?: string | null,
      organizationIdStatus?: string | null,
      start: string,
      status?: CalendarEntryParticipationStatus | null,
      title: string,
      topicName: string,
      updatedAt: string,
      userId: string,
      userPoolName: string,
    } | null,
    calendarEntryId: string,
    createdAt: string,
    id: string,
    start: string,
    status: CalendarEntryParticipationStatus,
    statusUpdateMessage?: string | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userIdStatus: string,
  } | null,
};

export type OnCalendarEntryParticipationDeletedSubscriptionVariables = {
  userId: string,
};

export type OnCalendarEntryParticipationDeletedSubscription = {
  onCalendarEntryParticipationDeleted?:  {
    __typename: "CalendarEntryParticipation",
    calendarEntry?:  {
      __typename: "CalendarEntry",
      createdAt: string,
      description?: string | null,
      end: string,
      id: string,
      isVirtual?: boolean | null,
      locationActionParam?: string | null,
      locationActionType?: string | null,
      locationName?: string | null,
      organizationId?: string | null,
      organizationIdStatus?: string | null,
      start: string,
      status?: CalendarEntryParticipationStatus | null,
      title: string,
      topicName: string,
      updatedAt: string,
      userId: string,
      userPoolName: string,
    } | null,
    calendarEntryId: string,
    createdAt: string,
    id: string,
    start: string,
    status: CalendarEntryParticipationStatus,
    statusUpdateMessage?: string | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
    userIdStatus: string,
  } | null,
};

export type OnConversationUpdatedSubscriptionVariables = {
  id: string,
};

export type OnConversationUpdatedSubscription = {
  onConversationUpdated?:  {
    __typename: "Conversation",
    createdAt: string,
    description?: string | null,
    id: string,
    memberIdsHash?: string | null,
    members?:  {
      __typename: "ModelUserConversationConnection",
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    mostRecentMessage?: string | null,
    mostRecentMessageCreatedAt?: string | null,
    name?: string | null,
    type?: ConversationType | null,
    updatedAt?: string | null,
    userId?: string | null,
  } | null,
};

export type OnCreateMessageSubscriptionVariables = {
  conversationId: string,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    _deleted?: boolean | null,
    _lastChangedAt?: number | null,
    _version?: number | null,
    author?:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    } | null,
    authorId: string,
    content: string,
    conversation?:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    } | null,
    conversationId: string,
    createdAt: string,
    id: string,
    isSent?: boolean | null,
    members?: Array< string | null > | null,
    sotName?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteMessageSubscriptionVariables = {
  conversationId: string,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?:  {
    __typename: "Message",
    _deleted?: boolean | null,
    _lastChangedAt?: number | null,
    _version?: number | null,
    author?:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    } | null,
    authorId: string,
    content: string,
    conversation?:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    } | null,
    conversationId: string,
    createdAt: string,
    id: string,
    isSent?: boolean | null,
    members?: Array< string | null > | null,
    sotName?: string | null,
    updatedAt: string,
  } | null,
};

export type OnMeetingInviteChangedSubscriptionVariables = {
  id: string,
};

export type OnMeetingInviteChangedSubscription = {
  onMeetingInviteChanged?:  {
    __typename: "MeetingParticipant",
    created: string,
    createdAt: string,
    id: string,
    invitationType?: InvitationType | null,
    invitee:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    inviteeId: string,
    inviter:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    inviterId: string,
    meeting:  {
      __typename: "Meeting",
      createdAt: string,
      end?: string | null,
      id: string,
      start: string,
      updatedAt: string,
    },
    meetingId: string,
    meetingTitle?: string | null,
    status: InviteStatus,
    updatedAt: string,
  } | null,
};

export type OnMeetingInviteReceivedSubscriptionVariables = {
  inviteeId: string,
};

export type OnMeetingInviteReceivedSubscription = {
  onMeetingInviteReceived?:  {
    __typename: "MeetingParticipant",
    created: string,
    createdAt: string,
    id: string,
    invitationType?: InvitationType | null,
    invitee:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    inviteeId: string,
    inviter:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    inviterId: string,
    meeting:  {
      __typename: "Meeting",
      createdAt: string,
      end?: string | null,
      id: string,
      start: string,
      updatedAt: string,
    },
    meetingId: string,
    meetingTitle?: string | null,
    status: InviteStatus,
    updatedAt: string,
  } | null,
};

export type OnMeetingRoomAttendanceCreatedSubscriptionVariables = {
  chimeMeetingId: string,
};

export type OnMeetingRoomAttendanceCreatedSubscription = {
  onMeetingRoomAttendanceCreated?:  {
    __typename: "MeetingRoomAttendance",
    chimeMeetingId?: string | null,
    createdAt: string,
    id?: string | null,
    updatedAt: string,
  } | null,
};

export type OnMeetingRoomAttendanceDeletedSubscriptionVariables = {
  chimeMeetingId: string,
};

export type OnMeetingRoomAttendanceDeletedSubscription = {
  onMeetingRoomAttendanceDeleted?:  {
    __typename: "MeetingRoomAttendance",
    chimeMeetingId?: string | null,
    createdAt: string,
    id?: string | null,
    updatedAt: string,
  } | null,
};

export type OnMessageCreatedSubscriptionVariables = {
  conversationId: string,
};

export type OnMessageCreatedSubscription = {
  onMessageCreated?:  {
    __typename: "Message",
    _deleted?: boolean | null,
    _lastChangedAt?: number | null,
    _version?: number | null,
    author?:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    } | null,
    authorId: string,
    content: string,
    conversation?:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    } | null,
    conversationId: string,
    createdAt: string,
    id: string,
    isSent?: boolean | null,
    members?: Array< string | null > | null,
    sotName?: string | null,
    updatedAt: string,
  } | null,
};

export type OnNotificationCreatedSubscriptionVariables = {
  userId: string,
};

export type OnNotificationCreatedSubscription = {
  onNotificationCreated?:  {
    __typename: "Notification",
    actionParam?: string | null,
    actionType?: string | null,
    content: string,
    createdAt: string,
    displayGroup?: NotificationDisplayGroup | null,
    id: string,
    read?: boolean | null,
    type: NotificationType,
    updatedAt: string,
    userId: string,
    userIdDisplayGroup?: string | null,
  } | null,
};

export type OnRaisedHandCreatedSubscriptionVariables = {
  chimeMeetingId: string,
};

export type OnRaisedHandCreatedSubscription = {
  onRaisedHandCreated?:  {
    __typename: "RaisedHand",
    chimeMeetingId?: string | null,
    createdAt: string,
    id?: string | null,
    updatedAt: string,
  } | null,
};

export type OnRaisedHandDeletedSubscriptionVariables = {
  chimeMeetingId: string,
};

export type OnRaisedHandDeletedSubscription = {
  onRaisedHandDeleted?:  {
    __typename: "RaisedHand",
    chimeMeetingId?: string | null,
    createdAt: string,
    id?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateMessageSubscriptionVariables = {
  conversationId: string,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?:  {
    __typename: "Message",
    _deleted?: boolean | null,
    _lastChangedAt?: number | null,
    _version?: number | null,
    author?:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    } | null,
    authorId: string,
    content: string,
    conversation?:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    } | null,
    conversationId: string,
    createdAt: string,
    id: string,
    isSent?: boolean | null,
    members?: Array< string | null > | null,
    sotName?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateUnreadCounterByIdSubscriptionVariables = {
  id: string,
};

export type OnUpdateUnreadCounterByIdSubscription = {
  onUpdateUnreadCounterById?:  {
    __typename: "UnreadCounter",
    contacts?: number | null,
    conversations?: number | null,
    createdAt: string,
    id: string,
    notifications?: number | null,
    requests?: number | null,
    schedules?: number | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserByIdSubscriptionVariables = {
  id: string,
};

export type OnUpdateUserByIdSubscription = {
  onUpdateUserById?:  {
    __typename: "User",
    conversations?:  {
      __typename: "ModelUserConversationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    id: string,
    incomingMeetings?:  {
      __typename: "ModelMeetingParticipantConnection",
      nextToken?: string | null,
    } | null,
    lastConnected: string,
    name?: string | null,
    outgoingMeetings?:  {
      __typename: "ModelMeetingParticipantConnection",
      nextToken?: string | null,
    } | null,
    pictureUrl?: string | null,
    presenceStatus: string,
    updatedAt: string,
  } | null,
};

export type OnUserConversationCreatedByConversationSubscriptionVariables = {
  conversationId: string,
};

export type OnUserConversationCreatedByConversationSubscription = {
  onUserConversationCreatedByConversation?:  {
    __typename: "UserConversation",
    conversation:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    },
    conversationId: string,
    createdAt: string,
    id: string,
    isChatOpen?: boolean | null,
    isMuted?: boolean | null,
    lastReadMessageCreatedAt?: string | null,
    mostRecentMessageCreatedAt?: string | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
  } | null,
};

export type OnUserConversationDeletedSubscriptionVariables = {
  userId: string,
};

export type OnUserConversationDeletedSubscription = {
  onUserConversationDeleted?:  {
    __typename: "UserConversation",
    conversation:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    },
    conversationId: string,
    createdAt: string,
    id: string,
    isChatOpen?: boolean | null,
    isMuted?: boolean | null,
    lastReadMessageCreatedAt?: string | null,
    mostRecentMessageCreatedAt?: string | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
  } | null,
};

export type OnUserConversationDeletedByConversationSubscriptionVariables = {
  conversationId: string,
};

export type OnUserConversationDeletedByConversationSubscription = {
  onUserConversationDeletedByConversation?:  {
    __typename: "UserConversation",
    conversation:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    },
    conversationId: string,
    createdAt: string,
    id: string,
    isChatOpen?: boolean | null,
    isMuted?: boolean | null,
    lastReadMessageCreatedAt?: string | null,
    mostRecentMessageCreatedAt?: string | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
  } | null,
};

export type OnUserConversationUpdatedSubscriptionVariables = {
  userId: string,
};

export type OnUserConversationUpdatedSubscription = {
  onUserConversationUpdated?:  {
    __typename: "UserConversation",
    conversation:  {
      __typename: "Conversation",
      createdAt: string,
      description?: string | null,
      id: string,
      memberIdsHash?: string | null,
      mostRecentMessage?: string | null,
      mostRecentMessageCreatedAt?: string | null,
      name?: string | null,
      type?: ConversationType | null,
      updatedAt?: string | null,
      userId?: string | null,
    },
    conversationId: string,
    createdAt: string,
    id: string,
    isChatOpen?: boolean | null,
    isMuted?: boolean | null,
    lastReadMessageCreatedAt?: string | null,
    mostRecentMessageCreatedAt?: string | null,
    updatedAt: string,
    user:  {
      __typename: "User",
      createdAt: string,
      id: string,
      lastConnected: string,
      name?: string | null,
      pictureUrl?: string | null,
      presenceStatus: string,
      updatedAt: string,
    },
    userId: string,
  } | null,
};
