import styled from "styled-components"
import branding from "../../../branding/branding"

export const BottomMenuContainer = styled.div<{ showShadow?: boolean }>`
    position: fixed;
    padding-top: 15px;
    padding-left: 10px;
    bottom: 0px;
    display: flex;
    align-items: flex-end;
    transition: width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    z-index: 100;
    box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowBTT : "initial")};
    background-color: ${(props) => (window.location.href.includes("/meeting/") ? branding.mainInfoColor : "#fff")};

    > .open-link {
        display: flex;
        text-align: center;
        padding-bottom: 5px;
        transition: display 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }

    > .closed-link {
        display: none;
        padding-bottom: 5px;
        transition: display 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }

    > .open-language {
        width: 30px;
        text-align: center;
    }

    & #ot-sdk-btn.ot-sdk-show-settings,
    #ot-sdk-btn.optanon-show-settings {
        font-family: ${branding.font1};
        background: transparent !important;
        border: none !important;
        color: inherit !important;
        padding: 0;
        font-size: inherit;
        line-height: 18px;
    }

    & #ot-sdk-btn.ot-sdk-show-settings:hover,
    #ot-sdk-btn.optanon-show-settings:hover {
        background: transparent !important;
        color: inherit !important;
        text-decoration: underline;
    }
`
