import { createState, State, useState } from "@hookstate/core"
import { API } from "aws-amplify"
import { v4 as uuidv4 } from "uuid"
import { InviteStatus } from "../../API"
import { MeetingKind } from "../../backendServices/MeetingServices"
import {
    buildGraphqlArgs,
    ensureChatConversation,
    ensureMeeting,
    getMeetingHistoryEntries,
    inviteToMeeting,
    MeetingParticipant,
    PresenceType,
    updateMeetingInvite
} from "../../backendServices/GraphQLServices"
import branding from "../../branding/branding"
import { defaultLogger as logger, useAppState } from "../../globalStates/AppState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { onMeetingInviteChangedLight } from "../../graphql/ownSubscriptions"
import { onMeetingInviteReceived } from "../../graphql/subscriptions"
import { accessPresenceState } from "../../ui/PresenceIndicator"
import { getExternalMeetingId, useChimeContext } from "./ChimeContext"

const CALL_TIMEOUT = 60000

interface StateValues {
    outgoingInvites: MeetingParticipant[]
    incomingInvites: MeetingParticipant[]
    participationHistory: MeetingParticipant[]
}

const getStartValues = (): StateValues => {
    return {
        outgoingInvites: [],
        incomingInvites: [],
        participationHistory: []
    }
}

export interface MeetingContext {
    subscribeToCalls: (id: string) => Promise<void>
    sendInvite: (hailedId: string, meetingData?: { meetingId: string; meetingKind: MeetingKind }) => Promise<void>
    acceptInvite: (id: string) => Promise<void>
    declineInvite: (id: string) => void
    cancelInvite: (id: string) => void
    getOutgoingInvites: () => MeetingParticipant[]
    getIncomingInvites: () => MeetingParticipant[]
    getParticipationHistory: () => MeetingParticipant[]
    getCurrentMeetingParam: () =>
        | {
              meetingId: string
              meetingKind: MeetingKind
          }
        | undefined
}

let activeCalls: string[] = []
let handleOutgoingInviteTimer: number
let incomingCallSubscription: any

const useWrapState = (meeting: State<StateValues>) => {
    const chime = useChimeContext()
    const loggedInUser = useLoggedInState()
    const appState = useAppState()

    const addToHistoryFromInvite = (invite: MeetingParticipant) => {
        state.participationHistory.set((prevState) => {
            prevState.push(invite)
            return prevState
        })
    }

    function removeUserFromActiveCall(userId: string) {
        const index = activeCalls.indexOf(userId)
        if (index > -1) activeCalls.splice(index, 1)
    }

    return {
        subscribeToCalls: async (id: string) => {
            // Subscribe to incoming calls
            // TODO use correct type
            if (incomingCallSubscription) incomingCallSubscription.unsubscribe()
            incomingCallSubscription = (
                API.graphql(buildGraphqlArgs(onMeetingInviteReceived, { inviteeId: id })) as any
            ).subscribe({
                next: (resp: any) => {
                    const meetingInvite = resp.value.data.onMeetingInviteReceived
                    if (meetingInvite.inviter === id || accessPresenceState.getMyPresence() === PresenceType.DONOTDISTURB) return
                    activeCalls.push(meetingInvite.inviter)
                    meeting.incomingInvites.set((prevState) => {
                        prevState.push(meetingInvite)
                        return prevState
                    })
                    // Subscribe to incoming call changes, to react to cancelation
                    // TODO use correct type
                    const onInviteChangedListener = (
                        API.graphql(buildGraphqlArgs(onMeetingInviteChangedLight, { id: meetingInvite.id })) as any
                    ).subscribe({
                        next: (resp: any) => {
                            const meetingInvitee: MeetingParticipant = resp.value.data.onMeetingInviteChanged
                            switch (meetingInvitee.status) {
                                case InviteStatus.ACCEPTED:
                                    if (window.sessionStorage.getItem("currentTabCall")) {
                                        chime.createOrJoinMeeting(meetingInvitee.meeting.id)
                                    }
                                    break
                                case InviteStatus.CANCELED || InviteStatus.TIMEOUT:
                                    window.sessionStorage.removeItem("currentTabCall")
                                    setTimeout(() => {
                                        appState.setMissedCallNotification(
                                            true,
                                            meetingInvitee.inviter.id,
                                            meetingInvitee.inviter.name
                                        )
                                    }, 2000)

                                    break
                                default:
                                    window.sessionStorage.removeItem("currentTabCall")
                                    break
                            }
                            // Remove call from current call list
                            meeting.set((prevState) => {
                                let idToRemove = 0
                                for (; idToRemove < prevState.incomingInvites.length; idToRemove++) {
                                    if (prevState.incomingInvites[idToRemove].id === meetingInvitee.id) {
                                        break
                                    }
                                }
                                prevState.incomingInvites.splice(idToRemove, 1)
                                return prevState
                            })
                            removeUserFromActiveCall(meetingInvitee.id)
                            onInviteChangedListener.unsubscribe()
                            addToHistoryFromInvite(meetingInvitee)
                        }
                    })
                }
            })
            const invites = await getMeetingHistoryEntries(id)
            if (invites) {
                state.participationHistory.set(invites)
            }
        },
        sendInvite: async (hailedId: string, meetingData?: { meetingId: string; meetingKind: MeetingKind }) => {
            if (!hailedId) return
            let alreadyHailing = false
            for (const otherId of activeCalls) {
                if (otherId === hailedId) {
                    alreadyHailing = true
                    break
                }
            }
            if (alreadyHailing) return
            activeCalls.push(hailedId)

            const ensureMeetingPromise = async (externalMeetingId: string) => {
                const result = await ensureMeeting(externalMeetingId)
                if (!result) {
                    removeUserFromActiveCall(hailedId)
                    throw new Error("failure while starting call")
                }
            }

            const ensureConversationPromise = async (externalMeetingId: string) => {
                const participantLimit =
                    meetingData?.meetingKind === "calenderEntry" ? branding.configuration.calendarEntryParticipantLimit : 10
                const chatConversationAndHailedParticipationExists = await ensureChatConversation(
                    externalMeetingId,
                    loggedInUser.user()!.profileId,
                    hailedId,
                    participantLimit
                )
                if (!chatConversationAndHailedParticipationExists) {
                    removeUserFromActiveCall(hailedId)
                    throw new Error("failure while creating chat conversation (call might be full)")
                }
            }

            const externalMeetingId: string = meetingData
                ? getExternalMeetingId(meetingData.meetingId, meetingData.meetingKind)
                : getExternalMeetingId(uuidv4(), "call")
            const ensures =
                !meetingData || meetingData.meetingKind === "call"
                    ? [ensureMeetingPromise(externalMeetingId), ensureConversationPromise(externalMeetingId)]
                    : [ensureMeetingPromise(externalMeetingId)]
            await Promise.all(ensures)

            const outgoingInvite = await inviteToMeeting(loggedInUser.user()!.profileId, hailedId, externalMeetingId)
            if (outgoingInvite) {
                meeting.set((prevState) => {
                    prevState.outgoingInvites.push(outgoingInvite)
                    return prevState
                })

                // Listen for updates on our invite
                // TODO use correct type
                const onInviteChangedListener = (
                    API.graphql(buildGraphqlArgs(onMeetingInviteChangedLight, { id: outgoingInvite.id })) as any
                ).subscribe({
                    next: (resp: any) => {
                        const call: MeetingParticipant = resp.value.data.onMeetingInviteChanged
                        switch (call.status) {
                            // We accepted the invite
                            case InviteStatus.ACCEPTED:
                                chime.createOrJoinMeeting(outgoingInvite!.meeting.id)
                                break
                            // Timeout or other party canceled the invite
                            default:
                                break
                        }
                        meeting.set((prevState) => {
                            let idToRemove = 0
                            for (; idToRemove < prevState.outgoingInvites.length; idToRemove++) {
                                if (prevState.outgoingInvites[idToRemove].id === outgoingInvite.id) {
                                    break
                                }
                            }
                            prevState.outgoingInvites.splice(idToRemove, 1)
                            return prevState
                        })
                        removeUserFromActiveCall(outgoingInvite.invitee.id)
                        // Either we get an affirmative reaction or a negative, in either case we won't need to listen any longer to this invite
                        // => Unsubscribe and clear timeoutimer if set
                        if (handleOutgoingInviteTimer) clearTimeout(handleOutgoingInviteTimer)
                        onInviteChangedListener.unsubscribe()
                        addToHistoryFromInvite(outgoingInvite)
                    }
                })
                if (handleOutgoingInviteTimer) clearTimeout(handleOutgoingInviteTimer)
                handleOutgoingInviteTimer = window.setTimeout(() => {
                    updateMeetingInvite(outgoingInvite.id, InviteStatus.TIMEOUT)
                }, CALL_TIMEOUT)
            } else {
                removeUserFromActiveCall(hailedId)
                logger.error("failure while starting call")
            }
        },
        acceptInvite: async (id: string) => {
            updateMeetingInvite(id, InviteStatus.ACCEPTED)
        },
        declineInvite: (id: string) => {
            updateMeetingInvite(id, InviteStatus.DECLINED)
        },
        cancelInvite: (id: string) => {
            updateMeetingInvite(id, InviteStatus.CANCELED)
        },
        getOutgoingInvites: () => {
            return meeting.value.outgoingInvites
        },
        getIncomingInvites: () => {
            return meeting.value.incomingInvites
        },
        getParticipationHistory: () => {
            return meeting.value.participationHistory
        },
        getCurrentMeetingParam: () => {
            return chime.getName() ? { meetingId: chime.getName(), meetingKind: chime.getKind() } : undefined
        }
    }
}

const state = createState<StateValues>(getStartValues())
export const useMeetingContext = (): MeetingContext => useWrapState(useState(state))
