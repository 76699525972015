import * as React from "react"
import { useState, useEffect, useRef } from "react"
import { Category, Entity, News } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { getNewsDateFormatted } from "../detailPages/components/DetailPageSections"
import { DetailNavLink } from "../detailPages/DetailNavLink"
import { useLanguageState } from "../../globalStates/LanguageState"
import { MobileVersionContainer } from "../../utils/Device"
import BadgeArea from "../../ui/BadgeArea"
import BookmarkWithToggle from "../../ui/BookmarkWithToggle"
import { SectionHeaderTitle } from "../../ui/CompaniesTilesLayout"
import { IconNewsLink } from "../../ui/Icons"
import {
    LinkIcon,
    Image,
    TopSectionRoot,
    NewsSlider,
    GeneralSectionRoot,
    HeaderContainer,
    NewsDate,
    NewsTitle,
    NewsTeaser,
    TileRoot,
    TopBookmarkContainer,
    TopDateTitleContainer,
    Prio1Container,
    Prio2Container,
    MostReadSectionTitle,
    MostReadSectionRoot,
    MostReadItemRoot,
    MostReadItemLogoRoot,
    MostReadItemLogo,
    MostReadItemContent,
    MostReadSectionTileWrapper,
    NewsLinkIconContainer,
    SourceTagRoot,
    SourceTagTriangle,
    SourceTagTextContainer,
    SourceTagText,
    AdBanner,
    AdSpiritAdBannerWrapper,
    MobileAdBanner,
    SliderAndBannerSection,
    TopNewsContentWrapper,
    StyledNewsTopItem
} from "./NewsPageSections.styled"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { Col, Row } from "react-bootstrap"
import { timeAgo } from "../../utils/DateUtils"
import TileRow from "../../ui/TileRow"
import { trackNewsAction } from "../../backendServices/TrackingServices"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { sectionOrder, Sections, SectionType } from "../../utils/searchUtils"
import { trackSelectContent, trackViewSearchResult } from "../../utils/GTMTracking"

export function getNewsSections(sections: Sections, searchKrit?: string): News[][] {
    let newsSections: News[][] = [[], []]

    sectionOrder.forEach((sectionType: SectionType) => {
        switch (sectionType) {
            case SectionType.TOP: {
                const section = sections[sectionType]
                if (section) {
                    section.entities.forEach((entity: Entity) => {
                        const newsItem: News = entity as News

                        //Prio 1 group of the Top section always shows one piece of news at a time.
                        //This would always be the newest piece of news with the TOTL value of 0.
                        //All other news of this Prio group are sorted normally in the general section below.
                        if (newsItem.totl === 0 && newsSections[0].findIndex((n: News) => n.totl === 0) === -1) {
                            newsSections[0].push(newsItem)
                        }

                        //Prio 2 group of the Top section always shows four news at a time.
                        //This would always be the four newest pieces of news with the TOTL value greater than 0.
                        //All other news of this Prio group are sorted normally in the general section below.}
                        else if (newsItem.totl > 0 && newsSections[0].filter((n: News) => n.totl > 0).length < 4) {
                            newsSections[0].push(newsItem)
                        }
                    })
                }
                break
            }

            case SectionType.ALL: {
                const section = sections[sectionType]
                if (section) {
                    const entitiesList = section.entities

                    entitiesList.forEach((entity: Entity) => {
                        const newsItem: News = entity as News

                        if (newsSections[0].findIndex((n: News) => n.id === newsItem.id) > -1) {
                            //we don't display the news that are already shown in the Top section
                            return
                        }

                        newsSections[1].push(newsItem)
                    })
                }
                break
            }
        }
    })

    return newsSections
}

type NewsPageSectionType = "top" | "all" | "mostRead"

interface NewsPageSectionsProps {
    sections: Sections
    searchKrit?: string
    showOnlyBookmarks: boolean
    mostReadNewsList: News[]
    mostReadSectionVisible: boolean
    sourceTags: any[]
}

const NewsPageSections: React.FunctionComponent<NewsPageSectionsProps> = React.memo((props: NewsPageSectionsProps) => {
    const windowSize = useWindowDimensions()
    const { useMobileDesign } = useWindowDimensions()
    const [newsSections, setNewsSections] = useState<News[][]>([])
    const [visibleSections, setVisibleSections] = useState<NewsPageSectionType[]>([])

    const showMostReadSection = React.useCallback((): boolean => {
        //we don't want to show Most Read section when a category filter is selected, or "Show only bookmarks" toggle is enabled
        return (
            props.mostReadSectionVisible &&
            props.mostReadNewsList.length > 0 &&
            !props.showOnlyBookmarks &&
            (props.searchKrit === undefined || (props.searchKrit !== undefined && props.searchKrit?.length! === 0))
        )
    }, [props.mostReadSectionVisible, props.mostReadNewsList.length, props.showOnlyBookmarks, props.searchKrit])

    const getGeneralSectionCol = (): number => {
        return !showMostReadSection() || useMobileDesign ? 12 : windowSize.width > 3400 ? 10 : 9
    }

    const getMostReadSectionCol = (): number => {
        return windowSize.width > 3400 ? 2 : 3
    }

    useEffect(() => {
        setNewsSections(getNewsSections(props.sections, props.searchKrit))
        // eslint-disable-next-line
    }, [props.sections])

    useEffect(() => {
        const newsSectionTypesTemp: NewsPageSectionType[] = []

        if (props.sections[SectionType.ALL]) {
            newsSectionTypesTemp.push("all")
        }

        if (props.sections[SectionType.TOP] && !props.searchKrit) {
            newsSectionTypesTemp.push("top")
        }

        if (showMostReadSection()) {
            newsSectionTypesTemp.push("mostRead")
        }
        setVisibleSections(newsSectionTypesTemp)
    }, [props.sections, props.mostReadNewsList, props.showOnlyBookmarks, props.searchKrit, showMostReadSection])

    return (
        <>
            {newsSections.map((section: News[], sectionIndex: number) => {
                switch (sectionIndex) {
                    case 0:
                        return props.searchKrit ? null : (
                            <TopSection newsList={section} searchKrit={props.searchKrit} sourceTags={props.sourceTags} />
                        )
                    case 1:
                        return (
                            <>
                                {showMostReadSection() && (
                                    <MobileVersionContainer>
                                        <MostReadSection
                                            newsList={props.mostReadNewsList}
                                            searchKrit={props.searchKrit}
                                            sourceTags={props.sourceTags}
                                        />
                                    </MobileVersionContainer>
                                )}
                                <Row style={{ marginRight: "20px" }}>
                                    <Col xs={getGeneralSectionCol()} style={{ paddingRight: "0px" }}>
                                        <GeneralSection
                                            newsList={section}
                                            searchKrit={props.searchKrit}
                                            className={showMostReadSection() ? "" : "mostReadSectionNotVisible"}
                                            sourceTags={props.sourceTags}
                                            visibleSections={visibleSections}
                                        />
                                    </Col>
                                    {showMostReadSection() && !useMobileDesign && (
                                        <Col xs={getMostReadSectionCol()}>
                                            <MostReadSection
                                                newsList={props.mostReadNewsList}
                                                searchKrit={props.searchKrit}
                                                sourceTags={props.sourceTags}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </>
                        )
                    default:
                        return null
                }
            })}
        </>
    )
})

export default NewsPageSections

interface NewsSectionProps {
    newsList: News[]
    searchKrit?: string
    className?: string
    sourceTags: any[]
    visibleSections?: NewsPageSectionType[]
}

export const TopSection: React.FunctionComponent<NewsSectionProps> = (props: NewsSectionProps) => {
    const { useMobileDesign } = useWindowDimensions()

    const [prio1Item, setPrio1Item] = useState<News>()
    const [prio2Items, setPrio2Items] = useState<News[]>([])

    const adBannerRef = React.useRef<HTMLModElement>(null)

    useEffect(() => {
        setPrio1Item(props.newsList.find((n: News) => n.totl === 0))
        setPrio2Items(props.newsList.filter((n: News) => n.totl > 0).slice(0, 4))
        // eslint-disable-next-line
    }, [props.newsList])

    const getNewsForMainSlider = (): News[] => {
        //in mobile view all news from the top section are put into the slider, without affecting the order
        let newsForMainSlider: News[] = []

        if (prio1Item) newsForMainSlider.push(prio1Item)

        prio2Items.forEach((item: News) => {
            newsForMainSlider.push(item)
        })

        return newsForMainSlider
    }

    const renderSlides = () => {
        return getNewsForMainSlider().map((item: News) => (
            <NewsTile key={item.id} newsItem={item} searchKrit={props.searchKrit} prio="top" sourceTags={props.sourceTags} />
        ))
    }

    return (
        <>
            <TopSectionRoot className={props.className ?? ""}>
                {useMobileDesign ? (
                    <>
                        {getNewsForMainSlider().length > 0 && (
                            <SliderAndBannerSection>
                                <>
                                    <NewsSlider
                                        dots={true}
                                        slidesToShow={1}
                                        slidesToScroll={1}
                                        autoplay={getNewsForMainSlider().length > 1}
                                        autoplaySpeed={branding.newsPageContent.topSectionSliderAutoplayValue ?? 10000}
                                    >
                                        {renderSlides()}
                                    </NewsSlider>
                                </>
                                <AdSpiritAdBannerWrapper>
                                    <MobileAdBanner
                                        data-asm-cdn={branding.newsPageContent.adSpiritAdBanner.cdnLink}
                                        data-asm-host={branding.newsPageContent.adSpiritAdBanner.hostLink}
                                        data-asm-params={`pid=${branding.newsPageContent.adSpiritAdBanner.mobilePID}&gdpr_consent=${branding.newsPageContent.adSpiritAdBanner.gdprConsent}`}
                                        className={branding.newsPageContent.adSpiritAdBanner.className}
                                        ref={adBannerRef}
                                    />
                                </AdSpiritAdBannerWrapper>
                            </SliderAndBannerSection>
                        )}
                    </>
                ) : (
                    <>
                        {prio1Item && <div> asd </div> && (
                            <Prio1Container>
                                <NewsTile
                                    key={prio1Item.id}
                                    newsItem={prio1Item}
                                    searchKrit={props.searchKrit}
                                    prio="top"
                                    sourceTags={props.sourceTags}
                                />
                            </Prio1Container>
                        )}
                        {prio2Items.length > 0 && (
                            <Prio2Container>
                                {prio2Items.map((item: News) => {
                                    return (
                                        <NewsTile
                                            key={item.id}
                                            newsItem={item}
                                            searchKrit={props.searchKrit}
                                            prio="mid"
                                            sourceTags={props.sourceTags}
                                        />
                                    )
                                })}
                            </Prio2Container>
                        )}
                    </>
                )}
            </TopSectionRoot>
        </>
    )
}

export const GeneralSection: React.FunctionComponent<NewsSectionProps> = (props: NewsSectionProps) => {
    const adBannerRef = React.useRef<HTMLModElement>(null)

    const title = branding.exhibitorsPageContent.sectionHeaderAllExhibitors
        .split("{$count}")
        .join(props.newsList.length.toString())

    useEffect(() => {
        if (branding.newsPageContent.adSpiritAdBanner.useAdBannerScript && adBannerRef.current) {
            const adBannerScript: HTMLScriptElement = document.createElement("script")
            adBannerScript.async = true
            adBannerScript.src = branding.newsPageContent.adSpiritAdBanner.scriptSrc
            adBannerScript.type = "text/javascript"
            adBannerScript.dataset.cdn = branding.newsPageContent.adSpiritAdBanner.cdnLink
            adBannerScript.dataset.host = branding.newsPageContent.adSpiritAdBanner.hostLink

            const head: HTMLHeadElement = document.getElementsByTagName("head")[0]
            head.appendChild(adBannerScript)
        }
    }, [adBannerRef.current]) //eslint-disable-line

    return (
        <>
            {props.searchKrit && props.searchKrit?.length > 0 && (
                <MobileVersionContainer>
                    <SectionHeaderTitle style={{ margin: "10px 0px 15px 20px" }}>{title}</SectionHeaderTitle>
                </MobileVersionContainer>
            )}
            {branding.newsPageContent.adSpiritAdBanner.useAdBannerScript && (
                <AdSpiritAdBannerWrapper>
                    <AdBanner
                        data-asm-cdn={branding.newsPageContent.adSpiritAdBanner.cdnLink}
                        data-asm-host={branding.newsPageContent.adSpiritAdBanner.hostLink}
                        data-asm-params={`pid=${branding.newsPageContent.adSpiritAdBanner.desktopPID}&gdpr_consent=${branding.newsPageContent.adSpiritAdBanner.gdprConsent}`}
                        className={branding.newsPageContent.adSpiritAdBanner.className}
                        ref={adBannerRef}
                    />
                </AdSpiritAdBannerWrapper>
            )}
            <GeneralSectionRoot id="generalSection" className={props.className ?? ""}>
                {props.newsList.map((item: News) => {
                    return <NewsTile key={item.id} newsItem={item} searchKrit={props.searchKrit} sourceTags={props.sourceTags} />
                })}
            </GeneralSectionRoot>
        </>
    )
}

export const MostReadSection: React.FunctionComponent<NewsSectionProps> = (props: NewsSectionProps) => {
    const { useMobileDesign } = useWindowDimensions()

    const [height, setHeight] = useState<string>("auto")

    useEffect(() => {
        var contentElement = document.getElementById("generalSection")

        if (contentElement) {
            setHeight(contentElement?.offsetHeight?.toString() + "px")
        }
        // eslint-disable-next-line
    }, [document.getElementById("generalSection")])

    return (
        <MostReadSectionRoot style={{ height: useMobileDesign ? "auto" : height }}>
            <MostReadSectionTitle>{branding.newsPageContent.mostReadSectionTitle}</MostReadSectionTitle>
            {useMobileDesign ? (
                <TileRow
                    iconVisible={false}
                    title=""
                    titleVisible={false}
                    navLinkTextVisible={false}
                    hideShadowsAndScrollButtons={false}
                    offsetLeft={0}
                    navLinkText=""
                    scrollComponent={false}
                    childWidth={135 + 10}
                    childCount={props.newsList.length}
                    customMargin="-45px 0px 0 0px"
                >
                    <MostReadSectionTileWrapper count={props.newsList.length}>
                        {props.newsList.map((item: News) => (
                            <div style={{ width: 135, height: "auto" }} key={item.id}>
                                <NewsTile newsItem={item} mostRead />
                            </div>
                        ))}
                    </MostReadSectionTileWrapper>
                </TileRow>
            ) : (
                <>
                    {props.newsList.map((item: News, index: number) => {
                        return (
                            <NewsTile key={item.id} newsItem={item} mostRead lastInList={index === props.newsList.length - 1} />
                        )
                    })}
                </>
            )}
        </MostReadSectionRoot>
    )
}

interface NewsTileProps {
    newsItem: News
    searchKrit?: string
    prio?: string
    mostRead?: boolean
    lastInList?: boolean
    sourceTags?: any[]
}

const NewsTile: React.FunctionComponent<NewsTileProps> = (props: NewsTileProps) => {
    const windowSize = useWindowDimensions()
    const { useMobileDesign } = useWindowDimensions()
    const userState = useLoggedInState()
    const profileId = userState.user()?.profileId!
    const lang = useLanguageState().getLanguage()
    const [bookmarkVisible, setBookmarkVisible] = useState<boolean>(false)
    const sourceTagCatIds: string[] = branding.newsPageContent.sourceTagConfiguration.categorySearchConfig.catIds

    const targetRef = useRef(null)

    let categories: Category[] = props.newsItem.categories || []

    if (sourceTagCatIds && sourceTagCatIds?.length > 0) {
        categories = categories.sort(function (a, b) {
            return sourceTagCatIds?.indexOf(a.id) - sourceTagCatIds.indexOf(b.id)
        })
    }

    const sourceTag: any = categories.find((category: Category) =>
        props.sourceTags?.find((tag: any) => tag.alias === category.id)
    )

    const getNewsDate = (): string | undefined => {
        return getNewsDateFormatted(props.newsItem.date, lang, branding.eventTiming.eventDaysFormatPatternNewsList)
    }

    const getMostReadLogoCol = (): number => {
        return useMobileDesign ? 12 : windowSize.width > 2600 ? 2 : 3
    }

    const getMostReadContentCol = (): number => {
        return windowSize.width > 2600 ? 10 : 9
    }

    const callback = (entries: any) => {
        entries.forEach((entry: any) => {
            if (entry.isIntersecting) {
                trackViewSearchResult(
                    props.newsItem.id,
                    props.newsItem.name,
                    "News",
                    "News",
                    props.searchKrit?.split("##")?.filter(Boolean).join(",")
                )
            }
        })
    }

    useEffect(() => {
        const targetElement = targetRef.current

        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0
        }

        const observer = new IntersectionObserver(callback, options)

        if (targetElement) {
            observer.observe(targetElement)
        }

        return () => {
            if (targetElement) {
                observer.unobserve(targetElement)
            }
        }
    }, []) // eslint-disable-line

    let content: JSX.Element = props.prio ? (
        <TopNewsContentWrapper>
            <Image src={props.newsItem.logoUrl ?? "/branding/applicationMedia/no-news-image.png"} className={props.prio ?? ""}>
                <HeaderContainer className={props.prio ?? ""}>
                    <SourceTagRoot>{sourceTag && <SourceTag categoryName={sourceTag.name} />}</SourceTagRoot>
                    <TopBookmarkContainer>
                        {true && (
                            <BookmarkWithToggle
                                newBookmarkItem
                                fontSize="20px"
                                color="#fff"
                                favIconBasic={true}
                                type="news"
                                id={props.newsItem.id}
                                name={props.newsItem.name as string}
                            />
                        )}
                    </TopBookmarkContainer>
                </HeaderContainer>

                <TopDateTitleContainer>
                    <StyledNewsTopItem>
                        {props.newsItem.date && <NewsDate className={props.prio ?? ""}>{getNewsDate()}</NewsDate>}
                        {props.newsItem.externalUrl && (
                            <NewsLinkIconContainer>
                                {IconNewsLink({
                                    fill: "#fff",
                                    height: "13",
                                    width: "13"
                                })}
                            </NewsLinkIconContainer>
                        )}
                    </StyledNewsTopItem>
                    <NewsTitle className={props.prio ?? ""}>{props.newsItem.name}</NewsTitle>
                    {branding.newsPageContent.categoryBadgesVisible &&
                        props.newsItem.categories &&
                        props.newsItem.categories?.length! > 0 && (
                            <BadgeArea
                                maxBadgeCount={branding.exhibitorsPageContent.exhibitorTilesMaxBadgeCount}
                                categories={props.newsItem.categories}
                                marginTop="15px"
                                marginBottom="0"
                                fontSize={branding.categoryBadgesShowfloorTextSize}
                            />
                        )}
                </TopDateTitleContainer>
            </Image>
        </TopNewsContentWrapper>
    ) : (
        <>
            <SourceTagRoot className="general">{sourceTag && <SourceTag categoryName={sourceTag.name} />}</SourceTagRoot>
            <Image
                src={props.newsItem.logoUrl ?? "/branding/applicationMedia/no-news-image.png"}
                className={"general " + (bookmarkVisible && "hover")}
            >
                <LinkIcon>
                    {bookmarkVisible && (
                        <BookmarkWithToggle
                            newBookmarkItem
                            fontSize="15px"
                            color="#fff"
                            favIconBasic={true}
                            type="news"
                            id={props.newsItem.id}
                            name={props.newsItem.name as string}
                        />
                    )}
                </LinkIcon>
            </Image>
            <HeaderContainer>
                <NewsDate>{props.newsItem.date && getNewsDate()}</NewsDate>

                {props.newsItem.externalUrl &&
                    IconNewsLink({
                        fill: branding.newsPageContent.newsTilesLayoutDateTimeColor,
                        height: "15",
                        width: "15"
                    })}
            </HeaderContainer>
            <NewsTitle>{props.newsItem.name}</NewsTitle>
            <NewsTeaser>{props.newsItem.descriptionTeaser}</NewsTeaser>
        </>
    )

    if (props.mostRead) {
        content = (
            <MostReadItemRoot className={props.lastInList && "lastInList"}>
                <Col xs={getMostReadLogoCol()} style={{ paddingLeft: "0px" }}>
                    <MostReadItemLogoRoot>
                        <MostReadItemLogo src={props.newsItem.logoUrl ?? "/branding/applicationMedia/no-news-image.png"} alt="" />
                    </MostReadItemLogoRoot>
                    <MobileVersionContainer>
                        <BadgeArea categories={props.newsItem.categories || []} simpleLayout />
                        <NewsTitle className="mostRead">{props.newsItem.name}</NewsTitle>
                    </MobileVersionContainer>
                </Col>
                {!useMobileDesign && (
                    <Col xs={getMostReadContentCol()} style={{ paddingLeft: "0px" }}>
                        <MostReadItemContent>
                            <BadgeArea categories={props.newsItem.categories || []} simpleLayout />
                            <NewsTitle className="mostRead">{props.newsItem.name}</NewsTitle>
                            {props.newsItem.date && <NewsDate>{timeAgo(lang, new Date(props.newsItem.date))}</NewsDate>}
                        </MostReadItemContent>
                    </Col>
                )}
            </MostReadItemRoot>
        )
        return (
            <div
                onClick={() => {
                    trackNewsAction(profileId, "news", props.newsItem.id)

                    trackSelectContent(props.newsItem.name, "Details", "News", props.prio ? "Top section" : "General section")
                }}
            >
                {props.newsItem.externalUrl ? (
                    <a href={props.newsItem.externalUrl} target="_blank" rel="noopener noreferrer">
                        {content}
                    </a>
                ) : (
                    <DetailNavLink
                        id={props.newsItem.id}
                        type="news"
                        name={props.newsItem.name}
                        source="NEWSLIST"
                        searchKrit={props.searchKrit}
                    >
                        {content}
                    </DetailNavLink>
                )}
            </div>
        )
    }

    return (
        <TileRoot
            ref={targetRef}
            onMouseEnter={() => {
                setBookmarkVisible(true)
            }}
            onMouseLeave={() => {
                setBookmarkVisible(false)
            }}
            onClick={() => {
                trackNewsAction(profileId, "news", props.newsItem.id)

                trackSelectContent(props.newsItem.name, "Details", "News", "Most read section")
            }}
            className={props.prio ?? "general"}
        >
            {props.newsItem.externalUrl ? (
                <a href={props.newsItem.externalUrl} target="_blank" rel="noopener noreferrer">
                    {content}
                </a>
            ) : (
                <DetailNavLink
                    id={props.newsItem.id}
                    type="news"
                    name={props.newsItem.name}
                    source="NEWSLIST"
                    searchKrit={props.searchKrit}
                >
                    {content}
                </DetailNavLink>
            )}
        </TileRoot>
    )
}

interface SourceTagProps {
    categoryName: string
}

const SourceTag: React.FunctionComponent<SourceTagProps> = (props: SourceTagProps) => {
    return (
        <>
            <SourceTagTriangle />
            <SourceTagTextContainer>
                <SourceTagText>{props.categoryName}</SourceTagText>
                <SourceTagText className="double">{props.categoryName}</SourceTagText>
            </SourceTagTextContainer>
        </>
    )
}
